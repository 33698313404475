import { FC } from 'react'
import { TypeModuleTableDataCellProps } from 'Type'


const ModuleTableDataTableCellDisplayPercentage :FC<TypeModuleTableDataCellProps> = ({ value } :TypeModuleTableDataCellProps) => {
    if (!value) return null
    const valueStr :string = `${value}`
    const decimals :number = Math.max((valueStr.includes('.')) ? valueStr.split('.')[1].length - 2 : 0, 0)
    return <>{ Number(value).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: decimals}) }</>
}

export default ModuleTableDataTableCellDisplayPercentage