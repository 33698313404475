import { ListGroup } from 'eui'
import { ModalSession } from 'modules'
import { useState } from 'react'
import { Nav } from 'types'

type LoginTypes = 'user' | 'api'

type Props = {
    onSuccess: (response: Nav) => void
}

const Options = ({onSuccess} :Props) => {

    const [login, setLogin] = useState<LoginTypes | undefined>()

    const closeModal = () => setLogin(undefined)

    const onSuccessIntercept = (nav :Nav) => {
        closeModal()
        onSuccess(nav)
    }

    const listItems = [
        {
            label: 'User',
            iconType: 'user',
            size: 's',
            onClick: () => {setLogin('user')}
        },
        {
            label: 'API',
            iconType: 'compute',
            size: 's',
            onClick: () => {setLogin('api')}
        }
    ]

    return <>
        <ListGroup listItems={listItems}/>
        {(login) ? <ModalSession type={login} onSuccess={onSuccessIntercept} onClose={closeModal}/> : null}
    </>
}

export default Options