import { HookModuleTableDataProps, HookNavContext, HookNavPage } from 'Hook'
import { ModulePage, ModuleTableHeaderActions } from 'Module'
import { PageTableBody, PageTableSidebar } from 'Page'
import { TypeEntityRow, TypeEntityTable, TypeEntityTableVersion, TypeHttpLoadable,
    TypeModuleTableDataProps, TypeNav, TypeNavHttpControl } from 'Type'

const PageTable = () => {

    //page
    const pageControl :TypeNavHttpControl<TypeEntityRow> = HookNavPage<TypeEntityRow>()
    const pageNav :TypeNav<TypeEntityRow> | undefined = pageControl.res?.data

    //context
    const table :TypeEntityTable | undefined = HookNavContext('table', pageNav)
    const version :TypeEntityTableVersion | undefined = HookNavContext('version', pageNav)

    //props
    const tableProps :TypeModuleTableDataProps = HookModuleTableDataProps(pageControl, 'version')

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Data', control: pageControl},
        {label: 'Columns', control: tableProps.columnControl}
    ]

    const sidebar = version && table && <PageTableSidebar pageControl={pageControl} table={table} version={version}/>
    const body = <PageTableBody {...tableProps}/>
    const headerActions = <ModuleTableHeaderActions fieldNameMap={tableProps.fieldNameMap}/>

    return <ModulePage control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={pageControl} socketControl={tableProps.updatesControl} rightActions={headerActions}>{body}</ModulePage>
}

export default PageTable