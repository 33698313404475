import { AuditDisplay } from 'display';
import IsAdminDisplay from 'display/parts/IsAdminDisplay';
import { MembershipEntity } from 'entity';
import { DisplayConfig } from 'types';

let config :DisplayConfig<MembershipEntity>[] = [
    {
        field: 'memberName',
        name: 'Member Name'
    },
    {
        field: 'memberGlobalId',
        name: 'Member Global Id'
    },
    ...IsAdminDisplay,
    ...AuditDisplay
]

export default config