import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'globalId',
        name: 'Global Id',
        sortable: true
    },
]

export default config