import React from 'react'
import {EuiAvatar} from '@elastic/eui'
import { TypeEuiPropsIconType } from 'Type'

type PropsIcon = {
    iconType :TypeEuiPropsIconType
    iconSize? :'s' | 'm' | 'l' | 'xl' | 'original' | 'xxl'
    iconColor? :string
    name :string
    color? :string
    type? :'space' | 'user'
    size? :'s' | 'm' | 'l' | 'xl'
    isDisabled? :boolean
}

const ComponentEuiAvatar = ({ iconType, iconSize, iconColor, name, type, size = 'm', isDisabled }: PropsIcon) =>
    <EuiAvatar iconType={iconType} iconSize={iconSize} iconColor={iconColor} name={name}
    type={type} size={size} isDisabled={isDisabled}/>


export default ComponentEuiAvatar