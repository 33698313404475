import moment from 'moment'

type DisplayProps = 'date' | 'date-time' | 'time' | 'date-time-z'

type Props = {
    value :any
    display :DisplayProps
}

const formats :Record<DisplayProps, string> = {
    'date' : 'LLL',
    'date-time' : 'LLL',
    'time' : 'LTS',
    'date-time-z' : 'LLL',
}

const ComponentMoment = ({ value, display } :Props) => {
    if (!value) return null
    return <>{moment(value).format(formats[display])}</>
}

export default ComponentMoment