import { HookNavGet } from 'Hook'
import {TypeEntitySearchResult, TypeNav, TypeNavHttpControl, TypeUrlParam} from 'Type'

const NULL_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: false}
const ERROR_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: true}

const HookNavFormFieldSearch = (nav :TypeNav<any>, field :string, value :string) :[TypeEntitySearchResult[], boolean] => {
    const path :string = nav.path

    const searchParam :TypeUrlParam = {
        type: 'formSearch-*',
        dynamic: [field],
        values: [value]
    }

    const control :TypeNavHttpControl<TypeEntitySearchResult> = HookNavGet<TypeEntitySearchResult>({linkTo: path, paramUpdates: [searchParam]})

    const error :TypeEntitySearchResult[] | undefined = (control.res?.error) ? [ERROR_FIELD] : undefined

    const options :TypeEntitySearchResult[] = [NULL_FIELD, ...(control.res?.data?.data.page?.content ?? [])]

    return [error || options, control.isLoading]
}

export default HookNavFormFieldSearch