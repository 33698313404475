import { EuiTabs } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
    expand? :boolean
    bottomBorder? :boolean
    size? : 's' | 'm' | 'l' | 'xl'
}

const Tabs = ({expand, bottomBorder, size, children} :Props) =>
    <EuiTabs expand={expand} bottomBorder={bottomBorder} size={size}>{ children }</EuiTabs>

export default Tabs