import { HookNavGetAll, HookNavResource } from 'Hook'
import {TypeNav, TypeNavDataPageRequestSortDirection, TypeNavHttpControl, TypeNavKey } from 'Type'

type Props = {
    key :TypeNavKey,
    nav? :TypeNav<any>,
    sortDirection? :TypeNavDataPageRequestSortDirection
}

const HookNavResourceNavAll = <T>({ key, nav, sortDirection } :Props) :TypeNavHttpControl<T> => {
    return HookNavGetAll<T>(HookNavResource(key, nav), sortDirection)
}

export default HookNavResourceNavAll