
type Props = {
    title :string
}

const GreyHeader = ({title} :Props) => {
    const style :any = {
        backgroundColor: '#EDF0F5',
        fontWeight: 600,
        padding: '8px 16px',
        borderBottom: '1px solid #D3DAE6',
        borderTop: '1px solid #D3DAE6',
    }
    return <div style={style}>{title}</div>
}

export default GreyHeader