import { useNavigate } from "react-router-dom"
import {PageTitle} from "../../components"
import {ResponderEntity} from "../../entity"
import {FlexGroup, FlexItem, PageSection, Spacer} from "../../eui"
import {NavStore} from "../../store"
import {Nav, NavKey} from "../../types"
import {
    GenericActionButtons,
    NavDataEntity,
    ResponderPageContentMap,
    ResponderPageContentTest,
    ResponderPageContentUrl
} from "../index"

type Props = {
    tab? :NavKey
}

const responderKey :NavKey = 'responder'

const ResponderPageContent = ({tab} :Props) => {

    const navigate = useNavigate()

    const resourceNav :Nav | undefined = NavStore(store => store.contextNavs[responderKey])
    const resourceEntity :ResponderEntity | undefined = NavStore(store => store.nav?.context[responderKey])

    if (!resourceEntity) return null

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {resourceEntity.name}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                            <GenericActionButtons nav={resourceNav} contextKey={'responder'} toggleIcon={'gear'} buttonSize={'s'} onSuccess={() => {navigate(resourceEntity.ownerLinkTo)}}/>
                        </FlexItem>
                    </FlexGroup>
                    <FlexGroup>
                        <FlexItem>
                            <Spacer size={'s'}/>
                            <p style={{color: '#69707D'}}>{resourceEntity.description}</p>
                            <Spacer/>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>

                </FlexItem>
            </FlexGroup>
        </PageSection>
        <PageSection color={'plain'} alignment={'top'} paddingSize={'none'}>
            {!tab && <NavDataEntity configKey={'ws'} ignoreFields={['name', 'description', 'ownerGlobalId']}/>}
            {tab === 'url' && <ResponderPageContentUrl/>}
            {tab === 'test' && <ResponderPageContentTest/>}
            {tab === 'map' && <ResponderPageContentMap/>}
        </PageSection>
    </>
}

export default ResponderPageContent