import { FieldText, FormRow, FieldPassword, FieldSwitch, FieldSelect, FieldNumber, FieldDateTimeZ, FieldDateTime, FieldDate, FieldFile, FieldTime, FieldYearmonth, FieldTextArea, FieldPhoneNumber, FieldPercentage } from 'eui'
import { Field } from 'props'
import {FieldOnChange, FieldSearch, FieldSelectOption, Nav, NavControlActionFormField } from 'types'
import { NavSearch } from 'components'

type Props = {
    nav :Nav
    field :NavControlActionFormField
    value :string | File | null
    error? :string
    onChange: FieldOnChange

}

const fieldToProps = ({nav, field, value = '', onChange} :Props) :Field => {
    let search :FieldSearch = {
        keys: field.search || [],
        url: nav.path
    }
    return {
        placeholder: field.placeholder,
        name: field.name,
        value: value,
        isInvalid: false,
        onChange: onChange,
        search: (field.type === 'search') ? search : undefined
    }
}

const integerField = (props :Field) :React.ReactElement => {
    return <FieldNumber {...props} step={1}></FieldNumber>
}

const decimalField = (props :Field) :React.ReactElement => {
    return <FieldNumber {...props} step={0.01}></FieldNumber>
}

const textField = (props :Field) :React.ReactElement => {
    return <FieldText {...props} ></FieldText>
}

const textareaField = (props :Field) :React.ReactElement => {
    return <FieldTextArea {...props} ></FieldTextArea>
}

const passwordField = (props :Field) :React.ReactElement => {
    return <FieldPassword {...props} ></FieldPassword>
}

const boolField = (props :Field) :React.ReactElement => {
    return <FieldSwitch {...props} ></FieldSwitch>
}

const selectField = (props :Field, options? :FieldSelectOption[]) :React.ReactElement => {
    return <FieldSelect {...props} options={options}></FieldSelect>
}

const searchField = (props :Field) :React.ReactElement => {
    return <NavSearch {...props}></NavSearch>
}

const datetimezField = (props :Field) :React.ReactElement => {
    return <FieldDateTimeZ {...props}></FieldDateTimeZ>
}

const datetimeField = (props :Field) :React.ReactElement => {
    return <FieldDateTime {...props}></FieldDateTime>
}

const dateField = (props :Field) :React.ReactElement => {
    return <FieldDate {...props}></FieldDate>
}

const fileField = (props :Field, accept? :string) :React.ReactElement => {
    return <FieldFile {...props} accept={accept}></FieldFile>
}

const timeField = (props :Field) :React.ReactElement => {
    return <FieldTime {...props}></FieldTime>
}

const yearmonthField = (props :Field) :React.ReactElement => {
    return <FieldYearmonth {...props}></FieldYearmonth>
}

const phoneNumberField = (props :Field) :React.ReactElement => {
    return <FieldPhoneNumber {...props}></FieldPhoneNumber>
}
const percentageField = (props :Field) :React.ReactElement => {
    return <FieldPercentage {...props}></FieldPercentage>
}


const matchToInput = (props :Props) :React.ReactElement => {
    const inputProps :Field = fieldToProps(props)
    switch (props.field.type) {
        case 'integer' : return integerField(inputProps)
        case 'decimal' : return decimalField(inputProps)
        case 'text' : return textField(inputProps)
        case 'textarea' : return textareaField(inputProps)
        case 'password': return passwordField(inputProps)
        case 'bool': return boolField(inputProps)
        case 'select': return selectField(inputProps, props.field.options)
        case 'search': return searchField(inputProps)
        case 'datetimez' : return datetimezField(inputProps)
        case 'datetime' : return datetimeField(inputProps)
        case 'date' : return dateField(inputProps)
        case 'time' : return timeField(inputProps)
        case 'yearmonth' : return yearmonthField(inputProps)
        case 'year': return integerField(inputProps)
        case 'file' : return fileField(inputProps)
        case 'excel' : return fileField(inputProps, '.xlsx')
        case 'word' : return fileField(inputProps, '.docx')
        case 'pdf' : return fileField(inputProps, '.pdf')
        case 'image' : return fileField(inputProps, '.jpeg, .png, .gif, .tiff, .psd, .pdf, .eps, .ai, .indd, .raw')
        case 'telephone_number' : return phoneNumberField(inputProps)
        case 'percentage' : return percentageField(inputProps)
    }
    return <></>
}

const NavField = (props :Props) => {
    return <FormRow label={`${props.field.title}${(!props.field.required) ? ' (optional)' : ''}`} error={(props.error) ? [props.error] : undefined} fullWidth>
        {matchToInput(props)}
    </FormRow>
}

export default NavField