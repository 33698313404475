import {Panel} from 'eui';
import {CellEntity, TableVersionColumnEntity} from 'entity';
import {CellValueRendererConfig} from 'config';
import {Nav} from 'types';
import {ButtonEmpty, FlexGroup, FlexItem, Spacer} from "../../eui";
import {NavClient} from "../../utils";
import {useState} from "react";
import {NavControlResource, NavKey} from "../../types";
import PreviewImageModal from "../PreviewImageModal/PreviewImageModal";
import {CellExtractNavigationButton} from "../index";

type Props = {
    cellNav: Nav
    column: TableVersionColumnEntity
    cellEntity: CellEntity
    rowId :number
}

const extractKey :NavKey = 'extract'

const CellContextMenuDataContent = ({cellNav, column, cellEntity, rowId} :Props) => {

    const isDownloadable: boolean = cellNav.control.downloadable

    const [preview, setPreview] = useState<boolean>(false)


    const extractResource :NavControlResource | undefined = cellNav.control.resource[extractKey]

    return <Panel>
        {CellValueRendererConfig.renderValue(cellEntity.value, column, rowId)}
        <Spacer size={'m'}/>
        <FlexGroup gutterSize={'s'} justifyContent={'spaceBetween'}>
            <FlexItem>
                {extractResource && column.type === 'excel' && <CellExtractNavigationButton extractResource={extractResource} versionNumber={cellEntity.versionNumber}/>}
                {column.type === 'image' && <ButtonEmpty size={'m'} color={'primary'} fill={false} onClick={() => {setPreview(true)}} iconType={'image'}>Preview</ButtonEmpty>}
                {column.type === 'image' && preview && <PreviewImageModal nav={cellNav} onClose={() => {setPreview(false)}}/>}
            </FlexItem>
            <FlexItem>
                {isDownloadable && <ButtonEmpty size={'m'} iconType={'download'} onClick={() => {
                    NavClient.download(cellNav.path)
                }}>Download</ButtonEmpty>}
            </FlexItem>
        </FlexGroup>
    </Panel>
}

export default CellContextMenuDataContent