import {ApiEntity, UserEntity } from 'entity'
import {Avatar, FlexGroup, FlexItem, HeaderLink, HeaderSectionItem, Popover, Text } from 'eui'
import { useState } from 'react'
import { SessionStore } from 'store'
import { Nav } from 'types'
import { GlobalId, Jwt } from 'utils'

const fullName = (entity :any | undefined) :string => {
    if (!entity) return ''
    if (entity.firstName && entity.lastName) return `${entity.firstName} ${entity.lastName}`
    if (entity.name) return entity.name
    return ''
}

const User = () => {

    let [showPopover, setShowPopover] = useState<boolean>(false)
    const togglePopover = () => setShowPopover(!showPopover)
    const closePopover = () => setShowPopover(false)

    const setToken = SessionStore(state => state.setToken)
    const clearToken = SessionStore(state => state.clearToken)
    const token = SessionStore(state => state.token)
    const entity :any | undefined = SessionStore(state => state.entity)

    if (!token || !entity) return null
    
    const globalId :string = Jwt.extractSub(token) || ''

    const onLogout = () => {
        closePopover()
        clearToken()
    }

    const name :string = fullName(entity)

    return <HeaderSectionItem key={token || ''}>
        <HeaderLink to={GlobalId.getLink(globalId)}><Avatar name={name} style={{cursor: 'pointer'}}/></HeaderLink>
    </HeaderSectionItem>
}

export default User