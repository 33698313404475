import {useState} from 'react'
import { TypeModuleTableDataContextColorControl } from 'Type'
import { UtilColor } from 'Util'

const colors = ['#ff0000', '#ff8000', '#ffff00', '#80ff00',
    '#00ff00', '#00ff80', '#00ffff', '#0080ff',
    '#0000ff', '#8000ff', '#ff00ff', '#ff0080'].sort(() => (Math.random() > .5) ? 1 : -1)

const HookModuleTableDataContextColor = () :TypeModuleTableDataContextColorControl => {
    const [map, setMap] = useState<{[key :string] : string}>({})

    const addColor = (key :string) :string => {
        if (map[key]) return map[key]
        const newMap = {...map}
        const newColor :string = UtilColor.convertHexToRGBA(colors.shift() || 'FF0080FF', 0.35)
        newMap[key] = newColor
        setMap(newMap)
        return newColor
    }

    return {map, addColor}
}

export default HookModuleTableDataContextColor