import {EuiHeaderSection} from '@elastic/eui'
import React, {PropsWithChildren} from 'react'

type Props = {
    grow?: boolean
}

const ComponentEuiHeaderSection = ({ grow, children } :PropsWithChildren<Props>) => {
    return <EuiHeaderSection grow={grow}>{children}</EuiHeaderSection>
}

export default ComponentEuiHeaderSection