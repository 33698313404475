import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiSpacer,
    ComponentNavSidebarButtonNavigate, ComponentNavSidebarContextEntity } from 'Component'
import { TypeEntityTableVersion, TypeNav, TypeNavControlResource, TypeNavHttpControl } from 'Type'

type Props = {
    pageControl :TypeNavHttpControl<TypeEntityTableVersion>
    rowResource :TypeNavControlResource
}

const PageVersionSidebar = ({ pageControl, rowResource } :Props) => {

    const nav :TypeNav<TypeEntityTableVersion> | undefined = pageControl.res?.data

    if (!nav) return null
    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem>
            <ComponentNavSidebarContextEntity title={'Owner'} navKey={'user'} nameFields={['firstName', 'lastName']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Organisation'} navKey={'org'} nameFields={['name']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Workspace'} navKey={'ws'} nameFields={['name']} nav={nav}/>
            <ComponentEuiSpacer/>
            <ComponentNavSidebarButtonNavigate buttonType={'normal'} entity={rowResource} iconType={'database'} color={'primary'} fullWidth fill>{ 'Data' }</ComponentNavSidebarButtonNavigate>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageVersionSidebar