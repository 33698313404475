import {
    ComponentDivWidth, ComponentEuiModal, ComponentEuiModalBody, ComponentEuiModalFooter, ComponentEuiModalHeader, ComponentEuiModalHeaderTitle,
    ComponentEuiSpacer, ComponentNavFormButtonCancel, ComponentNavPageFilterTable } from 'Component'
import { TypeEntityTableVersionColumn, TypeNav, TypeNavControlFilterParam } from 'Type'
import { ModuleTableColumnFilterForm } from 'Module'
import { HookNavFilterPage } from 'Hook'

type Props = {
    nav :TypeNav<any>
    column :TypeEntityTableVersionColumn
    onClose :() => void
    fieldNameMap? :{[fieldNameCamel :string] :string}
}

const ModuleTableColumnFilterModal = ({ nav, column, fieldNameMap, onClose } :Props) => {

    const { setDataFilter } :TypeNavControlFilterParam = HookNavFilterPage(column.nameCamel)

    return <ComponentEuiModal onClose={onClose}>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>
                {`Filter ${column.name}`}
            </ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody>
            <ComponentDivWidth width={400}>
                <ComponentNavPageFilterTable fieldNameCamel={column.nameCamel} fieldNameMap={fieldNameMap}/>
            </ComponentDivWidth>
            <ComponentEuiSpacer/>
            <ModuleTableColumnFilterForm nav={nav} column={column} onChange={setDataFilter}/>
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonCancel onClick={onClose} text={'Close'}/>
        </ComponentEuiModalFooter>
    </ComponentEuiModal>
}

export default ModuleTableColumnFilterModal