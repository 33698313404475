import create from 'zustand'
import {GlobalId, Jwt, NavClient } from 'utils'
import {ApiEntity, UserEntity } from 'entity'
import { Nav } from 'types'
import { GlobalSession } from 'Global'

const KEY = 'session'

type Store = {
    token :string | undefined
    entity :UserEntity | ApiEntity | undefined
    setToken: (token :string) => Promise<UserEntity | ApiEntity | undefined>
    clearToken: () => void
}

const SessionStore = create<Store>((set, get) => {

    let timeout :any;

    const getSessionEntity = async (token :string | undefined) :Promise<UserEntity | ApiEntity | undefined> => {
        if (!token) return
        const globalId :string | undefined = Jwt.extractSub(token)
        const nav :Nav = await NavClient.fetchNav(GlobalId.getLink(globalId))
        const entity :UserEntity | ApiEntity | undefined = nav.data.entity
        set({entity})
        return entity
    }

    const getToken = () :string | undefined => {
        const token :string | null = localStorage.getItem(KEY)
        if (!token) return undefined
        if (Jwt.isTokenExpired(token)) {
            localStorage.removeItem(KEY)
            return undefined
        } else {
            return token
        }
    }

    const expirationTimeout = (token :string) => {
        const jwt = Jwt.decode(token)
        if (!jwt || !jwt.exp) return
        const millis = (jwt.exp * 1000) - Date.now()
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            get().clearToken()
        }, millis)
    }

    const clearToken = () => {
        localStorage.removeItem(KEY)
        set({token: undefined, entity: undefined})
        GlobalSession().setToken(undefined)
    }

    const setToken = async (token :string) :Promise<UserEntity | ApiEntity | undefined> => {
        localStorage.setItem(KEY, token)
        expirationTimeout(token)
        set({token})
        return getSessionEntity(token)
    }

    const token :string | undefined = getToken()
    setTimeout(() => {getSessionEntity(token)}, 1)

    if (token) expirationTimeout(token)

    return {
        token: token,
        entity: undefined,
        setToken: setToken,
        clearToken: clearToken
    }
})

export default SessionStore