import create from 'zustand'

import {TypeControlSwitch } from 'Type'

const KEY = 'show-table-system-columns'

const getValue = () :boolean => {
    return (localStorage.getItem(KEY) === 'true')
}

const setValue = (value :boolean) :boolean => {
    localStorage.setItem(KEY, `${value}`)
    return getValue()
}


const turnOn = () :boolean => {
    localStorage.setItem(KEY, 'true')
    return getValue()
}

const turnOff = () :boolean => {
    localStorage.setItem(KEY, 'false')
    return getValue()
}

const toggle = () :boolean => {
    localStorage.setItem(KEY, `${!getValue()}`)
    return getValue()
}

const GlobalSystemColumn = create<TypeControlSwitch>((set, get) => ({
    value: getValue(),
    turnOn: () => {
        set({value: turnOn()})
    },
    turnOff: () => {
        set({value: turnOff()})
    },
    set: (value :boolean) => {
        set({value: setValue(value)})
    },
    toggle: () => {
        set({value: toggle()})
    }
}))

export default GlobalSystemColumn

/*     value :boolean
    set :(on :boolean) => void,
    turnOn :() => void,
    turnOff :() => void,
    toggle :() => void */