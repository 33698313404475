import {CellRendererConfig} from 'config'
import './TableVersionRowTableCell.css'

type Props = {
    renderer :CellRendererConfig
    columnNameCamel :string
    rowIndex :any
    color? :string
}

const TableVersionRowTableCell = ({renderer, columnNameCamel, rowIndex, color} :Props) => {

    const style = {
        backgroundColor:(color) ? color : 'inherit',
    }

    return <div className={'cell'} style={style}>{renderer.render(columnNameCamel, rowIndex)}</div>
}

export default TableVersionRowTableCell