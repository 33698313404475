import {EuiSwitch, EuiSwitchEvent} from '@elastic/eui'

import { TypeEuiFormFieldProps } from 'Type'


type Props = TypeEuiFormFieldProps<string> & {
    label :string
}

const ComponentEuiFieldSwitch = ({value, onChange, label} :Props) => {

    const evtFn = (e: EuiSwitchEvent) => onChange(`${e.target.checked}`)

    return <EuiSwitch
        checked={`${value}` === 'true'}
        value={value}
        label={label}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldSwitch