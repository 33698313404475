import { PropsWithChildren } from 'react'
import { EuiPageSection } from '@elastic/eui'

type Props = {
    paddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    restrictWidth? :string | number | boolean
    bottomBorder? :boolean | 'extended'
    color? :'transparent' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain'
    alignment? :'center' | 'top' | 'horizontalCenter'
    grow? :boolean
}

const ComponentEuiPageSection = ({paddingSize, restrictWidth, bottomBorder, color, alignment, grow, children} :PropsWithChildren<Props>) => {
    return <EuiPageSection paddingSize={paddingSize} restrictWidth={restrictWidth} bottomBorder={bottomBorder}
                                    color={color} alignment={alignment} grow={grow}>{children}</EuiPageSection>
}

export default ComponentEuiPageSection