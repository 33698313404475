import {ComponentEuiButton, ComponentEuiDescriptionList, ComponentEuiFlexGroup, ComponentEuiSpacer } from 'Component'
import {HookNavActionAuto, HookNavGet, HookNavResourceNav } from 'Hook'
import { ModuleVersionConnectionMenuDirection } from 'Module'
import { useEffect } from 'react'
import {TypeEntityConnection, TypeEntityInterfaceMap, TypeEntityRequester, TypeEntityResponder,
    TypeEuiPropsDescriptionListItem, TypeNav, TypeNavHttpControl, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    connControl :TypeNavHttpControl<TypeEntityConnection>
    req :TypeEntityRequester
    mapControl :TypeNavHttpControl<TypeEntityInterfaceMap>
    addMapControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMap>
}


const findByDirectionFn = (direction :'req' | 'res') => (map :TypeEntityInterfaceMap) :boolean => map.direction === direction

const ModuleVersionConnectionMenu = ({ connControl, req, mapControl, addMapControl } :Props) => {

    const connNav :TypeNav<TypeEntityConnection> | undefined = connControl.res?.data
    const connectActionControl :TypeNavHttpControlActionAuto<TypeEntityConnection> = HookNavActionAuto<TypeEntityConnection>('post', connNav)
    const disconnectActionControl :TypeNavHttpControlActionAuto<TypeEntityConnection> = HookNavActionAuto<TypeEntityConnection>('delete', connNav)
    const conn :TypeEntityConnection | undefined = connNav?.data.entity
    const responderControl :TypeNavHttpControl<TypeEntityResponder> = HookNavGet({linkTo: conn?.globalId})
    const responderNav :TypeNav<TypeEntityResponder> | undefined = responderControl.res?.data
    const responderMapControl :TypeNavHttpControl<TypeEntityInterfaceMap> = HookNavResourceNav<TypeEntityInterfaceMap>({key: 'map', nav: responderNav})
    const maps :TypeEntityInterfaceMap[] = responderMapControl.res?.data?.data.page?.content ?? []

    const reqMap :TypeEntityInterfaceMap | undefined = maps.find(findByDirectionFn('req'))
    const resMap :TypeEntityInterfaceMap | undefined = maps.find(findByDirectionFn('res'))

    const onAddMapSuccess = () => {
        mapControl.submit()
        connControl.submit()
    }

    const items :TypeEuiPropsDescriptionListItem[] = []
    if (reqMap) items.push({title: 'Request Fields', description: <ModuleVersionConnectionMenuDirection responderMap={reqMap} req={req} onSuccess={onAddMapSuccess} addMapControl={addMapControl}/>})
    if (resMap) items.push({title: 'Response Fields', description: <ModuleVersionConnectionMenuDirection responderMap={resMap} req={req} onSuccess={onAddMapSuccess} addMapControl={addMapControl}/>})

    const connect = () => connectActionControl.addToPayload({})

    const disconnect = () => disconnectActionControl.addToPayload({})

    const isConnected :boolean = Boolean(conn?.isConnected)

    useEffect(() => {
        if (connectActionControl.submittedSuccess || disconnectActionControl.submittedSuccess) connControl.submit()
    }, [connectActionControl.submittedSuccess, disconnectActionControl.submittedSuccess])

    return <>
        <ComponentEuiDescriptionList listItems={items} type={'column'} textStyle={'reverse'} />
        <ComponentEuiSpacer/>
        <ComponentEuiFlexGroup justifyContent={'flexEnd'}>
            { (isConnected) ?
                <ComponentEuiButton onClick={disconnect} iconType={'bolt'} color={'danger'} isDisabled={!disconnectActionControl.isActionable}>Disconnect</ComponentEuiButton> :
                <ComponentEuiButton onClick={connect} iconType={'bolt'} color={'success'} isDisabled={!connectActionControl.isActionable}>Connect</ComponentEuiButton>
            }
        </ComponentEuiFlexGroup>
    </>
}

export default ModuleVersionConnectionMenu