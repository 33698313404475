import {LinkToEntity, TableVersionColumnEntity } from 'entity'
import { Link } from 'eui'
import moment from 'moment'
import { ReactNode } from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import { CellType, Nav, NavDataPage } from 'types'
import {NavClient, RouteUtils } from 'utils'

type CellRenderer = {
    render: (value :string, column?: TableVersionColumnEntity, rowId? :number) => ReactNode
}

type ReferenceProps = {
    value :string
    column?: TableVersionColumnEntity
    rowId? :number
}

const ReferenceRender = ({value, column, rowId} :ReferenceProps) => {

    const navigate = useNavigate()

    if (!column || !rowId) return <>value</>

    const onClick = async () => {
        const cellLink :string = RouteUtils.columnToCellLink(column, rowId)
        const cellParentLink :string = `${cellLink}/parent`
        const nav :Nav = await NavClient.fetchNav(cellParentLink)
        const parentEntity :LinkToEntity = nav.data.entity
        navigate(parentEntity.linkTo)
    }

    return <Link onClick={onClick}>{value}</Link>
}

const renderers :Record<CellType, CellRenderer> = {
    text: {render: (value: string) => value},
    bool: {render: (value: string) => {
            return `${value}`
        }
    },
    integer: {render: (value: string) => value},
    decimal: {render: (value: string) => value},
    datetimez: {render: (value: string) => moment(value).format('LLL')},
    datetime: {render: (value: string) => value},
    date: {render: (value: string) => value},
    time: {render: (value: string) => value},
    yearmonth: {render: (value: string) => value},
    year: {render: (value: string) => value},
    file: {render: (value: string) => value},
    excel: {render: (value: string) => value},
    word: {render: (value: string) => value},
    pdf: {render: (value: string) => value},
    image: {render: (value: string) => value},
    telephone_number: {render: (value: string) => value},
    globalid: {render: (value: string) => <Link href={`/${value}`}>{value}</Link>},
    userid: {render: (value: string) => <Link href={`/${value}`}>{value}</Link>},
    big_text: {render: (value: string) => value},
    url: {render: (value: string) => (typeof value === 'string') ? <a href={(value.includes('http://') || value.includes('https://')) ? value : `https://${value}`} target={'_blank'}>{value}</a> : undefined},
    email: {render: (value: string) => value},
    currency: {render: (value: string) => value},
    coordinate: {render: (value: string) => value},
    percentage: {render: (value: string) => {
        if (!value) return ''
        const valueStr :string = `${value}`
        const decimals :number = Math.max((valueStr.includes('.')) ? valueStr.split('.')[1].length - 2 : 0, 0)
        return (value) ? Number(value).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: decimals}) : ''
        }
    },
    reference: {render: (value: string, column?: TableVersionColumnEntity, rowId? :number) => <ReferenceRender value={value} column={column} rowId={rowId}/>},
}

export default {
    renderValue: (value :string, column: TableVersionColumnEntity, rowId :number) :ReactNode => {
        const renderer :CellRenderer | undefined = renderers[column.type]
        return renderer && renderer.render(value, column, rowId)
    }
}