import { ApiPage } from 'pages';
import { FinderNode } from 'types';
import Workspace from '../Workspace/Workspace';

const ApiProfile :FinderNode = {
    render: () => <ApiPage/>,
    next: {
        'ws' : {
            render: () => <ApiPage tab={'ws'}/>,
            next: {'*' : Workspace}
        },
    }
}

const Api :FinderNode = {
    next: {'*' : ApiProfile}
}

export default Api;