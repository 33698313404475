class Env {

    readonly httpUrl :string
    readonly wsUrl :string

    constructor() {
        this.httpUrl = (process.env.REACT_APP_HTTP_URL || '').replace(/\/$/, '')
        this.wsUrl = (process.env.REACT_APP_WS_URL || '').replace(/\/$/, '')
        console.log(`${this.httpUrl} ${this.wsUrl}`)
    }
}


export default new Env()

