import {Page, PageBody } from 'eui'
import {HomePageFeatureSection, HomePageFooterSection, HomePageHeroSection } from 'modules'

const HomePage = () => {
    return <Page>
        <PageBody>
            <HomePageHeroSection/>
            <HomePageFeatureSection/>
            <HomePageFooterSection/>
        </PageBody>
    </Page>
}

export default HomePage