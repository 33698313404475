import React, {ChangeEvent, ReactElement, useState} from 'react'
import CountryList, { Country } from 'country-list-with-dial-code-and-flag'
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber'
import {EuiFieldText} from '@elastic/eui'
import { Field } from 'props'
import {ButtonEmpty, ListGroup, Popover } from 'eui'

const util :PhoneNumberUtil = PhoneNumberUtil.getInstance()

const DialCodePopover = (setDialCode :(key :string | undefined) => void, dialCode? :string) :ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const countryCodes = CountryList.getAll().map(k => {
        return {
            label: `${k.flag} ${k.name} (${k.dial_code})`,
            size: 's',
            onClick: () => {
                setIsOpen(false)
                setDialCode(k.dial_code)
            }
        }
    })

    countryCodes.unshift({
        label: '',
        size: 's',
        onClick: () => {
            setIsOpen(false)
            setDialCode(undefined)
        }
    })

    return <Popover
        className={'euiFormControlLayout__prepend'}
        button={<ButtonEmpty size='xs' iconType='arrowDown' iconSide='right' onClick={() => {setIsOpen(true)}}> {dialCode}
        </ButtonEmpty>}
        closePopover={() => {setIsOpen(false)}} isOpen={isOpen} panelPaddingSize={'s'}>
        <div style={{maxHeight: 600, overflow: 'auto', width: 400}}>
            <ListGroup listItems={countryCodes}/>
        </div>
    </Popover>
}

/*type Integer = {
    dialCode?: string
    nationalNumber: string
}

const getCountryFromDialCode = (dialCode :string | undefined) :Country | undefined => {
    return (dialCode) ? CountryList.findOneByDialCode(dialCode) : undefined
}*/

const containsDialCode = (value :string | undefined) :boolean => {
    return (value && value.length > 0 && value.charAt(0) === '+') as boolean
}

/*const getNumber = (value :string | undefined, dialCode? :string) :Integer => {
    const country :Country | undefined = getCountryFromDialCode(dialCode)
    if (!country || !containsDialCode(value)) return {nationalNumber: value || ''}
    const number :PhoneNumber = util.parse(value, (country && country.code) || '')
    return {
        nationalNumber: (number.getNationalNumber() || '') as string,
        dialCode: (number.getCountryCode()) ? `+${number.getCountryCode()}` : undefined
    }
}

const updateDialCode = (number :Integer, dialCode: string | undefined) :Integer => {
    return {...number, dialCode: dialCode}
}

const toInternational = (number :Integer) :string => {
    console.log(number)
    const country :Country | undefined = getCountryFromDialCode(number.dialCode)
    console.log(country)
    if (country) {
        const phoneNumber :PhoneNumber = util.parseAndKeepRawInput(number.nationalNumber, country.code);
        return util.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
    }
    return number.nationalNumber
}*/

const getDialCode = (value :string) :string | undefined => {
    if (!containsDialCode(value)) return undefined
    const split :string[] = value.split(' ')
    return (split.length > 0) ? split[0] : undefined
}

const getNationalNumber = (value :string) :string => {
    if (!containsDialCode(value)) return value || ''
    const split :string[] = value.split(' ')
    return (split.length > 1) ? split[1] : value
}

const concatInternationalNumber = (dialCode :string | undefined, nationalNumber :string | undefined) :string => {
    return ((dialCode) ? `${dialCode} ` : '') + nationalNumber || ''
}

const validCharacters :string = '0123456789+()-. '

const validateCharacters = (value :string) :string => {
    let str = ''
    for (let i = 0; i < value.length; i++) {
        let c = value.charAt(i)
        if (validCharacters.includes(c)) str = str + c
    }
    return str
}

const FieldPhoneNumber = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : ''

    const dialCode :string | undefined = getDialCode(valueStr)
    const nationalNumber :string = getNationalNumber(valueStr)

    const fn = (e: ChangeEvent<HTMLInputElement>) => {
        const newNumber :string = concatInternationalNumber(dialCode, e.target.value)
        onChange(name, validateCharacters(newNumber))
    }

    const onDialCodeChange = (dialCode :string | undefined) => {
        onChange(name, concatInternationalNumber(dialCode, nationalNumber))
    }

    return <EuiFieldText placeholder={placeholder}
                         name={name}
                         value={getNationalNumber(valueStr)}
                         isInvalid={isInvalid}
                         fullWidth={fullWidth}
                         onChange={fn}
                         prepend={DialCodePopover(onDialCodeChange, dialCode)}

    />
}

export default FieldPhoneNumber