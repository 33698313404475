import { AuditDisplay } from 'display';
import DescriptionDisplay from 'display/parts/DescriptionDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import { TableVersionColumnEntity } from 'entity';
import { DisplayConfig } from 'types';

let config :DisplayConfig<TableVersionColumnEntity>[] = [
    ...NameDisplay,
    ...DescriptionDisplay,
    {field: 'orderIndex', name: 'Order'},
    {field: 'type', name: 'Type'},
    {field: 'isRequired', name: 'Is Required', render: (item :any) => (item) ? 'Yes' : 'No'},
    {field: 'isUnique', name: 'Is Unique', render: (item :any) => (item) ? 'Yes' : 'No'},
    ...AuditDisplay
]

export default config