import { ComponentEuiButtonIcon, ComponentEuiFlexItem } from 'Component'
import { HookNavDownload } from 'Hook'
import {TypeNav, TypeNavControlDownload } from 'Type'

type Props = {
    nav :TypeNav<any>
}

const ComponentNavActionDownloadButton = ({ nav } :Props) => {
    const { isDownloadable, isLoading, download } :TypeNavControlDownload =  HookNavDownload(nav)
    return (isDownloadable) ? <ComponentEuiFlexItem><ComponentEuiButtonIcon color={'text'} iconType={'download'} isLoading={isLoading} onClick={download}/></ComponentEuiFlexItem> : null
}

export default ComponentNavActionDownloadButton