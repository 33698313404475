import { AuditDisplay } from 'display'
import { DisplayConfig } from 'types'
import NameDisplay from '../parts/NameDisplay'
import DescriptionDisplay from "../parts/DescriptionDisplay";
import IsPublicDisplay from "../parts/IsPublicDisplay";
import IsCompleteDisplay from "../parts/IsCompleteDisplay";

const config :DisplayConfig[] = [
    ...NameDisplay,
    ...DescriptionDisplay,
    ...IsPublicDisplay,
    ...IsCompleteDisplay,
    ...AuditDisplay
]

export default config