import { ComponentEuiTabs } from 'Component'
import { ModuleRowSheetTab } from 'Module'
import { TypeEntityExtractSheet } from 'Type'

type Props = {
    sheets :TypeEntityExtractSheet[]
    active :TypeEntityExtractSheet
}

const ModuleRowChildTabs = ({ sheets, active } :Props) => {
    return <ComponentEuiTabs>
        {sheets.map((sheet :TypeEntityExtractSheet) => {
            return <ModuleRowSheetTab key={sheet.linkTo} active={active} sheet={sheet}/>
        })}
    </ComponentEuiTabs>
}

export default ModuleRowChildTabs