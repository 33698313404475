import { ComponentEuiLoadingSpinner, ComponentNavActionModal } from 'Component'
import { HookNavGet } from 'Hook'
import {TypeEntityLinkTo, TypeNav, TypeNavControlActionType, TypeNavHttpControl } from 'Type'

type Props<T extends TypeEntityLinkTo> = {
    entityName :string
    entity :T
    actionType :TypeNavControlActionType
    onSuccess :(entity? :T) => void
    onClose :() => void
}

const ComponentNavActionModalEntity = <T extends TypeEntityLinkTo>({entityName, entity, actionType, onClose, onSuccess} :Props<T>) => {
    const navControl :TypeNavHttpControl<T> = HookNavGet<T>(entity)
    const nav :TypeNav<T> | undefined = navControl.res?.data
    return (nav) ? <ComponentNavActionModal entityName={entityName} actionType={actionType} nav={nav} onClose={onClose} onSuccess={onSuccess}/> : <ComponentEuiLoadingSpinner/>
}

export default ComponentNavActionModalEntity