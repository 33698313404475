import React, { ReactNode } from 'react';
import {EuiBasicTable} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import { BasicTableColumn } from 'types';

type SortProps<T> = {
    field: keyof T,
    direction: 'asc' | 'desc'
}

type SortingProps<T> = {
    sort: SortProps<T>
    allowNeutralSort? :boolean
    enableAllColumns? :boolean
    readOnly? :boolean
}

type PaginationProps = {
    pageIndex: number
    pageSize: number
    totalItemCount: number
    pageSizeOptions: number[]
}

type Props<T> = {
    columns :BasicTableColumn<T>[]
    items :any[]
    actions? :any[]
    pagination? :PaginationProps
    sorting? :SortingProps<T>
    onChange? :(context :any) => void
    rowProps? :any
    itemId? :string
    itemIdToExpandedRowMap? :{[id: string]: ReactNode}
    clickable? :boolean
    onClick? :(t :T) => void
    actionTitle? :string
};

const linkTo :string = 'linkTo'

const BasicTable = <T extends object>({columns = [], items = [], onChange, pagination, sorting, actions, rowProps, itemId, itemIdToExpandedRowMap, clickable = true, onClick, actionTitle}: Props<T>) => {

    const navigate = useNavigate()

    const hasActions = actions && actions.length > 0

    const rowPropsDefault = (item :any) => {
        return {
            onClick: (onClick) ? () => onClick(item) : (clickable && item[linkTo]) ? () => {
                navigate(item[linkTo])
            } : undefined
        }
    }

    return <EuiBasicTable columns={(hasActions) ? [...columns, {name: actionTitle || 'Actions', actions: actions}] : columns}
        items={items}
        pagination={pagination}
        onChange={onChange}
        hasActions={hasActions}
        sorting={sorting}
        rowProps={rowProps || rowPropsDefault}
        itemId={itemId}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
    />
}

export default BasicTable