import { ComponentEuiButtonEmpty } from 'Component'

type Props = {
    onClick :() => void
    text? :string
}

const ComponentNavFormButtonSubmit = ({onClick, text} :Props) => {

    return <ComponentEuiButtonEmpty onClick={onClick} color={'danger'}>{text ?? 'Cancel'}</ComponentEuiButtonEmpty>
}

export default ComponentNavFormButtonSubmit