import React, {ReactElement, useEffect, useState} from 'react'
import { Field } from 'props'
import { Nav, ParamUpdate } from 'types'
import { NavClient, SearchParamUtils } from 'utils'
import {ButtonEmpty, ComboBox, ListGroup, Popover } from 'eui'
import { SearchOptionEntity } from 'entity'

const SearchKeyPopover = (keys :string[], key :string, setKey :(key :string) => void) :ReactElement | undefined => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    if (keys.length === 1) return undefined
    return <Popover
        className={'euiFormControlLayout__prepend'}
        button={<ButtonEmpty size='xs' iconType='arrowDown' iconSide='right' onClick={() => {setIsOpen(true)}}> {key}
        </ButtonEmpty>}
        closePopover={() => {setIsOpen(false)}} isOpen={isOpen} panelPaddingSize={'s'}>
        <ListGroup listItems={keys.map(k => {
            return {
                label: k,
                size: 's',
                onClick: () => {
                    setIsOpen(false)
                    setKey(k)
                }
            }
        })}/>
    </Popover>
}

const NavSearch = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true, search}: Field) => {

    const [isMounted, setIsMounted] = useState<boolean>(true)

    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState<SearchOptionEntity[]>([])
    const [key, setKey] = useState<string | undefined>(undefined)
    const [selected, setSelected] = useState<SearchOptionEntity | undefined>()

    useEffect(() => {
        if (isMounted) onSearchChange(value as string)
        return () => setIsMounted(false)
    }, [])

    if (!search) return null
    const {url, keys} = search

    const selectedKey = key || keys[0] || ''

    const onSearchChange = async (searchStr :string) => {
        setLoading(true)

        const updates :ParamUpdate[] = [
            { param: 'formSearch-' + selectedKey, paramValue: searchStr }
        ]

        const searchUrl : string = SearchParamUtils.updateParamForPath(url, updates)

        const responseNav :Nav = await NavClient.fetchNav(searchUrl)
        if (!isMounted) return
        const error = responseNav.error
        if (error) {
            setLoading(false)
            setOptions([{label: 'System error', key: '', disabled: true}])
        } else {
            setLoading(false)
            const page = responseNav.data.page
            const options :SearchOptionEntity[] = (page) ? page.content : []
            setOptions(options)
        }
    }

    const onInnerChange = (selectedOptions :any) => {
        if (selectedOptions.length > 0) {
            setSelected(selectedOptions[0])
            onChange(name, selectedOptions[0].key);
        } else {
            setSelected(undefined)
            onChange(name, null)
        }
    };

    return <ComboBox isLoading={isLoading} prepend={SearchKeyPopover(keys, selectedKey, setKey)} onSearchChange={onSearchChange} options={options} onChange={onInnerChange} selectedOptions={(selected) ? [selected] : options.filter(o => o.key === value)}/>
}

export default NavSearch