import { HookNavGet } from 'Hook'
import { useState } from 'react'
import { TypeNav, TypeNavHttpControl, TypeEntitySearchResult, TypeNavControlFilter } from 'Type'

type Props = {
    nav :TypeNav<any>
    fieldNameCamel :string
}

const NULL_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: false}
const ERROR_FIELD :TypeEntitySearchResult = {label: '', key: '', disabled: true}

const HookNavFilterField = ({ nav, fieldNameCamel } :Props) :TypeNavControlFilter => {
    const [searchStr, setSearchStr] = useState<string>('')

    const control :TypeNavHttpControl<TypeEntitySearchResult> = HookNavGet<TypeEntitySearchResult>({linkTo: nav.path, paramUpdates: [{type: 'field-*', dynamic: [fieldNameCamel],  values: [searchStr]}]})
    const isLoading :boolean = control.isLoading
    const error :TypeEntitySearchResult[] | undefined = (control.res?.error) ? [ERROR_FIELD] : undefined

    const results :TypeEntitySearchResult[] = error || [NULL_FIELD, ...(control.res?.data?.data.page?.content ?? [])]

    return {results, isLoading, setSearchStr}
}

export default HookNavFilterField