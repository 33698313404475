import {TypeNavControlActionRenderer, TypeNavControlActionType } from 'Type'

const RecordNavAction :Record<TypeNavControlActionType, TypeNavControlActionRenderer> = {
    delete: {
        icon: 'trash',
        color: 'danger',
        buttonText: (title: string, isTitleVerb? :boolean): string => (isTitleVerb) ? title : `Delete ${title.toLowerCase()}`,
        description: (entityName: string): string => `Delete this ${entityName.toLowerCase()}`,
    },
    post: {
        icon: 'pencil',
        color: 'primary',
        buttonText: (title: string, isTitleVerb? :boolean): string => (isTitleVerb) ? title : `Create ${title.toLowerCase()}`,
        description: (entityName: string): string => `Create a ${entityName.toLowerCase()}`,
    },
    put: {
        icon: 'documentEdit',
        color: 'primary',
        buttonText: (title: string, isTitleVerb? :boolean): string => (isTitleVerb) ? title : `Override ${title.toLowerCase()}`,
        description: (entityName: string): string => `Override this ${entityName.toLowerCase()}`,
    },
    patch: {
        icon: 'documentEdit',
        color: 'primary',
        buttonText: (title: string, isTitleVerb? :boolean): string => (isTitleVerb) ? title : 'Update',
        description: (entityName: string): string => `Update this ${entityName.toLowerCase()}`,
    }
}

export default RecordNavAction