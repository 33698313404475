import { EuiTab } from '@elastic/eui'
import { Children } from 'props'
import { ReactNode } from 'react'

type Props = Children & {
    isSelected? :boolean
    disabled? :boolean
    prepend? :ReactNode
    append? :ReactNode
    expand? :boolean
    size? :'s' | 'm' | 'l' | 'xl'
    onClick? :() => void
    className? :string
}

const Tab = ({className, isSelected, disabled, prepend, append, expand, size, onClick, children} :Props) =>
    <EuiTab className={className} isSelected={isSelected} disabled={disabled} prepend={prepend} append={append} expand={expand} size={size} onClick={onClick}>{ children }</EuiTab>

export default Tab