import { EuiDatePicker } from '@elastic/eui'
import { Moment } from 'moment'

import { ComponentEuiFieldPickerDateTimeZoneSelect } from 'Component'
import { TypeEuiFormFieldProps } from 'Type'
import { FORMAT_DATE_TIME_ZONE, FORMAT_TIME } from 'Format'

type Props = TypeEuiFormFieldProps<string>

const ComponentEuiFieldPickerDateTime = ({value, placeholder, isInvalid, fullWidth, isLoading, onChange} :Props) => {

    const evtFn = (e: Moment | null) => onChange(e?.format(FORMAT_DATE_TIME_ZONE) ?? '')

    return <><EuiDatePicker
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        isLoading={isLoading}
        dateFormat={FORMAT_DATE_TIME_ZONE}
        timeFormat={FORMAT_TIME}
        onChange={evtFn}
    />
    <ComponentEuiFieldPickerDateTimeZoneSelect value={value} onChange={evtFn}/></>
}

export default ComponentEuiFieldPickerDateTime