import { HookModuleTransformHeaderErrorMap, HookNavResource, HookNavResourceNav, HookNavResourceNavAll } from 'Hook'
import { ModulePageLoading, ModuleTransformBody } from 'Module'
import { TypeEntityTransform, TypeEntityTransformHeader, TypeEntityTransformLoad, TypeHttpLoadable, TypeModuleControlRefresh,
    TypeModuleTransformHeaderErrorControl, TypeNav, TypeNavControlResource, TypeNavHttpControl } from 'Type'
import { UtilAsync } from 'Util'

type Props = {
    transformControl :TypeNavHttpControl<TypeEntityTransform>
    loadControl :TypeNavHttpControl<TypeEntityTransformLoad>
}

const PageRowBodyTransform = ({ transformControl, loadControl } :Props) => {

    //transform
    const transformNav :TypeNav<any> | undefined = transformControl.res?.data

    //exclude
    const excludeResource :TypeNavControlResource | undefined = HookNavResource('exclude', transformNav)

    //header
    const headerControl :TypeNavHttpControl<TypeEntityTransformHeader> = HookNavResourceNavAll<TypeEntityTransformHeader>({key: 'header', nav: transformNav})
    const headerNav :TypeNav<TypeEntityTransformHeader> | undefined = headerControl.res?.data
    const headerErrorControl :TypeModuleTransformHeaderErrorControl = HookModuleTransformHeaderErrorMap(headerNav?.data.page?.content)

    //output
    const outputControl :TypeNavHttpControl<any> = HookNavResourceNav<any>({key: 'output', nav: transformNav})
    const outputNav :TypeNav<any> | undefined = outputControl.res?.data

    const refreshControl :TypeModuleControlRefresh = {
        headerRow: async () => {
            await transformControl.submit()
            await UtilAsync.awaitAll([headerControl.submit(), outputControl.submit(), loadControl.submit(), headerErrorControl.refreshAll()])
        },
        header: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([headerControl.submit(), headerErrorControl.refresh(destinationColumnNameCamel), outputControl.submit(), loadControl.submit()]),
        error: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([headerErrorControl.refresh(destinationColumnNameCamel), loadControl.submit()]),
        exclude: async () => UtilAsync.awaitAll([outputControl.submit(), loadControl.submit()])
    }

    const loaded = headerNav && outputNav && transformNav && headerErrorControl.errors && excludeResource

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Transformation', control: transformControl},
        {label: 'Headers', control: headerControl},
        {label: 'Output', control: outputControl}
    ]

    const body = loaded && <ModuleTransformBody headerNav={headerNav} headerErrors={headerErrorControl.errors} outputNav={outputNav} transformNav={transformNav} excludeResource={excludeResource} refreshControl={refreshControl}/>

    return <ModulePageLoading toLoad={toLoad}>{body}</ModulePageLoading>
}

export default PageRowBodyTransform