import { ProcessColorPicker } from 'config'
import {useState, useMemo, useEffect} from 'react'

type Values = {[key :string] :string}

type Returning = [any[], (rowIndex :number, values :Values) => void]



const CellColorMap = () :Returning => {

    const [colorMap, setColorMap] = useState<any[]>([])

    const colorPicker = useMemo<ProcessColorPicker>(() => new ProcessColorPicker(), [])

    const setColorMapValue = (rowIndex :number, values :{[key :string] :string}) => {
        const newColorMap :any[] = [...colorMap]
        const row :any = newColorMap[rowIndex] || {}
        Object.keys(values).map((k :string) => {
            row[k] = colorPicker.pick(values[k])
        })
        newColorMap[rowIndex] = row
        setColorMap(newColorMap)

        setTimeout(() => {removeColorMapValue(rowIndex, values)}, 400)
    }

    useEffect(() => {}, [colorMap])

    const removeColorMapValue = (rowIndex :number, values :{[key :string] :string}) => {
        setColorMap((colorMap:any[]) :any[] => {
            const newColorMap :any[] = [...colorMap]
            const row :any | undefined = newColorMap[rowIndex]
            if (!row) return colorMap
            Object.keys(values).forEach((k :string) => {
                if (row[k]) row[k] = null
            })
            newColorMap[rowIndex] = row
            return newColorMap
        })
    }

    return [colorMap, setColorMapValue]
}

export default CellColorMap