import { NavModalTable } from 'components'
import { InterfaceMapEntity } from 'entity'
import {LoadingSpinner, PageSection, Text } from 'eui'
import { useNav } from 'hooks'
import {useEffect, useState} from 'react'
import { NavControlResource, NavHookControl, Nav, NavKey, TableColumnHookControl } from 'types'
import {TableModalConnectInterfaceEntity} from 'modules'

type Props = {
    mapResource :NavControlResource
    columnControls :TableColumnHookControl
}

const ifaceKey :NavKey = 'iface'

const TableModalConnectInterfaceList = ({mapResource, columnControls} :Props) => {

    const [active, setActive] = useState<InterfaceMapEntity | undefined>()

    const reqNavControl :NavHookControl = useNav()
    useEffect(() => {reqNavControl.fetch(mapResource.linkTo)}, [mapResource.linkTo])
    const nav :Nav | undefined = reqNavControl.nav

    const onClick = (entity :InterfaceMapEntity) => {
        setActive(entity)
    }

    const onBack = () => {
        setActive(undefined)
        reqNavControl.refresh()
    }

    if (!nav) return <LoadingSpinner/>

    if (active) return <TableModalConnectInterfaceEntity map={active} onBack={onBack} columnControls={columnControls}/>

    return <>
        <PageSection paddingSize={'m'}>
            <Text><p>{nav.meta.description}</p></Text>
        </PageSection>
        <PageSection paddingSize={'none'}>
            <NavModalTable nav={nav} navKey={ifaceKey} navigate={'callback'} onSuccess={reqNavControl.refresh} ignoreAudit={true} ignoreFields={['description', 'isPublic', 'isComplete']} onClick={onClick}/>
        </PageSection>
    </>
}

export default TableModalConnectInterfaceList