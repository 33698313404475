import { TableEntity, TableVersionEntity } from 'entity'
import {useLocation, useNavigate } from 'react-router-dom'
import { NavKey } from 'types'

const versionNavKey :NavKey = 'version'
const rowNavKey :NavKey = 'row'

const NavigateToTable = () => {

    const navigate = useNavigate()
    const location = useLocation()

    return (table :TableEntity, version? :TableVersionEntity) => {
        const versionPath :string = (version) ? version.linkTo : `${table.linkTo}/${versionNavKey}/${table.latestVersionNumber}`
        const path :string = `${versionPath}/${rowNavKey}`
        if (!location.pathname.includes(path)) navigate(path)
    }
}

export default NavigateToTable