import React from 'react'
import {EuiPanel} from '@elastic/eui'

type Props = {
    hasShadow?: boolean
    hasBorder?: boolean
    paddingSize?: 's' | 'm' | 'l' | 'none'
    borderRadius?: 'm' | 'none'
    grow?: boolean
    color?: 'transparent' | 'plain' | 'subdued' | 'accent' | 'primary' | 'success' | 'warning' | 'danger'
    children: React.ReactNode
    style?: any
};

const Panel = ({hasShadow, hasBorder, paddingSize, borderRadius, grow, color, children, style} :Props) =>
    <EuiPanel hasShadow={hasShadow}
              hasBorder={hasBorder}
              paddingSize={paddingSize}
              borderRadius={borderRadius}
              grow={grow}
              color={color}
              style={style}
    >{children}</EuiPanel>


export default Panel