import {HookNavContextNav, HookNavPage, HookNavResource, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import { PageWorkspaceBody, PageWorkspaceSidebar } from 'Page'
import { useNavigate } from 'react-router-dom'
import { TypeEntityLinkTo, TypeEntityMember, TypeEntityWorkspace, TypeHttpLoadable, TypeNav,
    TypeNavControlResource, TypeNavControlResourceConfig, TypeNavHttpControl, TypeNavKey, TypeUrlParam } from 'Type'

const ws :TypeNavKey = 'ws'

const pageSizeParam :TypeUrlParam = {type: 'size', values: ['10']}

type Props<T> = {
    activeResourceKey :TypeNavKey
    showColumns :(keyof T)[]
}

const PageWorkspaceGeneric = <T extends object & TypeEntityLinkTo>({ activeResourceKey, showColumns } :Props<T>) => {

    const navigate = useNavigate()

    //Table
    const pageControl :TypeNavHttpControl<T> = HookNavPage<T>()
    const pageNav :TypeNav<T> | undefined = pageControl.res?.data

    //Workspace
    const workspaceControl :TypeNavHttpControl<TypeEntityWorkspace> = HookNavContextNav<TypeEntityWorkspace>(ws, pageNav)
    const workspaceNav :TypeNav<TypeEntityWorkspace> | undefined = workspaceControl.res?.data
    const workspaceEntity :TypeEntityWorkspace | undefined = workspaceNav?.data.entity

    //Member
    const memberControl :TypeNavHttpControl<TypeEntityMember> = HookNavResourceNav<TypeEntityMember>({key: 'member', nav: workspaceNav})

    //Resource
    const activeResource :TypeNavControlResource | undefined = HookNavResource(activeResourceKey, workspaceNav)

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Workspace', control: workspaceControl},
        {label: activeResource?.meta.title ?? 'Page', control: pageControl},
    ]

    const navigateToEntity = (entity? :T) => { if (entity) navigate(entity.linkTo) }

    const resourceConfig :TypeNavControlResourceConfig<T>[] = [
        {
            key :'table',
           entityName: 'Table',
            resource: HookNavResource('table', workspaceNav),
            onActionSuccess : navigateToEntity
        },
        {
            key :'iface',
            entityName: 'Interface',
            resource: HookNavResource('iface', workspaceNav),
            onActionSuccess : navigateToEntity
        },
        {
            key :'responder',
            entityName: 'Responder',
            resource: HookNavResource('responder', workspaceNav),
            onActionSuccess : navigateToEntity
        }
    ]

    const sidebar = workspaceNav && workspaceEntity && <PageWorkspaceSidebar pageControl={pageControl} workspaceEntity={workspaceEntity} memberControl={memberControl}/>
    const body = <PageWorkspaceBody pageControl={pageControl} activeResourceKey={activeResourceKey} resourceConfigs={resourceConfig} showColumns={showColumns}/>

    return <ModulePage control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={pageControl}>{body}</ModulePage>

}

export default PageWorkspaceGeneric