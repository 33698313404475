import {Button, ButtonEmpty, ButtonIcon, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle} from 'eui';
import {Nav, NavControlAction, NavControlActionType} from 'types';
import {useState} from 'react';
import {MappingUtils, NavClient} from 'utils';
import {NavStore} from "../../store";

type Props = {
    rowId :number
    linkTo :string
}

const deleteActionType :NavControlActionType = 'delete'

const TableVersionRowTableRowDelete = ({rowId, linkTo} :Props) => {

    const [rowNav, setRowNav] = useState<Nav | undefined>(undefined)
    const refresh :() => void = NavStore(store => store.refresh)

    const onClick = async () => {
        setRowNav(await NavClient.fetchNav(linkTo))
    }

    const onClose = () => {
        setRowNav(undefined)
    }

    const deleteAction :NavControlAction | undefined = rowNav?.control.action[deleteActionType]

    const onSubmit = async () => {
        if (deleteAction && rowNav) await NavClient.action(rowNav, deleteActionType, {})
        refresh()
    }

    return <>
        <ButtonIcon ariaLabel="Delete row" iconType={'trash'} size={'xs'} color={'danger'} onClick={onClick}/>
        {deleteAction && <Modal onClose={onClose}>
            <ModalHeader>
                <ModalHeaderTitle>
                    <h1>{`Delete row ${rowId}`}</h1>
                </ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                {deleteAction && !deleteAction.auth.authorized && <p>{deleteAction.auth.message}</p>}
            </ModalBody>
            <ModalFooter>
                <ButtonEmpty color={'danger'} onClick={onClose}>Cancel</ButtonEmpty>
                {deleteAction && deleteAction.auth.authorized && <Button fill color={'danger'} onClick={onSubmit}>{MappingUtils.buttonTitle(deleteActionType)}</Button>}
            </ModalFooter>
        </Modal>}
    </>
}

export default TableVersionRowTableRowDelete