import {HookNavGet, HookNavPage, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import {PageOrganisationBody, PageOrganisationSidebar } from 'Page';
import {TypeEntityMember, TypeEntityOrganisation, TypeEntityWorkspace, TypeHttpLoadable, TypeNav, TypeNavHttpControl, TypeNavKey } from 'Type'

const orgKey :TypeNavKey = 'org'

const PageOrganisation = () => {

    //Workspace
    const wsControl :TypeNavHttpControl<TypeEntityWorkspace> = HookNavPage<TypeEntityWorkspace>()
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    //Organisation
    const orgEntity :TypeEntityOrganisation | undefined = wsNav?.context[orgKey] as TypeEntityOrganisation
    const orgControl :TypeNavHttpControl<TypeEntityOrganisation> = HookNavGet<TypeEntityOrganisation>({linkTo: orgEntity?.linkTo})
    const orgNav :TypeNav<TypeEntityOrganisation> | undefined = orgControl.res?.data

    //Member
    const memberControl :TypeNavHttpControl<TypeEntityMember> = HookNavResourceNav<TypeEntityMember>({key: 'member', nav: orgNav})

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Organisation', control: wsControl},
        {label: 'Workspaces', control: wsControl},
    ]

    const sidebar = <PageOrganisationSidebar orgEntity={orgEntity} memberControl={memberControl}/>
    const body = <PageOrganisationBody wsControl={wsControl}/>

    return <ModulePage control={wsControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={wsControl}>{body}</ModulePage>
}

export default PageOrganisation