import { EuiModal } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    children :ReactNode
    onClose :() => void
    maxWidth? :string | number | boolean
    initialFocus? :string | HTMLElement | (() => HTMLElement)
}

const ComponentEuiModal  = ({children, onClose, maxWidth, initialFocus} :Props) =>
    <EuiModal onClose={onClose} maxWidth={maxWidth} initialFocus={initialFocus}>{children}</EuiModal>

export default ComponentEuiModal