import { TableFilterBadge } from 'components'
import { FlexGroup, FlexItem } from 'eui'
import {Nav, TableFilter } from 'types'
import { SearchParamUtils } from 'utils'

type Props = {
    nav? :Nav
    setNav? :(newNav :Nav) => void
}

const NavTableFilterBadges = ({nav, setNav} :Props) => {

    const filters :TableFilter[] = (nav) ? SearchParamUtils.getTableFilterParams(nav.path) : SearchParamUtils.getPageTableFilterParams()

    return <FlexGroup>
        { filters.map(filter => <FlexItem key={filter.field}><TableFilterBadge tableFilter={filter} nav={nav} setNav={setNav}/></FlexItem>) }
    </FlexGroup>

}

export default NavTableFilterBadges