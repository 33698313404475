import { ComponentEuiBadge, ComponentEuiSpacer, ComponentNavSidebarContextEntity, ComponentPageSidebarEntity } from 'Component'
import { HookNavGetCount } from 'Hook'
import { ModuleTransformLoad } from 'Module'
import { ReactNode } from 'react'
import { TypeEntityTransformDestination, TypeEntityTransformLoad, TypeNav } from 'Type'

type Props = {
    pageNav? :TypeNav<any>
    loadNav :TypeNav<TypeEntityTransformLoad>
    destination :TypeEntityTransformDestination
}

const PageTransformSidebar = ({ pageNav, loadNav, destination } :Props) => {
    const destinationTableRowCount :number | undefined = HookNavGetCount(destination)
    const destinationTableRowCountBadge :ReactNode = destinationTableRowCount && <ComponentEuiBadge color={'primary'}>{destinationTableRowCount}</ComponentEuiBadge>
    return <>
        <ComponentNavSidebarContextEntity title={'Extract'} navKey={'extract'} nameFields={['cellFileName']} nav={pageNav}/>
        <ComponentNavSidebarContextEntity title={'Sheet'} navKey={'sheet'} nameFields={['name']} nav={pageNav}/>
        <ComponentPageSidebarEntity title={'Destination'} name={destination.tableName} href={destination.linkTo} right={destinationTableRowCountBadge}/>
        <ComponentEuiSpacer size={'m'}/>
        <ModuleTransformLoad loadNav={loadNav} destination={destination}/>
    </>
}

export default PageTransformSidebar