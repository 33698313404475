import react from 'react'

import {CodeBlock, Spacer} from 'eui'
import {Nav} from 'types'
import { NavStore } from 'store'

const Raw = () => {

    const nav :Nav | undefined = NavStore(store => store.nav)

    return <CodeBlock language={'json'} fontSize={'m'} paddingSize={'m'} whiteSpace={'pre-wrap'} lineNumbers>{nav}</CodeBlock>

}
export default Raw