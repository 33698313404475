import { CollapsibleLeftNavBar, CollapsibleSideBarItem, SideBarActionModal, SideBarListItem } from 'components'
import {ExtractEntity, SheetEntity, TableEntity, TableVersionEntity, TransformationEntity } from 'entity'
import {Icon, Page, SideNav, Spacer } from 'eui'
import {useNavigateToExtract, useNavigateToTable } from 'hooks'
import { NavResourceSearchFilter } from 'modules'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {ExtractStore, NavStore } from 'store'
import { Nav, NavControlAction, NavControlResource, NavDataPage, NavKey, SideNavItem } from 'types'
import { MappingUtils, NavClient } from 'utils'
import {LinkToEntity} from "../../entity";

type Props = {
    callBack?: () => void
}

const transformKey :NavKey = 'transform'

const ExtractPageLeftNavBar = ({callBack} :Props) => {

    const navigate = useNavigate()
    const navigateToTable = useNavigateToTable()
    const navigateToExtract = useNavigateToExtract()

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const table :TableEntity | undefined = pageNav && pageNav.context['table']
    const version :TableVersionEntity | undefined = pageNav && pageNav.context['version']
    const extract :ExtractEntity | undefined = pageNav && pageNav.context['extract']
    const row :LinkToEntity | undefined = pageNav && pageNav.context['row']

    const sheets :NavDataPage<SheetEntity> | undefined = ExtractStore(store => store.sheets)
    const sheet :SheetEntity | undefined = ExtractStore(store => store.sheet)
    const sheetNav :Nav | undefined = ExtractStore(store => store.sheetNav)

    const transformationResource :NavControlResource | undefined = sheetNav && sheetNav.control.resource[transformKey]

    const [transformationNav, setTransformationNav] = useState<Nav | undefined>(undefined)

    const newTransformationAction :NavControlAction | undefined = transformationNav && transformationNav.control.action['post']

    const fetchTransformationNav = async () => {
        if (transformationResource) setTransformationNav(await NavClient.fetchNav(transformationResource.linkTo))
    }

    useEffect(() => {fetchTransformationNav()}, [transformationResource])

    useEffect(() => {
        if (callBack && table && version && extract && sheet) callBack()
    }, [table, version, extract, sheet])

    const navigateOverride = (transformation :TransformationEntity) => navigate(transformation.linkTo)

    if (!table || !version || !extract || !sheet || !row) return null

    const rootWorkbook :SideNavItem = {
        id: 'title',
        name: 'Workbook',
        items: [
            {
                id: 'workbook',
                name: extract.cellFileName
            }
        ]
    }

    const rootSheets :SideNavItem = {
        id: 'title',
        name: 'Sheets',
        items: ((sheets && sheets.content) || []).map((s :SheetEntity) => {
            return {
                id: s.linkTo,
                isSelected: s.linkTo === sheet.linkTo,
                name: s.name,
                onClick: () => navigateToExtract(extract, s)
            } as SideNavItem
        })
    }

    const rootTransform :SideNavItem = {
        id: 'title',
        name: 'Transformations',
        items: []
    }

    return <CollapsibleLeftNavBar expandedWidth={240} collapsedWidth={0} darker>
        <Spacer size={'s'}/>
        <CollapsibleSideBarItem>
            <span onClick={() => {navigate(row.linkTo)}} style={{color: '#98A2B3', cursor: 'pointer', textDecoration: 'none'}}><Icon type={'arrowLeft'} size={'s'}/>   Back to row  </span>
        </CollapsibleSideBarItem>
        <Spacer/>
        <CollapsibleSideBarItem>
            <SideNav items={[rootWorkbook]}/>
        </CollapsibleSideBarItem>
        <Spacer/>
        <CollapsibleSideBarItem>
            <SideNav items={[rootSheets]}/>
        </CollapsibleSideBarItem>
        <Spacer/>
        <CollapsibleSideBarItem>
            <SideNav items={[rootTransform]}/>
            {(transformationNav && newTransformationAction) ? <SideBarActionModal nav={transformationNav} action={newTransformationAction} tabKey={'transform'}/> : null}
            <Spacer size={'m'}/>
            {(transformationNav) ? <NavResourceSearchFilter nav={transformationNav} field={'name'} navigateOverride={navigateOverride}/> : null}
        </CollapsibleSideBarItem>
    </CollapsibleLeftNavBar>
}

export default ExtractPageLeftNavBar