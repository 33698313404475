import React, {ChangeEvent} from 'react'
import {EuiSwitch} from '@elastic/eui'
import { Field } from 'props'

type Props = Field & {
    label? :string
}

const FieldSwitch = ({placeholder, name, value = 'false', isInvalid = false, onChange, fullWidth = true, label}: Props) => {

    const fn = (e :any) => {
        onChange(name, e.target.checked + '')
    }

    return <EuiSwitch placeholder={placeholder}
                           label={label || ''}
                           checked={(`${value}` === 'true')}
                           onChange={fn}/>
}

export default FieldSwitch