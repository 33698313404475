import { HookPageParam } from 'Hook'
import { useEffect } from 'react'

import { TypeControlParamSimple, TypeUrlParam, TypeUrlParamType } from 'Type'

const HookPageParamSimple = (type :TypeUrlParamType, isValid :(val :string) => boolean, defaultVal? :string) :TypeControlParamSimple => {

    const { params, set } = HookPageParam(type)

    const param :TypeUrlParam | undefined = params[0]

    const paramValue :string | undefined = param?.values[0]

    const value :string | undefined = (paramValue && isValid(paramValue)) ? paramValue : defaultVal

    useEffect(() => {
        if (!paramValue && value) setUrlParam(value)
    }, [paramValue, value])

    const setUrlParam = (newValue :string) => {
        set([newValue])
    }

    return [value, setUrlParam]
}

export default HookPageParamSimple