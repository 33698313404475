import React from 'react'
import {EuiCodeBlock} from '@elastic/eui'

type Props = {
    language: string,
    fontSize?: 's' | 'm' | 'l'
    paddingSize?: 'none' | 's' | 'm' | 'l'
    whiteSpace?: 'pre' | 'pre-wrap'
    lineNumbers?: boolean
    children?: any
}

const CodeBlock = ({ language, fontSize, whiteSpace, lineNumbers, children  }: Props) =>
    <EuiCodeBlock language={language} fontSize={fontSize} whiteSpace={whiteSpace} lineNumbers={lineNumbers}>{JSON.stringify(children, undefined, 4)}</EuiCodeBlock>

export default CodeBlock