import {ModuleTableDataTableCellDisplayReference, ModuleTableDataTableCellDisplayStandard, ModuleTableDataTableCellDisplayDateTimeZ, ModuleTableDataTableCellDisplayPercentage, ModuleTableDataTableCellDisplayUrl, ModuleTableDataTableCellDisplayGlobalId } from 'Module'
import { FC } from 'react'
import { TypeEntityCellType, TypeModuleTableDataCellProps } from 'Type'


const RecordTableDataCellDisplay :Record<TypeEntityCellType, FC<TypeModuleTableDataCellProps>> = {
    globalid: ModuleTableDataTableCellDisplayGlobalId,
    userid: ModuleTableDataTableCellDisplayGlobalId,
    text: ModuleTableDataTableCellDisplayStandard,
    big_text: ModuleTableDataTableCellDisplayStandard,
    url: ModuleTableDataTableCellDisplayUrl,
    email: ModuleTableDataTableCellDisplayStandard,
    telephone_number: ModuleTableDataTableCellDisplayStandard,
    integer: ModuleTableDataTableCellDisplayStandard,
    year: ModuleTableDataTableCellDisplayStandard,
    decimal: ModuleTableDataTableCellDisplayStandard,
    currency: ModuleTableDataTableCellDisplayStandard,
    coordinate: ModuleTableDataTableCellDisplayStandard,
    percentage: ModuleTableDataTableCellDisplayPercentage,
    date: ModuleTableDataTableCellDisplayStandard,
    time: ModuleTableDataTableCellDisplayStandard,
    datetime: ModuleTableDataTableCellDisplayStandard,
    datetimez: ModuleTableDataTableCellDisplayDateTimeZ,
    yearmonth: ModuleTableDataTableCellDisplayStandard,
    file: ModuleTableDataTableCellDisplayStandard,
    excel: ModuleTableDataTableCellDisplayStandard,
    word: ModuleTableDataTableCellDisplayStandard,
    pdf: ModuleTableDataTableCellDisplayStandard,
    image: ModuleTableDataTableCellDisplayStandard,
    reference: ModuleTableDataTableCellDisplayReference,
    bool: ModuleTableDataTableCellDisplayStandard
}

export default RecordTableDataCellDisplay