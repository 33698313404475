import create from 'zustand'

import { TypeControlSession } from 'Type'
import { UtilJwt } from 'Util'

const KEY = 'session'

const GlobalSession = create<TypeControlSession>((set, get) => {

    const token = localStorage.getItem(KEY) ?? undefined

    const globalId :string | undefined = UtilJwt.extractSub(token)

    const setToken = (token? :string) :string | undefined => {
        if (token) {
            localStorage.setItem(KEY, token)
        } else {
            localStorage.removeItem(KEY)
        }
        const globalId :string | undefined = UtilJwt.extractSub(token)
        set({token: token, globalId})
        return globalId
    }

    const clearToken = () => { setToken(undefined) }

    return {
        token,
        globalId,
        setToken,
        clearToken
    }
})

export default GlobalSession