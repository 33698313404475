import { HookNavContextOptional, HookNavGet, HookNavResource, HookNavResourceNavAll, HookNavSocket } from 'Hook'
import { TypeEntityLinkTo, TypeEntityRow, TypeEntityTableVersionColumn,
    TypeHttpLoadable, TypeModuleTableDataProps, TypeNav,
    TypeNavControlResource, TypeNavHttpControl, TypeNavKey, TypeNavSocketPayload, TypeWebSocketControl } from 'Type'
import { UtilRoute } from 'Util'

const HookModuleTableDataProps = (pageControl :TypeNavHttpControl<any>, ownerKey :TypeNavKey) :TypeModuleTableDataProps => {

    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    const ownerContext :TypeEntityLinkTo | undefined = HookNavContextOptional<TypeEntityLinkTo>(ownerKey, pageNav)
    const ownerControl :TypeNavHttpControl<TypeEntityLinkTo> = HookNavGet<TypeEntityLinkTo>({linkTo: ownerContext?.linkTo})
    const ownerNav :TypeNav<TypeEntityLinkTo> | undefined = ownerControl.res?.data

    //Figure out correct dataControl.  Don't make extra request if pageControl is dataControl
    const ownerDataResource :TypeNavControlResource | undefined = HookNavResource('row', ownerNav)
    if (ownerNav && !ownerDataResource) throw new Error("Data resource not found.");
    const isPageData :boolean = (ownerNav && ownerDataResource) ? UtilRoute.pathMatch(ownerDataResource.linkTo, pageControl.linkTo) : true

    const ownerDataControl :TypeNavHttpControl<TypeEntityRow> = HookNavGet<TypeEntityRow>({linkTo: (isPageData) ? undefined : ownerDataResource?.linkTo})
    const dataControl :TypeNavHttpControl<TypeEntityRow> = (isPageData) ? pageControl as TypeNavHttpControl<TypeEntityRow> : ownerDataControl

    const updatesControl :TypeWebSocketControl<TypeNavSocketPayload<number>> = HookNavSocket(dataControl)
    const columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn> = HookNavResourceNavAll<TypeEntityTableVersionColumn>({key: 'column', nav: ownerNav});

    const columns :TypeEntityTableVersionColumn[] = columnControl.res?.data?.data.page?.content ?? []
    const fieldNameMap :{[fieldNameCamel :string] :string} = {}
    columns.forEach((col :TypeEntityTableVersionColumn) => fieldNameMap[col.nameCamel] = col.name)

    const toLoad :TypeHttpLoadable[] = [
        { label: 'Table', control: ownerControl },
        { label: 'Data', control: dataControl},
        { label: 'Column', control: columnControl }
    ]

    return {
        dataControl,
        updatesControl,
        columnControl,
        fieldNameMap,
        toLoad,
    }
}

export default HookModuleTableDataProps