import {Icon, LoadingSpinner, Tab, Tabs } from 'eui'
import { NavResourceTab } from 'modules'
import {ReactNode, useState } from 'react'
import { NavStore } from 'store'
import { Nav, NavKey } from 'types'
import { MappingUtils } from 'utils'

import './NavResourceTabs.css'

type Props = {
    nav : Nav
    tab? :NavKey
    ignore? :string[]
    tablessTitle? :string
    children? :ReactNode
}

const NavResourceTabs = ({nav, tab, ignore = [], tablessTitle, children} :Props) => {

    const [loadingTab, setLoadingTab] = useState<NavKey | undefined>(undefined)

    const pageNav :Nav | undefined = NavStore(store => store.nav)

    return <Tabs bottomBorder={false}>
        {children}
        {Object.values(nav.control.resource).filter(r => (!ignore.includes(r.key))).map(r =>
        <NavResourceTab key={r.key} resource={r} activeTab={tab}/>)}
    </Tabs>
}

export default NavResourceTabs