import { CollapsibleLeftNavBar, CollapsibleSideBarItem, SideBarListItem } from 'components'
import { WorkspaceEntity } from 'entity'
import {FlexGroup, FlexItem, Icon, SideNav, Spacer } from 'eui'
import { Link, useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { Nav, NavControlResource, NavKey, SideNavItem } from 'types'

type Props = {
    tab? :NavKey
}

const buildSideNavItem = (resource :NavControlResource, navigate :(linkTo :string) => void, tab? :NavKey, rightArrow? :boolean) :SideNavItem => {
    return {
        id: resource.key,
        name: <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}><FlexItem grow>{resource.meta.title}</FlexItem><FlexItem>{(rightArrow) ? <Icon size={'s'} type={'arrowRight'}/> : null}</FlexItem></FlexGroup>,
        onClick: () => navigate(resource.linkTo),
        isSelected: (resource.key === tab)
    }
}

const tableKey :NavKey = 'table'
const memberKey :NavKey = 'member'
const ifaceKey :NavKey = 'iface'
const responderKey :NavKey = 'responder'

const WorkspacePageLeftNavBar = ({tab} :Props) => {

    const navigate = useNavigate()

    const workspaceNav :Nav | undefined = NavStore(state => state.contextNavs['ws'] )

    if (!workspaceNav) return null

    const workspace :WorkspaceEntity = workspaceNav.data.entity as WorkspaceEntity
    const tableResource :NavControlResource | undefined = workspaceNav.control.resource[tableKey]
    const memberResource :NavControlResource | undefined = workspaceNav.control.resource[memberKey]
    const ifaceResource :NavControlResource | undefined = workspaceNav.control.resource[ifaceKey]
    const responderResource :NavControlResource | undefined = workspaceNav.control.resource[responderKey]

    const workspaceItems :SideNavItem[] = [
        {
            id: 'details',
            name: 'Details',
            onClick: () => navigate(workspace.linkTo),
            isSelected: !tab
        }
    ]

    if (memberResource) workspaceItems.push(buildSideNavItem(memberResource, navigate, tab))
    if (tableResource) workspaceItems.push(buildSideNavItem(tableResource, navigate, tab, true))
    if (ifaceResource) workspaceItems.push(buildSideNavItem(ifaceResource, navigate, tab))
    if (responderResource) workspaceItems.push(buildSideNavItem(responderResource, navigate, tab))

    const workspaceItem :SideNavItem = {
        id: 'workspace',
        name: workspace.name,
        items: workspaceItems,
        forceOpen: true
    }

    const rootItem :SideNavItem = {
        id: 'title',
        name: 'Workspaces',
        items: [workspaceItem]
    }

    return <CollapsibleLeftNavBar expandedWidth={240} collapsedWidth={0} darker>
            <Spacer size={'s'}/>
            <CollapsibleSideBarItem gutter={0}>
                <SideNav
                    items={[rootItem]}
                />
            </CollapsibleSideBarItem>
    </CollapsibleLeftNavBar>
}

export default WorkspacePageLeftNavBar