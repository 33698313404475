import { PageTitle } from 'components'

import {FlexGroup, FlexItem, Page, PageBody, PageSection, SideNav, Spacer } from 'eui'

import {useLocation, useNavigate} from 'react-router-dom'

import {DocumentationContentConfig} from 'config'
import {NavKey, SideNavItem, DocumentationHash, DocumentationContentItem} from 'types'
import {DocumentationSectionVideo} from 'modules'

type Props = {
    tab? :NavKey
}

const DocumentationPage = ({tab} :Props) => {

    const navigate = useNavigate()

    const { hash } = useLocation();

    const newDocumentationItem = (id :DocumentationHash) :SideNavItem => {
        const content :DocumentationContentItem = DocumentationContentConfig[id]
        return {
            id: id,
            name: content.title,
            items: (content.items) ? content.items.map((childItemId :DocumentationHash) => newDocumentationItem(childItemId)) : [],
            onClick: () => navigate({hash: id}),
            isSelected: id === hash,
        }
    }

    const items :SideNavItem[] = [
        newDocumentationItem('#collaboration'),
        newDocumentationItem('#table'),
        newDocumentationItem('#extract'),
        newDocumentationItem('#transform'),
        newDocumentationItem('#identity'),
    ]

    const headerHash :string = hash.split('-')[0]
    const headerItem :SideNavItem | undefined = items.find(i => i.id === headerHash)

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <FlexItem>
                    <div style={{width: '320px', height: '100%', background: '#F7F8FC', padding: '16px 24px'}}>
                        <SideNav heading={'Documentation'} items={items}/>
                    </div>
                </FlexItem>
                <FlexItem grow>
                    <div style={{borderLeft: '1px solid #D3DAE6', width: '100%', height: '100%', paddingTop: '36px'}}>
                        {(headerItem && <DocumentationSectionVideo documentationHash={headerItem.id as DocumentationHash}/>) || <PageSection restrictWidth>
                            <PageTitle>Documentation</PageTitle>
                            <Spacer/>
                            <p>Welcome to the Morph Cloud documentation!</p>
                            <Spacer/>
                            <p>The documentation is currently a work in progress.  Please feel free to contact us with any queries at contact@morphcloud.com.</p>
                        </PageSection>}
                    </div>
                </FlexItem>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default DocumentationPage