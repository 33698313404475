import { ComponentEuiTableBasic } from 'Component'
import { HookNavFilterPage } from 'Hook'
import { TypeEuiPropsBasicTableColumn, TypeEuiPropsBasicTableColumnActions, TypeNavControlFilterParam, TypeNavDataFilter } from 'Type'

type Props = {
    fieldNameCamel? :string
    fieldNameMap? :{[fieldNameCamel :string] :string}
}

const ComponentNavPageFilterTable = ({ fieldNameCamel, fieldNameMap = {} } :Props) => {
    const { dataFilters, removeDataFilter } :TypeNavControlFilterParam = HookNavFilterPage(fieldNameCamel)

    const columns :TypeEuiPropsBasicTableColumn<TypeNavDataFilter>[] = [
        {
            field: 'fieldNameCamel',
            name: 'Field',
            render: (value :any, filter :TypeNavDataFilter) => fieldNameMap[value] ?? value
        },
        {
            field: 'operator',
            name: 'Operator',
        },
        {
            field: 'value',
            name: 'Value',
            width: '40%'
        }
    ]

    const actionColumn :TypeEuiPropsBasicTableColumnActions<TypeNavDataFilter> = {
        name: 'Actions',
        actions: [{
            name: 'Remove',
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            description: 'Remove filter',
            onClick: removeDataFilter
        }]
    }

    return <ComponentEuiTableBasic items={dataFilters} columns={[...columns, actionColumn]} hasActions={true} />
}

export default ComponentNavPageFilterTable