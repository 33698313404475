import { ComponentEuiButton } from 'Component'
import { TypeNav, TypeNavControlAction, TypeNavControlActionType } from 'Type'

type Props = {
    entityName :string
    nav :TypeNav<any>
    actionType :TypeNavControlActionType
    onClick :() => void
    isLoading :boolean
    isDisabled? :boolean
}

const ComponentNavFormButtonSubmit = ({entityName, nav, actionType, onClick, isLoading, isDisabled} :Props) => {

    const action :TypeNavControlAction | undefined = nav.control.action[actionType]

    if (!action) return null

    const unavailable :boolean = !action
    const unauthorized :boolean = !action.auth.authorized

    const innerIsDisabled :boolean = isDisabled || unavailable || unauthorized

    return <ComponentEuiButton onClick={onClick} isLoading={isLoading} isDisabled={innerIsDisabled}>{entityName}</ComponentEuiButton>
}

export default ComponentNavFormButtonSubmit