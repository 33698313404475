
import {FlexGroup, Page, PageBody } from 'eui'
import {TablePageLeftNavBar, UserPageLeftNavBar, ColumnPageContent } from 'modules'
import { useState } from 'react'
import { FlexPageContent } from 'components'

const ColumnPage = () => {

    const [loadedSideBar, setLoadedSideBar] = useState<boolean>()

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar forceCollapsed={true} wsSelected/>
                <TablePageLeftNavBar callBack={() => setLoadedSideBar(true)}/>
                <FlexPageContent leftBorder={true} minWidth={0}>
                    {(loadedSideBar) ? <ColumnPageContent/> : null}
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>

}

export default ColumnPage