
import {useEffect} from "react";
import {PageTitle} from "../../components";
import {InterfaceMapEntity, InterfaceMapFieldEntity} from "../../entity";
import {BasicTable, Card, FlexGroup, FlexItem, PageSection, Panel, Spacer} from "../../eui";
import {useNav} from "../../hooks";
import {BasicTableColumn, DisplayConfig, NavHookControl} from "../../types";
import {RouteUtils} from "../../utils";

type Props = {
    responseData? :any
    mapEntity :InterfaceMapEntity
}

const InterfaceMappingTestResponse = ({responseData, mapEntity} :Props) => {

    const navControl :NavHookControl = useNav()
    useEffect(() => {navControl.fetchAll(RouteUtils.interfaceMapField(mapEntity))}, [])

    const data :any[] = navControl.nav?.data.page?.content.map((f :InterfaceMapFieldEntity) => {
        return {
            name: f.name,
            mapName: f.mapName,
            mapNameCamel: f.mapNameCamel,
            type: f.type,
            result: responseData && responseData[f.nameCamel]
        }
    }) ?? []

    const config :DisplayConfig[] = [
        {
           field: 'name',
           name: 'Name'
        },
        {
            field: 'mapName',
            name: 'Map Name'
        },
        {
            field: 'mapNameCamel',
            name: 'Map Name Camel'
        },
        {
            field: 'result',
            name: 'Test Result',
            render: (value :string) => <span style={{fontWeight: 600}}>{value}</span>
        },
    ]

    return <Panel paddingSize={'none'} hasBorder={true} grow={false}>
        <PageSection paddingSize={'m'}>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {'Service Response'}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                        </FlexItem>
                    </FlexGroup>
                    <FlexGroup>
                        <FlexItem>
                            <Spacer size={'s'}/>
                            <p style={{color: '#69707D'}}></p>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>
                </FlexItem>
            </FlexGroup>
        </PageSection>
        <BasicTable columns={config} items={data} onChange={(context :any) => {console.log(context)}}/>
    </Panel>
}

export default InterfaceMappingTestResponse