import { EuiDatePicker } from '@elastic/eui'
import { Moment } from 'moment'
import { FORMAT_TIME } from 'Format'
import { TypeEuiFormFieldProps } from 'Type'

type Props = TypeEuiFormFieldProps<string>

const ComponentEuiFieldPickerTime = ({value, placeholder, isInvalid, fullWidth, isLoading, onChange} :Props) => {

    const evtFn = (e: Moment | null) => onChange(e?.format(FORMAT_TIME) ?? '')

    return <EuiDatePicker
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        isLoading={isLoading}
        dateFormat={FORMAT_TIME}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldPickerTime