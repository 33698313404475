import React from 'react'
import {EuiModalHeader} from '@elastic/eui'

type Props = {
    children?: React.ReactNode
}

const ModalHeader = ({children}: Props) =>
    <EuiModalHeader>{children}</EuiModalHeader>


export default ModalHeader