import {ComponentDivCenter,
    ComponentDivPadding, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiTab, ComponentEuiTabs, ComponentNavActionMenu } from 'Component'
import { useNavigate } from 'react-router-dom'
import {TypeEntityLinkTo, TypeNav, TypeNavControlResource, TypeNavControlResourceConfig, TypeNavKey } from 'Type'


type Props<T extends object & TypeEntityLinkTo> = {
    activeKey? :TypeNavKey
    activeNav? :TypeNav<any>
    resourceConfigs :TypeNavControlResourceConfig<T>[]
}

const ComponentNavResourceTabs = <T extends object & TypeEntityLinkTo>({ activeKey, activeNav, resourceConfigs } :Props<T>) => {

    const navigate = useNavigate()

    const navigateToResourceFn = (r :TypeNavControlResource) => () => navigate(r.linkTo)

    const isActive = (c :TypeNavControlResourceConfig<T>) :boolean => c.key === activeKey

    const activeConfig :TypeNavControlResourceConfig<T> | undefined = resourceConfigs.find(isActive)

    return <ComponentEuiFlexGroup justifyContent={'spaceBetween'} alignItems={'flexEnd'}>
        <ComponentEuiFlexItem>
            <ComponentEuiTabs>
                {resourceConfigs.map((c :TypeNavControlResourceConfig<T>) => c.resource && <ComponentEuiTab key={c.key} onClick={navigateToResourceFn(c.resource)} disabled={!c.resource.auth.authorized} isSelected={activeKey && isActive(c)}>
                    { c.resource.meta.title }
                </ComponentEuiTab>)}
            </ComponentEuiTabs>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ComponentDivCenter>
                <ComponentDivPadding padding={8}>
                    { activeConfig && activeNav && <ComponentNavActionMenu nav={activeNav} entityName={activeConfig.entityName} onActionSuccess={activeConfig.onActionSuccess}/> }
                </ComponentDivPadding>
            </ComponentDivCenter>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ComponentNavResourceTabs