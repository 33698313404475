
import { HookModuleTableDataCellValue } from 'Hook'
import { TypeEuiPropsDataGridCellValueElement, TypeModuleTableDataCellValue } from 'Type'

import './ModuleTableDataTableCell.css'

const ModuleTableDataTableCell = ({ rowIndex, columnId } :TypeEuiPropsDataGridCellValueElement) => {

    const { displayCellValue, color } :TypeModuleTableDataCellValue = HookModuleTableDataCellValue(rowIndex, columnId)

    const style = {
        backgroundColor: (color) ? color : 'inherit',
    }

    return <div className={'ModuleTableDataTableCell'} style={style}>
        {displayCellValue}
    </div>
}

export default ModuleTableDataTableCell