import { FlexGrid, FlexItem } from 'eui'
import {ReactNode, useState} from 'react'
import { DisplayConfig, Base64File } from 'types'
import { MappingUtils, Base64Utils } from 'utils'
import {Icon, FlexGroup} from 'eui';
import {ButtonIcon, Popover} from "../../eui";
import './EntityDisplay.css'
type Props = {
    object :any
    config? :DisplayConfig[]
    ignoreFields? :string[]
    columns? :1 | 2 | 3 | 4
    renderPopover? :(field :string) => ReactNode
}

type ItemProps = {
    field :string
    label :ReactNode
    text :ReactNode
    renderPopover? :(field :string) => ReactNode
}

type Base64FileItemProps = Base64File & {
    label :ReactNode

}

const Item = ({field, label, text, renderPopover} :ItemProps) => {

    const [hover, setHover] = useState<boolean>(false)
    const [popover, setPopover] = useState<boolean>(false)

    return <FlexItem grow>
        <div style={{minWidth: '300px', padding: '24px 24px 0px 24px'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <div style={{fontWeight: 700, paddingLeft: '10px', fontSize: '12px', lineHeight: '16px', marginBottom: '4px'}}>{label}</div>
            <div style={{minHeight: '40px', fontSize: '16px', lineHeight: '20px', backgroundColor: '#FAFBFD', borderRadius: '6px', padding: '10px', color: '#6a788f'}}>
                <FlexGroup gutterSize={'none'} alignItems={'baseline'}>
                    <FlexItem grow>{text}</FlexItem>
                    {(renderPopover && (hover || popover)) ? <FlexItem>
                        <Popover
                            button={<div className={'popoverButtonIcon'}><ButtonIcon size={'xs'} display={'fill'} iconType={'expand'} onClick={() => setPopover(true)} ariaLabel={'Cell menu'}/></div>}
                            isOpen={popover}
                            closePopover={() => setPopover(false)}
                            anchorPosition={'downLeft'}
                            panelPaddingSize={'none'}
                        >{renderPopover(field)}
                        </Popover>

                    </FlexItem> : null}

                </FlexGroup>
            </div>
        </div>
    </FlexItem>
}

const Base64FileItem = ({label, fileName, data} :Base64FileItemProps) => <FlexItem grow>
    <div style={{minWidth: '300px', padding: '24px 24px 0px 24px'}}>
        <div style={{fontWeight: 700, fontSize: '12px', lineHeight: '16px', marginBottom: '4px'}}>{label}</div>
        <div style={{minHeight: '40px', fontSize: '16px', lineHeight: '20px', backgroundColor: '#FAFBFD', borderRadius: '6px', padding: '10px', color: '#6a788f'}}>
            <FlexGroup justifyContent={'spaceBetween'}>
                <FlexItem>
                    {fileName}
                </FlexItem>
                <FlexItem>
                    <a download={fileName} href={Base64Utils.buildDownloadStr({fileName: fileName, data: data})}><Icon type={'download'}/></a>
                </FlexItem>
            </FlexGroup>
        </div>
    </div>
</FlexItem>

const EntityDisplay = ({object, config, ignoreFields = [], columns = 2, renderPopover} :Props) => {
    return <FlexGrid columns={columns} direction={'column'} alignItems={'center'} gutterSize={'none'} responsive={true}>
        {(config || MappingUtils.generateDisplayConfig([object])).filter((c :DisplayConfig) => (!ignoreFields.includes(c.field))).map((c :DisplayConfig) => {
            let value = (typeof object[c.field] === 'boolean') ? `${object[c.field]}` : object[c.field]

            const fieldItem = (value && value['fileName'] && value['data']) ? <Base64FileItem label={c.name} fileName={value['fileName']} data={value['data']}/> :
                <Item field={c.field} key={c.field} label={c.name} text={(c.render) ? c.render(value) : value} renderPopover={renderPopover}/>
            return fieldItem
        })}
    </FlexGrid>

}

export default EntityDisplay