import { RecordNavFieldDisplay } from 'Record'
import { TypeEuiPropsBasicTableColumn, TypeField, TypeFieldDisplay } from 'Type'
import { UtilString } from 'Util'


const renderDefault = <T>(field :(keyof T)) :TypeEuiPropsBasicTableColumn<T> => ({
    field: field as string,
    name: UtilString.titleFromCamel(field as string)
})

const HookNavDisplayColumn = <T extends object>(field :(keyof T), customRenders? :{[field :string] :TypeFieldDisplay}) :TypeEuiPropsBasicTableColumn<T> => {
    const display :TypeFieldDisplay | undefined = (customRenders && customRenders[field as string]) ? customRenders[field as string] : RecordNavFieldDisplay[field as TypeField]
    return (display) ? display.column() : renderDefault(field)
}

export default HookNavDisplayColumn