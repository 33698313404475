import { AuditDisplay } from 'display'
import { DisplayConfig } from 'types'
import NameDisplay from '../parts/NameDisplay'
import DescriptionDisplay from "../parts/DescriptionDisplay"
import IsPublicDisplay from "../parts/IsPublicDisplay"

const config :DisplayConfig[] = [
    ...NameDisplay,
    ...DescriptionDisplay,
    {field: 'type', name: 'Type'},
    ...IsPublicDisplay,
    ...AuditDisplay
]

export default config