import { ComponentEuiLink } from 'Component'
import { HookNavNavigateEntity } from 'Hook'
import { TypeEntityLinkTo } from 'Type'

type Props<T extends object & TypeEntityLinkTo> = {
    entity :T
    showColumn :(keyof T)
}

const ComponentNavDataPageTableSidebarItem = <T extends object & TypeEntityLinkTo>({ entity, showColumn } :Props<T>) => {

    const onClick :() => void = HookNavNavigateEntity({ entity: entity })

    return <ComponentEuiLink onClick={onClick}>{ entity[showColumn] }</ComponentEuiLink>
}

export default ComponentNavDataPageTableSidebarItem