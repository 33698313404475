import {ReactNode, Ref } from 'react'
import { EuiDataGrid } from '@elastic/eui'
import {DataGridCellPopoverElement, DataGridCellValueElement, DataGridColumn, DataGridColumnVisibility, DataGridControlColumn,
    DataGridInMemory, DataGridPagination, DataGridRef, DataGridRowHeightsOptions, DataGridSchemaDetector,
    DataGridSorting, DataGridStyle, DataGridToolbarVisibilityOptions } from 'props'

type Props = {
    height? :string | number
    width? :string | number
    columns :DataGridColumn[]
    leadingControlColumns? :DataGridControlColumn[]
    trailingControlColumns? :DataGridControlColumn[]
    columnVisibility :DataGridColumnVisibility
    schemaDetectors? :DataGridSchemaDetector[]
    rowCount :number
    renderCellValue :(props :DataGridCellValueElement) => ReactNode
    renderCellPopover? :(props :DataGridCellPopoverElement) => ReactNode
    gridStyle? :DataGridStyle
    toolbarVisibility? :DataGridToolbarVisibilityOptions
    inMemory? :DataGridInMemory
    pagination? :DataGridPagination
    sorting? :DataGridSorting
    onColumnResize? :(resize :{ columnId: string, width: number }) => void
    minSizeForControls? :number
    rowHeightOptions? :DataGridRowHeightsOptions
    ref? :Ref<DataGridRef>

}

const DataGrid = ({height, width, columns, leadingControlColumns, trailingControlColumns, columnVisibility,
                    schemaDetectors, rowCount, renderCellValue, renderCellPopover, gridStyle, toolbarVisibility,
                    inMemory, pagination, sorting, onColumnResize, minSizeForControls, rowHeightOptions, ref} :Props) => {
    return <EuiDataGrid aria-labelledby={'data-grid'} height={height} width={width} columns={columns} leadingControlColumns={leadingControlColumns}
        trailingControlColumns={trailingControlColumns} columnVisibility={columnVisibility} schemaDetectors={schemaDetectors}
        rowCount={rowCount} renderCellValue={renderCellValue} renderCellPopover={renderCellPopover} gridStyle={gridStyle}
        toolbarVisibility={toolbarVisibility} inMemory={inMemory} pagination={pagination} sorting={sorting} onColumnResize={onColumnResize}
        minSizeForControls={minSizeForControls} rowHeightsOptions={rowHeightOptions} ref={ref}
    ></EuiDataGrid>
}

export default DataGrid