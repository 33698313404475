import { EuiButtonIcon } from '@elastic/eui'
import { TypeEuiPropsIconType } from 'Type'

type Props = {
    type? :string
    iconType :TypeEuiPropsIconType
    color? :'text' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'ghost'
    isDisabled? :boolean
    size? :'xs' | 's' | 'm'
    iconSize? :'s' | 'm' | 'l' | 'xl' | 'original' | 'xxl'
    isSelected? :boolean
    display? :'base' | 'fill' | 'empty'
    isLoading? :boolean
    onClick? :() => void
}

const ComponentEuiButtonIcon = ({type, iconType, color, isDisabled, size, iconSize, isSelected, display, isLoading, onClick} :Props) =>
    <EuiButtonIcon aria-label={iconType} type={type} iconType={iconType} color={color} isDisabled={isDisabled} size={size} iconSize={iconSize}
                   isSelected={isSelected} display={display} isLoading={isLoading} onClick={onClick}/>

export default ComponentEuiButtonIcon