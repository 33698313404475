import {AxiosRequestHeaders} from 'axios'
import { HookControlSwitch, HookHttpUrl, HookNavHttpHeaders } from 'Hook'
import { useEffect, useState } from 'react'
import { TypeControlSwitch, TypeHttpControlDownloadBlob } from 'Type'
import { UtilHttpClient } from 'Util'

const HookHttpDownloadBlob = (path? :string) :TypeHttpControlDownloadBlob => {

    const { url } = HookHttpUrl(path)
    const headers :AxiosRequestHeaders = HookNavHttpHeaders()
    const [blob, setBlob] = useState<Blob | undefined>()

    const loadingControl :TypeControlSwitch = HookControlSwitch()

    const isLoading :boolean = loadingControl.value

    const getBlob = async () => {
        if (!url) return
        loadingControl.turnOn()
        setBlob(await UtilHttpClient.blob(url, headers))
        loadingControl.turnOff()
    }

    useEffect(() => {
        getBlob()
    }, [path])

    return { blob, isLoading }
}

export default HookHttpDownloadBlob