import { HookHttpClient } from 'Hook'
import { useState } from 'react'
import {TypeHttpControl, TypeHttpControlAction, TypeHttpPayload, TypeUrlParam } from 'Type'

type SendMethod = 'post' | 'patch' | 'put' | 'delete'

type Props = {
    path? :string,
    method :SendMethod,
    paramUpdates? :TypeUrlParam[],
    initialPayload? :TypeHttpPayload,
    calcIsActionable? :(currentPayload :TypeHttpPayload) => boolean
    authorized? :boolean
}

const HookHttpAction = <Res>({path, method, paramUpdates, initialPayload, calcIsActionable, authorized = true} :Props) :TypeHttpControlAction<Res> => {

    const [payload, setPayload] = useState<TypeHttpPayload>(initialPayload ?? {})

    const addToPayload = (newPayload :TypeHttpPayload) => {
        setPayload((currentPayload :TypeHttpPayload) => {
            return {...currentPayload, ...newPayload}
        })
    }

    const resetPayload = () => setPayload(initialPayload ?? {})

    const control :TypeHttpControl<Res> = HookHttpClient({path, method, paramUpdates, payload})

    const isActionable :boolean = authorized && ((calcIsActionable) ? calcIsActionable(payload) : true)

    return {...control, payload, addToPayload, resetPayload, isActionable}
}

export default HookHttpAction