import { NoDataPlaceholder } from 'components'
import { ExtractEntity, SheetEntity } from 'entity'
import {LoadingSpinner, PageSection } from 'eui'
import { ExtractSheetDataTable } from 'modules'
import { useEffect, useState } from 'react'
import {ExtractStore, NavStore } from 'store'
import { Nav, NavDataPage } from 'types'


const ExtractPageContent = () => {

    const data :any[] | undefined = ExtractStore(store => store.data)
    const loading :boolean = ExtractStore(store => store.loading)

    return (loading) ? <NoDataPlaceholder>
        <LoadingSpinner size={'xxl'}/>
    </NoDataPlaceholder> :
    <PageSection paddingSize={'none'}>
        <div style={{margin: '-23px -1px 0px -1px'}}>
            { data && <ExtractSheetDataTable data={data}/> }
        </div>
    </PageSection>
}

export default ExtractPageContent