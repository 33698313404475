import {ComponentEuiLoadingSpinner, ComponentNavDataPageTable, ComponentPagePanel } from 'Component'
import {HookModuleVersionColumnMapping, HookNavResourceNav } from 'Hook'
import {ReactNode} from 'react'
import {TypeEntityInterfaceMap, TypeEntityInterfaceMapField, TypeEntityTableVersion,
    TypeEntityTableVersionColumn, TypeModuleVersionColumnMappingControl,
    TypeNav, TypeNavHttpControl, TypeNavKey } from 'Type'
import { ModuleVersionRequesterMapFieldTableColumnMapper } from 'Module'


type Props = {
    navControl :TypeNavHttpControl<TypeEntityInterfaceMap>
    allColumnsNav :TypeNav<TypeEntityTableVersionColumn>
    columnRefresh :() => void
    versionNav :TypeNav<TypeEntityTableVersion>
}

const key :TypeNavKey = 'field'

const ModuleVersionRequesterMapFieldTable = ({ navControl, allColumnsNav, columnRefresh, versionNav } :Props) => {

    const nav :TypeNav<TypeEntityInterfaceMap> | undefined = navControl.res?.data
    const columnMappingControl :TypeModuleVersionColumnMappingControl = HookModuleVersionColumnMapping(versionNav, allColumnsNav, columnRefresh, nav)

    const map :TypeEntityInterfaceMap | undefined = nav?.data.entity
    const fieldNavControl :TypeNavHttpControl<TypeEntityInterfaceMapField> = HookNavResourceNav({key, nav})
    const fieldNav :TypeNav<TypeEntityInterfaceMapField> | undefined = fieldNavControl.res?.data

    const customMenuComponent = (navControl :TypeNavHttpControl<TypeEntityInterfaceMapField>) :ReactNode => <ModuleVersionRequesterMapFieldTableColumnMapper navControl={navControl} columnMappingControl={columnMappingControl}/>

    return (fieldNav && map) ? <ComponentPagePanel title={`${map.name} Fields`}>
        <ComponentNavDataPageTable initialNav={fieldNav} entityName={'Field'} showColumns={['name', 'type', 'isRequired']} action={'custom'} customMenuComponent={customMenuComponent}/>
    </ComponentPagePanel> : <ComponentEuiLoadingSpinner/>
}

export default ModuleVersionRequesterMapFieldTable