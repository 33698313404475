import {AxiosRequestHeaders} from 'axios'
import {HookControlSwitch, HookHttpUrl, HookNavHttpHeaders } from 'Hook'
import {TypeControlSwitch, TypeHttpControlDownload } from 'Type'
import { UtilHttpClient } from 'Util'

const HookHttpDownload = (path? :string) :TypeHttpControlDownload => {

    const { url } = HookHttpUrl(path)
    const headers :AxiosRequestHeaders = HookNavHttpHeaders()
    const switchControl :TypeControlSwitch = HookControlSwitch()


    const download = async () => {
        if (!url) return
        switchControl.turnOn()
        await UtilHttpClient.download(url, headers)
        switchControl.turnOff()
    }

    const isLoading :boolean = switchControl.value

    return { isLoading, download }

}

export default HookHttpDownload