import { ComponentEuiFlexGrid, ComponentEuiFlexItem } from 'Component'
import {ModuleTransformOutputTablePopoverRowContentExclude, ModuleTransformOutputTablePopoverRowContentHeader } from 'Module'
import { TypeEntityTransform, TypeModuleControlRefresh, TypeNav, TypeNavControlResource } from 'Type'

type Props = {
    rowIndex :string
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformOutputTablePopoverRowContent = ({rowIndex, transformNav, excludeResource, refreshControl} :Props) => {

    const currentRowIndex :number | undefined = transformNav.data.entity?.headerRowIndex

    return <ComponentEuiFlexGrid columns={1} gutterSize={'s'}>
        { (currentRowIndex) ? null : <ComponentEuiFlexItem>
            <ModuleTransformOutputTablePopoverRowContentHeader rowIndex={rowIndex} transformNav={transformNav} refreshControl={refreshControl}/>
        </ComponentEuiFlexItem> }
        <ComponentEuiFlexItem>
            <ModuleTransformOutputTablePopoverRowContentExclude rowIndex={rowIndex} excludeResource={excludeResource} refreshControl={refreshControl}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGrid>
}

export default ModuleTransformOutputTablePopoverRowContent