import {HookNavRedirect} from 'Hook'
import {useEffect} from 'react'
import { TypeNavKey } from 'Type'

type Props = {
    navKey: TypeNavKey
}

const ComponentNavPageRedirect = ({ navKey } :Props) => {
    const redirect :() => void = HookNavRedirect(navKey)
    useEffect(() => { redirect() }, [])
    return null
}

export default ComponentNavPageRedirect