import {ComponentDivMarginHorizontal, ComponentDivPadding,
    ComponentEuiBeacon, ComponentEuiFlexGroup, ComponentEuiTablePagination, ComponentNavActionDownloadButton } from 'Component'
import { FlexItem } from 'eui'
import { HookNavPaginatePage } from 'Hook'
import { TypeNav, TypeWebSocketControl } from 'Type'

type Props = {
    nav? :TypeNav<any>
    itemsPerPageOptions? :number[]
    socketControl? :TypeWebSocketControl<any>
}

const ComponentNavDataPagePagination = ({ nav, itemsPerPageOptions = [5, 10, 25, 50, 100], socketControl } :Props) => {

    const { size, page, pageCount, setPage, setSize } = HookNavPaginatePage(nav)

    return <ComponentDivPadding padding={4}>
        <ComponentEuiFlexGroup alignItems={'center'} gutterSize={'s'}>
            { nav && <ComponentNavActionDownloadButton nav={nav}/> }
            <FlexItem grow>
                <ComponentEuiTablePagination showPerPageOptions={true} itemsPerPage={size} activePage={page} pageCount={pageCount}
                     itemsPerPageOptions={itemsPerPageOptions} onChangeItemsPerPage={setSize} onChangePage={setPage}/>
            </FlexItem>
            {socketControl && <FlexItem>
              <ComponentDivMarginHorizontal margin={10}>
                <ComponentEuiBeacon color={(socketControl.isConnected) ? 'success' : 'subdued'}/>
              </ComponentDivMarginHorizontal>
            </FlexItem>}
        </ComponentEuiFlexGroup>
    </ComponentDivPadding>
}

export default ComponentNavDataPagePagination