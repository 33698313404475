import React, { ReactNode } from 'react'

import {
    ComponentDivMarginHorizontal, ComponentEuiHeader, ComponentEuiHeaderSection, ComponentEuiHeaderSectionItem, ComponentMorphLogoHeader } from 'Component'
import { ModulePageHeaderBreadcrumbs, ModulePageHeaderItemNavigator, ModulePageHeaderItemLink, ModulePageHeaderItemProfile } from 'Module'
import { TypeNavHttpControl } from 'Type'
import { FORMAT_PAGE_URLS } from 'Format'
import { GlobalSession } from 'Global'

type Props = {
    control? :TypeNavHttpControl<any>
    hasBreadcrumbs :boolean,
    rightActions? :ReactNode
}

const ModulePageHeader = ({ control, hasBreadcrumbs, rightActions } :Props) => {

    const globalId :string | undefined = GlobalSession(store => store.globalId)

    return <>
        <ComponentEuiHeader dark>
            <ComponentEuiHeaderSection grow={false}>
                <ComponentEuiHeaderSectionItem>
                    <ComponentMorphLogoHeader/>
                </ComponentEuiHeaderSectionItem>
            </ComponentEuiHeaderSection>
            <ComponentEuiHeaderSection grow={false}>
                <ModulePageHeaderItemNavigator/>
                {(globalId) ? <ModulePageHeaderItemProfile globalId={globalId}/> : <>
                    <ModulePageHeaderItemLink title={'Login'} link={FORMAT_PAGE_URLS.userLogin}/>
                    <ModulePageHeaderItemLink title={'Signup'} link={FORMAT_PAGE_URLS.userSignup}/>
                </>}
            </ComponentEuiHeaderSection>
        </ComponentEuiHeader>
        { hasBreadcrumbs && control && <ComponentEuiHeader>
            <ComponentEuiHeaderSection grow>
                <ComponentEuiHeaderSectionItem>
                    <ModulePageHeaderBreadcrumbs control={control}/>
                </ComponentEuiHeaderSectionItem>
            </ComponentEuiHeaderSection>
            <ComponentEuiHeaderSection>
                <ComponentEuiHeaderSectionItem>
                    <ComponentDivMarginHorizontal margin={12}>
                        { rightActions }
                    </ComponentDivMarginHorizontal>
                </ComponentEuiHeaderSectionItem>
            </ComponentEuiHeaderSection>
        </ComponentEuiHeader> }
    </>
}

export default ModulePageHeader