import { ComponentEuiFieldSwitch } from 'Component'
import { TypeControlSwitch } from 'Type'

type Props = {
    label :string
    control :TypeControlSwitch
}

const ComponentControlSwitch = ({ label, control } :Props) => {

    return <ComponentEuiFieldSwitch label={label} value={`${control.value}`} onChange={control.toggle}/>
}

export default ComponentControlSwitch