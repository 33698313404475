import {HookNavParamSimple, HookPageParamSimple } from 'Hook'
import {
    TypeControlParamSimple,
    TypeEntityTableVersionColumn,
    TypeEuiPropsDataGridSorting, TypeModuleTableColumnControlSort, TypeNav, TypeNavDataPageRequestSortDirection, TypeNavHttpControl } from 'Type'

const sortDirections :TypeNavDataPageRequestSortDirection[] = ['asc', 'desc']

const isValidDirection = (sortDirection :string) :boolean => sortDirections.includes(sortDirection as TypeNavDataPageRequestSortDirection)

const isValidFieldFn = (columns :TypeEntityTableVersionColumn[]) => (sortField :string) :boolean => columns.find((col :TypeEntityTableVersionColumn) => col.nameSlug === sortField) !== undefined

const defaultPageValue :boolean = true

const HookModuleTableColumnSort = (nav :TypeNav<any>, columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>) :TypeModuleTableColumnControlSort => {

    HookNavParamSimple({type: 'sortDirection', nav, defaultPageValue})
    HookNavParamSimple({type: 'sortField', nav, defaultPageValue})

    const [sortDirectionStr, setSortDirection] :TypeControlParamSimple = HookPageParamSimple('sortDirection', isValidDirection)
    const sortDirection :TypeNavDataPageRequestSortDirection | undefined = sortDirectionStr as TypeNavDataPageRequestSortDirection
    const [sortField, setSortField] :TypeControlParamSimple = HookPageParamSimple('sortField', isValidFieldFn(columnControl.res?.data?.data.page?.content ?? []))

    const sort = (nameCamel :string, dir :TypeNavDataPageRequestSortDirection) => {
        if (nameCamel !== sortField) setSortField(nameCamel)
        if (dir as string !== sortDirection) setSortDirection(dir)
    }

    const sorting :TypeEuiPropsDataGridSorting = {
        onSort: (columns: { id: string; direction: 'asc' | 'desc'; }[]) => {
            if (columns.length) sort(columns[0].id, columns[0].direction)
        },
        columns: (sortField && sortDirection) ? [{id: sortField, direction: sortDirection}] : []
    }

    return { sort, sorting, sortDirection, sortField }
}

export default HookModuleTableColumnSort