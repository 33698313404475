import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiSpacer, ComponentNavSidebarTitle } from 'Component'
import { ModuleApiSecretReset } from 'Module'

import { TypeEntityApi, TypeNav } from 'Type'

type Props = {
    apiEntity :TypeEntityApi
    secretNav? :TypeNav<TypeEntityApi>
}

const PageApiSidebar = ({ apiEntity, secretNav } :Props) => {

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={apiEntity.name} iconType={'bolt'} entityName={'Api'}/>
            <ComponentEuiSpacer/>
            <ModuleApiSecretReset apiEntity={apiEntity} secretNav={secretNav}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageApiSidebar