import {ComponentEuiButtonEmpty, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { ModuleTransformTitlePopoverExcludeContent } from 'Module'
import { TypeControlSwitch, TypeModuleControlRefresh, TypeNavControlResource } from 'Type'

type Props = {
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformTitlePopoverExclude = ({excludeResource, refreshControl} :Props) => {

    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'none'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} color={'primary'} iconType={'arrowDown'} iconSide={'right'} size={'s'}>{'Exclude'}</ComponentEuiButtonEmpty>}>
        <ModuleTransformTitlePopoverExcludeContent excludeResource={excludeResource} refreshControl={refreshControl} onClose={menuSwitch.turnOff}/>
    </ComponentEuiPopover>
}

export default ModuleTransformTitlePopoverExclude