import {FlexGroup, FlexItem, Page, PageBody } from 'eui'
import { HomePageFooterSection } from 'modules'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

const StaticPage = ({children} :Props) => {
    return <Page>
        <PageBody>
            <FlexGroup direction={'column'} justifyContent={'spaceBetween'}>
                <FlexItem>
                    {children}
                </FlexItem>
                <FlexItem>
                    <HomePageFooterSection/>
                </FlexItem>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default StaticPage