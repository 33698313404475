import { EuiSelectable, EuiSelectableOption } from '@elastic/eui'
import { Spacer } from 'eui'
import { ReactElement, useState } from 'react'

type SelectableSearchProps = {
    id? :string
    //value? :string
    //placeholder? :string
    //append? :string | ReactElement
    //prepend? :string | ReactElement
    //name? :string
    //fullWidth? :boolean
    //isLoading? :boolean
    //compressed? :boolean
    //inputRef? :(node: HTMLInputElement) => void
    //isInvalid? :boolean
    //isClearable? :boolean
    //onChange? :(searchValue: string, matchingOptions: any[]) => void
}

type SelectableOptionsList = {
    activeOptionIndex? :number
    showIcons? :boolean
    singleSelection? :boolean | 'always'
    bordered? :boolean
    onFocusBadge? :boolean
    paddingSize? :'s' | 'none'
    textWrap? :'truncate' | 'wrap'
    rowHeight? :number
    isVitualized? :boolean
}

type SelectableOptionProps = {
    label? :string
    searchableLabel? :string
    key? :string
    checked? :'on' | 'off'
    disabled? :boolean
    isGroupLabel? :boolean
    prepend? :any
    append? :any
    ref? :(optionIndex: number) => void
    data? :{ [key: string]: any; }
}


type Props = {
    searchable? :boolean
    searchProps? :SelectableSearchProps
    children? :any
    options? :SelectableOptionProps[]
    singleSelection? :boolean | 'always'
    allowExclusions? :boolean
    isLoading? :boolean
    height? :number | 'full'
    listProps? :SelectableOptionsList
    loadingMassage? :string | ReactElement
    noMatchesMessage? :string | ReactElement
    emptyMessage? :string | ReactElement
    errorMessage? :string | ReactElement
    isPreFiltered? :boolean
}

type SimpleSelectItem = {
    label :string
    checked? :'on' | 'off'
    disabled? :boolean
}

type SimpleProps = {
    options :SimpleSelectItem[]
    onChange :(options: SimpleSelectItem[]) => void
    onSearchChange :(searchValue: string, matchingOptions: SimpleSelectItem[]) => void
    searchPrepend? :any
    placeholder? :string
    emptyMessage? :string | ReactElement
    isLoading? :boolean
    listProps? :SelectableOptionsList
    height? :number | 'full'
}

const Selectable = ({options, onChange, onSearchChange, searchPrepend, placeholder = 'Search', emptyMessage = '', isLoading, listProps} :SimpleProps) => {
    return (
        <EuiSelectable
            placeholder={placeholder}
            aria-label={placeholder}
            searchable={true}
            isLoading={isLoading}
            searchProps={{
                'data-test-subj': 'selectableSearchHere',
                prepend: searchPrepend,
                onChange: onSearchChange
            }}
            options={options}
            onChange={onChange}
            emptyMessage={emptyMessage}
            listProps={listProps}
        >
            {(list, search) => (
                <>
                    {search}
                    <Spacer size={'m'}/>
                    {list}
                </>
            )}
        </EuiSelectable>
    );
};

export default Selectable