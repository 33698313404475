import {ComponentMoment, ComponentNavGlobalIdLink } from 'Component'
import {TypeField, TypeFieldDisplay } from 'Type'


const renderGlobalId = (value :any) => <ComponentNavGlobalIdLink globalId={value}/>
const renderDateTimeZ = (value :any) => <ComponentMoment value={value} display={'date-time-z'}/>

const RecordNavFieldDisplay :Record<TypeField, TypeFieldDisplay> = {
    createdBy: {column: () => ({field: 'createdBy', name: 'Created By', render: renderGlobalId})},
    createdAt: {column: () => ({field: 'createdAt', name: 'Created At', render: renderDateTimeZ})},
    fieldNameCamel: {column: () => ({field: 'fieldNameCamel', name: 'Field'})},
    isRequired: {column: () => ({field: 'isRequired', name: 'Required'})},
    isPublic: {column: () => ({field: 'isPublic', name: 'Public'})},
    globalId: {column: () => ({field: 'globalId', name: 'Name', render: renderGlobalId})},
    ownerGlobalId: {column: () => ({field: 'ownerGlobalId', name: 'Owner', render: renderGlobalId})},
}

export default RecordNavFieldDisplay