import { FlexPageContent } from 'components'
import {FlexGroup, Page, PageBody } from 'eui'
import { TablePageLeftNavBar, UserPageLeftNavBar, WorkspacePageContent, WorkspacePageLeftNavBar } from 'modules'
import {NavKey } from 'types'

type Props = {
    tab? :NavKey
}

const WorkspacePage = ({tab} :Props) => {

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar forceCollapsed={true} wsSelected/>
                {(tab === 'table') ? <TablePageLeftNavBar/> : <WorkspacePageLeftNavBar tab={tab}/>}
                <FlexPageContent leftBorder={true}>
                    <WorkspacePageContent tab={tab}/>
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default WorkspacePage