import { ComponentEuiButtonIcon } from 'Component'
import { HookControlSwitch, HookNavGet } from 'Hook'
import { ModuleTableCellArchiveModal } from 'Module'
import {TypeControlSwitch, TypeEntityCell, TypeEntityCellArchive,
    TypeEntityRow,
    TypeEntityTableVersionColumn, TypeNav, TypeNavHttpControl, TypeNavKey } from 'Type'

type Props = {
    nav :TypeNav<TypeEntityCell>
    column :TypeEntityTableVersionColumn
    row :TypeEntityRow
}

const resource :TypeNavKey = 'archive'

const ModuleTableCellArchive = ({ nav, column, row } :Props) => {

    const modalSwitch :TypeControlSwitch = HookControlSwitch()

    const linkTo :string | undefined = (modalSwitch.value) ? nav.path : undefined

    const initialNavControl :TypeNavHttpControl<TypeEntityCellArchive> = HookNavGet({linkTo, resource})
    const initialNav :TypeNav<TypeEntityCellArchive> | undefined = initialNavControl.res?.data

    return <>
        <ComponentEuiButtonIcon color={'text'} iconType={'layers'} isLoading={initialNavControl.isLoading} onClick={modalSwitch.turnOn}/>
        { modalSwitch.value && initialNav && <ModuleTableCellArchiveModal initialNav={initialNav} onClose={modalSwitch.turnOff} column={column} row={row}/> }
    </>
}

export default ModuleTableCellArchive