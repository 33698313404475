import jwt_decode, { JwtPayload } from 'jwt-decode';

const decode = (token :string) :JwtPayload | undefined => {
    return jwt_decode<JwtPayload>(token)
}

const isExpired = (jwt? :JwtPayload) :boolean => {
    if (!jwt || !jwt.exp) return false
    return (!jwt.exp || jwt.exp * 1000 > Date.now()) ? false : true
}

const Jwt = {
    decode: decode,
    isExpired: isExpired,
    isTokenExpired: (token :string) :boolean => {
        return isExpired(decode(token))
    },
    extractSub: (token :string | undefined) :string | undefined => {
        if (!token) return undefined
        const decoded :JwtPayload = jwt_decode<JwtPayload>(token);
        return decoded.sub
    }
}

export default Jwt