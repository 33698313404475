import { ContextTableData } from 'Context'
import {useContext} from 'react'
import { TypeContextTableData, TypeEuiPropsDataGridCellValueElement } from 'Type'

const ModuleRowSheetDataCell = ({ rowIndex, columnId } :TypeEuiPropsDataGridCellValueElement) => {
    const context :TypeContextTableData = useContext(ContextTableData)
    const data :any[] = context.data
    const row :any = data[rowIndex] ?? {}
    return <div style={{minHeight: '36px', padding: '6px'}}>{row[columnId] ?? ''}</div>
}

export default ModuleRowSheetDataCell