import { PageTransform } from 'Page';
import { TransformationPage } from 'pages';
import { FinderNode } from 'types';

const Transformation :FinderNode = {
    next: {
        '*' : {
            render: () => /*<TransformationPage/>*/ <PageTransform/>,
            next: {
            }
        }
    }
}

export default Transformation