import { NoDataPlaceholder } from 'components'
import { ChildEntity, TableVersionColumnEntity } from 'entity'
import { TableVersionRowTable } from 'modules'
import { useEffect } from 'react'
import { NavStore } from 'store'
import { Nav, NavDataPage, TableColumnHookControl} from 'types'
import {useTableColumns} from 'hooks'
import {NavClient, RouteUtils} from 'utils';
import {TableSectionToolbar} from "../index";
import {PageSection} from "../../eui";

type Props = {
    child :ChildEntity
}


const RowContentChildTable = ({child} :Props) => {

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const refresh :() => void = NavStore(store => store.refresh)
    const rowPage :NavDataPage<any> | undefined = pageNav?.data.page

    const tableLinkTo :string = child.childTableLinkTo
    const versionNumber :number = child.childVersionNumber

    const columnsControl :TableColumnHookControl = useTableColumns()

    const {fetchColumns, toggleShowSystemColumns, moveColumn, columns} :TableColumnHookControl = columnsControl

    const doFetchColumns = async () => {
        const childVersionNav :Nav = await NavClient.fetchNav(RouteUtils.version(tableLinkTo, versionNumber))
        fetchColumns(childVersionNav)
    }

    useEffect(() => {
        doFetchColumns()
    }, [tableLinkTo, versionNumber])

    if (!pageNav || !rowPage || !columns) return null

    const hasRows :boolean = rowPage.totalElements > 0
    const hasColumns :boolean = columns.filter((c :TableVersionColumnEntity) => !c.isSystem).length > 0

    if (!hasColumns) return <NoDataPlaceholder text={'This table has no columns.  Please click on the column button to add some.'}/>
    if (!hasRows) return <NoDataPlaceholder text={'This table has no rows.  The table will display once you have added one.'}/>

    return <>
        <TableSectionToolbar columnControls={columnsControl}/>
        <PageSection paddingSize={'none'}>
            <div style={{margin: '-1px -1px'}}>
                <TableVersionRowTable columns={columns} rowNav={pageNav} moveColumn={moveColumn}/>
            </div>
        </PageSection>
    </>
}

export default RowContentChildTable