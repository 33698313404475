import { AuditDisplay } from 'display';
import DescriptionDisplay from 'display/parts/DescriptionDisplay';
import IsPublicDisplay from 'display/parts/IsPublicDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import OwnerDisplay from 'display/parts/OwnerDisplay';
import {CellEntity } from 'entity';
import { DisplayConfig } from 'types';

const config :DisplayConfig<CellEntity>[] = [
    {
        field: 'value',
        name: 'Value',
    },
    {
        field: 'versionNumber',
        name: 'Version Number',
    },
    ...AuditDisplay
]

export default config