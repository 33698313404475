import {Button, Card, FlexGroup, FlexItem, Link, Page, Spacer, Text } from 'eui'
import { HeaderBar } from 'modules'
import { useNavigate } from 'react-router-dom'
import { Nav, NavError } from 'types'

type Props = {
    nav? :Nav
    token? :string
}

const ErrorPage = ({nav, token} :Props) => {

    const navigate = useNavigate()

    const safeRenderHeader = () => {
        try {
            return <HeaderBar/>
        } catch (err) {
            return <></>
        }
    }

    const title :string = nav?.error?.message ?? 'Something went wrong.'

    const lastSuccessfulRoute :string = nav?.error?.lastSuccessfulRoute ?? '/'


    return <>
        {safeRenderHeader()}
        <Page>
            <FlexGroup justifyContent={'center'} alignItems={'center'}>
                <FlexItem>
                    <Card icon={'wrench'} iconSize={'xxl'} display={'danger'} title={title} footer={
                        <div>
                            <Button aria-label='Go back' onClick={() => {navigate(lastSuccessfulRoute)}} color={'danger'}>Go back</Button>
                            <Spacer size='s'/>
                        </div>
                    }/>
                </FlexItem>
            </FlexGroup>

        </Page>
    </>
}

export default ErrorPage