import React from 'react'
import { EuiListGroup } from '@elastic/eui'

type Props = {
    listItems: any[]
}

const HeaderSectionItem = ({ listItems }: Props) =>
    <EuiListGroup listItems={listItems}/>

export default HeaderSectionItem