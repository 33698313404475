import { ReactNode } from 'react'
import { EuiBadge } from '@elastic/eui'

type Props = {
    iconType? :string
    iconSide? :'left' | 'right'
    color? :string
    isDisabled? :boolean
    iconOnClick? :() => void
    children :ReactNode
}

const fn :() => void = () => {}

const ComponentEuiBadge = ({iconType, iconSide, color, isDisabled, children, iconOnClick} :Props) => {
    return <EuiBadge iconType={iconType} iconSide={iconSide} color={color} isDisabled={isDisabled}
                     iconOnClick={iconOnClick || fn} iconOnClickAriaLabel={' '}>{children}</EuiBadge>
}

export default ComponentEuiBadge