import { ReactNode } from 'react'
import {Text} from 'eui'

type ParaProps = {
    children: ReactNode
}

const StaticPageParagraph = ({children} :ParaProps) => {
    return <Text><p style={{lineHeight: '160%'}}>
        {children}
    </p></Text>
}

export default StaticPageParagraph