import {Nav, NavControlAction, NavControlActionType, NavControlResource, NavDataPage} from 'types'
import {NavClient} from 'utils'
import {ButtonEmpty} from 'eui'
import {ExtractEntity} from 'entity'
import {useNavigate} from 'react-router-dom'

type Props = {
    extractResource :NavControlResource
    versionNumber :number
}

const postActionType :NavControlActionType = 'post'

const CellExtractNavigationButton = ({extractResource, versionNumber} :Props) => {

    const navigate = useNavigate()

    if (!extractResource) return null

    const navigateToExtract = async () => {
        const extractsNav :Nav = await NavClient.fetchNav(extractResource.linkTo)
        const postAction :NavControlAction | undefined = extractsNav.control.action[postActionType]
        if (postAction.auth.authorized) {
            const newExtractNav = await NavClient.action(extractsNav, postActionType, {})
            const newExtractEntity: ExtractEntity | undefined = newExtractNav.data.entity
            if (newExtractEntity) navigate(newExtractEntity.linkTo)
        } else {
            const page :NavDataPage<ExtractEntity> | undefined = extractsNav.data.page
            const filteredContent :ExtractEntity[] = page?.content.filter((e :ExtractEntity) => e.cellVersionNumber === versionNumber) ?? []
            if (page && filteredContent.length > 0) navigate(filteredContent[0].linkTo)
        }
    }

    return (extractResource) ? <ButtonEmpty size={'m'} color={'primary'} fill={false} onClick={navigateToExtract} iconType={'tableDensityNormal'}>Extract</ButtonEmpty> : null

}

export default CellExtractNavigationButton