import {ComponentDivMarginHorizontal, ComponentDivMarginVertical, ComponentNavMetaPageSectionHeader } from 'Component'
import {HookModuleRowExtractSheet, HookNavContext, HookNavContextOptional } from 'Hook'
import {ModulePageLoading, ModuleRowSheetData, ModuleRowSheetTabs } from 'Module'
import {TypeEntityExtract, TypeEntityExtractSheet, TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    pageControl :TypeNavHttpControl<any>
}

const PageRowBodyExtract = ({ pageControl } :Props) => {

    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    const extractContext :TypeEntityExtract | undefined = HookNavContext('extract', pageNav)
    const sheetContext :TypeEntityExtractSheet | undefined = HookNavContextOptional('sheet', pageNav)

    const sheetResourceControl :TypeNavHttpControl<TypeEntityExtractSheet> = HookModuleRowExtractSheet(pageNav)
    const sheets :TypeEntityExtractSheet[] = sheetResourceControl.res?.data?.data.page?.content ?? []

    if (!extractContext) return null

    const data :any[] = pageNav?.data.page?.content ?? []

    const toLoad :TypeHttpLoadable[] = [
        { label: 'Data', control: pageControl },
        { label: 'Sheets', control: sheetResourceControl },
    ]

    return <ModulePageLoading toLoad={toLoad}>
        <ComponentNavMetaPageSectionHeader title={`Extract ${extractContext.cellFileName}`} compact/>
        {sheetContext && <>
          <ModuleRowSheetTabs sheets={sheets} active={sheetContext}/>
          <ComponentDivMarginHorizontal margin={-1}>
            <ComponentDivMarginVertical margin={-1}>
                <ModuleRowSheetData data={data}/>
            </ComponentDivMarginVertical>
          </ComponentDivMarginHorizontal>
        </>}
    </ModulePageLoading>
}

export default PageRowBodyExtract