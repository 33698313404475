import {ApiDisplay, UserDisplay, WorkspaceDisplay, OrganisationDisplay, MembershipDisplay, MemberDisplay, TableDisplay,
    VersionDisplay, ColumnDisplay, CellDisplay, ArchiveDisplay, ExtractDisplay, TransformationDisplay, TransformationHeaderErrorDisplay,
    IfaceDisplay, FieldDisplay, ResponderDisplay, UrlDisplay, ConnectionDisplay} from 'display'
import { Link } from 'react-router-dom'
import { DisplayConfig, NavControlActionType, NavKey } from 'types'
import { StringUtils } from 'utils'
import {InterfaceMapFieldDisplay} from "../../display";

const linkColumn = 'linkTo'

const keyToColumn = (key :string) :DisplayConfig => {
    const isLink = (key === linkColumn);
    let column = {
        field: key,
        name: key,
        truncateText: true,
        render: (isLink) ? (value :any) => {
            return <Link to={value}>{value}</Link>
        } : undefined,
        sortable: (!isLink)
    }
    return column;
}

const largestObject = (list :any[]) :any => {
    if (!list || list.length === 0) return {}
    let longestObject :any = list[0]
    let longestLength :number = Object.keys(list[0]).length
    for (let i = 1; i < list.length; i++) {
        const length = Object.keys(list[i]).length
        if (length > longestLength) {
            longestLength = length
            longestObject = list[i]
        }
    }
    return longestObject
}

const getItemColumns = (item :any) :any[] => {
    return Object.keys(item).filter(k => (k !== linkColumn)).map(keyToColumn);
}

type MapContextItem = {
    icon :string,
    title :string,
    order :number,
    displayConfig :DisplayConfig[]
}

const contextMap : Map<NavKey, MapContextItem> = new Map<NavKey, MapContextItem>([
    ['user', { icon: 'userAvatar', title: 'User', order: 1, displayConfig: UserDisplay }],
    ['org', { icon: 'logoEnterpriseSearch', title: 'Ogansiation', order: 2, displayConfig: OrganisationDisplay }],
    ['ws', { icon: 'spaces', title: 'Workspace', order: 3, displayConfig: WorkspaceDisplay }],
    ['api', { icon: 'logoSiteSearch', title: 'API', order: 4, displayConfig: ApiDisplay }],
    ['membership', { icon: 'logoMetrics', title: 'Membership', order: 5, displayConfig: MembershipDisplay }],
    ['member', { icon: 'logoMetrics', title: 'Member', order: 6, displayConfig: MemberDisplay }],
    ['table', { icon: 'logoElasticStack', title: 'Table', order: 7, displayConfig: TableDisplay }],
    ['version', { icon: 'logoLogging', title: 'Version', order: 8, displayConfig: VersionDisplay }],
    ['column', {icon: '', title: 'Column', order: 9, displayConfig: ColumnDisplay }],
    ['cell', {icon: '', title: 'Cell', order: 10, displayConfig: CellDisplay}],
    ['archive', {icon: '', title: 'Archive', order: 11, displayConfig: ArchiveDisplay}],
    ['extract', {icon: 'logoEnterpriseSearch', title: 'Extract', order: 12, displayConfig: ExtractDisplay}],
    ['row', {icon: '', title: 'Row', order: 13, displayConfig: []}],
    ['sheet', {icon: 'logoObservability', title: 'Sheet', order: 14, displayConfig: TransformationDisplay}],
    ['transform', {icon: 'logoWorkplaceSearch', title: 'Transformation', order: 15, displayConfig: TransformationDisplay}],
    ['error', {icon: '', title: 'Error', order: 16, displayConfig: TransformationHeaderErrorDisplay}],
    ['iface', {icon: '', title: 'Interface', order: 17, displayConfig: IfaceDisplay}],
    ['field', {icon: '', title: 'Map Field', order: 18, displayConfig: FieldDisplay}],
    ['responder', {icon: '', title: 'Responder', order: 19, displayConfig: ResponderDisplay}],
    ['url', {icon: '', title: 'Url', order: 20, displayConfig: UrlDisplay}],
    ['map-field', {icon: '', title: 'Map Map Field', order: 20, displayConfig: InterfaceMapFieldDisplay}],
    ['conn', {icon: '', title: 'Connection', order: 21, displayConfig: ConnectionDisplay}]
])

const getContextOrder = (key :NavKey) :number => {
    const item :MapContextItem | undefined = contextMap.get(key)
    return (item) ? item.order : 100
}

const generateDisplayConfig = (items :any[]) => {
    if (items.length === 0) return []
    const obj :any = largestObject(items)
    return getItemColumns(obj)
}

const MappingUtils = {
    contextIcon: (key :NavKey) :string => {
        const item :MapContextItem | undefined = contextMap.get(key)
        return (item) ? item.icon : 'kubernetesPod'
    },
    contextTitle: (key :NavKey) :string => {
        const item :MapContextItem | undefined = contextMap.get(key)
        return (item) ? item.title : StringUtils.capitalizeFirst(key)
    },
    contextDisplayConfig: (key :NavKey, ignoreAudit? :boolean) :DisplayConfig[] => {
        const item :MapContextItem | undefined = contextMap.get(key)
        const config :DisplayConfig[] = (item) ? item.displayConfig : []
        return (ignoreAudit) ? config.filter((c) => {return !((c.field === 'createdAt') || (c.field === 'createdBy'))}) : config
    },
    contextOrderFn: (key1 :NavKey, key2 :NavKey) :number => {
        return getContextOrder(key1) - getContextOrder(key2)
    },
    generateDisplayConfig: generateDisplayConfig,
    buttonTitle: (type :NavControlActionType) => {
        switch (type) {
            case 'post' : return 'Submit'
            case 'put' : return 'Save'
            case 'patch' : return 'Save'
            case 'delete' :return 'Delete'
        }
        return StringUtils.capitalizeFirst(type)
    }
}

export default MappingUtils