import { EuiProgress } from '@elastic/eui'
import {ReactNode} from 'react'

import './ComponentEuiProgress.css'

type Props = {
    className? :string
    label? :ReactNode
    max? :number
    value? :number
    valueText? :ReactNode
    size? :'xs' | 's' | 'm' | 'l'
    color? :'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued'
    position? :'fixed' | 'static' | 'absolute'
}

const ComponentEuiProgress = ({className, label, max, value, valueText, size, color, position} :Props) =>
    <EuiProgress className={className} label={label} max={max} value={value} valueText={valueText} size={size} color={color} position={position}/>

export default ComponentEuiProgress