import { EuiIcon } from '@elastic/eui'

type Props = {
    onClick? :() => void
    size? :'s' | 'm' | 'l' | 'xl' | 'original' | 'xxl'
    color? :string
    type :string
    title? :string
    titleId? :string
    onIconLoad? :() => void
}

const Icon = ({onClick, size, color, type, title, titleId, onIconLoad} :Props) => <EuiIcon onClick={onClick} size={size} color={color} type={type} title={title} titleId={titleId} onIconLoad={onIconLoad}/>

export default Icon