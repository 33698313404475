import { HookModuleTransformHeaderErrorMap, HookNavContext, HookNavContextNav, HookNavPage,
    HookNavResource, HookNavResourceNav, HookNavResourceNavAll } from 'Hook'
import { ModulePage } from 'Module'
import { PageTransformBody, PageTransformSidebar } from 'Page'
import {TypeEntityExtract, TypeEntityExtractSheet, TypeEntityTransformDestination, TypeEntityTransformHeader,
    TypeEntityTransformLoad, TypeHttpLoadable, TypeModuleControlRefresh, TypeModuleTransformHeaderErrorControl,
    TypeNav, TypeNavControlResource, TypeNavHttpControl } from 'Type'
import {UtilAsync} from 'Util'

const PageTransform = () => {

    //page
    const pageControl :TypeNavHttpControl<any> = HookNavPage<any>()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    //context
    const extract :TypeEntityExtract | undefined = HookNavContext<TypeEntityExtract>('extract', pageNav)
    const sheet :TypeEntityExtractSheet | undefined = HookNavContext<TypeEntityExtractSheet>('sheet', pageNav)

    //transform
    const transformControl :TypeNavHttpControl<any> = HookNavContextNav<any>('transform', pageNav)
    const transformNav :TypeNav<any> | undefined = transformControl.res?.data

    //exclude
    const excludeResource :TypeNavControlResource | undefined = HookNavResource('exclude', transformNav)

    //header
    const headerControl :TypeNavHttpControl<TypeEntityTransformHeader> = HookNavResourceNavAll<TypeEntityTransformHeader>({key: 'header', nav: transformNav})
    const headerNav :TypeNav<TypeEntityTransformHeader> | undefined = headerControl.res?.data
    const headerErrorControl :TypeModuleTransformHeaderErrorControl = HookModuleTransformHeaderErrorMap(headerNav?.data.page?.content)

    //output
    const outputControl :TypeNavHttpControl<any> = HookNavResourceNav<any>({key: 'output', nav: transformNav})
    const outputNav :TypeNav<any> | undefined = outputControl.res?.data

    //destination
    const destinationControl :TypeNavHttpControl<any> = HookNavResourceNav<TypeEntityTransformDestination>({key: 'destination', nav: transformNav})
    const destination :TypeEntityTransformDestination | undefined = destinationControl.res?.data?.data.entity

    //load
    const loadControl :TypeNavHttpControl<any> = HookNavResourceNav<TypeEntityTransformLoad>({key: 'load', nav: transformNav})
    const loadNav :TypeNav<TypeEntityTransformLoad> | undefined = loadControl.res?.data

    const refreshControl :TypeModuleControlRefresh = {
        headerRow: async () => {
            await transformControl.submit()
            await UtilAsync.awaitAll([headerControl.submit(), outputControl.submit(), loadControl.submit(), headerErrorControl.refreshAll()])
        },
        header: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([headerControl.submit(), headerErrorControl.refresh(destinationColumnNameCamel), outputControl.submit(), loadControl.submit()]),
        error: async (destinationColumnNameCamel :string) => UtilAsync.awaitAll([headerErrorControl.refresh(destinationColumnNameCamel), loadControl.submit()]),
        exclude: async () => UtilAsync.awaitAll([outputControl.submit(), loadControl.submit()])
    }

    const loaded = destination && extract && sheet && headerNav && outputNav && transformNav && loadNav && headerErrorControl.errors && excludeResource

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Transformation', control: transformControl},
        {label: 'Headers', control: headerControl},
        {label: 'Destination', control: destinationControl},
        {label: 'Output', control: outputControl}
    ]

    const sidebar = loaded && <PageTransformSidebar pageNav={pageNav} loadNav={loadNav} destination={destination}/>
    const body = loaded && <PageTransformBody headerNav={headerNav} headerErrors={headerErrorControl.errors} outputNav={outputNav} transformNav={transformNav} excludeResource={excludeResource} refreshControl={refreshControl}/>

    return <ModulePage control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad}>{body}</ModulePage>

}

export default PageTransform