import { ContextNavMeta } from 'Context'
import { HookHttpGetMulti } from 'Hook'
import {PropsWithChildren, useState} from 'react'
import {TypeContextNavMeta, TypeHttpControlGetMulti, TypeHttpResponse, TypeNav, TypeNavMeta, TypeNavMetaMap } from 'Type'
import { UtilGlobalId } from 'Util'

type Props = {}

type Res = TypeNav<any>

const buildPathMap = (globalIds :Set<string>) :{[globalId :string] :string} => {
    const pathMap :{[globalId :string] :string} = {}
    globalIds.forEach((globalId :string) => pathMap[globalId] = UtilGlobalId.toLink(globalId))
    return pathMap
}

const buildMap = (resMap :{ [key :string] :TypeHttpResponse<Res>}) :TypeNavMetaMap => {
    const map :TypeNavMetaMap = {}
    Object.keys(resMap).forEach((globalId :string) => {
        const res :TypeHttpResponse<Res> | undefined = resMap[globalId]
        const meta :TypeNavMeta | undefined = res.data?.meta
        if (meta) map[globalId] = meta
    })
    return map
}

const ModulePageContextNavMeta = ({ children } :PropsWithChildren<Props>) => {
    const [globalIds, setGlobalIds] = useState<Set<string>>(new Set())

    const resMapControl :TypeHttpControlGetMulti<Res> = HookHttpGetMulti({pathMap: buildPathMap(globalIds)})

    const fetchMeta = (newGlobalIds :string[]) => {
        setGlobalIds((currentGlobalIds :Set<string>) => {
            const newSet :Set<string> = new Set<string>(currentGlobalIds)
            newGlobalIds.forEach((globalId :string) => {newSet.add(globalId)})
            return newSet
        })
    }

    const map :TypeNavMetaMap = buildMap(resMapControl.resMap ?? {})

    const value :TypeContextNavMeta = {
        map,
        fetchMeta
    }

    return <ContextNavMeta.Provider value={value}>
        { children }
    </ContextNavMeta.Provider>

}

export default ModulePageContextNavMeta