import {NavStore} from "../../store";
import {Nav, NavControlResource, NavKey} from "../../types";
import {
    FlexGroup,
    FlexItem,
    Link,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalHeaderTitle,
    PageSection,
    Spacer
} from "../../eui";
import {EntityDisplay, PageTitle} from "../../components";
import {TableEntity, TableVersionColumnEntity} from "../../entity";
import {GenericActionButtons, NavDataPageTable, NavResourceTabContent} from "../index";
import {LoadingPage} from "../../pages";
import {useEffect, useState} from "react";
import {MappingUtils, NavClient} from "../../utils";

type ActionProps = {
    column :TableVersionColumnEntity
}

const tableKey :NavKey = 'table'
const versionKey :NavKey = 'version'
const rowKey :NavKey = 'row'
const columnKey :NavKey = 'column'
const refKey :NavKey = 'ref'

const ColumnActions = ({column} :ActionProps) => {

    const [columnNav, setColumnNav] = useState<Nav | undefined>()

    const fetchNav = async () => {
        setColumnNav(await NavClient.fetchNav(column.linkTo))
    }

    useEffect(() => {
        fetchNav()
    }, [column.linkTo])

    return (columnNav) ? <GenericActionButtons nav={columnNav} contextKey={columnKey} toggleIcon={'gear'} buttonSize={'s'} ignore={['patch']}/> : null
}

const ColumnPageContent = () => {

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const refresh :() => void = NavStore(store => store.refresh)
    const versionNav :Nav | undefined = NavStore(store => store.contextNavs[versionKey])
    const refResource :NavControlResource | undefined = versionNav.control.resource[refKey]

    const rowResource :NavControlResource | undefined = versionNav.control.resource[rowKey]
    const tableEntity :TableEntity | undefined = pageNav && pageNav.context[tableKey]
    const versionEntity :TableEntity | undefined = pageNav && pageNav.context[versionKey]

    const [refNav, setRefNav] = useState<Nav | undefined>(undefined)
    const [selectedColumn, setSelectedColumn] = useState<TableVersionColumnEntity | undefined>()

    const getRefNav = async () => setRefNav(await NavClient.fetchNav(refResource.linkTo))

    useEffect(() => {getRefNav()},[refResource.linkTo])

    if (!pageNav || !tableEntity || !versionEntity || !rowResource || !refResource || !refNav) return <LoadingPage/>

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <FlexGroup alignItems={'baseline'}>
                <FlexItem>
                    <PageTitle>
                        <Link href={rowResource.linkTo} color={'text'} >{tableEntity.name}</Link> columns
                    </PageTitle>
                </FlexItem>
            </FlexGroup>
            <Spacer/>
        </PageSection>
        <Spacer size={'s'}/>
        <PageSection xPadding={24} paddingSize={'m'}>
            <FlexGroup justifyContent={'spaceBetween'}>
                <FlexItem grow>
                    <p style={{color: '#69707D'}}>{pageNav.meta.description}</p>
                    <Spacer/>
                </FlexItem>
                <FlexItem>
                    <GenericActionButtons nav={pageNav} contextKey={columnKey} button={'empty'} buttonSize={'s'} buttonTitle={{'post' : 'New Data Column'}}/>
                </FlexItem>
                <FlexItem>
                    <GenericActionButtons nav={refNav} contextKey={columnKey} button={'empty'} buttonSize={'s'} buttonTitle={{'post' : 'New Reference Column'}} onSuccess={refresh}/>
                </FlexItem>
            </FlexGroup>
        </PageSection>
        <NavDataPageTable nav={pageNav} config={MappingUtils.contextDisplayConfig(columnKey)} navigateOverride={(e :TableVersionColumnEntity) => setSelectedColumn(e)}/>
        { selectedColumn && <Modal onClose={() => {setSelectedColumn(undefined)}}>
            <ModalHeader>
              <FlexGroup alignItems={'baseline'}>
                  <FlexItem>
                    <PageTitle>
                      <ModalHeaderTitle><h1>{selectedColumn.name}</h1></ModalHeaderTitle>
                    </PageTitle>
                  </FlexItem>
                  <FlexItem>
                    <ColumnActions column={selectedColumn}/>
                  </FlexItem>
              </FlexGroup>
            </ModalHeader>
            <ModalBody>
                <EntityDisplay object={selectedColumn} config={MappingUtils.contextDisplayConfig(columnKey)}/>
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
        </Modal> }
        </>
}

export default ColumnPageContent