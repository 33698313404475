import create from 'zustand'

import { Nav, NavControlResource, NavDataPage, NavKey } from 'types'
import { ExtractEntity, SheetEntity } from 'entity'
import {DateUtils, NavClient, RouteUtils } from 'utils'

type Store = {
    loading :boolean
    loadingKey :string
    extract? :ExtractEntity,
    sheet? :SheetEntity,
    sheets? :NavDataPage<SheetEntity>,
    extractNav? :Nav,
    sheetsNav? :Nav,
    sheetNav? :Nav,
    data? :any[],
    clear: () => void
    load: (extract :ExtractEntity, newSheet? :SheetEntity) => void
}

const sheetKey :NavKey = 'sheet'

const buildLoadingKey = (newExtract :ExtractEntity, newSheet? :SheetEntity) :string => {
    if (newSheet) {
        return `${newExtract.linkTo} ${newSheet.linkTo}`
    } else {
        return newExtract.linkTo
    }
}

const ExtractStore = create<Store>((set, get) => ({
    loading: false,
    loadingKey: '',
    extract: undefined,
    sheet: undefined,
    sheets: undefined,
    extractNav: undefined,
    sheetsNav: undefined,
    sheetNav: undefined,
    data :undefined,
    clear: () => {
        set({loading: false, loadingKey: '', extract: undefined, sheet: undefined, sheets: undefined, extractNav: undefined, sheetsNav: undefined, sheetNav: undefined, data: undefined})
    },
    load: async (newExtract :ExtractEntity, newSheet? :SheetEntity) => {
        const loadingKey :string = buildLoadingKey(newExtract, newSheet)
        if (get().loading && get().loadingKey === loadingKey) return
        set({loading: true, loadingKey: loadingKey})
        const currentExtract :ExtractEntity | undefined = get().extract
        const currentSheets :NavDataPage<SheetEntity> | undefined = get().sheets
        const currentSheet :SheetEntity | undefined = get().sheet
        const currentExtractNav :Nav | undefined = get().extractNav
        const currentSheetsNav :Nav | undefined = get().sheetsNav
        const currentSheetNav :Nav | undefined = get().sheetNav
        const currentData :any[] | undefined = get().data

        let extract :ExtractEntity | undefined = undefined
        let sheets :NavDataPage<SheetEntity> | undefined = undefined
        let sheet :SheetEntity | undefined = undefined
        let extractNav :Nav | undefined = undefined
        let sheetsNav :Nav | undefined = undefined
        let sheetNav :Nav | undefined = undefined
        let data :any[] | undefined = undefined

        if (currentExtract && currentExtract.linkTo === newExtract.linkTo &&
            currentExtractNav && currentExtractNav.data.entity &&
            (currentExtractNav.data.entity as ExtractEntity).linkTo === newExtract.linkTo) {
            extract = currentExtract
            extractNav = currentExtractNav
        } else {
            extract = newExtract
            extractNav = await NavClient.fetchNav(newExtract.linkTo)
        }

        const sheetsResource :NavControlResource | undefined = extractNav.control.resource['sheet']
        if (sheetsResource && currentSheets && currentSheetsNav && sheetsResource.linkTo === RouteUtils.pathOnly(currentSheetsNav.path)) {
            sheets = currentSheets
            sheetsNav = currentSheetsNav
        } else if (sheetsResource) {
            sheetsNav = await NavClient.fetchAll(sheetsResource.linkTo)
            sheets = sheetsNav.data.page
        }

        const getAndSetData = async (sheetNav :Nav) => {
            const dataResource :NavControlResource | undefined = sheetNav.control.resource['data']
            if (dataResource) data = (await NavClient.fetchNav(dataResource.linkTo)).data.list
        }

        if (newSheet && currentSheet && newSheet.linkTo === currentSheet.linkTo && currentSheetNav &&
        currentSheetNav.data.entity && (currentSheetNav.data.entity as SheetEntity).linkTo === newSheet.linkTo) {
            sheet = currentSheet
            sheetNav = currentSheetNav
            data = currentData
        } else if (newSheet) {
            sheet = newSheet
            sheetNav = await NavClient.fetchNav(sheet.linkTo)
            await getAndSetData(sheetNav)
        } else if (sheets && sheets.totalElements > 0) {
            sheet = sheets.content[0]
            sheetNav = await NavClient.fetchNav(sheet.linkTo)
            await getAndSetData(sheetNav)
        }

        set({extract, sheet, sheets, extractNav, sheetsNav, sheetNav, data, loading: false})
    }
}))

export default ExtractStore