

import {BasicTable, FlexGroup, FlexItem, Spacer} from 'eui'
import {DisplayConfig, Nav, NavData, NavDataPage, Param, ParamUpdate} from 'types'
import { NavStore } from 'store'
import {MappingUtils, SearchParamUtils } from 'utils'
import { NavTablePagination } from 'components'
import { NavTableFilter, NavTableFilterBadges } from 'modules'
import { useUpdateQueryParams } from 'hooks'
import { useNavigate } from 'react-router-dom'

const linkColumn = 'linkTo'

type Props<E extends object> = {
    config? :DisplayConfig<E>[]
    nav? :Nav
    navigateOverride?: (entity :E) => void
}

const NavDataPageTable = <E extends object>({config, nav, navigateOverride} :Props<E>) => {

    const navigate = useNavigate()
    const setParams = useUpdateQueryParams()

    const navOpt :Nav | undefined = nav || NavStore(state => state.nav)
    if (!navOpt) return null

    const data :NavData = navOpt.data
    const page :NavDataPage | undefined = data.page
    const list :any[] | undefined = data.list
    if (!page && !list) return null
    const content :any[] = page && page.content || list || []

    const getFieldName = (nameCamel :string) :string => {
        if (config) for (let i = 0; i < config.length; i++) if (config[i].field === nameCamel) return config[i].name as string
        return nameCamel
    }

    const isInConfig = (nameCamel :string) :boolean => {
        if (!config) return true
        for (let i = 0; i < config.length; i++) if (config[i].field === nameCamel) return true
        return false
    }

    const fieldFilterOptions = page?.searchableFields
        .filter(isInConfig)
        .map((s :string) => {return {value: s, text: getFieldName(s)}}) ?? []

    const sorting = (page) ? {
        sort: page.request.sort,
        enableAllColumns: true,
        readOnly: false
    } : undefined

    const onChange = (evt :any) => {
        setParams([
            { param: 'sortDirection', paramValue: evt.sort.direction },
            { param: 'sortField', paramValue: evt.sort.field }
        ])
    }

    const columns = config || MappingUtils.generateDisplayConfig(content)

    const rowProps = (item :any) => {
        return {
            onClick: (item[linkColumn]) ? () => {
                if (navigateOverride) {
                    navigateOverride(item)
                } else {
                    navigate(item[linkColumn])
                }
            } : undefined
        }
    }

    return <>
        { fieldFilterOptions.length > 0 && <div style={{margin: '6px 8px 6px 8px'}}><FlexGroup>
            <FlexItem><NavTableFilter fieldOptions={fieldFilterOptions}/></FlexItem>
            <FlexItem><NavTableFilterBadges/></FlexItem>
        </FlexGroup></div> }
        <Spacer size={'s'}/>
        <BasicTable columns={columns} items={content} onChange={onChange} sorting={sorting} rowProps={rowProps}/>
        <NavTablePagination totalElements={(page) ? page.totalElements : 0}/>
    </>
}


export default NavDataPageTable