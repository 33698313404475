
import {ReactElement, ReactNode} from 'react'

import { ComponentEuiFormRow, ComponentEuiText } from 'Component'
import { TypeNavControlActionFormField } from 'Type'

type Props = {
    field :TypeNavControlActionFormField
    error? :string
    fullWidth? :boolean
    disabled? :boolean
    children :ReactElement
}

const ComponentNavFormRow = ({ field, error, fullWidth, disabled, children } :Props) => {

    const title :ReactNode = <>
        <ComponentEuiText>{field.title}{(field.required) && <span style={{color: 'red'}}>{' *'}</span>}</ComponentEuiText>
    </>

    return <ComponentEuiFormRow label={title} isDisabled={disabled} isInvalid={!!error} error={error} fullWidth={fullWidth}>
        {children}
    </ComponentEuiFormRow>
}

export default ComponentNavFormRow