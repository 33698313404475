import { AuditDisplay } from 'display';
import { TransformationHeaderErrorEntity } from 'entity'
import { DisplayConfig } from 'types'

let config :DisplayConfig<TransformationHeaderErrorEntity>[] = [
    {
        field: 'cellValue',
        name: 'Cell Value',
        sortable: true
    },
    {
        field: 'fixValue',
        name: 'Fix Value',
        sortable: true
    },
    {
        field: 'sheetRowIndex',
        name: 'Sheet Row',
        sortable: true
    },
    ...AuditDisplay
]

export default config