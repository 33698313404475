
import { EntityDisplay } from 'components'
import {Nav, NavData, NavKey} from 'types'
import { NavStore } from 'store'
import { MappingUtils } from 'utils'

type Props = {
    nav? :Nav
    configKey? :NavKey
    ignoreFields? :string[]
}

const NavDataEntity = ({nav, configKey, ignoreFields} :Props) => {

    const innerNav = nav || NavStore(state => state.nav)
    if (!innerNav) return null

    const data :NavData = innerNav.data
    const entity :any | undefined = data.entity
    if (!entity) return null
    
    return <EntityDisplay ignoreFields={ignoreFields} object={entity} config={(configKey) ? MappingUtils.contextDisplayConfig(configKey) : undefined}/>
}

export default NavDataEntity