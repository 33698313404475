import { ReactNode } from 'react'
import './NoDatePlaceholder.css'

type Props = {
    text? :string
    children? :ReactNode
}

const NoDataPlaceholder = ({text, children} :Props) => {
    return <div className={'no-data-placeholder'}>{text || children}</div>
}

export default NoDataPlaceholder