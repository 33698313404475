import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentNavPageFilterTablePopover } from 'Component'
import { ModuleTableColumnControlSystemToggle } from 'Module'

type Props = {
    fieldNameMap :{[fieldNameCamel :string] :string}
}

const ModuleTableHeaderActions = ({ fieldNameMap } :Props) => {
    return <ComponentEuiFlexGroup alignItems={'baseline'} justifyContent={'flexEnd'}>
        <ComponentEuiFlexItem>
            <ComponentNavPageFilterTablePopover fieldNameMap={fieldNameMap}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ModuleTableColumnControlSystemToggle/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ModuleTableHeaderActions