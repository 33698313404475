import { ReactNode } from 'react'

import './SideBarListItem.css'

type Props = {
    children :ReactNode
    isTitle? :boolean
}

const SideBarListItem = ({ isTitle, children} :Props) => {
    return <div className={(isTitle) ? 'side-bar-title' : 'side-bar-list-item'}>
        {children}
    </div>
}

export default SideBarListItem