import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'isComplete',
        name: 'Complete',
        sortable: true,
        render: (value :any) => {
            return (value && `${value}` === 'true') ? 'Yes' : 'No'
        }
    },
]

export default config