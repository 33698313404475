import { EuiSelect } from '@elastic/eui'
import React, { ChangeEvent } from 'react'
import { TypeEuiFormFieldProps, TypeEuiFormFieldSelectOption } from 'Type'


type Props = TypeEuiFormFieldProps<string> & {
    options?: TypeEuiFormFieldSelectOption[]
}

const ComponentEuiFieldSelect = ({value, placeholder, isInvalid, fullWidth, isLoading, prepend, append, onChange, options} :Props) => {

    const evtFn = (e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)

    const opts = [...options || []]
    opts.unshift({text: '', value: '' })

    return <EuiSelect
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        prepend={prepend}
        append={append}
        isLoading={isLoading}
        options={opts}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldSelect