import {BackButton, CollapsibleLeftNavBar, CollapsibleSideBarItem, SideBarActionModal } from 'components'
import { TableEntity } from 'entity'
import {SideNav, Spacer } from 'eui'
import { useNavigateToTable } from 'hooks'
import { NavResourceSearchFilter } from 'modules'
import {useEffect, useState } from 'react'
import { NavStore } from 'store'
import {Nav, NavControlAction, NavControlResource, SideNavItem } from 'types'
import { NavClient } from 'utils'

type Props = {
    callBack?: () => void
}

const TablePageLeftNavBar = ({callBack} :Props) => {

    const navigateToTable = useNavigateToTable()
    const navigateOverride = (tableEntity :TableEntity) => navigateToTable(tableEntity)

    const workspaceNav :Nav | undefined = NavStore(store => store.contextNavs['ws'])
    const workspaceTableResource :NavControlResource | undefined = (workspaceNav) ? workspaceNav.control.resource['table'] : undefined
    const [workspaceTableNav, setWorkspaceTableNav] = useState<Nav | undefined>()

    const getAndSetWorkspaceNav = async () => {
        if (workspaceTableResource) {
            setWorkspaceTableNav(await NavClient.fetchNav(workspaceTableResource.linkTo))
            if (callBack) callBack()
        }
    }

    const workspaceTableLinkTo :string = (workspaceNav) ? workspaceNav.path : ''

    useEffect(() => {getAndSetWorkspaceNav()}, [workspaceTableLinkTo])

    if (!workspaceNav || !workspaceTableNav) return null

    const rootItem :SideNavItem = {
        id: 'title',
        name: 'Tables',
        items: []
    }

    const newTableAction :NavControlAction | undefined = workspaceTableNav.control.action['post']

    return <CollapsibleLeftNavBar expandedWidth={240} collapsedWidth={0} darker>
        <CollapsibleSideBarItem gutter={0}>
            <Spacer size={'s'}/>
            <BackButton linkTo={workspaceNav.path}/>
            <Spacer size={'m'}/>
            <SideNav items={[rootItem]}/>
            {(newTableAction) ? <SideBarActionModal nav={workspaceTableNav} action={newTableAction} tabKey={'table'} onSuccess={getAndSetWorkspaceNav}/> : null}
            <Spacer size={'m'}/>
            <NavResourceSearchFilter nav={workspaceTableNav} field={'name'} navigateOverride={navigateOverride}/>
        </CollapsibleSideBarItem>
    </CollapsibleLeftNavBar>
}

export default TablePageLeftNavBar