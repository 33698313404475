import { TypeEntityLinkTo, TypeNavKey } from 'Type'
import { UtilString } from 'Util'

const getPath = (linkTo :string | TypeEntityLinkTo | undefined, resource? :TypeNavKey) :string | undefined => {
    if (!linkTo) return undefined
    const entityPath :string | undefined = (UtilString.isString(linkTo)) ? linkTo as string : (linkTo as TypeEntityLinkTo).linkTo
    if (!entityPath) return undefined
    return (resource) ? `${entityPath}/${resource}` : entityPath
}

const jumpPath = (entity :TypeEntityLinkTo, add? :TypeNavKey) :string => {
    return (add) ? `${UtilString.removeTrailingChars(entity.linkTo, '/')}/${add}` : entity.linkTo
}

const UtilNav = { getPath, jumpPath }

export default UtilNav

