import { EuiEmptyPrompt } from '@elastic/eui'
import {ReactElement, ReactNode} from "react";
import { TypeEuiPropsIconType } from 'Type'

type Props = {
    color? :'transparent' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain'
    hasBorder? :boolean
    hasShadow? :boolean
    iconType? :TypeEuiPropsIconType
    iconColor? :string
    icon? :ReactNode
    title? :ReactElement
    titleSize? :'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l'
    body? :ReactNode
    actions? :ReactNode
    footer? :ReactNode
    layout? :'horizontal' | 'vertical'
    paddingSize? :'none' | 's' | 'm' | 'l'
}

const ComponentEuiEmptyPrompt = ({ color, hasBorder, hasShadow, iconType, iconColor, icon, title, titleSize, body, actions, footer, layout, paddingSize } :Props) =>
    <EuiEmptyPrompt color={color} hasBorder={hasBorder} hasShadow={hasShadow} iconType={iconType} iconColor={iconColor} icon={icon}
    title={title} titleSize={titleSize} body={body} actions={actions} footer={footer} layout={layout} paddingSize={paddingSize}/>

export default ComponentEuiEmptyPrompt