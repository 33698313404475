import {CSSProperties, ReactElement, ReactNode} from 'react'
import { EuiPopover } from '@elastic/eui'

type Props = {
    style?: CSSProperties
    className? :string
    id?: string
    ownFocus?: boolean
    repositionOnScroll?: boolean
    button: string | ReactElement
    isOpen: boolean
    anchorPosition?: 'upCenter' | 'upLeft' | 'upRight' | 'downCenter' | 'downLeft' | 'downRight' | 'leftCenter' | 'leftUp' | 'leftDown' | 'rightCenter' | 'rightUp' | 'rightDown'
    closePopover: () => void
    panelPaddingSize: 's' | 'm' | 'l' | 'none'
    children?: ReactNode
};

const ComponentEuiPopover = ({style, className, id, ownFocus, repositionOnScroll, button, isOpen, anchorPosition, closePopover, panelPaddingSize, children}: Props) =>
    <EuiPopover
        style={style}
        className={className}
        id={id}
        ownFocus={ownFocus}
        repositionOnScroll={repositionOnScroll}
        button={button}
        isOpen={isOpen}
        anchorPosition={anchorPosition}
        closePopover={closePopover}
        panelPaddingSize={panelPaddingSize}>
        {children}
    </EuiPopover>

export default ComponentEuiPopover