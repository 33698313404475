import React, {ChangeEvent, ReactElement, useState} from 'react'
import moment from 'moment-timezone'
import {EuiDatePicker} from '@elastic/eui'
import { Field } from 'props'
import {ButtonEmpty, ListGroup, Popover, Spacer } from 'eui'
import { Moment } from 'moment'

const STRING_FORMAT = 'HH:mm:ss'

const FieldTime = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : ''

    const datetime = (valueStr !== '') ? moment(valueStr, STRING_FORMAT) : undefined

    const tzFn = (tz :string) => {
        if (datetime) onChange(name, datetime.format(STRING_FORMAT))
    }

    const fn = (date: moment.Moment | null, event?: React.SyntheticEvent<any>) => {
        onChange(name, (date) ? date.format(STRING_FORMAT) : '')
    }

    return <EuiDatePicker
        showTimeSelect
        showTimeSelectOnly
        selected={datetime}
        onChange={fn}
        dateFormat={STRING_FORMAT}
        timeFormat={STRING_FORMAT}
    />
}

export default FieldTime