



const intToExcelCol = (number :number) => {
    let colName :string = '',
        dividend :number = Math.floor(Math.abs(number)),
        rest :number

    while (dividend > 0) {
        rest = (dividend - 1) % 26;
        colName = String.fromCharCode(65 + rest) + colName
        dividend = parseInt(`${(dividend - rest)/26}`)
    }
    return colName
}

const COLUMN_IDS :string[] = Array.from(Array(10000).keys()).map(intToExcelCol)
COLUMN_IDS.shift()

const buildColumnIdArray = (columnIds :Set<string>) :string[] => {
    const res :string[] = [], foundColumnIds :Set<string> = new Set<string>()
    let i :number = 0
    while (foundColumnIds.size < columnIds.size) {
        let current :string = COLUMN_IDS[i]
        res.push(current)
        if (columnIds.has(current)) foundColumnIds.add(current)
        i++
    }
    return res
}

const buildColumnIdArrayFromData = (data :string[]) :string[] => {
    const columnIds :Set<string> = new Set<string>()
    data.flatMap(Object.keys).forEach((colId :string) => columnIds.add(colId))
    columnIds.delete('')
    return buildColumnIdArray(columnIds)
}

export default { buildColumnIdArrayFromData }