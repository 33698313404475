import { ComponentEuiFieldComboBox } from 'Component'
import { HookNavFilterField } from 'Hook'
import {TypeEntityTableVersionColumn, TypeEuiPropsFieldComboBox, TypeNav, TypeNavControlFilter, TypeNavDataFilter } from 'Type'

type Props = {
    nav :TypeNav<any>
    column :TypeEntityTableVersionColumn
    placeholder? :string
    fullWidth? :boolean
    onChange :(filter :TypeNavDataFilter) => void
    selectedOptions? :TypeEuiPropsFieldComboBox[]
}

const ModuleTableColumnFilterForm = ({nav, column, placeholder, fullWidth, onChange, selectedOptions} :Props) => {
    const { results, isLoading, setSearchStr } :TypeNavControlFilter = HookNavFilterField({nav, fieldNameCamel: column.nameCamel})

    const onComboBoxChange = (option :TypeEuiPropsFieldComboBox[]) => {
        if (option.length && option[0].key) {
            onChange({
                fieldNameCamel: column.nameCamel,
                operator: 'eq',
                value: option[0].key
            })
        }
    }

    return <ComponentEuiFieldComboBox isLoading={isLoading} onSearchChange={setSearchStr} onChange={onComboBoxChange}
                                      options={results} selectedOptions={selectedOptions ?? []} fullWidth={fullWidth}
                                      placeholder={placeholder}
                                      prepend={<></>}/>
}

export default ModuleTableColumnFilterForm