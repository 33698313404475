import {useEffect, useState} from 'react'
import {TypeControlPageViewport} from 'Type'

const HookPageViewport = () :TypeControlPageViewport => {

    const getViewport = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const [viewport, setViewport] = useState<TypeControlPageViewport>(getViewport())

    useEffect(() => {
        window.addEventListener('resize', () => setViewport(getViewport));
    }, [])
    return viewport
}

export default HookPageViewport