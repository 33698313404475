import {EuiListGroup} from '@elastic/eui'
import {PropsWithChildren} from 'react'

type Props = {
    bordered? :false
    flush? :boolean
    gutterSize? :'none' | 's' | 'm'
    color? :'text' | 'primary' | 'subdued'
    size? :'xs' | 's' | 'm' | 'l'
    maxWidth? :boolean | string | number
    showToolTips? :boolean
    wrapText? :boolean
}

const ComponentEuiListGroup = ({bordered, flush, gutterSize, color, size, maxWidth, showToolTips, wrapText, children } :PropsWithChildren<Props>) =>
    <EuiListGroup bordered={bordered} flush={flush} gutterSize={gutterSize} color={color} size={size} maxWidth={maxWidth} showToolTips={showToolTips} wrapText={wrapText}>
        { children }
    </EuiListGroup>


export default ComponentEuiListGroup