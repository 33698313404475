import { PageSection, LoadingSpinner, Text } from 'eui'
import {useEffect, useState} from 'react'
import { NavControlResource, NavHookControl, Nav, NavKey, TableColumnHookControl } from 'types'
import {useNav} from 'hooks'
import { NavModalTable } from 'components'
import {ConnectionEntity} from 'entity'
import {TableModalConnectResponderEntity} from 'modules'

type Props = {
    connResource :NavControlResource
    columnControls :TableColumnHookControl
}

const connKey :NavKey = 'conn'

const TableModalConnectResponderList = ({connResource, columnControls} :Props) => {

    const reqNavControl :NavHookControl = useNav()
    useEffect(() => {reqNavControl.fetch(connResource.linkTo)}, [connResource.linkTo])
    const nav :Nav | undefined = reqNavControl.nav

    const [active, setActive] = useState<ConnectionEntity | undefined>()

    const onClick = (entity :ConnectionEntity) => {
        setActive(entity)
    }

    const onBack = () => {
        setActive(undefined)
        reqNavControl.refresh()
    }

    if (active) return <TableModalConnectResponderEntity connection={active} onBack={onBack} columnControls={columnControls}/>

    return <>
        <PageSection paddingSize={'m'}>
            { nav && <Text><p>{nav.meta.description}</p></Text>}
        </PageSection>
        <PageSection paddingSize={'none'}>
            { nav ? <NavModalTable nav={nav} navKey={connKey} navigate={'callback'} onSuccess={reqNavControl.refresh} ignoreAudit={true} ignoreFields={['description', 'interfaceCount']} onClick={onClick}/> : <LoadingSpinner/>}
        </PageSection>
    </>
}

export default TableModalConnectResponderList