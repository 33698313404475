import { WorkspacePage } from 'pages';
import { FinderNode } from 'types';
import Table from '../Table/Table';

const Workspace :FinderNode = {
    render: () => <WorkspacePage/>,
    next: {
        'table' : {
            render: () => <WorkspacePage tab={'table'}/>,
            next: {'*' : Table}
        },
        'member' : {
            render: () => <WorkspacePage tab={'member'}/>,
            next: {}
        },
        'iface' : {
            render: () => <WorkspacePage tab={'iface'}/>,
            next: {}
        },
        'responder' : {
            render: () => <WorkspacePage tab={'responder'}/>,
            next: {}
        }
    }
}

export default Workspace;