import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'ownerGlobalId',
        name: 'Owner',
        sortable: true,
    },
]

export default config