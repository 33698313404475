import { AuditDisplay } from 'display';
import CreatedAtDisplay from 'display/parts/CreatedAtDisplay';
import GlobalIdDisplay from 'display/parts/GlobalIdDisplay';
import { WorkspaceEntity } from 'entity';
import { DisplayConfig } from 'types';

let config :DisplayConfig<WorkspaceEntity>[] = [
    {
        field: 'firstName',
        name: 'First Name',
        sortable: true
    },
    {
        field: 'lastName',
        name: 'Last Name',
        sortable: true
    },
    {
        field: 'userId',
        name: 'User Id',
        sortable: true
    },
    ...GlobalIdDisplay,
    ...CreatedAtDisplay
]

export default config