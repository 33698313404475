import {ComponentDivPadding, ComponentDivWidth, ComponentEuiLoadingSpinner, ComponentEuiSpacer } from 'Component'
import {HookModuleTableDataCellNav } from 'Hook'
import {ModuleTableCellActions, ModuleTableCellDetails } from 'Module'
import { TypeEntityCell, TypeEntityRow, TypeEntityTableVersionColumn, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    row :TypeEntityRow
    column :TypeEntityTableVersionColumn
}

const ModuleTableCellPopover = ({ row, column } :Props) => {

    const cellControl :TypeNavHttpControl<TypeEntityCell> = HookModuleTableDataCellNav({row, column})
    const cellNav :TypeNav<TypeEntityCell> | undefined = cellControl.res?.data
    const cell :TypeEntityCell | undefined = cellNav?.data.entity

    return <ComponentDivWidth width={380}><ComponentDivPadding padding={8}>
        { (cell) ? <ModuleTableCellDetails row={row} column={column} cell={cell}/> : <ComponentEuiLoadingSpinner/> }
        { cell && cellNav && <>
            <ComponentEuiSpacer/>
            <ModuleTableCellActions column={column} nav={cellNav} cell={cell} row={row}/>
        </>}
    </ComponentDivPadding></ComponentDivWidth>
}

export default ModuleTableCellPopover