import { SearchOptionEntity, TableEntity } from 'entity'
import { Selectable } from 'eui'
import {useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Nav, ParamUpdate, SimpleSelectItem } from 'types'
import { NavClient, SearchParamUtils } from 'utils'
import './NavResourceSearchFilter.css'

type Props<E> = {
    nav :Nav
    field :string
    navigateOverride?: (entity :E) => void
}

const initialSearchResults = (nav :Nav) :SimpleSelectItem[] => {
    if (!nav.data.page) return []
    return nav.data.page.content.map(t => {return {
        label: t.name
    }})
}

const NavResourceSearchFilter = <E extends object>({nav, field, navigateOverride} :Props<E>) => {

    const navigate = useNavigate()
    const searchValueRef = useRef('')

    const [searchResults, setSearchResults] = useState<SimpleSelectItem[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setSearchResults(initialSearchResults(nav))
    }, [nav.path, (nav.data.page && nav.data.page.totalElements) || 0])

    const onSearchChange = async (searchValue: string, matchingOptions: any[]) => {
        if (searchValueRef.current === searchValue) return
        setIsLoading(true)
        searchValueRef.current = searchValue
        const searchNav :Nav = await NavClient.searchTableField(nav, field, searchValue)
        const searchOptions :SearchOptionEntity[] = (searchNav.data.page && searchNav.data.page.content) || []
        setSearchResults(searchOptions.map((item :SearchOptionEntity) :SimpleSelectItem => {
            return {
                label: item.label,
            }
        }))
        setIsLoading(false)
    }

    const selectValue = (options :SimpleSelectItem[]) => {
        const filtered :SimpleSelectItem[] = options.filter(o => o.checked === 'on')
        if (filtered.length > 0) {
            navigateToFilter(filtered[0].label)
        }
    }

    const navigateToFilter = async (value :string) => {
        const updateParam :ParamUpdate = {param: `filter-${field}`, paramValue: value}
        const newPath :string = SearchParamUtils.appendParamForPath(nav.path, [updateParam])
        const filteredNav :Nav = await NavClient.fetchNav(newPath)
        if (filteredNav.data.page && filteredNav.data.page.content[0] && filteredNav.data.page.content[0].linkTo) {
            if (navigateOverride) {
                navigateOverride(filteredNav.data.page.content[0])
            } else {
                navigate(filteredNav.data.page.content[0].linkTo)
            }
        }
    }

    return <div className={'nav-resource-search-filter'}><Selectable options={searchResults} onChange={selectValue} onSearchChange={onSearchChange} placeholder={'Search Tables'} isLoading={isLoading} height={'full'}/></div>
}

export default NavResourceSearchFilter