import { EuiDatePicker } from '@elastic/eui'
import { Moment } from 'moment'
import { TypeEuiFormFieldProps } from 'Type'
import { FORMAT_DATE } from 'Format'

type Props = TypeEuiFormFieldProps<string>

const ComponentEuiFieldPickerDate = ({value, placeholder, isInvalid, fullWidth, isLoading, onChange} :Props) => {

    const evtFn = (e: Moment | null) => onChange(e?.format(FORMAT_DATE) ?? '')

    return <EuiDatePicker
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        isLoading={isLoading}
        dateFormat={FORMAT_DATE}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldPickerDate