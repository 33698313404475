import { PageTitle, StaticPageParagraph } from 'components'
import { Page, PageBody, PageSection, Title, Text, Spacer } from 'eui'
import { StaticPage } from 'modules'
import {ReactNode} from 'react';


const PrivacyPage = () => {
    return <StaticPage>
        <PageSection restrictWidth bottomBorder={'extended'}>
            <Title><h1>Privacy Policy</h1></Title>
        </PageSection>
        <PageSection restrictWidth>
            <StaticPageParagraph>
                Please click <a href={'/Morphcloud - Website Privacy Policy - 2023-07-24.pdf'} target={'_blank'} rel={'noreferrer'} download={'Morphcloud - Website Privacy Policy - 2023-07-24.pdf'}>here</a> to download a copy of our Privacy Policy.
            </StaticPageParagraph>
        </PageSection>
    </StaticPage>
}

export default PrivacyPage