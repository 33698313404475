import {useNavigate} from 'react-router-dom'

import {ComponentEuiAvatar, ComponentEuiHeaderLink, ComponentEuiHeaderSectionItem } from 'Component'
import { UtilGlobalId } from 'Util'

type Props = {
    globalId :string
}

const ModulePageHeaderItemProfile = ({globalId} :Props) => {

    const navigate = useNavigate()

    const onClick = () => {
        if (globalId) navigate(UtilGlobalId.toLink(globalId))
    }

    return <ComponentEuiHeaderSectionItem>
        <ComponentEuiHeaderLink onClick={onClick}><ComponentEuiAvatar iconType={(UtilGlobalId.isApi(globalId)) ? 'bolt' : 'userAvatar'} size={'m'} iconColor={'text'} name={globalId}/></ComponentEuiHeaderLink>
    </ComponentEuiHeaderSectionItem>
}

export default ModulePageHeaderItemProfile