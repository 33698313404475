import {FlexGroup, FlexItem, PageSection, Spacer, Title } from 'eui'
import Image1 from './landing-table.png'
import Image2 from './landing-transform.png'
import Image3 from './CloudDataExchange.svg'
import './HomePageFeatureSection.css'

type FeatureProps = {
    number :1 | 2 | 3
    numberColor :'#Db589A' | '#5DB7F9' | '#43EFD2'
    title :string
    body :string
}

const Feature = ({number, numberColor, title, body} :FeatureProps) => {
    return <FlexGroup gutterSize={'xl'}>
        <FlexItem grow>
            <div style={{width: '80%', margin: '0px auto'}}>
                <div className={'feature-title'}>
                    <span style={{color: numberColor}}>{number}. </span>{title}
                </div>
                <Spacer/>
                <div style={{fontSize: '24px', lineHeight: 1.6}}>
                    {body}
                </div>
            </div>
        </FlexItem>
        <FlexItem grow>
            <div style={{minWidth: '300px', width: '80%', margin: '0px auto', boxShadow: '14px 14px 40px rgba(102, 102, 102, .2)', borderRadius: '5px', overflow: 'hidden', border: '1px solid #ddd'}}>
                <img src={(number === 1) ? Image1 : (number === 2) ? Image2 : Image3} alt={'landing table image'} style={{width: '100%'}}/>
            </div>
        </FlexItem>
    </FlexGroup>
}

const FeatureReverse = ({number, numberColor, title, body} :FeatureProps) => {
    return <FlexGroup gutterSize={'xl'} direction={'rowReverse'}>
        <FlexItem grow>
            <div style={{width: '80%', margin: '0px auto'}}>
                <div className={'feature-title'}>
                    <span style={{color: numberColor}}>{number}. </span>{title}
                </div>
                <Spacer/>
                <div style={{fontSize: '24px', lineHeight: 1.6}}>
                    {body}
                </div>
            </div>
        </FlexItem>
        <FlexItem grow>
            <div style={{minWidth: '300px', width: '80%', margin: '0px auto', boxShadow: '14px 14px 40px rgba(102, 102, 102, .2)', borderRadius: '5px', overflow: 'hidden', border: '1px solid #ddd'}}>
                <img src={(number === 1) ? Image1 : (number === 2) ? Image2 : Image3} alt={'landing table image'} style={{width: '100%'}}/>
            </div>
        </FlexItem>
    </FlexGroup>
}

const FeatureDataEnchange = ({number, numberColor, title, body} :FeatureProps) => {
    return <FlexGroup gutterSize={'xl'}>
        <FlexItem grow>
            <div style={{width: '80%', margin: '0px auto'}}>
                <div className={'feature-title'}>
                    <span style={{color: numberColor}}>{number}. </span>{title}
                </div>
                <Spacer/>
                <div style={{fontSize: '24px', lineHeight: 1.6}}>
                    {body}
                </div>
            </div>
        </FlexItem>
        <FlexItem grow>
            <div style={{minWidth: '300px', width: '40%', margin: '0px auto'}}>
                <img src={(number === 1) ? Image1 : (number === 2) ? Image2 : Image3} alt={'landing table image'} style={{width: '100%'}}/>
            </div>
        </FlexItem>
    </FlexGroup>
}


const HomePageFeatureSection = () => {
    return <PageSection paddingSize={'xl'} style={{zIndex: 2, backgroundColor: 'white'}}>
        <Spacer size={'xxl'}/>
        <FlexGroup gutterSize={'xl'}>
            <FlexItem grow>
                <div style={{width: '80%', margin: '0px auto'}}>
                    <p style={{fontSize: '44px', fontWeight: 400}}>Key Features</p>
                </div>
            </FlexItem>
            <FlexItem grow></FlexItem>
        </FlexGroup>
        <Spacer size={'xxl'}/>
        <Feature number={1} numberColor={'#Db589A'} title={'Data tables'}
                 body={'Design relational data tables to fit your data without compromise.  Choose from 24 column data types, including files, to build the data reality you need.'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <FeatureReverse number={2} numberColor={'#5DB7F9'} title={'Excel transformation'}
                 body={'Once your tables are built.  You can efficiently extract, transform and load the contents of Excel files, with cell level validation and mapping memory, onto your new tables.'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <FeatureDataEnchange number={3} numberColor={'#43EFD2'} title={'Data marketplace'}
                        body={'Coming soon!  The most comprehensive and easiest to use data marketplace on the planet.'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
    </PageSection>
}

export default HomePageFeatureSection