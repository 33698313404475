import { EuiCallOut } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    title? :string,
    iconType? :string,
    color? :'primary' | 'success' | 'warning' | 'danger'
    size? :'m' | 's'
    heading? :'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
    children? :ReactNode
}

const Callout = ({ title, iconType, color, size, heading, children } :Props) =>
    <EuiCallOut title={title} iconType={iconType} color={color} size={size} heading={heading}>{children}</EuiCallOut>

export default Callout