import {Modal} from 'eui';
import {Nav} from 'types';
import {useEffect, useState} from 'react';
import {NavClient} from "../../utils";
import {LoadingPage} from "../../pages";
import {Panel} from "../../eui";

type Props = {
    nav: Nav
    onClose: () => void
}

const PreviewImageModal = ({nav, onClose} :Props) => {

    const [data, setData] = useState<Blob | undefined>()

    const fetchData = async () => {
        if (nav.control.downloadable) {
            setData(await NavClient.fetchDownloadBlob(nav.path))
        }
    }

    useEffect(() => {
        fetchData()
    }, [nav.path])

    const urlCreator = window.URL || window.webkitURL;
    const imageUrl :string | undefined = data && urlCreator.createObjectURL(data);


    return <Modal onClose={onClose} style={{padding: '24px'}}>
            { (imageUrl) ? <img src={imageUrl}></img> : <LoadingPage/> }
    </Modal>
}

export default PreviewImageModal