import { HookHttpGetMulti } from 'Hook'
import { TypeEntityLinkTo, TypeHttpControlGetMulti, TypeNav, TypeNavKey, TypeUrlParam } from 'Type'
import { UtilNav } from 'Util'

type Props = {
    entities? :TypeEntityLinkTo[]
    resource? :TypeNavKey
    updateParams? :TypeUrlParam[]
}

const HookNavGetMulti = <T>({entities, resource, updateParams} :Props) :TypeHttpControlGetMulti<TypeNav<T>> => {
    const pathMap :{[key :string] :string} = {}
    if (entities) entities.forEach((e :TypeEntityLinkTo) => {pathMap[e.linkTo] = UtilNav.jumpPath(e, resource)})

    const control :TypeHttpControlGetMulti<TypeNav<T>> = HookHttpGetMulti<TypeNav<T>>({pathMap, updateParams})

    return control
}

export default HookNavGetMulti