import React, {ChangeEvent} from 'react'
import {EuiFieldPassword} from '@elastic/eui'
import { Field } from 'props'

const FieldPassword = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : '';

    const fn = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value)
    }
    
    return <EuiFieldPassword placeholder={placeholder}
                        fullWidth={fullWidth}
                        name={name}
                        value={valueStr}
                        isInvalid={isInvalid}
                        onChange={fn}/>
}

export default FieldPassword