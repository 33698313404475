import { ComponentNavSidebarTitle,ComponentNavDataPageTableSidebar, ComponentEuiSpacer, ComponentEuiFlexGroup, ComponentEuiFlexItem } from 'Component'
import {TypeEntityApi, TypeEntityOrganisation, TypeEntityUser, TypeNavHttpControl } from 'Type'
import {ModuleUserLogout} from 'Module'

type Props = {
    userEntity :TypeEntityUser
    orgControl :TypeNavHttpControl<TypeEntityOrganisation>
    apiControl :TypeNavHttpControl<TypeEntityApi>
}

const PageUserSidebar = ({ userEntity, orgControl, apiControl } :Props) => {

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={`${userEntity.firstName} ${userEntity.lastName}`} iconType={'userAvatar'} entityName={'User'}/>
            <ComponentEuiSpacer size={'xl'}/>
            <ComponentNavDataPageTableSidebar navControl={orgControl} showColumn={'name'} entityName={'Organisation'} title={'Organisations'} emptyMessage={'You are not a member of any organisations'}/>
            <ComponentEuiSpacer/>
            <ComponentNavDataPageTableSidebar navControl={apiControl} showColumn={'name'} entityName={'API Key'} title={'API Keys'} emptyMessage={'You have no API keys'}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ModuleUserLogout userEntity={userEntity}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageUserSidebar