import {ApiEntity, UserEntity } from 'entity'
import {HeaderLink, HeaderSectionItem, Popover } from 'eui'
import { ModalSession, SignUpModal } from 'modules'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionStore } from 'store'
import {DataPayload, Nav } from 'types'
import { NavClient } from 'utils'

const SignUp = () => {

    const navigate = useNavigate()

    const setToken = SessionStore(state => state.setToken)
    const token = SessionStore(state => state.token)
    const entity :UserEntity | ApiEntity | undefined = SessionStore(state => state.entity)

    const [open, setModal] = useState<boolean>(false)

    if (token && entity) return null

    return <HeaderSectionItem key={token || ''}>
        <HeaderLink onClick={() => setModal(true)}>Sign Up</HeaderLink>
        {(open) ? <SignUpModal onClose={() => setModal(false)}/> : null}
    </HeaderSectionItem>
}

export default SignUp