import { AuditDisplay } from 'display';
import IsPublicDisplay from 'display/parts/IsPublicDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import { WorkspaceEntity } from 'entity';
import { DisplayConfig } from 'types';

let config :DisplayConfig<WorkspaceEntity>[] = [...NameDisplay, {
    field: 'website',
    name: 'Website',
    sortable: true
},  ...IsPublicDisplay, ...AuditDisplay]

export default config