import {TableVersionColumnEntity} from 'entity'
import { ReactNode } from 'react'
import {RouteUtils } from 'utils'
import {CellValueRendererConfig} from 'config';

const rowIdNameCamel :string = 'rowId'
const linkTo = 'linkTo'

class CellRendererConfig {
    columnsByNameCamel :{ [key :string] : TableVersionColumnEntity } = {}
    data :any[]

    constructor(columns: TableVersionColumnEntity[], data :any[]) {
        columns.forEach((c :TableVersionColumnEntity) => {this.columnsByNameCamel[c.nameCamel] = c;})
        this.data = data
    }

    render(columnNameCamel :string, rowIndex :number = 0) :ReactNode {
        const row :any | undefined = this.rowIndexToRow(rowIndex);
        const value :any | undefined = this.getValue(columnNameCamel, rowIndex)
        const rowId :number | undefined = row[rowIdNameCamel]
        return this.renderValue(columnNameCamel, rowId, value)
    }

    renderValue(columnNameCamel :string, rowId :any, value :any) :ReactNode {
        const column :TableVersionColumnEntity | undefined = this.nameCamelToColumnEntity(columnNameCamel)
        return (column && rowId) ? CellValueRendererConfig.renderValue(value, column, rowId) : <></>
    }

    buildCellUrl(nameCamel :string, rowIndex :number) :string | undefined {
        const row :any | null = this.rowIndexToRow(rowIndex)
        const columnEntity :TableVersionColumnEntity | undefined = this.nameCamelToColumnEntity(nameCamel)
        if (!row || !columnEntity || !row[linkTo]) return undefined
        return RouteUtils.rowToCell(row[linkTo], columnEntity.nameSlug)
    }

    rowIndexToRow(rowIndex :number) :any | undefined {
        return this.data[rowIndex]
    }

    rowIndexToRowId(rowIndex :number) :any | undefined {
        let row = this.rowIndexToRow(rowIndex)
        return row[rowIdNameCamel] || undefined
    }

    getValue(columnNameCamel :string, rowIndex :number = 0) :any | undefined {
        let row = this.rowIndexToRow(rowIndex)
        return row[columnNameCamel]
    }

    nameCamelToColumnEntity(nameCamel :string) :TableVersionColumnEntity | undefined {
        return this.columnsByNameCamel[nameCamel]
    }

}

export default CellRendererConfig