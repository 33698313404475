import { HookHttpActionPayloadBuild } from 'Hook'
import { TypeHttpPayload, TypeNav } from 'Type'

type Props = {
    nav :TypeNav<any>
    formPayload :TypeHttpPayload
}

const HookNavFormPayloadBuild = ({nav, formPayload} :Props) :TypeHttpPayload => {
    const entity :any = {...nav.data.entity}
    return HookHttpActionPayloadBuild({entity, formPayload})
}

export default HookNavFormPayloadBuild