import { ComponentEuiDataGrid } from 'Component'
import { ContextTableData } from 'Context'
import { ModuleRowSheetDataCell } from 'Module'
import { TypeContextTableData, TypeEuiPropsDataGridCellValueElement, TypeEuiPropsDataGridColumn,
    TypeEuiPropsDataGridColumnVisibility, TypeEuiPropsDataGridControlColumn } from 'Type'
import { UtilExcel } from 'Util'

type Props = {
    data :any[]
}

const ModuleRowSheetData = ({ data } :Props) => {

    const context :TypeContextTableData = {
        data: data,
        columns: {},
        updates: {},
        colors: {}
    }

    const columns :TypeEuiPropsDataGridColumn[] = UtilExcel.buildColumnIdArrayFromData(data).map((columnId :string) => {
        return {
            id: columnId,
            displayAsText: columnId,
        }
    })

    const leadingControlColumns :TypeEuiPropsDataGridControlColumn[] = [
        {
            id: 'actions',
            width: 40,
            headerCellRender: () => (
                <span></span>
            ),
            rowCellRender: (props :TypeEuiPropsDataGridCellValueElement) => {
                return <ModuleRowSheetDataCell {...props} columnId={''}/>
            }
        }
    ]

    const columnVisibility :TypeEuiPropsDataGridColumnVisibility = {
        visibleColumns: columns.map((col :TypeEuiPropsDataGridColumn) => col.id),
        setVisibleColumns: () => {}
    }

    const renderCellValue = (props :TypeEuiPropsDataGridCellValueElement) => {
        return <ModuleRowSheetDataCell {...props}/>
    }

    return <ContextTableData.Provider value={context}>
        <ComponentEuiDataGrid columns={columns} rowCount={data.length} columnVisibility={columnVisibility}
                              renderCellValue={renderCellValue} toolbarVisibility={false} leadingControlColumns={leadingControlColumns}/>
    </ContextTableData.Provider>
}

export default ModuleRowSheetData