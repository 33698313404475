import { PageTitle } from 'components'
import {ApiEntity, UserEntity } from 'entity'
import {Button, FlexGroup, FlexItem, PageSection, Spacer } from 'eui'
import { GenericActionButtons, NavDataEntity, NavDetailsTab, NavResourceTabContent, NavResourceTabs } from 'modules'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NavStore, SessionStore } from 'store'
import {Nav, NavKey } from 'types'
import { MappingUtils } from 'utils'

type Props = {
    tabKey? :NavKey
}

const actionTitles = {delete: 'Close Account', patch: 'Update'}

const apiKey :NavKey = 'api'

const ApiPageContent = ({tabKey} :Props) => {

    const navigate :NavigateFunction = useNavigate()

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const apiNav :Nav | undefined = NavStore(store => store.contextNavs[apiKey])
    const clearToken :() => void = SessionStore(state => state.clearToken)
    const sessionEntity :UserEntity | ApiEntity | undefined = SessionStore(state => state.entity)

    if (!pageNav || !apiNav) return null

    const api :ApiEntity | undefined = pageNav.context[apiKey] as ApiEntity

    if (!api) return null

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {api.name}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                            <GenericActionButtons nav={apiNav} contextKey={'user'} onSuccess={() => {navigate('/')}} toggleIcon={'gear'} buttonSize={'s'} buttonTitle={actionTitles}/>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>
                    {sessionEntity && sessionEntity.linkTo === api.linkTo && <Button iconType={'exit'} onClick={clearToken} color={'accent'} size={'s'} fill={false}>Logout</Button>}
                </FlexItem>
            </FlexGroup>

            <Spacer size={'m'}/>
            <NavResourceTabs nav={apiNav} tab={tabKey}>
                <NavDetailsTab linkTo={api.linkTo} isSelected={(!tabKey)} icon={MappingUtils.contextIcon(apiKey)}/>
            </NavResourceTabs>
        </PageSection>
        <PageSection color={'plain'} alignment={'top'} paddingSize={'none'}>
            {tabKey ?
                <NavResourceTabContent nav={pageNav} navKey={tabKey} /> :
                <NavDataEntity configKey={apiKey}/>
            }
        </PageSection>
    </>
}

export default ApiPageContent