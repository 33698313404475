import { PageTitle } from 'components'
import { OrganisationEntity } from 'entity'
import {FlexGroup, FlexItem, PageSection, Spacer } from 'eui'
import { GenericActionButtons, NavDataEntity, NavDetailsTab, NavResourceTabContent, NavResourceTabs } from 'modules'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { Nav, NavKey } from 'types'
import { MappingUtils } from 'utils'

type Props = {
    tabKey? :NavKey
}

const actionTitles = {delete: 'Close Account', patch: 'Update'}

const OrganisationPageContent = ({tabKey} :Props) => {

    const navigate :NavigateFunction = useNavigate()

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const orgNav :Nav | undefined = NavStore(store => store.contextNavs['org'])
    if (!pageNav || !orgNav) return null

    const organisation :OrganisationEntity | undefined = pageNav.context['org'] as OrganisationEntity

    if (!organisation) return null

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {organisation.name}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                            <GenericActionButtons nav={orgNav} contextKey={'user'} onSuccess={() => {navigate('/')}} toggleIcon={'gear'} buttonSize={'s'} buttonTitle={actionTitles}/>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>

                </FlexItem>
            </FlexGroup>

            <Spacer size={'m'}/>
            <NavResourceTabs nav={orgNav} tab={tabKey}>
                <NavDetailsTab linkTo={organisation.linkTo} isSelected={(!tabKey)} icon={MappingUtils.contextIcon('org')}/>
            </NavResourceTabs>
        </PageSection>
        <PageSection color={'plain'} alignment={'top'} paddingSize={'none'}>
            {tabKey ?
                <NavResourceTabContent nav={pageNav} navKey={tabKey} /> :
                <NavDataEntity configKey={'org'}/>
            }
        </PageSection>
    </>
}

export default OrganisationPageContent