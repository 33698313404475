import { ComponentEuiButton, ComponentEuiCallout, ComponentEuiSpacer } from 'Component'
import { HookNavAction, HookPageLocation } from 'Hook'
import {TypeControlPageLocation, TypeEntityTransformDestination, TypeEntityTransformLoad, TypeNav, TypeNavControlAction, TypeNavHttpControlAction } from 'Type'

type Props = {
    loadNav :TypeNav<TypeEntityTransformLoad>
    destination :TypeEntityTransformDestination
}

const ModuleTransformLoad = ({loadNav, destination} :Props) => {

    const action :TypeNavControlAction | undefined = loadNav.control.action['post']
    const control :TypeNavHttpControlAction<TypeEntityTransformLoad> = HookNavAction('post', loadNav)
    const location :TypeControlPageLocation = HookPageLocation()

    const onClick = async () => {
        await control.submit()
        location.setLocation(destination.linkTo)
    }

    const transformationComplete :boolean = action.auth.message === 'Transformation complete.'

    return <>
        <ComponentEuiButton color={'success'} iconType={'container'} onClick={onClick} isDisabled={!action.auth.authorized} fullWidth isLoading={control.isLoading}>Load</ComponentEuiButton>
        <ComponentEuiSpacer/>
        {action.auth.message && <ComponentEuiCallout color={(transformationComplete) ? 'success' : 'warning'} title={action.auth.message} iconType={(transformationComplete) ? 'check' : 'alert'}/>}
    </>
}

export default ModuleTransformLoad