import {HeaderLink, HeaderSectionItem } from 'eui'
import { GlobalViewNav } from 'Global'

const Toggle = () => {
    const isNav :boolean = GlobalViewNav(state => state.isNav);
    const toggleNav :() => void = GlobalViewNav(state => state.toggleNav);

    return <HeaderSectionItem>
        <HeaderLink key={`${isNav}`} isActive={isNav} onClick={toggleNav}>{(isNav) ? 'Exit API Navigator' : 'API Navigator'}</HeaderLink>
    </HeaderSectionItem>
}

export default Toggle