import { TypeWebSocketControl, TypeWebSocketPayloadAcc } from 'Type'

const MAX_RETRY = 10
const RETRY_INTERVAL_MS = 3000
const RETRY_INTERVAL_DECAY = 3

const HEARTBEAT_INTERVAL_MS = 50000
const HEARTBEAT_MESSAGE :string = 'beep'

class ClassClientSocket<M extends TypeWebSocketPayloadAcc> {

    private readonly url :string
    private readonly onChange :(update :TypeWebSocketControl<M>) => void
    private client :WebSocket | undefined
    public state :TypeWebSocketControl<M>
    private connectCount :number

    constructor(url :string, onChange: (update :TypeWebSocketControl<M>) => void, state? :TypeWebSocketControl<M>) {
        this.url = url
        this.connectCount = 0
        this.onChange = onChange
        this.state = state || {messages: [], latestMessage: undefined, isConnected: false }
        this.startWebsocket()
    }

    private startWebsocket() {
        this.permanentlyClose()
        this.connectCount = this.connectCount += 1
        this.client = new WebSocket(this.url)
        this.client.onopen = () => this.onOpen()
        this.client.onclose = () => this.onClose()
        this.client.onmessage = (event :MessageEvent) => this.onMessage(event)
    }

    private onOpen() {
        this.updateConnection(true)
        this.heartbeat()
    }

    private onClose() {
        this.updateConnection(false)
        if (this.connectCount < MAX_RETRY) {
            setTimeout(() => {
                this.startWebsocket()
            }, (this.connectCount * RETRY_INTERVAL_DECAY * RETRY_INTERVAL_MS))
        }
    }

    private onMessage(event :MessageEvent) {
        const latestMessage :M & TypeWebSocketPayloadAcc = JSON.parse(event.data)
        latestMessage.clientTimestampMs = Date.now()
        this.updateMessage(latestMessage)
    }


    private isOpen() :boolean {
        return Boolean(this.client && this.client.readyState === WebSocket.OPEN)
    }

    private sendMessage(message :any) {
        if (this.isOpen()) this.client?.send(message)
    }

    private heartbeat() {
        if (this.isOpen()) {
            setTimeout(() => {
                this.sendMessage(HEARTBEAT_MESSAGE)
                this.heartbeat()
            }, HEARTBEAT_INTERVAL_MS)
        }
    }

    private updateState(newState :TypeWebSocketControl<M>) {
        this.state = newState
        this.onChange(newState)
    }

    private updateMessage(latestMessage :M) {
        const newState :TypeWebSocketControl<M> = {...this.state, latestMessage}
        newState.messages.push(latestMessage)
        this.updateState(newState)
    }

    private updateConnection(isConnected :boolean) {
        const newState :TypeWebSocketControl<M> = {...this.state, isConnected}
        this.updateState(newState)
    }

    permanentlyClose() {
        if (this.client) {
            this.client.onclose = () => {}
            this.client.close()
        }
        this.connectCount = MAX_RETRY
        this.updateConnection(false)
    }

    cloneAndClose() :ClassClientSocket<M> {
        this.permanentlyClose()
        return new ClassClientSocket<M>(this.url, this.onChange, this.state)
    }
}

export default ClassClientSocket