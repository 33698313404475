import { ComponentDivWidth, ComponentEuiLoadingSpinner, ComponentNavDataPageTablePopover } from 'Component'
import { HookNavGet } from 'Hook'
import { TypeEntityTransformExclude, TypeModuleControlRefresh, TypeNav, TypeNavControlResource, TypeNavHttpControl } from 'Type'

type Props = {
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
    onClose :() => void
    onActionSuccess?: () => void
}

const ModuleTransformTitlePopoverExcludeContent = ({excludeResource, refreshControl, onClose} :Props) => {

    const control :TypeNavHttpControl<TypeEntityTransformExclude> = HookNavGet({linkTo: excludeResource})

    const excludeNav :TypeNav<TypeEntityTransformExclude> | undefined = control.res?.data

    const onActionSuccess = () => {
        control.submit()
        refreshControl.exclude()
    }

    return <ComponentDivWidth width={300}>
        {(excludeNav) ? <ComponentNavDataPageTablePopover title={'Excluded Rows'} nav={excludeNav} showColumns={['sheetRowIndex']} onClose={onClose} entityName={'Exclusion'} onActionSuccess={onActionSuccess}/> : <ComponentEuiLoadingSpinner/> }
    </ComponentDivWidth>
}

export default ModuleTransformTitlePopoverExcludeContent