
import { ModuleTableDataTableCell, ModuleTableDataTableCellPopover } from 'Module'
import { ReactNode} from 'react'
import {TypeEuiPropsDataGridCellPopoverElement, TypeEuiPropsDataGridCellValueElement, TypeModuleTableDataCellRenderer } from 'Type'

const HookModuleTableDataCellRenderer = () :TypeModuleTableDataCellRenderer => {


    const renderCellValue = (props :TypeEuiPropsDataGridCellValueElement) :ReactNode => <ModuleTableDataTableCell {...props}/>

    const renderCellPopover = (props :TypeEuiPropsDataGridCellPopoverElement) => {
        return <ModuleTableDataTableCellPopover rowIndex={props.rowIndex} columnNameSlug={props.columnId} />
    }

    return { renderCellValue, renderCellPopover }
}

export default HookModuleTableDataCellRenderer