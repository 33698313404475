import AuditDisplay from 'display/Audit/AuditDisplay';
import GlobalIdDisplay from 'display/parts/GlobalIdDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import {ApiEntity } from 'entity';
import { DisplayConfig } from 'types';

const ApiDisplay :DisplayConfig<ApiEntity>[] = [
    ...NameDisplay,
    {
        field: 'apiId',
        name: 'API Id'
    },
    ...GlobalIdDisplay,
    ...AuditDisplay
]

export default ApiDisplay