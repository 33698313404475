import { ComponentDivCenter, ComponentEuiCallout, ComponentNavActionPanel } from 'Component'
import {HookControlSwitch, HookNavPage} from 'Hook'
import { ModulePage } from 'Module'
import {TypeControlSwitch, TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'

const PagePasswordReset = () => {

    const successSwitch :TypeControlSwitch = HookControlSwitch()

    const pageControl :TypeNavHttpControl<any> = HookNavPage<any>()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Password Reset', control: pageControl}
    ]

    const onSuccess = async () => successSwitch.turnOn()

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentDivCenter>
            { pageNav && successSwitch.value === false && <ComponentNavActionPanel title={'Reset Password'} isTitleVerb nav={pageNav} actionType={'post'} onSuccess={onSuccess}/> }
            { successSwitch.value && <ComponentEuiCallout color={'success'} iconType={'check'} title={'Password request email sent.  Please check your email.'}/> }
        </ComponentDivCenter>
    </ModulePage>

}

export default PagePasswordReset