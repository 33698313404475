import { ComponentDivMarginHorizontal, ComponentDivMarginVertical } from 'Component'
import { ModuleTableDataTable } from 'Module'
import { TypeModuleTableDataProps } from 'Type'


const PageTableBody = ({ dataControl, updatesControl, columnControl, fieldNameMap, toLoad } :TypeModuleTableDataProps) => {
    return <ComponentDivMarginHorizontal margin={-1}>
            <ComponentDivMarginVertical margin={-1}>
                { <ModuleTableDataTable dataControl={dataControl} columnControl={columnControl} fieldNameMap={fieldNameMap} updatesControl={updatesControl} toLoad={toLoad}/>}
            </ComponentDivMarginVertical>
        </ComponentDivMarginHorizontal>
}

export default PageTableBody