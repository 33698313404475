import {
    ComponentEuiButton, ComponentEuiButtonEmpty, ComponentEuiDescriptionList,
    ComponentEuiLoadingSpinner, ComponentEuiModal,
    ComponentEuiModalBody, ComponentEuiModalFooter, ComponentEuiModalHeader, ComponentEuiModalHeaderTitle } from 'Component'
import {HookControlSwitch, HookNavActionAuto } from 'Hook'
import {TypeControlSwitch, TypeEntityApi, TypeEuiPropsDescriptionListItem, TypeNav, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    apiEntity :TypeEntityApi
    secretNav? :TypeNav<TypeEntityApi>
}

const ModuleApiSecretReset = ({ apiEntity, secretNav } :Props) => {

    const modalSwitch :TypeControlSwitch = HookControlSwitch()

    const action :TypeNavHttpControlActionAuto<TypeEntityApi> = HookNavActionAuto<TypeEntityApi>('patch', secretNav)

    const api :TypeEntityApi = action.res?.data?.data.entity ?? apiEntity

    const details :TypeEuiPropsDescriptionListItem[] = [
        {
            title: 'Name',
            description: api.name
        },
        {
            title: 'Api ID',
            description: api.apiId
        },
        {
            title: 'Secret',
            description: (api.secret) ? api.secret : (action.isLoading) ? <ComponentEuiLoadingSpinner/> : <ComponentEuiButton color={'danger'} onClick={action.submit} size={'s'}>Refresh</ComponentEuiButton>
        }
    ]

    return <>
        { action.isActionable && <ComponentEuiButtonEmpty onClick={modalSwitch.turnOn} color={'primary'}>Admin</ComponentEuiButtonEmpty> }
        { modalSwitch.value && <ComponentEuiModal onClose={modalSwitch.turnOff}>
            <ComponentEuiModalHeader>
                <ComponentEuiModalHeaderTitle>Details</ComponentEuiModalHeaderTitle>
            </ComponentEuiModalHeader>
            <ComponentEuiModalBody>
                <ComponentEuiDescriptionList listItems={details}/>
            </ComponentEuiModalBody>
            <ComponentEuiModalFooter>

            </ComponentEuiModalFooter>
        </ComponentEuiModal>}
    </>

}

export default ModuleApiSecretReset