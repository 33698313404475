import { HookPageLocation } from 'Hook'
import {FORMAT_PAGE_STATIC, FORMAT_PAGE_HEADER_SINGLE_HEIGHT} from 'Format'
import {TypeNavHttpControl, TypePageHeaderHeight } from 'Type'

const HookPageHeaderHeight = (control? :TypeNavHttpControl<any>) :TypePageHeaderHeight => {
    const { location } = HookPageLocation()
    const hasBreadcrumbs :boolean = Boolean(control && !FORMAT_PAGE_STATIC.includes(location))
    const headerHeight :number = FORMAT_PAGE_HEADER_SINGLE_HEIGHT * ((hasBreadcrumbs) ? 2 : 1)
    return { hasBreadcrumbs, headerHeight }
}

export default HookPageHeaderHeight