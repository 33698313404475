import {Icon, LoadingSpinner, Tab } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    linkTo :string
    isSelected :boolean
    icon? :string
}

const NavDetailsTab = ({linkTo, isSelected, icon = 'editorUnorderedList'} :Props) => {

    const navigate = useNavigate()

    const onClick = async () => {
        navigate(linkTo)
    }

    return <Tab className={(isSelected) ? 'nav-resource-tab-active' : 'nav-resource-tab'}
                prepend={<Icon type={icon} color={(isSelected) ? 'primary' : undefined}/>}
                onClick={onClick}
                isSelected={isSelected}>
        Details
    </Tab>
}

export default NavDetailsTab