import {useEffect} from "react";
import {NavModalTable, PageTitle} from "../../components";
import {InterfaceMapEntity} from "../../entity";
import {Card, FlexGroup, FlexItem, LoadingSpinner, PageSection, Panel, Spacer} from "../../eui";
import {useNav} from "../../hooks";
import {Nav, NavHookControl, NavKey} from "../../types";
import {MappingUtils, RouteUtils} from "../../utils";
import {GenericActionButtons, NavDataPageTable} from "../index";

type Props = {
    map :InterfaceMapEntity
    title :string
}

const fieldKey :NavKey = 'map-field'

const InterfaceMappingFieldSection = ({map, title} :Props) => {
    const navMapControl :NavHookControl = useNav()
    const navFieldControl :NavHookControl = useNav()

    useEffect(() => {
        navMapControl.fetch(map.linkTo)
        navFieldControl.fetch(RouteUtils.interfaceMapField(map))
    }, [map.linkTo])

    const mapNav :Nav | undefined = navMapControl.nav
    const fieldNav :Nav | undefined = navFieldControl.nav

    if (!mapNav || !fieldNav) return <LoadingSpinner/>

    return <>
        <Panel paddingSize={'none'} hasBorder={true} grow={false}>
            <PageSection paddingSize={'m'}>
                <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                    <FlexItem>
                        <FlexGroup alignItems={'baseline'}>
                            <FlexItem>
                                <PageTitle>
                                    {map.name}
                                </PageTitle>
                            </FlexItem>
                            <FlexItem>
                                <GenericActionButtons nav={mapNav} contextKey={'ws'} toggleIcon={'gear'} buttonSize={'s'}/>
                            </FlexItem>
                        </FlexGroup>
                        <FlexGroup>
                            <FlexItem>
                                <Spacer size={'s'}/>
                                <p style={{color: '#69707D'}}>{title}</p>
                                <Spacer/>
                            </FlexItem>
                        </FlexGroup>
                    </FlexItem>
                    <FlexItem>
                    </FlexItem>
                </FlexGroup>
            </PageSection>
            <NavModalTable nav={fieldNav} navKey={fieldKey} navigate={'control'} onSuccess={navFieldControl.refresh} ignoreAudit={true}/>
        </Panel>
    </>
}

export default InterfaceMappingFieldSection