import {NavActionModal, NavForm } from 'components'
import { UserEntity } from 'entity'
import {Button, Page, PageBody } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import {DataPayload, Nav, NavControlAction, NavControlActionType } from 'types'
import { MappingUtils } from 'utils'

type Props = {
    title? :string,
    actionType :NavControlActionType,
    onSuccess :(response: Nav, req? :DataPayload) => void
    onClose? :() => void
}

const GenericPageForm = ({ title, actionType, onSuccess } :Props) => {

    const navigate = useNavigate()

    const nav :Nav | undefined = NavStore(state => state.nav)

    const [submit, setSubmit] = useState<boolean>(false)

    const onSubmit = () => setSubmit(true)

    const action :NavControlAction | undefined = nav && nav.control.action[actionType]

    const innerOnClose = () => {
        const user :UserEntity | undefined = nav && nav.context['user']
        navigate((user && user.linkTo) || '/')
    }

    return <Page>
        <PageBody paddingSize={'none'}>
            { nav && <NavActionModal nav={nav} actionType={actionType} onSuccess={onSuccess} onClose={innerOnClose} title={title}/> }
        </PageBody>
    </Page>
}

export default GenericPageForm