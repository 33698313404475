import {ApiEntity, UserEntity } from 'entity'
import {HeaderLink, HeaderSectionItem, Popover } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionStore } from 'store'
import { Nav } from 'types'


import LoginOptions from './Options/Options'

const Login = () => {

    const navigate = useNavigate()

    let [showPopover, setShowPopover] = useState<boolean>(false)
    const togglePopover = () => setShowPopover(!showPopover)
    const closePopover = () => setShowPopover(false)
    
    const setToken = SessionStore(state => state.setToken)
    const token = SessionStore(state => state.token)
    const entity :UserEntity | ApiEntity | undefined = SessionStore(state => state.entity)

    const onSuccess = async (nav :Nav) => {
        closePopover()
        if (nav && nav.data && nav.data.entity) {
            const newEntity :UserEntity | ApiEntity | undefined = await setToken(nav.data.entity)
            if (newEntity) navigate(newEntity.linkTo)
        }
    }
    if (token && entity) return null

    return <HeaderSectionItem key={token || ''}>
        <Popover
            button={<HeaderLink onClick={togglePopover}>Login</HeaderLink>}
            isOpen={showPopover}
            closePopover={() => setShowPopover(false)}
            anchorPosition={'downRight'}
            panelPaddingSize={'s'}>
            {<LoginOptions onSuccess={onSuccess}/>}
        </Popover>
    </HeaderSectionItem>
}

export default Login