import React, {PropsWithChildren} from 'react'
import {EuiFlexGroup} from '@elastic/eui'

type Props = {
    gutterSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'none'
    className?: string
    justifyContent?: 'center' | 'flexStart' | 'flexEnd' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly'
    responsive?: boolean
    alignItems?: 'center' | 'baseline' | 'stretch' | 'flexStart' | 'flexEnd'
    direction?: 'column' | 'row' | 'rowReverse' | 'columnReverse'
    style?: object
    wrap? :boolean
};

const ComponentEuiFlexGroup = ({gutterSize, className, responsive, justifyContent, alignItems, direction, children, style, wrap}: PropsWithChildren<Props>) =>
    <EuiFlexGroup gutterSize={gutterSize}
                  className={className}
                  responsive={responsive}
                  justifyContent={justifyContent}
                  alignItems={alignItems}
                  direction={direction}
                  style={style}
                  wrap={wrap}>
        {children}
    </EuiFlexGroup>

export default ComponentEuiFlexGroup