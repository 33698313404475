import {ApiEntity, UserEntity } from 'entity'
import { Callout, Link, Spacer } from 'eui'
import {ReactNode, useEffect, useState } from 'react'
import {NavStore, SessionStore } from 'store'
import { Nav, NavControlAction, NavControlResource, NavKey } from 'types'
import { JsxElement } from 'typescript'
import { NavClient } from 'utils'

type Props = {
}

const emailConfirmKey :NavKey = 'email-confirmation'

const callout = (icon :string, color: 'primary' | 'success' | 'danger', title: string, children: ReactNode) => {
    return <>
        <Callout title={title} iconType={icon} color={color}>
            {children}
        </Callout>
        <Spacer size={'m'}/>
    </>
}


const EmailConfirmationCallout = ({} :Props) => {

    const sessionEntity :UserEntity | ApiEntity | undefined = SessionStore(store => store.entity)
    const userNav :Nav | undefined = NavStore(store => store.contextNavs['user'])
    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const user :UserEntity | undefined = pageNav && pageNav.context['user']
    const [emailConfirmationNav, setEmailConfirmationNav] = useState<Nav | undefined>(undefined)
    const [sentSuccess, setSentSuccess] = useState<boolean>(false)
    const [sentError, setSentError] = useState<boolean>(false)

    const emailConfirmationResource :NavControlResource | undefined = userNav && userNav.control.resource[emailConfirmKey]
    const emailConfirmationAction :NavControlAction | undefined = emailConfirmationNav && emailConfirmationNav && emailConfirmationNav.control.action['post']

    const sessionIsContextUser :boolean = Boolean(sessionEntity && user && sessionEntity.linkTo === user.linkTo)
    const canPost :boolean = Boolean(emailConfirmationAction && emailConfirmationAction.auth.authorized)
    const emailNeedsConfirming :boolean = Boolean(user && !user.emailConfirmation)


    useEffect(() => {
        const fetchEmailConfirmationNav = async () => {
            if (sessionIsContextUser && emailConfirmationResource) setEmailConfirmationNav(await NavClient.fetchNav(emailConfirmationResource.linkTo))
        }
        fetchEmailConfirmationNav()
    }, [user && user.linkTo, (sessionEntity && sessionEntity.linkTo) || ''])

    const onClick = async () => {
        if (emailConfirmationNav && emailConfirmationNav.control.action['post']) {
            const respNav :Nav = await NavClient.action(emailConfirmationNav, 'post', {})
            if (respNav.error) {
                setSentError(true)
            } else {
                setSentSuccess(true)
            }
        }
    }

    if (sentSuccess) return callout('check', 'success', 'Confirmation email resent!',
        <>Please check your Inbox.</>)

    if (sentError) return callout('warning', 'danger', 'Apologies, there was an error resending your confirmation email',
        <>Please try again later.</>)

    if (sessionIsContextUser && emailNeedsConfirming && canPost) return callout('pin', 'primary', 'To get started, please confirm your email!',
        <>Confirm your email by clicking the "Verify Email Address" button in the email we sent you.  Or click <Link onClick={onClick}>here</Link> to resend the email.</>)

    return <></>
}

export default EmailConfirmationCallout