import {Nav, NavHookControl} from 'types'
import {NavClient} from 'utils'
import {useState} from 'react'


const NavHook = () :NavHookControl => {
    const [nav, setNav] = useState<Nav | undefined>()

    const fetch = async (linkTo :string) => {
        setNav(undefined)
        setNav(await NavClient.fetchNav(linkTo))
    }

    const fetchAll = async (linkTo :string) => {
        setNav(undefined)
        setNav(await NavClient.fetchAll(linkTo))
    }

    const refresh = async () => {
        if (nav) setNav(await NavClient.fetchNav(nav.path))
    }

    const refreshAll = async () => {
        if (nav) setNav(await NavClient.fetchAll(nav.path))
    }

    return {
        nav,
        fetch,
        fetchAll,
        refresh,
        refreshAll
    }
}

export default NavHook;