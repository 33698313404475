import { EntityDisplay } from 'components'
import { CellRendererConfig } from 'config'
import { TableVersionColumnEntity } from 'entity'
import { ReactNode } from 'react'
import { DisplayConfig } from 'types'
import {SystemColumnsUtils} from "../../utils";
import {CellContextMenu} from "../index";
import {LinkToEntity} from "../../entity";

type Props = {
    rowEntity :LinkToEntity
    columns :TableVersionColumnEntity[]
}

const TableRowDisplay = ({rowEntity, columns} :Props) => {

    const showSystemColumns :boolean = SystemColumnsUtils.getShowSystemColumns()

    const renderer :CellRendererConfig = new CellRendererConfig(columns, [rowEntity])

    const display :DisplayConfig[] = columns.filter((c :TableVersionColumnEntity) => {
        return (showSystemColumns || !c.isSystem)
    }).map(
        (c :TableVersionColumnEntity) => {
            return {
                field: c.nameCamel,
                name: c.name,
                render: (value :any, record?: any) :ReactNode => {
                    return renderer.render(c.nameCamel)
                }
            }
        }
    )

    const renderPopover = (field :string) :ReactNode => {
        const column :TableVersionColumnEntity | undefined = renderer.nameCamelToColumnEntity(field)
        const rowId :number | undefined = renderer.rowIndexToRowId(0)
        return (column && rowId) ? <CellContextMenu column={column} rowId={rowId} rowLinkTo={rowEntity.linkTo}/> : null;
    }

    return <EntityDisplay object={rowEntity} config={display} columns={3} renderPopover={renderPopover}/>
}

export default TableRowDisplay