import { PageTitle } from 'components'
import { TableEntity, TableVersionEntity } from 'entity'
import {FlexGroup, FlexItem, PageSection, Beacon, Spacer } from 'eui'
import { useNavigateToTable, useTableColumns } from 'hooks'
import { GenericActionButtons, TableSectionToolbar, TableVersionRowTable } from 'modules'
import { useEffect } from 'react'
import {useLocation} from "react-router-dom";
import { NavStore, TableSocketStore } from 'store'
import { Nav, NavKey, TableColumnHookControl } from 'types'

const actionTitles = {delete: 'Delete'}

const tableKey :NavKey = 'table'
const versionKey :NavKey = 'version'

const TablePageContent = () => {

    const location = useLocation()
    const navigateToTable :(table :TableEntity, version? :TableVersionEntity) => void = useNavigateToTable()
    const connect :(nav :Nav) => void = TableSocketStore(store => store.connect)
    const isConnected :boolean = TableSocketStore(store => store.isConnected)

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const tableNav :Nav | undefined = NavStore(store => store.contextNavs[tableKey])
    const versionNav :Nav | undefined = NavStore(store => store.contextNavs[versionKey])

    const tableEntity :TableEntity | undefined = pageNav && pageNav.context[tableKey]
    const versionEntity :TableVersionEntity | undefined = pageNav && pageNav.context[versionKey]

    const columnControls :TableColumnHookControl = useTableColumns()

    const doFetchColumns = async () => {
        columnControls.fetchColumns(versionNav)
    }

    useEffect(() => {
        doFetchColumns()
    }, [versionNav?.path])

    const isCorrectTable = () :boolean => (tableEntity) ? location.pathname.includes(tableEntity.linkTo) : false

    useEffect(() => {
        if (isCorrectTable() && tableEntity) navigateToTable(tableEntity, versionEntity)
    }, [tableEntity?.linkTo, versionEntity?.linkTo])

    useEffect(() => {if (versionEntity && pageNav && pageNav.control.connectable) connect(pageNav)}, [pageNav?.path, versionEntity])

    if (!pageNav || !tableNav || !tableEntity || !versionEntity) return null

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'} key={pageNav.path}>
            <FlexGroup alignItems={'baseline'}>
                <FlexItem>
                    <PageTitle>
                        {tableEntity.name}
                    </PageTitle>
                </FlexItem>
                <FlexItem grow>
                    <GenericActionButtons nav={tableNav} contextKey={'user'} toggleIcon={'gear'} buttonSize={'s'} buttonTitle={actionTitles}/>
                </FlexItem>
                <FlexItem>
                    <Beacon color={(isConnected) ? 'success' : 'subdued'}/>
                </FlexItem>
            </FlexGroup>
            <Spacer/>
        </PageSection>
        {columnControls.columns &&
          <>
            <TableSectionToolbar columnControls={columnControls}/>
            <div style={{margin: '0px -1px'}}>
              <PageSection paddingSize={'none'}>
                <TableVersionRowTable columns={columnControls.columns} rowNav={pageNav} moveColumn={columnControls.moveColumn}/>
              </PageSection>
            </div>
        </>}
    </>
}

export default TablePageContent