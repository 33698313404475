import {ComponentDivMarginVertical, ComponentEuiLoadingSpinner,
    ComponentEuiPageSection, ComponentNavDataPageTable, ComponentNavResourceTabs } from 'Component'
import { TypeEntityLinkTo, TypeNav, TypeNavControlResourceConfig, TypeNavHttpControl, TypeNavKey } from 'Type'

type Props<T extends object & TypeEntityLinkTo> = {
    activeResourceKey? :TypeNavKey
    pageControl :TypeNavHttpControl<T>
    resourceConfigs :TypeNavControlResourceConfig<T>[]
    showColumns :(keyof T)[]
}

const PageWorkspaceBody = <T extends object & TypeEntityLinkTo>({ activeResourceKey, pageControl, resourceConfigs, showColumns } :Props<T>) => {
    const pageNav :TypeNav<T> | undefined = pageControl.res?.data
    return (pageNav) ? <div>
        <ComponentEuiPageSection grow paddingSize={'none'} bottomBorder>
            <ComponentNavResourceTabs activeKey={activeResourceKey} resourceConfigs={resourceConfigs} activeNav={pageNav}/>
            <ComponentDivMarginVertical margin={-1}/>
        </ComponentEuiPageSection>
        <ComponentNavDataPageTable action={'navigate'} entityName={''} paginate={false} initialNav={pageNav} showColumns={showColumns}/>
    </div> : <ComponentEuiLoadingSpinner/>
}

export default PageWorkspaceBody