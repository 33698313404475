import { ComponentEuiLink } from 'Component'
import { HookNavGlobalIdMeta } from 'Hook'
import { TypeNavMeta } from 'Type'
import { UtilGlobalId } from 'Util'

type Props = {
    globalId :string
}

const ComponentNavGlobalIdLink = ({ globalId } :Props) => {
    if (!globalId) return null
    const meta :TypeNavMeta | undefined = HookNavGlobalIdMeta(globalId)
    const linkTo :string = UtilGlobalId.toLink(globalId)
    return <ComponentEuiLink href={linkTo}>{meta?.title ?? globalId}</ComponentEuiLink>
}

export default ComponentNavGlobalIdLink