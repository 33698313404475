import { ButtonEmpty } from 'eui'
import { Nav } from 'types'
import { NavClient } from 'utils'

type Props = {
    nav :Nav
    size? : 's' | 'm'
}

const NavFileDownloadButton = ({nav, size = 'm'} :Props) => {
    const isDownloadable: boolean = nav.control.downloadable
    return <ButtonEmpty size={size} iconType={'download'} isDisabled={!isDownloadable} onClick={() => {
        NavClient.download(nav.path)
    }}>Download</ButtonEmpty>
}

export default NavFileDownloadButton