import React, {ChangeEvent} from 'react'
import {EuiFieldNumber} from '@elastic/eui'
import { Field } from 'props'
type Props = Field & {
    min?: number
    max?: number
    step? :number
}

const isZero = (str :string) => {
    return (Number(str) === 0)
}

const FieldPercentage = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true, min, max, step}: Props) => {

    const fn = (e: ChangeEvent<HTMLInputElement>) => {
        const etv :string = e.target.value
        onChange(name, (isZero(etv)) ? etv : `${Number(e.target.value + 'e-2')}`)
    }

    let valueStr :string = ''

    if (typeof value === 'string') {
        if (isZero(value)) {
            valueStr = value
        } else {
            valueStr = `${Number(value + 'e2')}`
        }
    }

    return <EuiFieldNumber placeholder={placeholder}
                           name={name}
                           value={valueStr}
                           isInvalid={isInvalid}
                           fullWidth={fullWidth}
                           onChange={fn}
                           min={min}
                           max={max}
                           step={step}
                           append={'%'}


    />
}

export default FieldPercentage