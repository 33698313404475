import {ComponentEuiLoadingSpinner, ComponentNavActionModalButton, ComponentNavDataPageTable, ComponentNavMetaPageSectionHeader } from 'Component'
import { ModuleUserEmailConfirmation } from 'Module'
import {TypeEntityUser, TypeEntityWorkspace, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    userNav :TypeNav<TypeEntityUser>
    wsControl :TypeNavHttpControl<TypeEntityWorkspace>
}

const PageUserBody = ({ userNav, wsControl } :Props) => {
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    const newWsButton = wsNav && <ComponentNavActionModalButton nav={wsNav} entityName={'Workspace'} actionType={'post'} buttonType={'icon'} onSuccess={wsControl.submit} iconType={'plusInCircle'} color={'primary'} size={'m'} buttonTitle={'New Workspace'}/>

    return (wsNav) ? <>
        <ModuleUserEmailConfirmation userNav={userNav}/>
        <ComponentNavMetaPageSectionHeader title={'Workspaces'} bottomBorder={'extended'} rightActions={newWsButton} compact/>
        <ComponentNavDataPageTable action={'navigate'} entityName={'Workspace'} paginate={false} initialNav={wsNav} showColumns={['name', 'isPublic', 'ownerGlobalId']}/>
    </> : <ComponentEuiLoadingSpinner/>
}

export default PageUserBody