import React from 'react'
import {EuiModalBody} from '@elastic/eui'

import './ModalBody.css'

type Props = {
    children?: React.ReactNode
    padding? : 'none'
}

const ModalBody = ({children, padding}: Props) => {
    return <EuiModalBody className={(padding === 'none') ? 'modal-body-padding-none' : undefined}>{children}</EuiModalBody>
}

export default ModalBody