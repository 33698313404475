import {ComponentEuiModalBody,
    ComponentEuiModalFooter, ComponentEuiModalHeader, ComponentEuiModalHeaderTitle, ComponentNavFormButtonCancel, ComponentNavDataPageTable } from 'Component'
import {TypeEntityLinkTo, TypeNav, TypeNavControlActionHandler } from 'Type'
import { UtilString } from 'Util'

type Props<T extends object & TypeEntityLinkTo> = {
    title :string
    entityName :string
    nav :TypeNav<T>
    showColumns :(keyof T)[]
    onClose :() => void
    onActionSuccess?: () => void
}

const ComponentNavDataPageTablePopover = <T extends object & TypeEntityLinkTo>({title, entityName, nav, showColumns, onClose, onActionSuccess} :Props<T>) => {
    return <>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(title)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody zeroPadding>
            <ComponentNavDataPageTable initialNav={nav} showColumns={showColumns} entityName={entityName} onActionSuccess={onActionSuccess}/>
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonCancel onClick={onClose}/>
        </ComponentEuiModalFooter>
    </>
}

export default ComponentNavDataPageTablePopover