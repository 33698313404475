import {FlexGroup, FlexItem, PageSection, Spacer } from 'eui'
import {GenericActionButtons, NavDataPageTable } from 'modules'
import {DataPayload, Nav, NavKey } from 'types'
import { MappingUtils } from 'utils'

type Props<E> = {
    nav :Nav
    navKey :NavKey
    tableOnly? :boolean
    navigateOverride?: (entity :E) => void
    onSuccess? :(response: Nav, req? :DataPayload) => void
}

const NavResourceTabContent = <E extends object>({nav, navKey, tableOnly, navigateOverride, onSuccess} :Props<E>) => {
    return <>
        <Spacer size={'s'}/>
        {(!tableOnly) ? <PageSection xPadding={24} paddingSize={'m'}>
            <FlexGroup justifyContent={'spaceBetween'}>
                <FlexItem>
                    <p style={{color: '#69707D'}}>{nav.meta.description}</p>
                    <Spacer/>
                </FlexItem>
                <FlexItem>
                    <GenericActionButtons nav={nav} contextKey={navKey} button={'empty'} buttonSize={'s'} onSuccess={onSuccess}/>
                </FlexItem>
            </FlexGroup>
        </PageSection> : null}
        <NavDataPageTable nav={nav} config={MappingUtils.contextDisplayConfig(navKey)} navigateOverride={navigateOverride}/>
    </>
}
export default NavResourceTabContent