import {CellEntity, TableVersionColumnEntity} from "../../entity";
import {useEffect, useState} from "react";
import {Nav} from "../../types";
import {NavClient, RouteUtils} from "../../utils";
import {ContextMenu, LoadingSpinner} from "../../eui";
import {ContextMenuPanelDescriptorProps, ContextMenuProps} from "../../props";
import {LoadingPage} from "../../pages";
import ContextMenuPanelDescriptorItemProps
    from "../../props/ContextMenu/Panel/Descriptor/Item/ContextMenuPanelDescriptorItemProps";
import {
    CellContextMenuArchiveContent,
    CellContextMenuDetailsContent,
    CellContextMenuDataContent,
    CellContextMenuUpdateContent, CellContextMenuDeleteContent
} from "../index";

type Props = {
    rowLinkTo: string
    column :TableVersionColumnEntity
    rowId: number
}

const dataKey :string = 'data'
const detailsKey :string = 'details'
const archiveKey :string = 'archive'
const updateKey :string = 'update'
const deleteKey :string = 'delete'


const CellContextMenu = ({ rowLinkTo, column, rowId } :Props) => {

    const [cellNav, setCellNav] = useState<Nav | undefined>(undefined)
    const cellEntity :CellEntity | undefined = cellNav?.data.entity

    const fetchCellNav = async () => {
        setCellNav(await NavClient.fetchNav(RouteUtils.columnToCellLink(column, rowId)))
    }

    useEffect(() => {
        fetchCellNav()
    }, [rowLinkTo, column, rowLinkTo])

    if (!cellNav || !cellEntity) return <LoadingPage/>

    const panels :ContextMenuPanelDescriptorProps[] = [
        {
            id: 0,
            title: `${column.name} row ${rowId}`,
            items: [
                {
                    name: 'Data',
                    panel: dataKey,
                    key: dataKey
                },
                {
                    name: 'Details',
                    panel: detailsKey,
                    key: detailsKey
                },
                {
                    name: 'Archive',
                    panel: archiveKey,
                    key: archiveKey
                },
                {
                    name: 'Update',
                    panel: updateKey,
                    key: updateKey
                },
                {
                    name: 'Delete',
                    panel: deleteKey,
                    key: deleteKey
                },
            ]
        },
        {
            id: dataKey,
            title: 'Data',
            content: <CellContextMenuDataContent cellNav={cellNav} cellEntity={cellEntity} column={column} rowId={rowId}/>
        },
        {
            id: detailsKey,
            title: 'Details',
            content: <CellContextMenuDetailsContent entity={cellEntity} displayKey={'cell'} ignore={['name', 'value']}/>
        },
        {
            id: archiveKey,
            title: 'Archive',
            content: <CellContextMenuArchiveContent cellNav={cellNav} cellEntity={cellEntity} column={column} rowId={rowId}/>
        },
        {
            id: updateKey,
            title: 'Update',
            content: <CellContextMenuUpdateContent cellNav={cellNav} cellEntity={cellEntity} column={column} rowId={rowId}/>
        },
        {
            id: deleteKey,
            title: 'Delete',
            content: <CellContextMenuDeleteContent cellNav={cellNav} onDelete={fetchCellNav}/>
        }
    ]

    return <ContextMenu panels={panels} initialPanelId={0} size={'s'}/>
}

export default CellContextMenu