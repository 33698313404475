import {CSSProperties, PropsWithChildren} from 'react'
import {EuiPageSidebar} from '@elastic/eui'

type Props = {
    paddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    sticky? :boolean | {offset? :number}
    minWidth? :string | number
    responsive? :string[]
    borderRight? :boolean
}

const ComponentEuiPageSidebar = ({paddingSize, sticky, minWidth, responsive, borderRight, children} :PropsWithChildren<Props>) => {

    const style :CSSProperties = {}
    if (borderRight) {
        style.borderRight = '1px solid #cdd3df'
    }

    return <EuiPageSidebar style={style} paddingSize={paddingSize} sticky={sticky} minWidth={minWidth} responsive={responsive}>{ children }</EuiPageSidebar>
}


export default ComponentEuiPageSidebar