
import {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import {ComponentDivCenter, ComponentNavActionPanel } from 'Component'
import {HookHttpAction, HookNavPage } from 'Hook'
import { ModulePage } from 'Module'
import {TypeEntityLinkTo, TypeHttpLoadable, TypeHttpPayload,
    TypeHttpResponse, TypeNav, TypeNavHttpControl, TypeNavHttpControlAction } from 'Type'
import {FORMAT_PAGE_URLS, FORMAT_LOGIN_FIELDS} from 'Format'
import { GlobalSession } from 'Global'
import { UtilGlobalId } from 'Util'

const PageSignup = () => {

    const navigate = useNavigate()

    const loginControl :TypeNavHttpControlAction<string> = HookHttpAction({path: FORMAT_PAGE_URLS.userLogin, method: 'post'})

    const setToken :(token? :string) => string | undefined  = GlobalSession(store => store.setToken)

    const pageControl :TypeNavHttpControl<TypeEntityLinkTo> = HookNavPage<TypeEntityLinkTo>()
    const pageNav :TypeNav<TypeEntityLinkTo> | undefined = pageControl.res?.data

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Signup', control: pageControl}
    ]

    const onSuccess = async (entity :TypeEntityLinkTo, data :TypeHttpPayload) => {
        await loginControl.addToPayload(data)
    }

    const loginAndNavigate = async () => {
        if (loginControl.payload[FORMAT_LOGIN_FIELDS.userId] && loginControl.payload[FORMAT_LOGIN_FIELDS.password]) {
            const res :TypeHttpResponse<TypeNav<string>> = await loginControl.submit()
            const globalId :string | undefined = await setToken(res.data?.data.entity)
            if (globalId) navigate(UtilGlobalId.toLink(globalId))
        }
    }

    useEffect(() => {
        loginAndNavigate()
    }, [loginControl.payload])

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentDivCenter>
            { pageNav && <ComponentNavActionPanel title={'Signup'} isTitleVerb nav={pageNav} actionType={'post'} onSuccess={onSuccess}/> }
        </ComponentDivCenter>
    </ModulePage>
}

export default PageSignup