import { NavActionModal } from 'components'
import { Button } from 'eui'
import { useState } from 'react'
import { NavStore } from 'store'
import { Nav, NavControlActionType } from 'types'

type Props = {
    nav: Nav
    actionType :NavControlActionType
    buttonSize? : 's' | 'm'
    buttonFill? :boolean
    entityName :string
}

const NewTableRowForm = ({nav, actionType, buttonSize, buttonFill, entityName} :Props) => {

    const [open, setOpen] = useState<boolean>(false)

    const refreshNav = NavStore(state => state.refresh)

    const onClose = () => {
        setOpen(false)
    }

    const onSuccess = () => {
        onClose()
        //refreshNav()
    }

    const title :string = `New ${entityName}`

    const isDisabled :boolean = !nav.control.action['post'] || !nav.control.action['post'].auth.authorized

    return <>
        <Button iconType='plusInCircle' iconSide='left' onClick={() => {setOpen(true)}} isDisabled={isDisabled} size={buttonSize} fill={buttonFill}>{title}</Button>
        {(open) ? <NavActionModal nav={nav} actionType={actionType} title={title} onSuccess={onSuccess} onClose={onClose}/> : null}
    </>
}

export default NewTableRowForm