import {NavPageForm} from "../../components";
import {InterfaceMapEntity} from "../../entity";
import {Nav} from "../../types";
import {InterfaceMappingFieldSection} from "modules";

type Props = {
    mapNav :Nav
    refresh :() => void
    dir : 'req' | 'res'
}

const InterfaceMappingSection = ({mapNav, refresh, dir} :Props) => {

    const maps :InterfaceMapEntity[] | undefined = mapNav.data.page?.content

    const mapEntity :InterfaceMapEntity | undefined = maps?.find((m :InterfaceMapEntity) => (m.direction === dir))

    const title = (dir === 'req') ? 'Requester Data' : 'Response Data'

    return (mapEntity) ? <InterfaceMappingFieldSection map={mapEntity} title={title}/> :
        <NavPageForm nav={mapNav} title={title} actionType={'post'} onSuccess={refresh} initData={{dir: dir}} hideFields={['dir']}/>
}

export default InterfaceMappingSection