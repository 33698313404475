import { useState } from 'react'
import { HookNavGet, HookNavParamSimpleInteger } from 'Hook'
import { TypeNav, TypeControlParamSimple, TypeNavHttpControl, TypeNavControlPaginate, TypeEntityLinkTo } from 'Type'


type Props<T> = {
    initialNav? :TypeNav<T>
}

const HookNavPaginate = <T extends object & TypeEntityLinkTo>({initialNav} :Props<T>) :TypeNavControlPaginate<T> => {

    const [linkTo, setLinkTo] = useState<string | undefined>()

    const control :TypeNavHttpControl<T> = HookNavGet({linkTo})

    const nav :TypeNav<T> | undefined = control.res?.data ?? initialNav

    const [page, setPage] :TypeControlParamSimple<number> = HookNavParamSimpleInteger({nav, type: 'page', onChange: setLinkTo})
    const [size, setSize] :TypeControlParamSimple<number> = HookNavParamSimpleInteger({nav, type: 'size', onChange: setLinkTo})

    const data :T[] = nav?.data.page?.content ?? []

    const pageCount :number | undefined = nav?.data.page?.totalPages

    return {page, size, setPage, setSize, pageCount, data}
}

export default HookNavPaginate