import { EuiLink } from "@elastic/eui"
import { ReactNode } from "react"

type Props = {
    href? :string
    onClick?: () => void
    type?: 'button' | 'reset' | 'submit'
    color?: 'text' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'subdued'
    external? :boolean
    children :ReactNode
}

const Link = ({href, onClick, type, color, external, children}:Props) => {
    return <EuiLink href={href} onClick={onClick} type={type} color={color} external={external}>{children}</EuiLink>
}

export default Link