import {EuiDescriptionList} from '@elastic/eui'
import { TypeEuiPropsDescriptionListItem } from 'Type'

type Props = {
    listItems :TypeEuiPropsDescriptionListItem[]
    align? :'left' | 'center'
    compressed? :boolean
    textStyle? :'reverse' | 'normal'
    type? :'row' | 'inline' | 'column' | 'responsiveColumn'
    gutterSize? :'s' | 'm'
    descriptionClassName? :string
}

const ComponentEuiDescriptionList = ({listItems, align, compressed, textStyle, type, gutterSize, descriptionClassName} :Props) => {
    const descriptionProps = (descriptionClassName) ? {className: descriptionClassName} : undefined
    return <EuiDescriptionList listItems={listItems} align={align} compressed={compressed} textStyle={textStyle} type={type}
                        gutterSize={gutterSize} descriptionProps={descriptionProps}/>
}
export default ComponentEuiDescriptionList