import {PropsWithChildren, ReactNode} from 'react'
import {ComponentDivMarginHorizontal, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiPageSection, ComponentPageTitle } from 'Component'
import { TypeNavMeta } from 'Type'

type Props = {
    title? :string
    meta? :TypeNavMeta
    bottomBorder? :boolean | 'extended'
    rightActions? :ReactNode
    compact? :boolean
}

const ComponentNavMetaPageSectionHeader = ({ title, meta, bottomBorder, rightActions, compact } :PropsWithChildren<Props>) => {
    return <ComponentEuiPageSection paddingSize={(compact) ? 'xs' : 'm'} bottomBorder={bottomBorder}>
        <ComponentDivMarginHorizontal margin={4}>
            <ComponentEuiFlexGroup gutterSize={'none'} alignItems={'center'}>
                <ComponentEuiFlexItem grow>
                    <ComponentPageTitle secondary={(compact) ? true : false}>{ title || meta?.title }</ComponentPageTitle>
                </ComponentEuiFlexItem>
                <ComponentEuiFlexItem>{ rightActions }</ComponentEuiFlexItem>
            </ComponentEuiFlexGroup>
        </ComponentDivMarginHorizontal>
    </ComponentEuiPageSection>
}

export default ComponentNavMetaPageSectionHeader