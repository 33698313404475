import { useEffect } from 'react'

import { GlobalSession } from 'Global'
import { TypeControlSession } from 'Type'
import { UtilJwt } from 'Util'

const HookSession = () :TypeControlSession => {
    const control :TypeControlSession = GlobalSession(store => store)

    const unsafeToken :string | undefined = control.token

    const tokenExists = Boolean(unsafeToken)

    const tokenUnexpired = unsafeToken && !UtilJwt.isTokenExpired(unsafeToken)

    const token = (tokenExists && tokenUnexpired) ? unsafeToken : undefined

    useEffect(() => {
        if (token !== unsafeToken) control.setToken(token)
    }, [token])

    return {...control, token}
}

export default HookSession