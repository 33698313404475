import {ComponentEuiLoadingSpinner, ComponentNavDataPageTable, ComponentNavMetaPageSectionHeader } from 'Component'
import {TypeEntityWorkspace, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    wsControl :TypeNavHttpControl<TypeEntityWorkspace>
}

const PageApiBody = ({ wsControl } :Props) => {
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    return (wsNav) ? <>
        <ComponentNavMetaPageSectionHeader title={'Workspaces'} bottomBorder={'extended'} compact/>
        <ComponentNavDataPageTable action={'navigate'} entityName={'Workspace'} paginate={false} initialNav={wsNav} showColumns={['name', 'isPublic', 'ownerGlobalId']}/>
    </> : <ComponentEuiLoadingSpinner/>
}

export default PageApiBody