import { AuditDisplay } from 'display'
import { DisplayConfig } from 'types'
import NameDisplay from '../parts/NameDisplay'

const config :DisplayConfig[] = [
    ...NameDisplay,
    {field: 'mapName', name: 'Map Name'},
    {field: 'mapNameCamel', name: 'Map Name Camel'},
    ...AuditDisplay
]

export default config