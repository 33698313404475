import { HookPageParam } from 'Hook'
import { TypeControlParam, TypeNavControlFilterParam, TypeNavDataFilter, TypeUrlParam } from 'Type'

const HookNavFilterPage = (fieldNameCamel? :string) :TypeNavControlFilterParam => {
    const { params, append, removeValue } :TypeControlParam = HookPageParam('filter-*')

    const filterParams = (fieldNameCamel) ? (param :TypeUrlParam) => param.dynamic && param.dynamic[0] && param.dynamic[0] === fieldNameCamel : () => true

    const filteredParams :TypeUrlParam[] = params.filter(filterParams)

    const dataFilters :TypeNavDataFilter[] = []

    filteredParams.forEach((param :TypeUrlParam) => param.values.forEach((value :string) => {
        if (param.dynamic && param.dynamic[0]) dataFilters.push({
            fieldNameCamel: param.dynamic[0],
            operator: 'eq',
            value
        })
    }))

    const filterAlreadyExists = (dataFilter :TypeNavDataFilter) :boolean => {
        return dataFilters.filter((existingFilter :TypeNavDataFilter) =>
            existingFilter.fieldNameCamel === dataFilter.fieldNameCamel &&
            existingFilter.operator === dataFilter.operator &&
            existingFilter.value === dataFilter.value
        ).length > 0
    }

    const setDataFilter = (dataFilter :TypeNavDataFilter) => {
        if (filterAlreadyExists(dataFilter)) return
        append([dataFilter.value], [dataFilter.fieldNameCamel])
    }

    const removeDataFilter = (dataFilter :TypeNavDataFilter) => {
        removeValue([dataFilter.value], [dataFilter.fieldNameCamel])
    }

    return {
        dataFilters,
        setDataFilter,
        removeDataFilter
    }
}

export default HookNavFilterPage