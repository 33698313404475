import {
    ComponentDivWidth, ComponentEntityAuditDisplay, ComponentEuiFlexGroup,
    ComponentEuiFlexItem, ComponentEuiLoadingSpinner, ComponentEuiSpacer, ComponentNavActionMenu } from 'Component'
import { HookNavGet } from 'Hook'
import { ReactNode } from 'react'
import { TypeEntityLinkTo, TypeNav, TypeNavHttpControl } from 'Type'

type Props<T extends object & TypeEntityLinkTo> = {
    entityName :string
    entity :T
    onActionSuccess? :() => void
    customComponent? :(navControl :TypeNavHttpControl<T>) => ReactNode
    customOnly? :boolean
    customButtons? :(nav :TypeNav<T>) => ReactNode[]
    nav? :TypeNav<T>
}

const ComponentNavDataPageTableActionMenu = <T extends object & TypeEntityLinkTo>({ entityName, entity, nav, onActionSuccess, customComponent, customButtons, customOnly } :Props<T>) => {

    const navControl :TypeNavHttpControl<T> = HookNavGet<T>({linkTo: (nav) ? undefined : entity.linkTo})
    const innerNav :TypeNav<T> | undefined = nav ?? navControl.res?.data

    return <ComponentDivWidth width={'100%'}>
        {!customOnly && <ComponentEuiFlexGroup justifyContent={'spaceBetween'}>
            <ComponentEuiFlexItem grow>
                <ComponentEntityAuditDisplay entity={entity}/>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                {(innerNav) ?  <ComponentNavActionMenu nav={innerNav} entityName={entityName} onActionSuccess={onActionSuccess} customButtons={customButtons}/> : <ComponentEuiLoadingSpinner/> }
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup> }
        {customComponent && !customOnly && <ComponentEuiSpacer size={'s'}/>}
        {customComponent && customComponent(navControl)}
    </ComponentDivWidth>
}

export default ComponentNavDataPageTableActionMenu