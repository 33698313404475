import { HookNavContextOptional, HookNavGet, HookNavNavigateEntity, HookNavResourceNavAll } from 'Hook'
import { useEffect } from 'react'
import { TypeEntityExtract, TypeEntityExtractSheet, TypeNav, TypeNavHttpControl } from 'Type'

const HookModuleRowExtractSheet = (pageNav? :TypeNav<any>) :TypeNavHttpControl<TypeEntityExtractSheet> => {

    const extractContext :TypeEntityExtract | undefined = HookNavContextOptional('extract', pageNav)
    const extractControl :TypeNavHttpControl<TypeEntityExtract> = HookNavGet({linkTo: extractContext?.linkTo})
    const extractNav :TypeNav<TypeEntityExtract> | undefined = extractControl.res?.data
    const sheetResourceControl :TypeNavHttpControl<TypeEntityExtractSheet> = HookNavResourceNavAll({key :'sheet', nav: extractNav})
    const sheets :TypeEntityExtractSheet[] = sheetResourceControl.res?.data?.data.page?.content ?? []
    const sheetFirst :TypeEntityExtractSheet | undefined = sheets[0]
    const navigateToFirstSheet :() => void = HookNavNavigateEntity({entity: sheetFirst, resource: 'data'})

    const sheetContext :TypeEntityExtractSheet | undefined = HookNavContextOptional('sheet', pageNav)
    const sheetNotPresent :boolean = Boolean(pageNav && !sheetContext)

    useEffect(() => {
       if (sheetFirst && sheetNotPresent) {
           navigateToFirstSheet()
       }
    }, [sheetFirst, sheetNotPresent])

    return sheetResourceControl
}

export default HookModuleRowExtractSheet