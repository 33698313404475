import {NavPageForm} from "../../components";
import {NavStore} from "../../store";
import {Nav, NavControlAction} from "../../types";
import {NavDataEntity} from "../index";

const ResponderPageContentUrl = () => {

    const nav :Nav | undefined = NavStore(store => store.nav)
    const refresh :() => void = NavStore(store => store.refresh)

    const post :NavControlAction | undefined = nav?.control.action['post']

    if (!nav) return null

    if (post) return <NavPageForm nav={nav} actionType={'post'} title={'Responder service URL'} onSuccess={refresh}/>

    return <NavDataEntity configKey={'url'} ignoreFields={[]}/>
}

export default ResponderPageContentUrl