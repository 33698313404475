import {AxiosRequestHeaders} from "axios";
import { HookSession } from "Hook"

const AUTH_HEADER = 'Authorization'
const AUTH_PREFIX = 'Bearer '

const HookNavHttpHeaders = () :AxiosRequestHeaders => {
    const { token } = HookSession()
    const headers :AxiosRequestHeaders = {}
    if (token) headers[AUTH_HEADER] = `${AUTH_PREFIX}${token}`
    return headers
}

export default HookNavHttpHeaders