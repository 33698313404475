import { useLocation, useNavigate } from 'react-router-dom'
import { Nav, ParamUpdate } from 'types'
import { SearchParamUtils } from 'utils'

const UpdateQueryParams = () :(updates :ParamUpdate[]) => void => {

    const navigate = useNavigate()
    const location = useLocation()

    const setParams = async (updates :ParamUpdate[]) => {
        navigate(SearchParamUtils.updateParamForLocation(location, updates))
    }

    return setParams
}

export default UpdateQueryParams