import create from 'zustand'
import { Nav, NavMap } from 'types'
import { NavClient } from 'utils'
type Store = {
    loading :boolean
    nav? :Nav
    contextNavs : NavMap
    fetchNav : (url :string) => void
    refresh: () => void
    slickRefresh: () => void
    setNav: (nav :Nav) => void
}

const NavStore = create<Store>((set, get) => ({
    loading: false,
    nav: undefined,
    contextNavs: {},
    loadingMap: {},
    fetchNav: async (path :string) => {
        const nav: Nav = await NavClient.fetchNav(path, 'context', 'true')
        set({nav: undefined})
        set({nav, contextNavs: await NavClient.updateNavContextNavs(nav, get().contextNavs)})
    },
    refresh: async () => {
        const nav: Nav | undefined = get().nav
        if (nav) {
            const newNav :Nav = await NavClient.fetchNav(nav.path, 'context', 'true')
            set({nav: undefined})
            set({nav: newNav})
        }
    },
    slickRefresh: async () => {
        const nav: Nav | undefined = get().nav
        if (nav) {
            const newNav :Nav = await NavClient.fetchNav(nav.path, 'context', 'true')
            set({nav: newNav})
        }
    },
    setNav: async (nav :Nav) => {
        set({nav})
    },
}))

export default NavStore