
import {ModuleTableCellArchive, ModuleTableCellExtractButton, ModuleTableCellImagePreview } from 'Module'
import { TypeEntityCell, TypeEntityRow, TypeEntityTableVersionColumn, TypeNav, TypeNavControlResource } from 'Type'
import { ReactNode } from 'react'
import { ComponentNavDataPageTableActionMenu } from 'Component'
import { HookNavResource } from 'Hook'

type Props = {
    column :TypeEntityTableVersionColumn
    nav :TypeNav<TypeEntityCell>
    cell :TypeEntityCell
    row :TypeEntityRow
}

const ModuleTableCellActions = ({column, cell, row, nav} :Props) => {

    const extractResource :TypeNavControlResource | undefined = HookNavResource('extract', nav)
    const isExtractable :boolean = extractResource?.auth.authorized ?? false

    const isPreviewable :boolean = column.type === 'image'
    const hasArchive :boolean = cell.versionNumber > 1

    const customButtons :() => ReactNode[] = () => {
        const arr :ReactNode[] = []
        if (isExtractable) arr.push(<ModuleTableCellExtractButton entity={cell} cellNav={nav}/>)
        if (isPreviewable) arr.push(<ModuleTableCellImagePreview nav={nav}/>)
        if (hasArchive) arr.push(<ModuleTableCellArchive nav={nav} column={column} row={row}/>)
        return arr

    }
    return <ComponentNavDataPageTableActionMenu entityName={'Cell Value'} nav={nav} entity={cell} customButtons={customButtons}/>
}

export default ModuleTableCellActions