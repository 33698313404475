import {ButtonEmpty, FlexGroup, FlexItem, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle, Spacer } from 'eui'
import {DescriptionList} from 'components'
import {useEffect, useState } from 'react'
import { Nav, NavKey } from 'types'
import {MappingUtils, NavClient } from 'utils'
import { GenericActionButtons } from 'modules'
import {NavDataEntity} from "../index";
import {EntityDisplay} from "../../components";

type Props = {
    onClose: () => void
    onSuccess: () => void
    navLinkTo: string
    contextKey: NavKey
    title? :string
}

const GenericProfileModal = ({onClose, onSuccess, navLinkTo, contextKey, title} :Props) => {

    const [nav, setNav] = useState<Nav | undefined>(undefined)

    useEffect(() => { fetchNav() })

    const fetchNav = async () => {
        setNav(await NavClient.fetchNav(navLinkTo))
    }

    if (!nav) return null

    return <Modal onClose={onClose}>
        <ModalHeader>
            <ModalHeaderTitle><h1>{title || nav.meta.title}</h1></ModalHeaderTitle>
        </ModalHeader>
        <ModalBody>
            <FlexGroup justifyContent={'spaceBetween'}>
                <FlexItem></FlexItem>
                <FlexItem><GenericActionButtons nav={nav} contextKey={contextKey} onSuccess={onSuccess} toggleIcon={'gear'} buttonSize={'s'}/></FlexItem>
            </FlexGroup>
            <Spacer/>
            <EntityDisplay object={nav.data.entity} config={(contextKey) ? MappingUtils.contextDisplayConfig(contextKey) : undefined}/>
        </ModalBody>
        <ModalFooter>
            <ButtonEmpty color={'danger'} onClick={onClose}>Cancel</ButtonEmpty>
        </ModalFooter>
    </Modal>
}

export default GenericProfileModal