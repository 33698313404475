import { EuiPanel } from "@elastic/eui"
import {PropsWithChildren} from "react";

type Props = {
    element? :'button' | 'div'
    hasShadow? :boolean
    hasBorder? :boolean
    paddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    borderRadius? :'none' | 'm'
    grow? :boolean
    color? :'transparent' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain'
}

const ComponentEuiPanel = ({ element, hasShadow, hasBorder, paddingSize, borderRadius, grow, color, children } :PropsWithChildren<Props>) =>
    <EuiPanel  element={element} hasShadow={hasShadow} hasBorder={hasBorder} paddingSize={paddingSize} borderRadius={borderRadius} grow={grow} color={color}>
        { children }
    </EuiPanel>

export default ComponentEuiPanel