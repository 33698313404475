import { ApiEntity } from 'entity'
import {Button, ButtonEmpty, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle, Spacer } from 'eui'

type Props = {
    onClose: () => void
    apiEntity: ApiEntity
}

const ApiModalDisplayNew = ({onClose, apiEntity} :Props) => {
    return <Modal onClose={onClose} style={{width: '600px'}}>
        <ModalHeader>
            <ModalHeaderTitle><h1>{apiEntity.name}</h1></ModalHeaderTitle>
        </ModalHeader>
        <ModalBody>
            <p>You have successfully created a new API.</p>
            <Spacer/>
            <p><strong>API Id: </strong>{apiEntity.apiId}</p>
            <Spacer size={'s'}/>
            <p><strong>API Secret Id: </strong>{apiEntity.secret}</p>
            <Spacer/>
            <p>Make a note of the API Secret Id, once you close this modal you will not be able to retrieve it.</p>
        </ModalBody>
        <ModalFooter>
            <ButtonEmpty color={'danger'} onClick={onClose}>Close</ButtonEmpty>
        </ModalFooter>
    </Modal>
}

export default ApiModalDisplayNew