import {Tabs, Tab, PageSection, FlexGroup, FlexItem, Button, ButtonEmpty, Spacer} from 'eui'
import {useNav} from 'hooks'
import {useEffect, useState} from 'react'
import {Nav, NavControlResource, NavHookControl, ParamUpdate, TableColumnHookControl, NavKey} from 'types'
import {InterfaceMapEntity, SearchOptionEntity} from 'entity'
import { NavClient, SearchParamUtils } from 'utils'
import {TableModalConnectInterfaceEntityFieldMap} from 'modules'

type Props = {
    mapResource :NavControlResource
    columnControls :TableColumnHookControl
    refresh :() => void
}

type TabProps = {
    map :InterfaceMapEntity
    columnControls :TableColumnHookControl
}

const fieldKey :NavKey = 'field'

const TabMap = ({map, columnControls} :TabProps) => {

    const {nav, fetch} :NavHookControl = useNav()
    useEffect(() => {fetch(map.linkTo)}, [map.linkTo])
    const fieldResource :NavControlResource | undefined = nav?.control.resource[fieldKey]
    return fieldResource ? <TableModalConnectInterfaceEntityFieldMap fieldResource={fieldResource} columnControls={columnControls}/> : null
}



const TableModalConnectResponderEntityMaps = ({mapResource, columnControls, refresh} :Props) => {

    const [unmapped, setUnmapped] = useState<SearchOptionEntity[]>([])

    const navControl :NavHookControl = useNav()
    const nav :Nav | undefined = navControl.nav
    useEffect(() => {navControl.fetchAll(mapResource.linkTo)}, [mapResource.linkTo])
    useEffect(() => {fetchUnmapped()}, [nav?.path])

    const fetchUnmapped = async () => {
        if (nav) {
            const unmappedNav :Nav = await NavClient.searchFormField(nav, nav.control.search.keys[0], '')
            setUnmapped(unmappedNav.data.page?.content ?? [])
        }
    }

    const maps :InterfaceMapEntity[] = nav?.data.page?.content ?? []

    const onClickFn = (m :SearchOptionEntity) :() => void => {
        return async () => {
            if (nav) await NavClient.action(nav, 'post', {interfaceUuid: m.key})
            await navControl.refreshAll()
            await fetchUnmapped()
            refresh()
        }
    }

    const [active, setActive] = useState<InterfaceMapEntity | undefined>()

    useEffect(() => {
        if (maps.length > 0 && active === undefined) setActive(maps[0])
    }, [maps.length])

    const onClickTabFn = (m :InterfaceMapEntity) :() => void => {
        return () => {
            setActive(m)
        }
    }

    console.log(nav, maps, unmapped)

    return <>
        <PageSection paddingSize={'s'} bottomBorder={'extended'}>
            <FlexGroup>
                {unmapped.map((m :SearchOptionEntity) => {
                    return <FlexItem key={m.key}>
                        <ButtonEmpty onClick={onClickFn(m)} iconType={'plus'} size={'s'} >{m.label}</ButtonEmpty>
                    </FlexItem>
                })}
            </FlexGroup>
        </PageSection>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <Tabs bottomBorder={false}>
                {maps.map((m :InterfaceMapEntity) => {
                    return <Tab key={m.linkTo} isSelected={active && m.linkTo === active.linkTo} onClick={onClickTabFn(m)}>{m.name}</Tab>
                })}
            </Tabs>
        </PageSection>
        <PageSection paddingSize={'none'} bottomBorder={'extended'}>
            {active && <TabMap map={active} columnControls={columnControls}/>}
        </PageSection>
    </>
}

export default TableModalConnectResponderEntityMaps