import { Badge } from 'eui'
import {useLocation, useNavigate } from 'react-router-dom'
import { TableFilter, Nav } from 'types'
import { NavClient, SearchParamUtils } from 'utils'

type Props = {
    tableFilter :TableFilter
    nav? :Nav
    setNav? :(newNav :Nav) => void
}

const TableFilterBadge = ({ tableFilter, nav, setNav } :Props) => {

    const navigate = useNavigate()
    const location = useLocation()

    let str :string = `${tableFilter.field}:`
    let firstValue :boolean = true;
    tableFilter.values.forEach((v :string) => {
        str = ` ${str} ${(firstValue) ? '' : ' or '} "${v}"`
        firstValue = false
    })

    const remove = async () => {
        if (nav && setNav) {
            setNav(await NavClient.fetchNav(SearchParamUtils.removeParameterFromPath(nav.path, tableFilter.param)))
        } else {
            const newSearch = SearchParamUtils.removeParameterFromSearch(location.search, tableFilter.param)
            navigate(`${location.pathname}?${newSearch}`)
        }
    }

    return <Badge iconSide={'right'} color={'primary'} iconType={'cross'} iconOnClick={remove}>{str}</Badge>
}

export default TableFilterBadge