import {ComponentEuiBadge, ComponentEuiFlexGroup, ComponentEuiFlexItem,
    ComponentEuiLink,
    ComponentEuiSpacer, ComponentNavDataPageTableSidebar, ComponentNavSidebarContextEntity, ComponentNavSidebarTitle } from 'Component'
import {TypeEntityMember, TypeEntityTable, TypeEntityWorkspace, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    workspaceEntity :TypeEntityWorkspace
    pageControl :TypeNavHttpControl<any>
    memberControl :TypeNavHttpControl<TypeEntityMember>
}

const PageWorkspaceSidebar = ({ workspaceEntity, pageControl, memberControl } :Props) => {
    const nav :TypeNav<TypeEntityTable> | undefined = pageControl.res?.data
    if (!nav) return null

    const customMemberItemRender = (member :TypeEntityMember) => <ComponentEuiFlexGroup alignItems={'baseline'}>
        <ComponentEuiFlexItem grow>
            <ComponentEuiLink href={`/${member.memberGlobalId}`}>{member.memberName}</ComponentEuiLink>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>{(member.isAdmin) ? <ComponentEuiBadge color={'primary'}>Admin</ComponentEuiBadge> : null}</ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={workspaceEntity.name} iconType={'folderClosed'} entityName={'Workspace'}/>
            <ComponentEuiSpacer/>
            <ComponentNavSidebarContextEntity title={'Owner'} navKey={'user'} nameFields={['firstName', 'lastName']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Organisation'} navKey={'org'} nameFields={['name']} nav={nav}/>
            <ComponentEuiSpacer/>
            <ComponentNavDataPageTableSidebar navControl={memberControl} showColumn={'memberName'} entityName={'Member'} title={'Members'} emptyMessage={'You have no members'} customRender={customMemberItemRender}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageWorkspaceSidebar