import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'description',
        name: 'Description',
        sortable: true
    },
]

export default config