import React, {ChangeEvent, ReactElement, useState} from 'react'
import moment from 'moment-timezone'
import {EuiDatePicker} from '@elastic/eui'
import { Field } from 'props'
import {ButtonEmpty, ComboBox, ListGroup, Popover, Spacer } from 'eui'
import { Moment } from 'moment'

type TzProps = {
    keys :string[]
    tz :string
    setKey :(key :string) => void
}

const SearchKeyPopover = ({keys, tz, setKey} :TzProps) :ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const onSearchChange = () => {

    }

    const onChange = (options :any) => {
        if (options && options.length > 0 && options[0].label) setKey(options[0].label)
    }

    const options = keys.map(k => {return {label: k}})

    const selectedOptions = (tz) ? [{label: tz}] : []

    return <Popover
        className={'euiFormControlLayout__prepend'}
        button={<ButtonEmpty size='xs' iconType='arrowDown' iconSide='right' onClick={() => {setIsOpen(true)}}>{tz}</ButtonEmpty>}
        closePopover={() => {setIsOpen(false)}} isOpen={isOpen} panelPaddingSize={'s'}>
        <div style={{width : '320px'}}>
            <ComboBox
                options={options}
                onSearchChange={onSearchChange}
                onChange={onChange}
                selectedOptions={selectedOptions}
            />
        </div>
    </Popover>
}

const STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss Z'
const STRING_TIME_FORMAT = 'HH:mm:ss'

const FieldDateTimeZ = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : ''

    const initialTz = moment.tz.guess() || 'UTC'

    const [tz, setTz] = useState<string>(initialTz)

    const datetimez = (valueStr !== '') ? moment(valueStr, STRING_FORMAT).tz(tz) : undefined

    const tzFn = (tz :string) => {
        setTz(tz)
        if (datetimez) onChange(name, datetimez.tz(tz, true).format())
    }

    const fn = (date: moment.Moment | null, event?: React.SyntheticEvent<any>) => {
        onChange(name, (date) ? date.format(STRING_FORMAT) : '')
    }

    return <>
        <EuiDatePicker
            showTimeSelect
            dateFormat={STRING_FORMAT}
            timeFormat={STRING_TIME_FORMAT}
            selected={(datetimez) ? datetimez : datetimez}
            onChange={fn}
        />
        <Spacer size={'xs'}/>
        <SearchKeyPopover keys={moment.tz.names()} tz={tz} setKey={tzFn}/>
    </>
}

export default FieldDateTimeZ