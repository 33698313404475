import {CSSProperties, PropsWithChildren, ReactNode} from 'react'
import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiPage, ComponentEuiPageBody, ComponentEuiPageSidebar,
    ComponentEuiProgress, ComponentNavPagePagination, ComponentPageErrorPrompt } from 'Component'
import { HookPageHeaderHeight, HookPageViewport } from 'Hook'
import { TypeControlPageViewport, TypeHttpLoadable,
    TypeNav, TypeNavHttpControl, TypePageHeaderHeight, TypeWebSocketControl } from 'Type'
import {ModulePageContextNavMeta, ModulePageHeader, ModulePageLoading } from 'Module'

const bodyPanelStyle :CSSProperties = {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
}

type Props = {
    control :TypeNavHttpControl<any>
    sidebarContent? :ReactNode
    sidebarPaddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    toLoad? :TypeHttpLoadable[],
    paginationControl? :TypeNavHttpControl<any>
    socketControl? :TypeWebSocketControl<any>
    rightActions? :ReactNode
}

const ModulePage = ({control, sidebarContent, sidebarPaddingSize, toLoad = [], paginationControl, socketControl, rightActions, children} :PropsWithChildren<Props>) => {

    const paginationNav :TypeNav<any> | undefined = paginationControl?.res?.data
    const isPageable :boolean = Boolean(paginationNav?.data.page)
    const isLoading :boolean = paginationControl?.isLoading ?? false

    const viewport :TypeControlPageViewport = HookPageViewport()
    const headerHeight :TypePageHeaderHeight = HookPageHeaderHeight(control)
    const pageHeight :number = viewport.height - headerHeight.headerHeight

    const error :string | undefined = control?.res?.data?.error?.message || control?.res?.error

    return <ModulePageContextNavMeta>
        <ModulePageHeader control={control} hasBreadcrumbs={headerHeight.hasBreadcrumbs} rightActions={rightActions}/>
        <ComponentEuiPage height={pageHeight} paddingSize={'none'}>
            {(error) ? <ComponentPageErrorPrompt title={error}/> :
                <ModulePageLoading toLoad={toLoad}>
                    {sidebarContent && <ComponentEuiPageSidebar borderRight paddingSize={sidebarPaddingSize}>{sidebarContent}</ComponentEuiPageSidebar>}
                    <ComponentEuiPageBody panelled panelProps={{hasShadow: false, style: bodyPanelStyle}}>
                        <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'}>
                            <ComponentEuiFlexItem grow>
                                {children}
                            </ComponentEuiFlexItem>
                            { isPageable && paginationNav && !isLoading && <ComponentEuiFlexItem>
                              <ComponentNavPagePagination nav={paginationNav} socketControl={socketControl}/>
                            </ComponentEuiFlexItem> }
                            { isLoading && <ComponentEuiFlexItem>
                              <ComponentEuiProgress color={'primary'}/>
                            </ComponentEuiFlexItem> }
                        </ComponentEuiFlexGroup>
                    </ComponentEuiPageBody>
                </ModulePageLoading>
            }
        </ComponentEuiPage>
    </ModulePageContextNavMeta>
}

export default ModulePage