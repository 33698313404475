import { ComponentEuiDataGrid } from 'Component'
import { ContextTableData } from 'Context'
import { HookModuleTableColumn, HookModuleTableDataCellRenderer, HookPageLocation } from 'Hook'
import { ModuleTableDataContext } from 'Module'
import {useContext, useEffect, useRef} from 'react'
import { TypeContextTableData, TypeEntityRow, TypeEuiPropsDataGridColumn,
    TypeEuiPropsDataGridRef, TypeModuleTableColumnControl,
    TypeModuleTableDataCellRenderer,
    TypeModuleTableDataProps,
    TypeNav} from 'Type'

const removeFilter = (col :TypeEuiPropsDataGridColumn) :TypeEuiPropsDataGridColumn => {
    if (col.actions) col.actions.additional = []
    return col
}

const ModuleTableDataRowInner = ({ dataControl, columnControl } :TypeModuleTableDataProps) => {

    const dataContext :TypeContextTableData = useContext(ContextTableData)

    const dataNav :TypeNav<TypeEntityRow> | undefined = dataControl.res?.data

    const dataGridRef = useRef<TypeEuiPropsDataGridRef | null>(null);

    const { location } = HookPageLocation()

    const closeCellPopover :() => void = () => {
        if (dataGridRef.current) {
            dataGridRef.current.closeCellPopover()
        }
    }

    useEffect(() => {
        closeCellPopover()
    }, [location])

    const { gridColumns, columnVisibility, leadingControlColumns, trailingControlColumns, sorting } :TypeModuleTableColumnControl = HookModuleTableColumn(dataNav!, columnControl, dataContext.data)

    const { renderCellValue, renderCellPopover } :TypeModuleTableDataCellRenderer = HookModuleTableDataCellRenderer()

    return <ComponentEuiDataGrid columns={gridColumns.map(removeFilter)} rowCount={1} columnVisibility={columnVisibility}
         toolbarVisibility={true} renderCellValue={renderCellValue} leadingControlColumns={leadingControlColumns}
                                 trailingControlColumns={trailingControlColumns} renderCellPopover={renderCellPopover} sorting={sorting} forwardRef={dataGridRef}/>
}


const ModuleTableDataRow = ({ dataControl, columnControl, updatesControl, fieldNameMap, toLoad } :TypeModuleTableDataProps) => {
    return <div>
        <ModuleTableDataContext dataControl={dataControl} columnControl={columnControl} updatesControl={updatesControl}>
            <ModuleTableDataRowInner dataControl={dataControl} columnControl={columnControl} updatesControl={updatesControl} fieldNameMap={fieldNameMap} toLoad={toLoad}/>
        </ModuleTableDataContext>
    </div>
}

export default ModuleTableDataRow