import React from 'react'
import {EuiForm} from '@elastic/eui'

import {Children} from 'props'


type Props = {
    errors?: string[]
    fullWidth? :boolean
    children: any[]
}

export default ({errors = [], fullWidth, children}: Props) =>
    <EuiForm isInvalid={errors.length > 0} fullWidth={fullWidth} error={errors} component={'form'}>{children}</EuiForm>