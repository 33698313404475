import { UserEntity } from 'entity'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle, Page, Text } from 'eui'
import { useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { Nav } from 'types'

const EmailConfirmationPage = () => {

    const navigate = useNavigate()

    const nav :Nav | undefined = NavStore(state => state.nav)

    const onSuccess = () => {
        const user :UserEntity | undefined = nav && nav.context['user']
        navigate((user && user.linkTo) || '/')
    }

    return <Page>
        <Modal onClose={onSuccess}>
            <ModalHeader>
                <ModalHeaderTitle><h1>{'Email confirmed!'}</h1></ModalHeaderTitle>
            </ModalHeader>
            <ModalBody>
                <Text><p>Your email has been confirmed.  Please close this window to return to your profile page.</p></Text>
            </ModalBody>
            <ModalFooter>
                <Button fill onClick={onSuccess}>{'Return'}</Button>
            </ModalFooter>
        </Modal>
    </Page>
}

export default EmailConfirmationPage