const types = ['user', 'api', 'ws', 'org'] as const

type Types = typeof types[number]

const getType = (globalId :string) :Types => {
    const parts :string[] = globalId.split('/')
    if (parts.length !== 2 || !types.includes(parts[0] as Types)) throw new Error(`malformed GlobalId: ${globalId}`)
    return parts[0] as Types
}

const getLink = (globalId :string | undefined) :string => {
    if (!globalId) return '/'
    const parts :string[] = globalId.split('/')
    if (parts.length !== 2 || !types.includes(parts[0] as Types)) throw new Error(`malformed GlobalId: ${globalId}`)
    return `/${globalId}`
}

export default {getLink, getType}