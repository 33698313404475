import { DataGrid, TablePagination } from 'eui'
import { DataGridCellValueElement, DataGridColumn, DataGridColumnVisibility, DataGridControlColumn } from 'props';
import { useState } from 'react';
import { ExcelUtils } from 'utils';
import { ReactNode } from 'react';
import './ExtractSheetDataTable.css'

type Props = {
    data: any[]
    renderLeadingRowControl? : (sheetRowId :number) => ReactNode,
    headerRowId? :number,
    excludedRowIds? :number[],
    cellInner? :(value :string, rowId :number | undefined) => ReactNode
}

const longest = (data :any[]) :number => {
    let longest = 0;
    for (let i = 0; i < data.length; i++) {
        let keys :string[] = Object.keys(data[i]).sort()
        if (keys.length > 0) {
            let l :number = ExcelUtils.excelColToInt(keys[keys.length - 1])
            if (l > longest) longest = l
        }
    }
    return longest
}

const buildColumnArray = (data :any[]) :string[] => {
    const l :number = longest(data)
    let colArr :string[] = []
    for (let i = 1; i <= l; i++) {
        colArr.push(ExcelUtils.intToExcelCol(i))
    }
    return colArr
}

const buildColumns = (columnKeys :string[]) :DataGridColumn[] => {
    return columnKeys.map((colKey :string) => {
        return {
            id: colKey,
            displayAsText: colKey,
            actions: {
                showMoveLeft: false,
                showMoveRight: false,
                showHide: false
            }
        }
    })
}

const ExtractSheetDataTable = ({data, renderLeadingRowControl, headerRowId, excludedRowIds = [], cellInner} :Props) => {

    const columnArray = buildColumnArray(data)
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columnArray)
    const [itemsPerPage, setItemsPerPage] = useState<number>(25)
    const [activePage, setActivePage] = useState<number>(0)
    const pageCount = Math.ceil(data.length / itemsPerPage)

    const dataPage :any[] = data.slice(activePage * itemsPerPage, (activePage * itemsPerPage) + itemsPerPage)

    const onChangeItemsPerPage = (n :number) => {
        setItemsPerPage(n)
    }

    const onChangePage = (n :number) => {
        setActivePage(n)
    }

    const renderCellValue = (props :DataGridCellValueElement) => {
        const value :string = (dataPage[props.rowIndex] && dataPage[props.rowIndex][props.columnId]) ? dataPage[props.rowIndex][props.columnId] : ''
        const rowId :number | undefined = dataPage[props.rowIndex] && dataPage[props.rowIndex]['']
        return (cellInner) ? cellInner(value, rowId) : <div style={{minHeight: '36px', margin: '6px'}}>{value}</div>
    }

    const columnVisibility :DataGridColumnVisibility = {
        visibleColumns: visibleColumns,
        setVisibleColumns: (visibleColumns :string[]) => {setVisibleColumns(visibleColumns)}
    }

    const leadingControlColumns :DataGridControlColumn[] = [
        {
            id: 'actions',
            width: 40,
            headerCellRender: () => (
                <span></span>
            ),
            rowCellRender: (object :any) => {
                const rowId :number | undefined = dataPage[object.rowIndex] && dataPage[object.rowIndex]['']
                return (rowId && renderLeadingRowControl && renderLeadingRowControl(rowId)) || <span style={{fontWeight: 600, marginLeft: '6px'}}>{rowId}</span>
            }
        }
    ]

    const rowClasses = dataPage.reduce((map, row, i) => {
        if (row[''] && row[''] === headerRowId) map[i] = 'rowHeader'
        if (row[''] && excludedRowIds.includes(row[''])) map[i] = 'rowExclude'
        return map
    }, {})

    return <div>
        <DataGrid toolbarVisibility={true} columns={buildColumns(columnArray)} columnVisibility={columnVisibility} rowCount={Math.min(itemsPerPage, dataPage.length)}
                     renderCellValue={renderCellValue} leadingControlColumns={leadingControlColumns} gridStyle={{rowClasses}}/>
        <TablePagination showPerPageOptions={true} itemsPerPage={itemsPerPage} activePage={activePage} pageCount={pageCount} onChangeItemsPerPage={onChangeItemsPerPage} onChangePage={onChangePage} itemsPerPageOptions={[25, 50, 100, 200]}/>
    </div>
}

export default ExtractSheetDataTable