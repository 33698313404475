import {ExtractEntity, SheetEntity, TableEntity, TableVersionEntity } from 'entity'
import {useEffect, useState } from 'react'
import {useLocation, useNavigate } from 'react-router-dom'
import { ExtractStore } from 'store'
import {Nav, NavKey } from 'types'
import { NavClient } from 'utils'

const versionNavKey :NavKey = 'version'
const rowNavKey :NavKey = 'row'

const NavigateToTable = () :(extract :ExtractEntity, sheet? :SheetEntity) => void => {

    const navigate = useNavigate()
    const location = useLocation()

    const [clicked, setClicked] = useState<boolean>(false)

    const [load, sheet, loading] = ExtractStore(store => [store.load, store.sheet, store.loading])

    useEffect(() => {
        if (sheet && clicked && !loading) {
            navigate(sheet.linkTo)
        }
    }, [sheet, clicked, loading])

    return (extract :ExtractEntity, sheet? :SheetEntity) => {
        load(extract, sheet)
        setClicked(true)
    }
}

export default NavigateToTable