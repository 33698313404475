import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'isAdmin',
        name: 'Admin',
        sortable: true
    },
]

export default config