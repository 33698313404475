import { ComponentNavDataPageTableActionMenu } from 'Component'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TypeEntityLinkTo, TypeNav, TypeNavControlDataAction, TypeNavHttpControl } from 'Type'
type Props<T> = {
    itemId :(keyof T)
    entityName :string,
    action?: 'navigate' | 'menu' | 'custom'
    onActionSuccess?: () => void
    customMenuComponent? :(navControl :TypeNavHttpControl<T>) => ReactNode
    customButtons? :(nav :TypeNav<T>) => ReactNode[]
}

const HookNavDataPageAction = <T extends object & TypeEntityLinkTo>({itemId, entityName, action, onActionSuccess, customButtons, customMenuComponent} :Props<T>) :TypeNavControlDataAction<T> => {

    const navigate = useNavigate()
    const [selected, setSelected] = useState<T | undefined>()

    const navigateRowProps = (item: T) => ({onClick: () => {navigate(item.linkTo)}})
    const menuRowProps = (item: T) => ({onClick: () => {setSelected((selected?.linkTo === item.linkTo) ? undefined : item)}})

    const itemIdToExpandedRowMap :{[itemId :string] :ReactNode} = {}
    if (selected) itemIdToExpandedRowMap[selected.linkTo] = <ComponentNavDataPageTableActionMenu entity={selected} entityName={entityName}
         onActionSuccess={onActionSuccess} customComponent={customMenuComponent} customOnly={(action === 'custom')} customButtons={customButtons}/>

    const rowProps = (action === 'navigate') ? navigateRowProps : menuRowProps

    return { itemId, itemIdToExpandedRowMap, rowProps }
}

export default HookNavDataPageAction