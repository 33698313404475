import { HookEnvSock, HookSession } from 'Hook'
import { TypeHttpControlUrl } from 'Type'
import { UtilParam } from 'Util'

const HookWebSocketUrl = (path? :string) :TypeHttpControlUrl => {
    const backendUrl :string = HookEnvSock()
    const { token } = HookSession()

    const buildUrl = (path? :string) :string | undefined => {
        if (!path || !token) return undefined
        const url = UtilParam.url(backendUrl, path)
        url.setAllParams([{type: 'token', values: [token]}])
        return url.toString()
    }

    const url :string | undefined = buildUrl(path)

    return { url, buildUrl }
}

export default HookWebSocketUrl