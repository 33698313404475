import axios, {AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios'
import * as FileSaver from 'file-saver'
import {TypeHttpMethod, TypeHttpPayload, TypeHttpPayloadValue, TypeHttpRequestControl, TypeHttpResponse } from 'Type'

const isFilePresent = (data? :TypeHttpPayload) :boolean => {
    if (!data) return false
    const values :TypeHttpPayloadValue[] = Object.values(data)
    for (let i = 0; i < values.length; i++) if (values[i] instanceof File) return true
    return false
}

const buildError = <Res>(err :AxiosError) :TypeHttpResponse<Res> => {
    const errResp :AxiosResponse | undefined = err.response
    const data :any | undefined = errResp?.data
    const error :string = (typeof data === 'string') ? data as string : 'Unknown server error'
    const status :number = errResp?.status ?? 400
    return {data, status, error}
}

const request = <Res>(url :string, method :TypeHttpMethod, headers :AxiosRequestHeaders, payload? :TypeHttpPayload) :TypeHttpRequestControl<Res> => {
    const abort :AbortController = new AbortController()
    headers['Content-Type'] = (isFilePresent(payload)) ? 'multipart/form-data' : 'application/json'
    const config :AxiosRequestConfig<TypeHttpPayload> = {method, url, signal: abort.signal, data: payload, headers}
    const promise :Promise<TypeHttpResponse<Res>> = axios.request(config)
        .then(({status, data} :AxiosResponse) :TypeHttpResponse<Res> => { return { status, data, url } })
        .catch((err :AxiosError) :TypeHttpResponse<Res> => { return buildError(err) })
    return { promise, abort }
}

const download = async (url :string, headers :AxiosRequestHeaders) :Promise<void> => {
    const downloadPath = new URL(url)
    downloadPath.searchParams.append('download', 'true')
    await axios.request({method: 'get', url: downloadPath.toString(), headers: headers, responseType: 'arraybuffer'})
        .then(({data, headers} :AxiosResponse) :void => {
            const fileName = (headers['content-disposition'] || 'unknown').replace('attachment;filename=', '')
            FileSaver.saveAs(new Blob([data]), fileName)
        }).catch(() => {
            console.error('Error downloading file')
        })
}

const blob = async (url :string, headers :AxiosRequestHeaders) :Promise<Blob | undefined> => {
    const downloadPath = new URL(url)
    downloadPath.searchParams.append('download', 'true')
    return await axios.request({method: 'get', url: downloadPath.toString(), headers: headers, responseType: 'arraybuffer'})
        .then(({data} :AxiosResponse) :Blob => {
            return new Blob([data])
        }).catch(() => {
            console.error('Error downloading blob')
            return undefined
        })
}



const UtilHttpClient = { request, download, blob }

export default UtilHttpClient