import { HookHttpGet } from 'Hook'
import { TypeEntityLinkTo, TypeUrlParam, TypeNavHttpControl, TypeNavKey, TypeNav, TypeHttpControlGet } from 'Type'
import {UtilNav } from 'Util'

type Props<T> = {
    linkTo :string | TypeEntityLinkTo | undefined
    paramUpdates? :TypeUrlParam[]
    resource? :TypeNavKey
}

const HookNavGet = <T>({ linkTo, paramUpdates, resource } :Props<T>) :TypeNavHttpControl<T> => {
    const path :string | undefined = UtilNav.getPath(linkTo, resource)
    const control :TypeHttpControlGet<TypeNav<T>> = HookHttpGet({ path, paramUpdates })
    return {...control, linkTo: path || '' }
}

export default HookNavGet