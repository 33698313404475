import { EuiPageSection } from '@elastic/eui'
import { Children } from 'props'
import { ReactNode } from 'react'

type Props = Children & {
    style? :any
    restrictWidth? :string | number | boolean
    bottomBorder? :boolean | 'extended'
    color? :'transparent' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain'
    paddingSize? : 's' | 'none' | 'm' | 'xs' | 'l' | 'xl'
    alignment? : 'center' | 'top' | 'horizontalCenter'
    grow? :boolean
    xPadding? :number
}

const PageSection= ({style, restrictWidth, bottomBorder, color, paddingSize, alignment, grow, xPadding, children} :Props) => {
    const s :any = {...style}
    if (xPadding) {
        s.paddingLeft = xPadding
        s.paddingRight = xPadding
    }
    return <EuiPageSection style={s} restrictWidth={restrictWidth} bottomBorder={bottomBorder} color={color}
                    paddingSize={paddingSize} alignment={alignment} grow={grow}>{children}</EuiPageSection>
}

export default PageSection