import React, {ChangeEvent} from 'react'
import {EuiFieldText} from '@elastic/eui'
import { Field } from 'props'

const FieldText = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : '';

    const fn = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value)
    }

    return <EuiFieldText placeholder={placeholder}
                         name={name}
                         value={valueStr}
                         isInvalid={isInvalid}
                         fullWidth={fullWidth}
                         onChange={fn}/>
}

export default FieldText

