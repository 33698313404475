import { Nav } from 'types'
import { Env } from 'utils'
import './NavUrlDisplay.css'

type Props = {
    nav: Nav
}

const NavUrlDisplay = ({nav} :Props) => {
    const fullUrl = Env.httpUrl + nav.path

    return <div className={'nav-url-display'}>
        Currently showing response from:
        <span>{fullUrl}</span>
    </div>

    //return <div style={{ marginTop: 10, padding: '4 6', fontSize: 12, lineHeight: '16px ', color: '#343741', backgroundColor: 'rgb(247, 248, 252)'}}>
    //    {'Currently showing response from: '} <span style={{fontWeight: 600}}>{fullUrl}</span>
    //</div>
}

export default NavUrlDisplay