import { FlexItem, Icon } from 'eui'
import { ReactNode } from 'react'
import { CollapsedStore } from 'store'

import './CollapsibleLeftNavBar.css'

type Props = {
    expandedWidth :number
    collapsedWidth :number
    forceCollapsed? :boolean
    darker? :boolean
    children: ReactNode
}


const CollapsibleLeftNavBar = ({expandedWidth, collapsedWidth, forceCollapsed, darker, children} :Props) => {

    const isCollapsed :boolean = (forceCollapsed === undefined) ? CollapsedStore(store => store.isCollapsed) : forceCollapsed

    const style = {
        padding: (isCollapsed) ? '16px 8px' : '16px 24px',
        maxWidth: `${expandedWidth}px`
    }

    return <FlexItem>
        <div className={'collapsible-left-nav-bar-width'} style={{width: (isCollapsed) ? collapsedWidth : expandedWidth}}></div>
        <div className={(darker) ? 'collapsible-left-nav-bar-content-dark' : 'collapsible-left-nav-bar-content'} style={style}>
            {children}
        </div>
    </FlexItem>
}

export default CollapsibleLeftNavBar