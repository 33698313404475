import { ComponentEuiButton } from 'Component'
import { HookPageLocation, HookSession } from 'Hook'
import { TypeControlPageLocation, TypeControlSession, TypeEntityUser } from 'Type'
import { FORMAT_PAGE_URLS } from 'Format'

type Props = {
    userEntity :TypeEntityUser
}

const ModuleUserLogout = ({ userEntity } :Props) => {

    const { setLocation } :TypeControlPageLocation = HookPageLocation()
    const { clearToken, globalId } :TypeControlSession = HookSession()

    const isMe :boolean = userEntity.globalId === globalId

    const onClick = () => {
        clearToken()
        setLocation(FORMAT_PAGE_URLS.home)
    }

    return (isMe) ? <ComponentEuiButton onClick={onClick} iconType={'exit'} fullWidth color={'danger'}>{ 'Logout' }</ComponentEuiButton> : null
}

export default ModuleUserLogout