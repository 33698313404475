import { SearchOptionEntity } from 'entity'
import {Button, FieldSelect, FieldText, FlexGroup, FlexItem, FormRow, Popover, Selectable, Spacer, Text } from 'eui'
import {useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { FieldSelectOption, Nav, ParamUpdate, SimpleSelectItem } from 'types'
import {NavClient, SearchParamUtils } from 'utils'

type Props = {
    fieldOptions: FieldSelectOption[]
    nav? :Nav
    setNav? :(newNav :Nav) => void
}

const NavTableFilter = ({fieldOptions, nav, setNav} :Props) => {

    const navigate = useNavigate()

    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const toggleOpenFilter = () => setOpenFilter(!openFilter)

    const [field, setField] = useState<string | undefined>(undefined)
    const [searchResults, setSearchResults] = useState<SimpleSelectItem[]>([])

    const innerNav = nav || NavStore(state => state.nav)

    useEffect(() => {
        asyncSearch('')
    }, [field])

    if (!innerNav) return null

    const setFieldFn = (name :string, value :string | File | null) => {
        if (name === 'field') {
            setField(value as string)
            setSearchResults([])
        }
    }

    const onSearchChange = (searchValue: string, matchingOptions: any[]) => {
        asyncSearch(searchValue)
    }

    const selectValue = async (options :SimpleSelectItem[]) => {
        const filtered :SimpleSelectItem[] = options.filter(o => o.checked === 'on')
        if (filtered.length > 0) {
            if (field !== undefined) {
                const updateParam :ParamUpdate = SearchParamUtils.buildFilterParamUpdate(field, filtered[0].label)
                const newPath :string = SearchParamUtils.appendParamForPath(innerNav.path, [updateParam])
                if (nav && setNav) {
                    setNav(await NavClient.fetchNav(newPath))
                } else {
                    navigate(newPath)
                }
            }
        }
    }

    const asyncSearch = async (searchValue :string) => {
        if (field && searchValue !== undefined) {
            const searchNav :Nav = await NavClient.searchTableField(innerNav, field, searchValue)
            const searchOptions :SearchOptionEntity[] = (searchNav.data.page && searchNav.data.page.content) || []
            setSearchResults(searchOptions.map((item :SearchOptionEntity) :SimpleSelectItem => {
                return {
                    label: item.label,
                }
            }))
        }
    }

    return <Popover
        button={
            <Button size={'s'} color={'text'} iconType={'filter'} onClick={() => toggleOpenFilter()}>Filter</Button>
        }
        isOpen={openFilter}
        closePopover={() => setOpenFilter(false)}
        anchorPosition={'downLeft'}
        panelPaddingSize={'m'}
        >
        <Text><h4>New Filter</h4></Text>
        <Spacer size={'s'}/>
        <FlexGroup>
            <FlexItem grow={1}>
                <FormRow label={'Field'}>
                    <FieldSelect placeholder={''} name={'field'} value={field || ''} onChange={setFieldFn} isInvalid={false} options={fieldOptions}/>
                </FormRow>
            </FlexItem>
            <FlexItem  grow={1}>
                <FormRow label={'Filter'}>
                    <FieldText placeholder={''} name={'filter'} value={'equals'} onChange={() => {}} isInvalid={false}/>
                </FormRow>
            </FlexItem>
        </FlexGroup>
        <Spacer size={'s'}/>
        <FlexGroup>
            <FlexItem grow>
                <FormRow label={'Search'}>
                    <Selectable options={searchResults} onChange={selectValue} onSearchChange={onSearchChange}/>
                </FormRow>
            </FlexItem>
        </FlexGroup>
    </Popover>
}

export default NavTableFilter