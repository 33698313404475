import { MembershipEntity } from 'entity'
import { Button } from 'eui'
import {useEffect, useState } from 'react'
import { Nav, NavControlAction, NavControlResource, NavKey } from 'types'
import { NavClient } from 'utils'

type Props = {
    workspaceNav :Nav
}

const membershipKey :NavKey = 'membership'

const WorkspaceMembershipButton = ({ workspaceNav } :Props) => {

    const [membershipNav, setMembershipNav] = useState<Nav | undefined>()

    const fetchMembershipNav = async () => {
        const membershipResource :NavControlResource | undefined = workspaceNav?.control.resource[membershipKey]
        if (!membershipResource) return
        setMembershipNav(await NavClient.fetchNav(membershipResource.linkTo))
    }

    const deleteMembershipAction :NavControlAction | undefined = membershipNav?.control.action['delete']
    const membership :MembershipEntity | undefined = membershipNav?.data.entity

    const quitMembership = () => {

    }

    useEffect(() => {fetchMembershipNav()}, [workspaceNav && workspaceNav.path])

    return <Button iconType={'user'} onClick={quitMembership} isDisabled={!membership} color={'primary'} size={'s'} fill={false}>{ (membership?.isAdmin) ? 'Admin' : 'Member' }</Button>
}

export default WorkspaceMembershipButton