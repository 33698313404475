import React, {MouseEventHandler} from 'react'
import {EuiButtonIcon} from '@elastic/eui'

type Props = {
    type?: string
    display?: 'base' | 'fill' | 'empty'
    size?: 'xs' | 's' | 'm'
    isDisabled? :boolean
    color?: 'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text' | 'accent'
    iconType: string
    onClick: MouseEventHandler
    ariaLabel :string
};

const ButtonIcon =  ({type = 'button', display, isDisabled, onClick, size, color, iconType, ariaLabel}: Props) =>
    <EuiButtonIcon type={type} display={display} isDisabled={isDisabled} onClick={onClick} size={size} color={color} iconType={iconType} aria-label={ariaLabel}/>

export default ButtonIcon