import {ComponentDivWidth, ComponentEuiProgress } from 'Component'
import { TypeHttpLoadable } from 'Type'

type Props = {
    load :TypeHttpLoadable
    onMisaligned? :boolean
}

const ModulePageLoadingItem = ({ load, onMisaligned } :Props) => {

    const loading = (l :TypeHttpLoadable) => (onMisaligned) ? (l.control.isMisaligned && l.control.isLoading) : !l.control.res

   return <ComponentDivWidth width={400}>
       <ComponentEuiProgress size={'l'} max={100} label={load.label} valueText={true}
                                value={(loading(load)) ? 0 : 100} color={(load.control.res?.error) ? 'danger' : 'primary'}/>
   </ComponentDivWidth>
}


export default ModulePageLoadingItem