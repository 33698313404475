import {WebSocketEvent} from 'types'

type Listener = {
    id :string
    order :number
    fn :(event :WebSocketEvent) => void
}

class WebSocketClient {

    private readonly fullUrl :string
    private readonly client :WebSocket
    private readonly listeners :{[id :string] :Listener}
    private isConnected :boolean

    constructor(fullUrl :string, onOpen :() => void, onClose :() => void) {
        console.log(`New Websocket client for ${fullUrl}`)

        this.fullUrl = fullUrl
        this.client = new WebSocket(fullUrl)
        this.listeners = {}
        this.isConnected = false

        this.client.onopen = (event :any) => {
            console.log('Connection established')
            this.isConnected = true;
            onOpen()
        };

        this.client.onmessage = (event :any) => {
            let data :any = JSON.parse(event.data)
            console.log(`Connection message : ${event.data}`)
            let message :WebSocketEvent = {type: 'message', data}
            Object.values(this.listeners).sort((a :Listener, b:Listener) => {
                return a.order - b.order
            }).forEach((l :Listener) => {
                l.fn(message)
            })
        };

        this.client.onclose = (event :any) => {
            this.isConnected = false
            console.log('Connection closed')
            onClose()
        };

        this.client.onerror = (error :any) => {
            console.log('Connection error')
        };
    }

    listen(listener :Listener) {
        this.listeners[listener.id] = listener
    }

    message(message :any) {
        if (this.isConnected) this.client.send(JSON.stringify(message))
    }

    stopListening(id :string) {
        delete this.listeners[id]
    }

    close() {
        console.log('Closing')
        if (this.client && this.client.close) this.client.close()
    }
}

export default WebSocketClient