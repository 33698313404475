import { AuditDisplay } from 'display';
import DescriptionDisplay from 'display/parts/DescriptionDisplay';
import IsPublicDisplay from 'display/parts/IsPublicDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import OwnerDisplay from 'display/parts/OwnerDisplay';
import {TableEntity, WorkspaceEntity } from 'entity';
import { DisplayConfig } from 'types';

const config :DisplayConfig<TableEntity>[] = [...NameDisplay, ...DescriptionDisplay, ...IsPublicDisplay, ...AuditDisplay]

export default config