import CountryList, {Country} from 'country-list-with-dial-code-and-flag'

import { ComponentEuiButtonEmpty, ComponentEuiPopover, ComponentEuiListGroup, ComponentEuiListGroupItem } from 'Component'
import { HookControlSwitch } from 'Hook'
import { TypeControlSwitch } from 'Type'

type Props = {
    value :string
    onChange :(value :string) => void
}

const containsDialCode = (value :string) :boolean => (value.length > 0 && value.charAt(0) === '+')

const getDialCode = (value :string) :string => (containsDialCode(value)) ? value.split(' ')[0] : ''

const ComponentEuiFieldTelephoneCode = ({value, onChange} :Props) => {
    const control :TypeControlSwitch = HookControlSwitch()

    const dialCode :string = getDialCode(value)

    const selectDialCodeFn = (country :Country) => () => {
        const newDialCode :string = country.dial_code
        const newValue :string = (dialCode.length > 0) ? value.replace(dialCode, newDialCode) : `${newDialCode} ${value}`
        onChange(newValue)
        control.turnOff()
    }

    return <ComponentEuiPopover
        className={'euiFormControlLayout__prepend'}
        button={<ComponentEuiButtonEmpty size={'s'} iconType={'arrowDown'} iconSide={'right'} onClick={control.turnOn}> {dialCode}
        </ComponentEuiButtonEmpty>}
        closePopover={control.turnOff} isOpen={control.value} panelPaddingSize={'s'}>
        <div style={{maxHeight: 600, overflow: 'auto', width: 400}}>
            <ComponentEuiListGroup>
                {CountryList.getAll().map((country :Country) => {
                    return <ComponentEuiListGroupItem label={`${country.flag} ${country.name} (${country.dial_code})`} size={'s'} onClick={selectDialCodeFn(country)}/>
                })}

            </ComponentEuiListGroup>
        </div>
    </ComponentEuiPopover>
}

export default ComponentEuiFieldTelephoneCode