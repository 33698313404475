import { EuiLink } from '@elastic/eui'
import { CSSProperties, ReactNode} from 'react'

type Props = {
    href? :string
    onClick?: () => void
    type?: 'button' | 'reset' | 'submit'
    color?: 'text' | 'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'subdued'
    external? :boolean
    children :ReactNode
    fullWidth? :boolean
}

const ComponentEuiLink = ({href, onClick, type, color, external, children, fullWidth}:Props) => {

    const style :CSSProperties | undefined = (fullWidth) ? {
        width: '100%'
    } : undefined

    return <EuiLink style={style} href={href} onClick={onClick} type={type} color={color} external={external}>{children}</EuiLink>
}

export default ComponentEuiLink