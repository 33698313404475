import {AxiosRequestHeaders} from 'axios'
import { HookNavHttpHeaders } from 'Hook'
import {TypeEntityTableVersionColumn, TypeHttpPayload, TypeModuleTableColumnControlMove, TypeNavHttpControl } from 'Type'
import { UtilHttpClient } from 'Util'

const HookModuleTableColumnMove = (columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>) :TypeModuleTableColumnControlMove => {

    const columns :TypeEntityTableVersionColumn[] = columnControl.res?.data?.data.page?.content ?? []

    const headers :AxiosRequestHeaders = HookNavHttpHeaders()

    const moveColumn = async (nameSlug :string, move :number) => {
        const col :TypeEntityTableVersionColumn | undefined = columns.find((col :TypeEntityTableVersionColumn) => col.nameSlug === nameSlug)
        if (!col) return
        const dataPayload :TypeHttpPayload = {}
        dataPayload['reorder'] = move.toString()
        await UtilHttpClient.request(col.linkTo, 'patch', headers, dataPayload).promise
        columnControl.submit()
    }

    return { moveColumn }

}

export default HookModuleTableColumnMove