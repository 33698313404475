import { AuditDisplay } from 'display'
import { DisplayConfig } from 'types'
import NameDisplay from '../parts/NameDisplay'

const config :DisplayConfig[] = [
    ...NameDisplay,
    {field: 'direction', name: 'Direction'},
    ...AuditDisplay
]

export default config