import React, {ChangeEvent} from 'react'
import {EuiFieldNumber} from '@elastic/eui'
import { Field } from 'props'

type Props = Field & {
    min?: number
    max?: number
    step? :number
}

const FieldNumber = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true, min, max, step}: Props) => {

    const fn = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value)
    }

    const valueStr :string = (typeof value === 'string') ? value : ''

    return <EuiFieldNumber placeholder={placeholder}
                            name={name}
                            value={valueStr}
                            isInvalid={isInvalid}
                            fullWidth={fullWidth}
                            onChange={fn}
                            min={min}
                            max={max}
                            step={step}
    />
}

export default FieldNumber