import {ComponentEuiFieldSwitch, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentNavActionModalButton,
    ComponentNavDataPageTable, ComponentPagePanel } from 'Component'
import { ModuleVersionConnectionMenu } from 'Module'
import {TypeEntityConnection, TypeEntityInterfaceMap, TypeEntityRequester, TypeNav, TypeNavHttpControl, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    connectionControl :TypeNavHttpControl<TypeEntityConnection>
    setIsConnectedOnly: (isConnectedOnly :boolean) => void
    isConnectedOnly :boolean
    req :TypeEntityRequester
    mapControl :TypeNavHttpControl<TypeEntityInterfaceMap>
    addMapControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMap>
}

const ModuleVersionConnection = ({ connectionControl, setIsConnectedOnly, isConnectedOnly, req, mapControl, addMapControl } :Props) => {

    const connectionNav :TypeNav<TypeEntityConnection> | undefined = connectionControl.res?.data

    if (!connectionNav) return null

    const toggleConnected = () => setIsConnectedOnly(!isConnectedOnly)

    const rightActions = <ComponentEuiFlexGroup>
        <ComponentEuiFlexItem>
            <ComponentEuiFieldSwitch onChange={toggleConnected} value={`${isConnectedOnly}`} label={'Show connected only'}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ComponentNavActionModalButton nav={connectionNav} buttonType={'icon'} actionType={'post'} iconType={'plusInCircle'} color={'primary'} entityName={'Column'} onSuccess={connectionControl.submit} buttonTitle={'Add Connection'}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>

    const customMenuComponent = (connControl :TypeNavHttpControl<TypeEntityConnection>) => <ModuleVersionConnectionMenu connControl={connControl} req={req} mapControl={mapControl} addMapControl={addMapControl}/>

    return <ComponentPagePanel title={'Connect'} rightActions={rightActions}>
        <ComponentNavDataPageTable initialNav={connectionNav} showColumns={['globalId']} entityName={'Columns'} action={'custom'} onActionSuccess={connectionControl.submit} customMenuComponent={customMenuComponent}/>
    </ComponentPagePanel>
}

export default ModuleVersionConnection