import { TableVersionColumnEntity } from 'entity'
import { Nav } from 'types'
import {NavDataPage} from "../../types";

const KEY = 'show_table_system_columns'

const getShowSystemColumns = () :boolean => {
    const show :string | null = localStorage.getItem(KEY)
    if (show !== 'true') {
        localStorage.setItem(KEY, 'false')
        return false
    } else {
        localStorage.setItem(KEY, 'true')
        return true
    }
}

const toggleShowSystemColumns = () :boolean => {
    const show :boolean = getShowSystemColumns()
    if (show) {
        localStorage.setItem(KEY, 'false')
        return false
    } else {
        localStorage.setItem(KEY, 'true')
        return true
    }
}

const getVisibleColumns = (columnsNav :Nav, showSystemColumns :boolean) :TableVersionColumnEntity[] | undefined => {
    const columns :TableVersionColumnEntity[] | undefined = getAllColumns(columnsNav)
    if (!columns) return undefined
    return (showSystemColumns) ? columns : columns.filter(c => !c.isSystem || c.nameSlug === 'row-id')
}

const getAllColumns = (columnsNav :Nav) :TableVersionColumnEntity[] | undefined => {
    const page :NavDataPage<TableVersionColumnEntity> | undefined = columnsNav.data.page
    if (!page) return
    return page.content.sort((a :TableVersionColumnEntity, b :TableVersionColumnEntity) :number => {
            return (a.orderIndex - b.orderIndex)
        })
}

const SystemColumnsUtils = {
    getShowSystemColumns,
    toggleShowSystemColumns,
    getVisibleColumns,
    getAllColumns
}

export default SystemColumnsUtils