import {ComponentEuiHeaderLink, ComponentEuiHeaderSectionItem } from 'Component'
import { GlobalViewNav } from 'Global'

const ModulePageHeaderItemNavigator = () => {
    const isNav :boolean = GlobalViewNav(state => state.isNav);
    const toggleNav :() => void = GlobalViewNav(state => state.toggleNav);

    return <ComponentEuiHeaderSectionItem>
        <ComponentEuiHeaderLink key={`${isNav}`} isActive={isNav} onClick={toggleNav}>{(isNav) ? 'Exit API Navigator' : 'API Navigator'}</ComponentEuiHeaderLink>
    </ComponentEuiHeaderSectionItem>
}

export default ModulePageHeaderItemNavigator