import { EuiCard } from '@elastic/eui'
import { Icon } from 'eui'
import {ReactChild, ReactElement, ReactNode } from 'react'

type Props = {
    children? :ReactNode
    image? :string | ReactElement
    textAlign? :'left' | 'right' | 'center'
    footer? :ReactNode
    layout? : 'vertical'
    title :ReactChild
    titleElement? :'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
    titleSize? : 's' | 'xs'
    description? :string
    icon? :string
    iconSize? :'s' | 'm' | 'l' | 'xl' | 'original' | 'xxl'
    onClick? :() => void
    isDisabled? :boolean
    display? :'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued' | 'plain' | 'transparent'
    hasBorder? :boolean
    betaBadgeProps? :any
}

const Card = ({children, image, textAlign, footer, layout, title, titleElement, titleSize, description, icon, iconSize, onClick, isDisabled, display, hasBorder, betaBadgeProps} :Props) =>
    <EuiCard image={image} textAlign={textAlign} footer={footer} layout={layout} title={title} titleElement={titleElement}
             titleSize={titleSize} description={description} icon={(icon) ? <Icon type={icon} size={iconSize} color={(isDisabled) ? '#a2abba' : undefined}/> : undefined} onClick={onClick} isDisabled={isDisabled} display={display} hasBorder={hasBorder} betaBadgeProps={betaBadgeProps}>{ children }</EuiCard>

export default Card