import { ComponentEuiCallout, ComponentEuiLink } from 'Component'
import {HookControlSwitch, HookNavActionAuto, HookNavResourceNav, HookSession } from 'Hook'
import {TypeControlSession, TypeControlSwitch, TypeEntityUser, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    userNav :TypeNav<TypeEntityUser>
}

const Callout = ({ userNav } :Props) => {

    const resentSwitch :TypeControlSwitch = HookControlSwitch()
    const emailConfirmControl :TypeNavHttpControl<any> = HookNavResourceNav<any>({key: 'email-confirmation', nav: userNav})
    const emailConfirmNav :TypeNav<any> | undefined = emailConfirmControl.res?.data

    const resendEmailConfirm = HookNavActionAuto<any>('post', emailConfirmNav)

    const resendLink = async () => {
        await resendEmailConfirm.submit()
        resentSwitch.turnOn()
    }



    return (resentSwitch.value) ?
        <ComponentEuiCallout title={'Confirmation email resent.'} color={'success'} iconType={'check'}/> :
        <ComponentEuiCallout title={'To get started, please confirm your email.'} color={'primary'} iconType={'pin'}>
            <>Confirm your email by clicking the "Verify Email Address" button in the email we sent you.  Or click <ComponentEuiLink onClick={resendLink}>here</ComponentEuiLink> to resend the email.</>
        </ComponentEuiCallout>
}

const ModuleUserEmailConfirmation = ({ userNav } :Props) => {
    const { globalId } :TypeControlSession = HookSession()

    const userEntity :TypeEntityUser | undefined = userNav.data.entity

    if (!userEntity) return null

    const notMe :boolean = userEntity.globalId !== globalId

    const emailConfirmed :boolean = userEntity.emailConfirmation

    if (notMe || emailConfirmed) return null

    return <Callout userNav={userNav}/>

}

export default ModuleUserEmailConfirmation