import {MouseEventHandler, ReactNode} from 'react'
import {EuiButtonEmpty} from '@elastic/eui'
import { TypeEuiPropsIconType } from 'Type'

type Props = {
    style? :any
    type? :'button' | 'submit' | undefined
    isLoading? :boolean
    size? :'s' | 'm'
    isDisabled? :boolean
    color? :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    iconSide? :'left' | 'right'
    iconType? :TypeEuiPropsIconType
    iconSize? :'s' | 'm'
    onClick :MouseEventHandler
    children? :ReactNode
};

const ComponentEuiButtonEmpty = ({style, type, isLoading, onClick, size, color, iconSide, iconType, iconSize, isDisabled, children}: Props) =>
    <EuiButtonEmpty style={style} type={type} isLoading={isLoading} iconSize={iconSize} onClick={onClick} size={size} color={color} iconSide={iconSide}
                      iconType={iconType} isDisabled={isDisabled}>{children}</EuiButtonEmpty>

export default ComponentEuiButtonEmpty