import { DisplayConfig } from 'types';
import CreatedAtDisplay from '../parts/CreatedAtDisplay';
import {NavLink} from 'react-router-dom';

const config :DisplayConfig[] = [
    {
        field: 'createdBy',
        name: 'Created By',
        sortable: true,
        render: (value :any, record :any) => {
            return <NavLink to={`/${value}`} target={'_blank'}>{value}</NavLink>
        }
    },
    ...CreatedAtDisplay
]

export default config