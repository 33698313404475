import {EuiTextArea} from '@elastic/eui'
import React, {ChangeEvent} from 'react'
import { TypeEuiFormFieldProps } from 'Type'


type Props = TypeEuiFormFieldProps<string>

const ComponentEuiFieldText = ({value, placeholder, isInvalid, fullWidth, onChange} :Props) => {

    const evtFn = (e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)

    return <EuiTextArea
        placeholder={placeholder}
        value={value || ''}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldText