import { FC } from 'react'
import moment from 'moment'
import { TypeModuleTableDataCellProps } from 'Type'


const ModuleTableDataTableCellDisplayDataTimeZ :FC<TypeModuleTableDataCellProps> = ({ value } :TypeModuleTableDataCellProps) => {
    if (!value) return null
    return <>{moment(value).format('LLL')}</>
}

export default ModuleTableDataTableCellDisplayDataTimeZ