import {ButtonIcon} from "../../eui";
import {useNavigate} from "react-router-dom";

type Props = {
    linkTo :string
}

const TableVersionRowTableRowView = ({linkTo} :Props) => {
    const navigate = useNavigate()
    return <ButtonIcon ariaLabel="Navigate to row" iconType={'link'} size={'xs'} color={'primary'} onClick={() => {navigate(linkTo)}}/>
}

export default TableVersionRowTableRowView