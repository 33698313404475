import {CSSProperties, PropsWithChildren} from 'react'

type Props = {
    width? :number
    height? :number
}

const ComponentDivMax = ({width, height, children} :PropsWithChildren<Props>) => {
    const style :CSSProperties = {}
    if (width) style.maxWidth = `${width}px`
    if (height) style.maxHeight = `${height}px`
    return <div style={style}>{children}</div>
}

export default ComponentDivMax