import { ReactNode } from 'react'
import { ComponentDivCenter, ComponentEuiButton, ComponentEuiButtonEmpty, ComponentEuiButtonIcon } from 'Component'
import {TypeEntityLinkTo, TypeEuiPropsIconType, TypeNavKey } from 'Type'
import { HookNavNavigateEntity } from 'Hook'

type Props = {
    buttonType :'normal' | 'empty' | 'icon'
    iconType? :TypeEuiPropsIconType
    color? :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    entity :TypeEntityLinkTo
    resource? :TypeNavKey
    fullWidth? :boolean
    fill? :boolean
    children :ReactNode
}

const ComponentNavSidebarButtonNavigate = ({ entity, resource, iconType, fullWidth, fill, buttonType, color, children  } :Props) => {

    const onClick :() => void | undefined = HookNavNavigateEntity({entity, resource})

    if (!onClick) return null

    return <ComponentDivCenter>
        {(buttonType === 'normal') ? <ComponentEuiButton onClick={onClick} iconType={iconType} fullWidth={fullWidth} color={color} fill={fill}>{ children }</ComponentEuiButton> :
            (buttonType === 'empty') ? <ComponentEuiButtonEmpty onClick={onClick} iconType={iconType} color={color}>{ children }</ComponentEuiButtonEmpty> :
                (iconType) ? <ComponentEuiButtonIcon onClick={onClick} iconType={iconType} color={color}/> : null}
    </ComponentDivCenter>
}

export default ComponentNavSidebarButtonNavigate