import { NewTableRowForm } from 'components'
import {ChildEntity, TableEntity, TableVersionColumnEntity } from 'entity'
import { FlexGroup, FlexItem, PageSection, Button } from 'eui'
import {NavFileDownloadButton, NavTableFilter, NavTableFilterBadges, TableModalConnect } from 'modules'
import {useState} from 'react'
import { NavStore } from 'store'
import { Nav, NavDataPage, NavKey, NavControlResource, TableColumnHookControl } from 'types'
import {useNavigate} from 'react-router-dom'

type FilterOpt = {
    value :string
    text :string
}

const tableKey :NavKey = 'table'
const versionKey :NavKey = 'version'
const columnKey :NavKey = 'column'
const reqKey :NavKey = 'req'
const childKey :NavKey = 'child'

type Props = {
    columnControls :TableColumnHookControl
}

const TableSectionToolbar = ({columnControls} :Props) => {

    const navigate = useNavigate()

    const [connModalOpen, setConnModalOpen] = useState<boolean>(false)

    const pageNavOpt :Nav | undefined = NavStore(store => store.nav)
    const versionNav :Nav | undefined = NavStore(store => store.contextNavs[versionKey])
    const child :ChildEntity | undefined = NavStore(store => store.nav?.context[childKey])
    const columnResource :NavControlResource | undefined = versionNav.control.resource[columnKey]
    const reqResource :NavControlResource | undefined = versionNav.control.resource[reqKey]

    const childTableName :string = child?.childTableName ?? 'Row'

    const rowPageOpt :NavDataPage<any> | undefined = pageNavOpt?.data.page

    const getVisibleColumn = (nameCamel :string) :TableVersionColumnEntity | undefined =>
        columnControls.columns?.filter(c => c.nameCamel === nameCamel)[0]

    const isColumnsVisible = (nameCamel :string) :boolean => !!getVisibleColumn(nameCamel)

    const toFilterOpt = (nameCamel :string) :FilterOpt => {
        const column :TableVersionColumnEntity | undefined = getVisibleColumn(nameCamel)
        return {value: nameCamel, text: column?.name ?? nameCamel}
    }

    const fieldFilterOptions :FilterOpt[] = rowPageOpt?.searchableFields.filter(isColumnsVisible).map(toFilterOpt) ?? []

    return <PageSection paddingSize={'s'}>
        <FlexGroup gutterSize={'s'} justifyContent={'spaceBetween'}>
            <FlexItem>
                <FlexGroup gutterSize={'s'}>
                    <FlexItem>
                        {pageNavOpt && <NewTableRowForm nav={pageNavOpt} actionType={'post'} buttonSize={'s'} buttonFill entityName={childTableName}/>}
                    </FlexItem>
                    { fieldFilterOptions.length > 0 && <>
                      <FlexItem><NavTableFilter fieldOptions={fieldFilterOptions}/></FlexItem>
                      <FlexItem><NavTableFilterBadges/></FlexItem>
                    </> }
                </FlexGroup>
            </FlexItem>
            <FlexItem>
                <FlexGroup gutterSize={'s'}>
                    {pageNavOpt && <FlexItem><NavFileDownloadButton nav={pageNavOpt} size={'s'}/></FlexItem>}
                    {columnResource && <FlexItem><Button color={'primary'} size={'s'} onClick={() => {navigate(columnResource.linkTo)}} iconType={'tableDensityNormal'}>Columns</Button></FlexItem>}
                    {reqResource && <>
                        <FlexItem><Button color={'primary'} size={'s'} onClick={() => {setConnModalOpen(true)}} iconType={'bolt'}>Connect</Button></FlexItem>
                        {connModalOpen && <TableModalConnect reqResource={reqResource} onClose={() => {setConnModalOpen(false)}} columnControls={columnControls}/>}
                    </>}
                </FlexGroup>
            </FlexItem>
        </FlexGroup>
    </PageSection>

}

export default TableSectionToolbar