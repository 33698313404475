import { ChildEntity } from 'entity'
import {Tab, Tabs } from 'eui'
import { useNavigateToChildRow } from 'hooks'
import {useLocation, useNavigate } from 'react-router-dom'

type ChildProps = {
    child :ChildEntity
    allChildren :ChildEntity[]
}

const ChildTab = ({child, allChildren} :ChildProps) => {

    const navigate = useNavigateToChildRow()
    const { pathname } = useLocation()

    const onClick = async () => {
        navigate(child)
    }

    let tabTitle :string = child.childTableName

    const addVersion = (allChildren.filter(c => (c.childTableLinkTo === child.childTableLinkTo && child.childVersionNumber !== c.childVersionNumber)).length > 0)
    const addColumn = (allChildren.filter(c => (c.childTableLinkTo === child.childTableLinkTo && child.childColumnNameSlug !== c.childColumnNameSlug)).length > 0)

    tabTitle = (addVersion) ? `${tabTitle}.v${child.childVersionNumber}` :  tabTitle
    tabTitle = (addColumn) ? `${tabTitle} (${child.childColumnName})` :  tabTitle

    return <Tab onClick={onClick} isSelected={pathname.includes(child.linkTo)}>
        {tabTitle}
    </Tab>
}

type Props = {
    children :ChildEntity[]
}

const RowChildTabs = ({children} :Props) => {
    return <Tabs>
        {children.map((c :ChildEntity) => <ChildTab key={c.linkTo} child={c} allChildren={children}/>)}
    </Tabs>
}

export default RowChildTabs