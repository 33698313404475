import {ComponentEuiFieldNumber, ComponentEuiFieldPassword, ComponentEuiFieldSelect, ComponentEuiFieldSwitch,
    ComponentEuiFieldText, ComponentEuiFieldTextArea, ComponentEuiFieldPickerDate, ComponentEuiFieldPickerDateTime,
    ComponentEuiFieldPickerDateTimeZone, ComponentEuiFieldPickerFile, ComponentEuiFieldPickerTime,
    ComponentEuiFieldTelephone, ComponentEuiFieldPickerYearMonth, ComponentEuiFieldPercentage, ComponentNavFormFieldSearch } from 'Component'
import { TypeEuiFormFieldProps,
    TypeHttpPayloadValue, TypeNav, TypeNavControlActionFormFieldType, TypeNavFormFieldRenderer } from 'Type'
import {
    FORMAT_FILE_ACCEPT_EXCEL,
    FORMAT_FILE_ACCEPT_IMAGE,
    FORMAT_FILE_ACCEPT_PDF,
    FORMAT_FILE_ACCEPT_WORD
} from 'Format'

const stringProps = (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) :TypeEuiFormFieldProps<string> => {
    return {...props, value: props.value as string, onChange: (value :string) => props.onChange(value)}
}

const stringNullableProps = (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) :TypeEuiFormFieldProps<string | null> => {
    return {...props, value: props.value as string, onChange: (value :string | null) => props.onChange(value)}
}

const fileProps = (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) :TypeEuiFormFieldProps<File> => {
    return {...props, value: props.value as File, onChange: (value :File) => props.onChange(value)}
}

const RecordNavFieldInput :Record<TypeNavControlActionFormFieldType, TypeNavFormFieldRenderer> = {
    text: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldText {...stringProps(props)}/>
        }
    },
    textarea: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldTextArea {...stringProps(props)}/>
        }
    },
    password: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPassword {...stringProps(props)}/>
        }
    },
    bool: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldSwitch {...stringProps(props)} label={''}/>
        }
    },
    select: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldSelect {...stringProps(props)}/>
        }
    },
    integer: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldNumber {...stringProps(props)} step={1}/>
        }
    },
    decimal: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldNumber {...stringProps(props)}/>
        }
    },
    search: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>, nav :TypeNav<any>, searchKeys? :string[]) => {
            if (!nav) throw new Error("Cannot search without Navigator")
            if (!searchKeys || searchKeys.length === 0) throw new Error("Search not configured")
            return <ComponentNavFormFieldSearch {...stringNullableProps(props)} nav={nav} searchKeys={searchKeys}/>
        }
    },
    datetimez: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerDateTimeZone {...stringProps(props)}/>
        }
    },
    datetime: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerDateTime {...stringProps(props)}/>
        }
    },
    date: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerDate {...stringProps(props)}/>
        }
    },
    time: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerTime {...stringProps(props)}/>
        }
    },
    yearmonth: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerYearMonth {...stringProps(props)}/>
        }
    },
    year: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldNumber {...stringProps(props)} step={1}/>
        }
    },
    file: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerFile {...fileProps(props)} display={'default'}/>
        }
    },
    excel: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerFile {...fileProps(props)} display={'default'} accept={FORMAT_FILE_ACCEPT_EXCEL}/>
        }
    },
    word: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerFile {...fileProps(props)} display={'default'} accept={FORMAT_FILE_ACCEPT_WORD}/>
        }
    },
    pdf: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerFile {...fileProps(props)} display={'default'} accept={FORMAT_FILE_ACCEPT_PDF}/>
        }
    },
    image: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPickerFile {...fileProps(props)} display={'default'} accept={FORMAT_FILE_ACCEPT_IMAGE}/>
        }
    },
    telephone_number: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldTelephone {...stringProps(props)}/>
        }
    },
    percentage: {
        render: (props :TypeEuiFormFieldProps<TypeHttpPayloadValue>) => {
            return <ComponentEuiFieldPercentage {...stringProps(props)}/>
        }
    }
}

export default RecordNavFieldInput

