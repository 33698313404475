import {ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentNavActionDownloadButton, ComponentNavActionModalButton } from 'Component'
import { ReactNode } from 'react'
import { TypeNav } from 'Type'

type Props<T> = {
    nav :TypeNav<T>
    entityName :string
    onActionSuccess? :(entity? :T) => void
    customButtons? :(nav :TypeNav<T>) => ReactNode[]
}

const ComponentNavActionMenu = <T,>({ nav, entityName, onActionSuccess, customButtons } :Props<T>) => {
    return <ComponentEuiFlexGroup gutterSize={'xs'}>
        { customButtons && customButtons(nav).map( (customButton :ReactNode, index :number) => <ComponentEuiFlexItem key={index}>{ customButton }</ComponentEuiFlexItem>) }
        <ComponentNavActionDownloadButton nav={nav}/>
        <ComponentNavActionModalButton actionType={'post'} nav={nav} iconType={'plusInCircle'} color={'primary'} buttonType={'icon'} buttonTitle={'New'} entityName={entityName} onSuccess={onActionSuccess}/>
        <ComponentNavActionModalButton actionType={'patch'} nav={nav} iconType={'documentEdit'} color={'primary'} buttonType={'icon'} buttonTitle={'Update'} entityName={entityName} onSuccess={onActionSuccess}/>
        <ComponentNavActionModalButton actionType={'put'} nav={nav} iconType={'documentEdit'} color={'primary'} buttonType={'icon'} buttonTitle={'Override'} entityName={entityName} onSuccess={onActionSuccess}/>
        <ComponentNavActionModalButton actionType={'delete'} nav={nav} iconType={'trash'} color={'danger'} buttonType={'icon'} buttonTitle={'Delete'} entityName={entityName} onSuccess={onActionSuccess}/>
    </ComponentEuiFlexGroup>
}

export default ComponentNavActionMenu