const toLink = (globalId :string) :string => {
    return `/${globalId}`
}

const isApi = (globalId :string) :boolean =>
    globalId.split('/')[0] === 'api'

const UtilGlobalId = {
    toLink,
    isApi
}

export default UtilGlobalId