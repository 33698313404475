import {ComponentDivMarginHorizontal, ComponentEuiFlexGroup, ComponentEuiFlexItem } from 'Component'
import {ModuleTransformTitlePopoverExclude, ModuleTransformTitlePopoverHeader } from 'Module'
import {TypeEntityTransform, TypeModuleControlRefresh, TypeNav, TypeNavControlResource } from 'Type'

type Props = {
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformTitlePopover = ({transformNav, excludeResource, refreshControl} :Props) => {
    return <ComponentEuiFlexGroup gutterSize={'none'}>
        <ComponentEuiFlexItem>
            <ModuleTransformTitlePopoverHeader transformNav={transformNav} refreshControl={refreshControl}/>
            <ComponentDivMarginHorizontal margin={30}/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ModuleTransformTitlePopoverExclude excludeResource={excludeResource} refreshControl={refreshControl}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ModuleTransformTitlePopover