import { TableVersionColumnEntity } from "entity"
import { TypeEntityTable } from "Type";
import {NavKey} from 'types';
import {InterfaceMapEntity} from "../../entity";

const buildRoute = (parts :string[], i :number) :string => {
    let slice :string[] = parts.slice(0, i + 1)
    slice.unshift('')
    return slice.join('/')
}

const versionKey :NavKey = 'version'
const columnKey :NavKey = 'column'
const rowKey :NavKey = 'row'
const cellKey :NavKey = 'cell'
const fieldKey :NavKey = 'field'

const RouteUtils = {
    buildRoute: buildRoute,
    rowToCell: (rowLink :string, columnNameSlug :string) :string => {
        return `${rowLink}/${cellKey}/${columnNameSlug}`
    },
    columnToCellLink: (column :TableVersionColumnEntity, rowId :number) :string => {
        const parts :string[] = column.linkTo.split(`/`)
        parts.pop()
        parts.pop()
        const versionLink :string = parts.join('/')
        return `${versionLink}/${rowKey}/${rowId}/${cellKey}/${column.nameSlug}`
    },
    back: (path :string) => {
        if (path === '' || path === '/') return '/'
        const parts :string[] = path.split('/')
        return parts.slice(0, parts.length - 1).join('/')
    },
    pathOnly: (url :string) => (url || '').split('?')[0],
    isStatic: (path :string) => {
        return ['/about', '/documentation', '/privacy', '/terms'].includes(path)
    },
    column: (tableLinkTo :string, versionNumber :number) :string => {
        return `${tableLinkTo}/${versionKey}/${versionNumber}/${columnKey}`
    },
    version: (tableLinkTo :string, versionNumber :number) :string => {
        return `${tableLinkTo}/${versionKey}/${versionNumber}`
    },
    interfaceMapField: (map :InterfaceMapEntity) :string => {
        return `${map.linkTo}/${fieldKey}`
    }
}

export default RouteUtils