import {ComponentEuiFlexGroup, ComponentNavActionModalButton, ComponentNavDataPageTable, ComponentPagePanel } from 'Component'
import { TypeEntityTableVersionColumn, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
    refControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
}

const ModuleVersionColumn = ({ columnControl, refControl } :Props) => {

    const columnNav :TypeNav<TypeEntityTableVersionColumn> | undefined = columnControl.res?.data
    const refNav :TypeNav<TypeEntityTableVersionColumn> | undefined = refControl.res?.data

    if (!columnNav || !refNav) return null

    const rightActions = <ComponentEuiFlexGroup gutterSize={'s'}>
        <ComponentNavActionModalButton nav={refNav} buttonType={'empty'} size={'s'} actionType={'post'} iconType={'plusInCircle'} color={'primary'} entityName={'Column'} onSuccess={columnControl.submit} buttonTitle={'Ref'}/>
        <ComponentNavActionModalButton nav={columnNav} buttonType={'empty'} size={'s'} actionType={'post'} iconType={'plusInCircle'} color={'primary'} entityName={'Column'} onSuccess={columnControl.submit} buttonTitle={'Data'}/>
    </ComponentEuiFlexGroup>

    return <ComponentPagePanel title={'Columns'} rightActions={rightActions}>
        <ComponentNavDataPageTable initialNav={columnNav} showColumns={['name', 'orderIndex', 'type']} entityName={'Columns'} action={'menu'} onActionSuccess={columnControl.submit}/>
    </ComponentPagePanel>
}

export default ModuleVersionColumn