import { ComponentEuiButtonIcon, ComponentNavActionModalEntity } from 'Component'
import {HookControlSwitch } from 'Hook'
import {TypeControlSwitch, TypeEntityRow } from 'Type'

type Props = {
    row :TypeEntityRow
}

const ModuleTableColumnControlTrailing = ({ row } :Props) => {
    const modalControl :TypeControlSwitch = HookControlSwitch()

    return <>
        {modalControl.value ? <ComponentNavActionModalEntity entity={row} actionType={'delete'} entityName={`Row ${row.rowId}`} onSuccess={modalControl.turnOff} onClose={modalControl.turnOff}/> :
            <ComponentEuiButtonIcon iconType={'trash'} size={'xs'} color={'danger'} onClick={modalControl.turnOn}/> }
    </>
}

export default ModuleTableColumnControlTrailing