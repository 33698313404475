import {CSSProperties, PropsWithChildren} from 'react'

type Props = {}

const ComponentDivCenter = ({ children } :PropsWithChildren<Props>) => {
    const style :CSSProperties = {width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}
    return <div style={style}>
        { children }
    </div>
}

export default ComponentDivCenter