import {
    ComponentEuiBadge, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiLink, ComponentEuiSpacer,
    ComponentNavDataPageTableSidebar, ComponentNavSidebarTitle } from "Component"
import {TypeEntityMember, TypeEntityOrganisation, TypeNavHttpControl } from "Type"

type Props = {
    orgEntity :TypeEntityOrganisation
    memberControl :TypeNavHttpControl<TypeEntityMember>
}

const PageOrganisationSidebar = ({ orgEntity, memberControl } :Props) => {

    const customMemberItemRender = (member :TypeEntityMember) => <ComponentEuiFlexGroup alignItems={'baseline'}>
        <ComponentEuiFlexItem grow>
            <ComponentEuiLink href={`/${member.memberGlobalId}`}>{member.memberName}</ComponentEuiLink>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>{(member.isAdmin) ? <ComponentEuiBadge color={'primary'}>Admin</ComponentEuiBadge> : null}</ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={orgEntity.name} iconType={'users'} entityName={'User'}/>
            <ComponentEuiSpacer size={'xl'}/>
            <ComponentNavDataPageTableSidebar navControl={memberControl} showColumn={'memberName'} entityName={'Member'} title={'Members'} emptyMessage={'You have no members'} customRender={customMemberItemRender}/>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageOrganisationSidebar