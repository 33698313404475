import { EuiFlexGrid } from '@elastic/eui'
import { ReactNode } from 'react'

type Props = {
    columns? :1 | 2 | 3 | 4
    direction? :'row' | 'column'
    alignItems? :'end' | 'baseline' | 'center' | 'start' | 'stretch'
    gutterSize? :'none' | 's' | 'm' | 'l' | 'xl'
    responsive? :boolean
    children :ReactNode
}

const FlexGrid = ({columns, direction, alignItems, gutterSize, responsive, children} :Props) => <EuiFlexGrid columns={columns} direction={direction} alignItems={alignItems} gutterSize={gutterSize} responsive={responsive}>{children}</EuiFlexGrid>

export default FlexGrid