import {ColorUtils} from 'utils';

class ProcessColorPicker {
    private readonly map :{[key :string] : string} = {}
    private colors = ['#ff0000', '#ff8000', '#ffff00', '#80ff00',
                        '#00ff00', '#00ff80', '#00ffff', '#0080ff',
                        '#0000ff', '#8000ff', '#ff00ff', '#ff0080'].sort(() => (Math.random() > .5) ? 1 : -1)

    pick(processId :string) :string {
        if (!this.map[processId]) this.map[processId] = ColorUtils.convertHexToRGBA(this.colors.shift() || 'FF0080FF', 0.5);
        return this.map[processId]
    }
}

export default ProcessColorPicker