import { ReactNode } from 'react'

import './PageTitle.css'

type Props = {
    children :ReactNode
}


const PageTitle = ({children} :Props) => <h1 className={'page-title'}>{children}</h1>

export default PageTitle