import {useEffect, useState} from 'react'
import { NavControlResource, NavHookControl, NavKey, Nav, TableColumnHookControl } from 'types'
import {ButtonEmpty, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderTitle, PageSection, Tab, Tabs} from 'eui'
import {useNav} from 'hooks'
import {NavClient} from 'utils'
import {LinkToEntity} from 'entity'
import {TableModalConnectInterfaceList, TableModalConnectResponderList } from 'modules'

type Props = {
    reqResource :NavControlResource
    onClose: () => void
    columnControls :TableColumnHookControl
}

const connKey :NavKey = 'conn'
const mapKey :NavKey = 'map'

type TabKey = 'conn-list' | 'map-list'

const TableModalConnect = ({reqResource, onClose, columnControls} :Props) => {

    const reqNavControl :NavHookControl = useNav()

    const fetchNav = async () => {
        const tableReqNav :Nav = await NavClient.fetchNav(reqResource.linkTo)
        const newTableReqNav :Nav = (tableReqNav.control.action.post && tableReqNav.control.action.post.auth.authorized) ? await NavClient.action(tableReqNav, 'post', {}) : tableReqNav
        const reqEntity :LinkToEntity | undefined = newTableReqNav.data.entity
        if (reqEntity) reqNavControl.fetch(reqEntity.linkTo)
    }

    useEffect(() => {fetchNav()}, [reqResource.linkTo])

    const reqNav :Nav | undefined = reqNavControl.nav

    const connResource :NavControlResource | undefined = reqNav?.control.resource[connKey]
    const mapResource :NavControlResource | undefined = reqNav?.control.resource[mapKey]

    const [tab, setTab] = useState<TabKey>('conn-list')

    return <Modal onClose={onClose} style={{width: '800px'}}>
        <ModalHeader>
            <ModalHeaderTitle><h1>Connect</h1></ModalHeaderTitle>
        </ModalHeader>
        <ModalBody padding={'none'}>
            <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
                <Tabs bottomBorder={false}>
                    <Tab isSelected={tab === 'conn-list'} onClick={() => setTab('conn-list')}>Connect</Tab>
                    <Tab isSelected={tab === 'map-list'} onClick={() => setTab('map-list')}>Mapping</Tab>
                </Tabs>
            </PageSection>
            {(tab === 'conn-list') && connResource && <TableModalConnectResponderList connResource={connResource} columnControls={columnControls}/>}
            {(tab === 'map-list') && mapResource && <TableModalConnectInterfaceList mapResource={mapResource} columnControls={columnControls}/>}
            <PageSection paddingSize={'none'} bottomBorder={'extended'}></PageSection>
        </ModalBody>
        <ModalFooter>
            <ButtonEmpty color={'danger'} onClick={onClose}>Cancel</ButtonEmpty>
        </ModalFooter>
    </Modal>
}

export default TableModalConnect