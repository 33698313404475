import { ComponentEuiButtonEmpty } from 'Component'
import { HookNavActionAuto, HookNavGet } from 'Hook'
import { TypeEntityTransformExclude, TypeModuleControlRefresh, TypeNav,
    TypeNavControlResource, TypeNavHttpControl, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    rowIndex :string
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformOutputTablePopoverRowContentExclude = ({rowIndex, excludeResource, refreshControl} :Props) => {

    const navControl :TypeNavHttpControl<TypeEntityTransformExclude> = HookNavGet({linkTo: excludeResource.linkTo, paramUpdates: [{type: 'size', values: [`${0}`]}]})
    const nav :TypeNav<TypeEntityTransformExclude> | undefined = navControl.res?.data
    const actionControl :TypeNavHttpControlActionAuto<TypeEntityTransformExclude> = HookNavActionAuto('post', nav)

    const onExcludeClick = async () => {
        await actionControl.addToPayload({sheetRowIndex: rowIndex})
        await refreshControl.exclude()
    }
    return <ComponentEuiButtonEmpty onClick={onExcludeClick} color={'danger'}>Exclude row</ComponentEuiButtonEmpty>
}

export default ModuleTransformOutputTablePopoverRowContentExclude