
import { NavKey } from 'types'
import {FlexGroup, Page, PageBody } from 'eui'
import {TablePageContent, TablePageLeftNavBar, UserPageLeftNavBar } from 'modules'
import { useState } from 'react'
import { FlexPageContent } from 'components'

type Props = {
    tab? :NavKey
}

const TablePage = ({tab} :Props) => {

    const [loadedSideBar, setLoadedSideBar] = useState<boolean>()

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar forceCollapsed={true} wsSelected/>
                <TablePageLeftNavBar callBack={() => setLoadedSideBar(true)}/>
                <FlexPageContent leftBorder={true} minWidth={0}>
                    {(loadedSideBar) ? <TablePageContent/> : null}
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>

}

export default TablePage