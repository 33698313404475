import {FlexGroup, FlexItem, LoadingSpinner, PageSection} from "../../eui";
import {NavStore} from "../../store";
import {Nav} from "../../types";
import {InterfaceMappingSection} from "../index";

const ResponderPageContentMap = () => {

    const nav :Nav | undefined = NavStore(store => store.nav)
    const refresh :() => void = NavStore(store => store.refresh)

    if (!nav) return <LoadingSpinner/>

    return <PageSection>
        <FlexGroup>
            <FlexItem grow>
                <InterfaceMappingSection mapNav={nav} refresh={refresh} dir={'req'}/>
            </FlexItem>
            <FlexItem grow>
                <InterfaceMappingSection mapNav={nav} refresh={refresh} dir={'res'}/>
            </FlexItem>
        </FlexGroup>
    </PageSection>
}

export default ResponderPageContentMap