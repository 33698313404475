import { ChildEntity } from 'entity'
import { PageSection, Spacer } from 'eui'
import { RowChildTabs, RowContentHeader, RowContentRowDisplay } from 'modules'
import {useEffect, useState} from 'react'
import { NavStore } from 'store'
import { Nav, NavKey, NavControlResource, NavDataPage } from 'types'
import {useNavigate} from 'react-router-dom';
import {NavClient} from 'utils';
import {LoadingPage} from 'pages';
import {RowContentChildTable} from "../index";

const rowKey :NavKey = 'row'
const childKey :NavKey = 'child'

const RowPageContent = () => {

    const navigate = useNavigate()

    const childEntity :ChildEntity | undefined = NavStore(store => store.nav?.context[childKey])
    const childResource :NavControlResource | undefined = NavStore(store => store.contextNavs[rowKey]?.control.resource[childKey])
    const [childTables, setChildTables] = useState<ChildEntity[] | undefined>(undefined)

    const fetchChildTables = async () => {
        if (childResource) {
            const childTableNav :Nav = await NavClient.fetchAll(childResource.linkTo)
            const page :NavDataPage<ChildEntity> | undefined = childTableNav.data.page
            if (page) setChildTables(page.content)
        }
    }

    useEffect(() => {
        setChildTables(undefined)
        fetchChildTables()
    }, [childResource?.linkTo])

    useEffect(() => {
        if (!childEntity && childTables && childTables.length > 0) {
            const firstTable = childTables[0]
            navigate(`${firstTable.linkTo}/${rowKey}`)
        }
    }, [childEntity, childTables])


    return <>
        <RowContentHeader/>
        <RowContentRowDisplay/>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <div style={{margin: '-1px -1px'}}>
                <Spacer size={'s'}/>
                <RowChildTabs children={childTables || []}/>
            </div>
        </PageSection>
        {!childTables && <LoadingPage/>}
        {childEntity && <RowContentChildTable key={childEntity.childTableLinkTo} child={childEntity}/>}
    </>

}

export default RowPageContent
