import { ComponentDivCenter, ComponentEuiButtonEmpty, ComponentEuiEmptyPrompt } from 'Component'
import {HookNavContext, HookNavNavigateEntity, HookNavPage } from 'Hook'
import { ModulePage } from 'Module'
import {TypeEntityUser, TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'

const PageEmailConfirmationSuccess = () => {

    const pageControl :TypeNavHttpControl<any> = HookNavPage<any>()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data
    const userEntity :TypeEntityUser | undefined = HookNavContext<TypeEntityUser>('user', pageNav)
    const navigateToUser :() => void = HookNavNavigateEntity({ entity: userEntity })

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Confirmation', control: pageControl},
    ]

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentDivCenter>
            <ComponentEuiEmptyPrompt color={'success'} iconType={'check'} title={<h2>{'Email confirmed.'}</h2>}
                 body={<ComponentEuiButtonEmpty color={'primary'} iconType={'userAvatar'} onClick={navigateToUser}>Back</ComponentEuiButtonEmpty>}/>
        </ComponentDivCenter>
    </ModulePage>
}

export default PageEmailConfirmationSuccess