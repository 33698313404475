import React from 'react'
import {EuiHeaderLink} from '@elastic/eui'
import { useNavigate } from 'react-router-dom'

type Props = {
    to?: string
    onClick?: () => void
    isActive? :boolean
    children?: React.ReactNode
};

const ComponentEuiHeaderLink = ({to, isActive, onClick, children}: Props) => {
    let navigate = useNavigate();

    const fn = (to) ? () => {navigate(to)} : (onClick) ? onClick : () => {};

    return <EuiHeaderLink isActive={isActive} onClick={fn}>{children}</EuiHeaderLink>
}

export default ComponentEuiHeaderLink