import React, {ChangeEvent} from 'react'
import {EuiTextArea} from '@elastic/eui'
import { Field } from 'props'

const FieldTextArea = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : '';

    const fn = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(name, e.target.value)
    }

    return <EuiTextArea placeholder={placeholder}
                         name={name}
                         value={valueStr}
                         isInvalid={isInvalid}
                         fullWidth={fullWidth}
                         onChange={fn}/>
}

export default FieldTextArea