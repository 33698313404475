import { ConnectionEntity } from 'entity'
import { FlexGroup, FlexItem, PageSection, LoadingSpinner, Text, Button, Spacer } from 'eui'
import { useNav } from 'hooks'
import { TableModalConnectResponderEntityMaps } from 'modules'
import {useEffect} from 'react'
import {Nav, NavHookControl, TableColumnHookControl, NavControlResource, NavKey, NavControlAction} from 'types'
import {BackButton} from 'components'
import {NavClient} from 'utils'

type Props = {
    connection :ConnectionEntity
    onBack? :() => void
    columnControls :TableColumnHookControl
}

const mapKey :NavKey = 'map'

const TableModalConnectResponderEntity = ({connection, onBack, columnControls} :Props) => {

    const navControl :NavHookControl = useNav()
    const nav :Nav | undefined = navControl.nav
    const mapResource :NavControlResource | undefined = nav?.control.resource[mapKey]
    useEffect(() => {navControl.fetch(connection.linkTo)}, [connection.linkTo])

    if (!nav) return <LoadingSpinner/>

    const connectAction :NavControlAction | undefined = nav.control.action.post

    const isConnectable :boolean = connectAction && connectAction.auth.authorized

    const connect = async () => {
        if (isConnectable) {
            await NavClient.action(nav, 'post', {})
            navControl.refresh()
        }
    }

    const isConnected :boolean = nav && (nav.data.entity as ConnectionEntity).isConnected

    return <>
        <PageSection paddingSize={'m'}>
            <FlexGroup justifyContent={'flexStart'} gutterSize={'s'}>
                <FlexItem>
                    {onBack && <BackButton onClick={onBack}/>}
                    <Spacer size={'s'}/>
                </FlexItem>
            </FlexGroup>
            <FlexGroup justifyContent={'spaceBetween'} gutterSize={'s'}>
                <FlexItem>
                    <Text><h2>{nav.meta.title}</h2></Text>
                </FlexItem>
                <FlexItem>
                    {(isConnected) ? <Button color={'success'} iconType={'arrowDown'} iconSide={'right'} onClick={() => {}}>Connected</Button> :
                        <Button isDisabled={!isConnectable} onClick={connect} size={'s'}>Connect</Button>
                    }
                </FlexItem>
            </FlexGroup>
        </PageSection>
        {mapResource && <TableModalConnectResponderEntityMaps mapResource={mapResource} columnControls={columnControls} refresh={navControl.refresh}/>}
    </>
}

export default TableModalConnectResponderEntity