import React, {ReactElement} from 'react'
import {EuiTitle} from "@elastic/eui"

type Props = {
    size?: 'xs' | 's' | 'm' | 'l' | 'xxxs' | 'xxs'
    textTransform?: 'uppercase'
    id?: string
    children: ReactElement<any>
};

const Title = ({size = 'm', children}: Props)  =>
    <EuiTitle size={size}>{ children }</EuiTitle>

export default Title
