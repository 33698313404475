import { ComponentEuiButton } from 'Component'
import { HookNavActionAuto } from 'Hook'
import {TypeEntityTransform, TypeModuleControlRefresh, TypeNav, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    rowIndex :string
    transformNav :TypeNav<TypeEntityTransform>
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformOutputTablePopoverRowContentHeader = ({rowIndex, transformNav, refreshControl} :Props) => {

    const control :TypeNavHttpControlActionAuto<TypeEntityTransform> = HookNavActionAuto('patch', transformNav)

    const onHeaderClick = async () => {
        await control.addToPayload({headerRowIndex: rowIndex})
        await refreshControl.headerRow()
    }

    return <ComponentEuiButton onClick={onHeaderClick}>Set row as header</ComponentEuiButton>
}

export default ModuleTransformOutputTablePopoverRowContentHeader