const intToExcelCol = (number :number) => {
    let colName :string = '',
        dividend :number = Math.floor(Math.abs(number)),
        rest :number;

    while (dividend > 0) {
        rest = (dividend - 1) % 26;
        colName = String.fromCharCode(65 + rest) + colName;
        dividend = parseInt(`${(dividend - rest)/26}`);
    }
    return colName;
};


const excelColToInt = (colName :string) => {
    let digits = colName.toUpperCase().split(''),
        number = 0;

    for (let i = 0; i < digits.length; i++) {
        number += (digits[i].charCodeAt(0) - 64) * Math.pow(26, digits.length - i - 1);
    }

    return number;
};

export default {intToExcelCol, excelColToInt}