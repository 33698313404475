import { EuiTabs } from '@elastic/eui'
import { PropsWithChildren } from 'react'

type Props = {
    expand? :boolean
    bottomBorder? :boolean
    size? : 's' | 'm' | 'l' | 'xl'
}

const Tabs = ({expand, bottomBorder, size, children} :PropsWithChildren<Props>) =>
    <EuiTabs expand={expand} bottomBorder={bottomBorder} size={size}>{ children }</EuiTabs>

export default Tabs