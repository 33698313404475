import {ComponentDivWidth, ComponentEuiBadge, ComponentEuiButtonEmpty, ComponentEuiPopover, ComponentNavPageFilterTable } from 'Component'
import { HookControlSwitch, HookNavFilterPage } from 'Hook'
import { TypeControlSwitch, TypeNavControlFilterParam } from 'Type'

type Props = {
    fieldNameCamel? :string
    fieldNameMap? :{[fieldNameCamel :string] :string}
}

const ComponentNavPageFilterTablePopover = ({ fieldNameCamel, fieldNameMap } :Props) => {
    const { dataFilters } :TypeNavControlFilterParam = HookNavFilterPage(fieldNameCamel)
    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    const filterBadge = <span style={{marginLeft: '8px'}}><ComponentEuiBadge color={'primary'}>{dataFilters.length}</ComponentEuiBadge></span>

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'none'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} color={'primary'} size={'s'}>{'Filters'}{filterBadge}</ComponentEuiButtonEmpty>}>
        <ComponentDivWidth width={400}>
            <ComponentNavPageFilterTable fieldNameCamel={fieldNameCamel} fieldNameMap={fieldNameMap}/>
        </ComponentDivWidth>

    </ComponentEuiPopover>
}

export default ComponentNavPageFilterTablePopover