import React, {MouseEventHandler} from 'react'
import {EuiButton} from '@elastic/eui'

type Props = {
    type? :string
    fill? :boolean
    fullWidth? :boolean
    size? :'s' | 'm'
    isDisabled? :boolean
    color? :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text' | 'accent'
    iconSide? :'left' | 'right'
    iconType? :string
    onClick :MouseEventHandler
    children? :React.ReactNode
};

const Button =  ({type = 'button', fill = false, fullWidth, isDisabled, onClick, size, color, iconSide, iconType, children}: Props) => {
    return <EuiButton type={type} fill={fill} isDisabled={isDisabled} onClick={onClick} size={size} color={color}
                      iconSide={iconSide} iconType={iconType} fullWidth={fullWidth}>{children}</EuiButton>
}

export default Button