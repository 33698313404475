import { ComponentNavGlobalIdLink } from 'Component'
import { FC } from 'react'
import { TypeModuleTableDataCellProps } from 'Type'


const ModuleTableDataTableCellDisplayGlobalId  :FC<TypeModuleTableDataCellProps> = ({ value } :TypeModuleTableDataCellProps) => {
    if (!value) return null
    return <ComponentNavGlobalIdLink globalId={value}/>
}

export default ModuleTableDataTableCellDisplayGlobalId