import { HookNavGetMulti } from 'Hook'
import { TypeEntityTransformHeader, TypeEntityTransformHeaderError,
    TypeHttpControlGetMulti,
    TypeHttpResponse, TypeModuleTransformHeaderErrorControl, TypeNav } from 'Type'

const HookModuleTransformHeaderErrorMap = (headers? :TypeEntityTransformHeader[]) :TypeModuleTransformHeaderErrorControl => {

    //TODO on the backend let size -1 = get all
    const navControl :TypeHttpControlGetMulti<TypeNav<TypeEntityTransformHeaderError>> = HookNavGetMulti<TypeEntityTransformHeaderError>({entities: headers, resource: 'error', updateParams: [{type: 'size', values: ['-1']}]})

    const getDestinationColumnNameCamel = (linkTo :string) :string | undefined =>
        headers?.find((h :TypeEntityTransformHeader) => (h.linkTo === linkTo))?.destinationColumnNameCamel

    const getLinkTo = (destinationColumnNameCamel :string) :string | undefined =>
        headers?.find((h :TypeEntityTransformHeader) => (h.destinationColumnNameCamel === destinationColumnNameCamel))?.linkTo

    const refresh = async (destinationColumnNameCamel :string) :Promise<void> => {
        const linkTo :string | undefined = getLinkTo(destinationColumnNameCamel)
        if (linkTo) navControl.refresh(linkTo)
    }

    const refreshAll = navControl.refreshAll

    const control :TypeModuleTransformHeaderErrorControl = {
        errors: {},
        refresh,
        refreshAll
    }

    if (!headers || !navControl.resMap) return control

    const linkTos :string[] = Object.keys(navControl.resMap)

    for (let i = 0; i < linkTos.length; i++) {
        const linkTo :string = linkTos[i]
        const destinationColumnNameCamel :string | undefined = getDestinationColumnNameCamel(linkTo)
        const res :TypeHttpResponse<TypeNav<TypeEntityTransformHeaderError>> = navControl.resMap[linkTo]
        if (destinationColumnNameCamel) control.errors[destinationColumnNameCamel] = res.data?.data.page?.content ?? []
    }

    return control
}

export default HookModuleTransformHeaderErrorMap