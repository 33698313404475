import {Icon, LoadingSpinner, Tab } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {NavControlResource, NavKey } from 'types'
import { MappingUtils } from 'utils'

type Props = {
    resource :NavControlResource
    activeTab? :NavKey
}

const NavResourceTab = ({resource, activeTab} :Props) => {

    const navigate = useNavigate()

    const onClick = async () => {
        navigate(resource.linkTo)
    }

    const isSelected = (resource.key === activeTab)

    return <Tab className={(isSelected) ? 'nav-resource-tab-active' : 'nav-resource-tab'}
                prepend={<Icon type={MappingUtils.contextIcon(resource.key)} color={(isSelected) ? 'primary' : undefined}/>}
                onClick={onClick}
                isSelected={isSelected}>
        {resource.meta.title}
    </Tab>
}

export default NavResourceTab