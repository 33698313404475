import {ReactNode, useEffect, useState} from 'react'
import { TableSocketStore } from 'store'
import { WebSocketClient } from 'utils'
import { Nav, WebSocketEvent } from 'types'
import { TableVersionColumnEntity } from 'entity'
import { CellRendererConfig } from 'config'
import { DataGridCellValueElement } from 'props'
import { TableVersionRowTableCell } from 'modules'
import { useCellColorMap } from 'hooks'

type Returning = {
    rowCount :number
    renderer :CellRendererConfig
    renderCell: (props :DataGridCellValueElement) => ReactNode
}

const rowId = 'rowId'

const updatedBy = 'updatedBy'

const createdAt = 'createdAt'

const getRowIndex = (newData :any, stateData :any[]) :number | undefined => {
    for (let i = 0; i < stateData.length; i++) if (stateData[i][rowId] === newData[rowId]) return i
    return undefined
}

const buildProcessIdMap = (processId :string, keys :string[]) :any => {
    return keys.reduce((map :any, key :string) => {
        map[key] = processId
        return map;
    }, {})
}

const isNewRow = (newData :any) => (newData[createdAt])

const TableLiveData = (columns: TableVersionColumnEntity[], nav :Nav) :Returning => {

    const [colorMap, setColorMapValue] = useCellColorMap()
    const [data, setData] = useState<any[]>(nav.data.page?.content ?? [])
    const pageSize :number = nav.data.page?.request.size ?? 10

    const wsClient :WebSocketClient | undefined = TableSocketStore(store => store.client)
    const renderer :CellRendererConfig = new CellRendererConfig(columns, data)

    const updateColor = (rowIndex :number, newData :any) => {
        const processId :string | undefined = newData[updatedBy]
        if (!processId) return undefined
        setColorMapValue(rowIndex, buildProcessIdMap(processId, Object.keys(newData)))
    }

    const addNewRowFn = (newData :any, pageSize :number) => (stateData :any[]) => {
        updateColor(0, newData)
        const dataCopy :any[] = [...stateData]
        dataCopy.unshift(newData)
        if (dataCopy.length > pageSize) dataCopy.pop()
        return dataCopy
    }

    const updateRowFn =  (newData :any) => (stateData :any[]) => {
        const rowIndex :number | undefined = getRowIndex(newData, stateData)
        if (rowIndex === undefined) return stateData
        updateColor(rowIndex, newData)
        const dataCopy :any[] = [...stateData]
        Object.keys(newData).forEach((key :string) => {
            dataCopy[rowIndex][key] = newData[key]
        })
        return dataCopy
    }

    const newDataListenerFn = (event :WebSocketEvent) => {
        const newData :any = event.data
        if (isNewRow(newData)) {
            setData(addNewRowFn(newData, pageSize))
        } else {
            setData(updateRowFn(newData))
        }
    }

    useEffect(() => {
        if (wsClient) wsClient.listen({order: 0, fn: newDataListenerFn, id: 'table'})
    }, [wsClient])

    const getColor = (rowId :number, columnId :string) :string | undefined => colorMap[rowId] && colorMap[rowId][columnId]

    const renderCell = (props :DataGridCellValueElement) => {
        return <TableVersionRowTableCell renderer={renderer} rowIndex={props.rowIndex} columnNameCamel={props.columnId} color={getColor(props.rowIndex, props.columnId)}/>
    }

    const rowCount :number = data.length

    return {rowCount, renderer, renderCell}
}

export default TableLiveData