import { ComponentDivPadding, ComponentEuiFlexGroup,
    ComponentEuiFlexItem, ComponentEuiPageSection, ComponentEuiPanel, ComponentPageTitle } from 'Component'
import { PropsWithChildren, ReactNode } from 'react'

type Props = {
    title :string
    bodyPadding? :number
    rightActions? :ReactNode
}

const ComponentPagePanel = ({ title, bodyPadding = 0, rightActions, children } :PropsWithChildren<Props>) => {
    return <div>
        <ComponentEuiPanel borderRadius={'m'} hasBorder paddingSize={'none'}>
            <ComponentEuiPageSection paddingSize={'s'} bottomBorder={'extended'}>
                <ComponentEuiFlexGroup justifyContent={'spaceBetween'}>
                    <ComponentEuiFlexItem grow>
                        <ComponentPageTitle secondary>{ title }</ComponentPageTitle>
                    </ComponentEuiFlexItem>
                    { rightActions && <ComponentEuiFlexItem>
                        { rightActions }
                    </ComponentEuiFlexItem> }
                </ComponentEuiFlexGroup>
            </ComponentEuiPageSection>
            <ComponentDivPadding padding={bodyPadding}>
                {children}
            </ComponentDivPadding>
        </ComponentEuiPanel>
    </div>
}

export default ComponentPagePanel