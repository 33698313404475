import { NavField } from 'components'
import {Button, Callout, Form, LoadingSpinner, Panel, Spacer, Title } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import {DataPayload, FieldOnChange, Nav, NavControlAction, NavControlActionForm, NavControlActionType } from 'types'
import { FormUtils, MappingUtils, NavClient, RouteUtils } from 'utils'
import {NavControlActionFormField} from "../../types";

type Props = {
    title? :string
    nav :Nav
    actionType :NavControlActionType
    onSuccess? :(response: Nav, req? :DataPayload) => void
    fullWidth? :boolean
    hasBorder? :boolean
    initData? :DataPayload
    hideFields? :string[]
}


const NavPageForm = ({title, nav, actionType, onSuccess, fullWidth, hasBorder = false, initData, hideFields = []} :Props) => {
    const navigate = useNavigate()
    const action :NavControlAction | undefined = nav.control.action[actionType]
    const form :NavControlActionForm | undefined = action && action.form
    const [data, setData] = useState<DataPayload>(FormUtils.defaultValues(action, initData))
    const [formErrors, setFormErrors] = useState<{[key :string] :string }>({})

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const setNav :(nav :Nav) => void = NavStore(store => store.setNav)
    const loading :boolean = NavStore(store => store.loading)

    const onChange: FieldOnChange = (name: string, value: string | File | null) => {
        const updated :DataPayload = {...data}
        if (value !== null) {
            updated[name] = value
        } else {
            delete updated[name]
        }
        setData(updated)
    }

    const onSubmit = async () => {
        const responseNav :Nav = await NavClient.action(nav, actionType, data)
        const error = responseNav.error
        if (error) {
            setFormErrors({...error.fieldErrors})
            setErrorMessage(error.message)
        } else if (onSuccess) {
            onSuccess(responseNav, data)
        } else if (responseNav && responseNav.data.entity && responseNav.data.entity['linkTo']) {
            navigate(responseNav.data.entity['linkTo'])
        } else if (actionType === 'delete') {
            navigate(RouteUtils.back(responseNav.path))
        } else {
            setNav(responseNav)
            navigate({hash: ''})
        }
    }

    const fields :NavControlActionFormField[] = form?.fields.filter((f :NavControlActionFormField) => !hideFields?.includes(f.name)) ?? []

    return <div style={{width: (fullWidth) ? '100%' : '600px', margin: '24px auto'}}>
        <Panel hasShadow={false} hasBorder={hasBorder}>
            <Title><h1 style={{fontWeight: 600, fontSize: 22}}>{title || actionType}</h1></Title>
            <Spacer/>
            <Form fullWidth>
                {fields.map(field => {
                    const { name } = field
                    return <NavField key={name} nav={nav} value={data[name]} error={formErrors[name]} field={field} onChange={onChange}/>
                })}
                { errorMessage && <><Spacer size={'m'}/><Callout title={errorMessage} color={'danger'} size={'s'} iconType={'faceSad'}/></> }
                <Spacer/>
                <div style={{display: 'inline-block', width: '100%'}}>
                    {(loading) ? <LoadingSpinner/> : null}
                    <div style={{float: 'right'}}>
                        <Button fill onClick={onSubmit}>{MappingUtils.buttonTitle(actionType)}</Button>
                    </div>
                </div>
            </Form>
        </Panel>
    </div>
}

export default NavPageForm