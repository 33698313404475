import { InterfaceMapEntity, TableVersionColumnEntity } from 'entity'
import { PageSection, LoadingSpinner, Text, ButtonIcon, FlexGroup, FlexItem, BasicTable } from 'eui'
import { useNav } from 'hooks'
import { useEffect } from 'react'
import {NavHookControl, NavControlResource, NavKey, Nav, TableColumnHookControl} from 'types'
import {TableModalConnectInterfaceEntityFieldMap} from 'modules'

const fieldKey :NavKey = 'field'

type Props = {
    map :InterfaceMapEntity
    onBack? :() => void
    columnControls :TableColumnHookControl
}

const TableModalConnectInterfaceEntity = ({map, onBack, columnControls} :Props) => {

    const mapNavControl :NavHookControl = useNav()

    const mapNav = mapNavControl.nav
    const fieldResource :NavControlResource | undefined = mapNav?.control.resource[fieldKey]


    useEffect(() => {mapNavControl.fetch(map.linkTo)}, [map.linkTo])

    if (!mapNav) return <LoadingSpinner/>

    return <>
        <PageSection paddingSize={'m'}>
            <FlexGroup justifyContent={'flexStart'} gutterSize={'s'}>
                <FlexItem>
                    {onBack && <ButtonIcon ariaLabel={'back'} iconType={'arrowLeft'} onClick={onBack}/>}
                </FlexItem>
                <FlexItem>
                    <Text><p>{mapNav.meta.title}</p></Text>
                </FlexItem>
            </FlexGroup>
        </PageSection>
        <PageSection paddingSize={'none'}>
            {fieldResource && <TableModalConnectInterfaceEntityFieldMap fieldResource={fieldResource} columnControls={columnControls}/>}
        </PageSection>
    </>

}

export default TableModalConnectInterfaceEntity