import { ComponentEuiLoadingSpinner } from 'Component'
import {HookNavContext, HookNavPage } from 'Hook'
import { ModulePage } from 'Module'
import { useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import {TypeEntityTable, TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'
import { UtilRoute } from 'Util'

const PageTableRedirect = () => {
    const pageControl :TypeNavHttpControl<any> = HookNavPage()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data
    const tableEntity :TypeEntityTable | undefined = HookNavContext<TypeEntityTable>('table', pageNav)

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Table', control: pageControl},
    ]

    const navigate = useNavigate()

    useEffect(() => {
        if (tableEntity) navigate(UtilRoute.tableToRowLinkTo(tableEntity))
    }, [tableEntity?.linkTo])

    return <ModulePage control={pageControl} toLoad={toLoad}>
        <ComponentEuiLoadingSpinner/>
    </ModulePage>
}

export default PageTableRedirect