import {AxiosRequestHeaders} from 'axios'
import { HookHttpUrl, HookNavHttpHeaders } from 'Hook'
import {useEffect, useState} from 'react'
import {TypeHttpControlGetMulti, TypeHttpMethod, TypeHttpRequestControl, TypeHttpResponse, TypeHttpResponseMap, TypeUrlParam } from 'Type'
import {UtilAsync, UtilHttpClient } from 'Util'

type Props = {
    pathMap: { [key :string] :string }
    updateParams? :TypeUrlParam[]
}

type ControlMap<Res> = { [key :string] :TypeHttpRequestControl<Res> }
const method :TypeHttpMethod = 'get'

const HookHttpGetMulti = <Res>({ pathMap, updateParams } :Props) :TypeHttpControlGetMulti<Res> => {

    const keys :string[] = Object.keys(pathMap).sort()
    const keyDependency :string = keys.join('-')

    const { buildUrl } = HookHttpUrl()
    const headers :AxiosRequestHeaders = HookNavHttpHeaders()

    const [controlMap, setControlMap] = useState<ControlMap<Res>>({})
    const [resMap, setResMap] = useState<TypeHttpResponseMap<Res>>({})

    const getControl = (key :string) :TypeHttpRequestControl<Res> => {
        if (controlMap[key]) return controlMap[key]
        const path :string = pathMap[key]
        const url :string | undefined = buildUrl(path, updateParams)
        if (!url) throw new Error('Url not found')
        return UtilHttpClient.request(url, method, headers)
    }

    const setAllControls = () => {
        const controlMap :ControlMap<Res> = {}
        keys.forEach((key :string) => controlMap[key] = getControl(key))
        setControlMap(controlMap)
    }

    useEffect(() => {setAllControls()}, [keyDependency])

    const collectResponse = async (key :string) :Promise<TypeHttpResponse<Res> | undefined> => {
        if (resMap[key]) return resMap[key]
        const control :TypeHttpRequestControl<Res> | undefined = controlMap && controlMap[key]
        if (!control || control.abort.signal.aborted) return undefined
        const res :TypeHttpResponse<Res> = await control.promise
        return res
    }

    const setAllResponses = async () => {
        const resMap :TypeHttpResponseMap<Res> = {}
        for (let i = 0; i < keys.length; i++) {
            const key :string = keys[i]
            const res :TypeHttpResponse<Res> | undefined = await collectResponse(key)
            if (res) resMap[key] = res
        }
        setResMap(resMap)
    }

    const abortAll = () => {
        if (!controlMap) return
        const keys :string[] = Object.keys(controlMap)
        for (let i = 0; i < keys.length; i++) {
            const key: string = keys[i]
            const control: TypeHttpRequestControl<Res> = controlMap[key]
            control.abort.abort()
        }
    }

    useEffect(() => {
        setAllResponses()
        return abortAll
    }, [controlMap])

    const refresh = async (key :string) :Promise<void> => {
        const path :string = pathMap[key]
        const url :string | undefined = buildUrl(path)
        if (!url) throw new Error('Url not found')
        const control :TypeHttpRequestControl<Res> = UtilHttpClient.request(url, method, headers)
        const res :TypeHttpResponse<Res> = await control.promise
        if (!control.abort.signal.aborted) {
            setResMap((currentResMap :TypeHttpResponseMap<Res>) :TypeHttpResponseMap<Res> => {
                const newResMap :TypeHttpResponseMap<Res> = {...currentResMap}
                newResMap[key] = res
                return newResMap
            })
        }
    }

    const refreshAll = async () :Promise<void> => await UtilAsync.awaitAll(Object.keys(controlMap || {}).map(refresh))

    return { resMap, refresh, refreshAll }
}

export default HookHttpGetMulti