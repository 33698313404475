import { NavTablePagination } from 'components'
import { TableVersionColumnEntity } from 'entity'
import { DataGrid } from 'eui'
import { DataGridColumn, DataGridColumnVisibility, DataGridSchemaDetector, DataGridSorting, ListGroupItem, DataGridControlColumn } from 'props'
import {ReactNode, useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {Nav, PageSortDirection, ParamUpdate } from 'types'
import {NavClient, SearchParamUtils } from 'utils'
import CellContextMenu from "../CellContextMenu/CellContextMenu";
import { DataGridCellPopoverElement } from 'props'
import { useTableLiveData } from 'hooks'
import {TableVersionRowTableRowDelete, TableVersionRowTableRowView } from 'modules'

const linkTo = 'linkTo'

type Props = {
    columns: TableVersionColumnEntity[]
    rowNav: Nav
    moveColumn?: (nameSlug :string, move :number) => void
}

const columnAction = (label :string, icon :string, onClick :() => void) :ListGroupItem => {
    return {
        label: label,
        iconType: icon,
        onClick: onClick
    }
}

const columnMapper = (col :TableVersionColumnEntity, activateCell: (rowIndex :number, columnId :string) => void, update? :(nameSlug :string, move :number) => void) :DataGridColumn => {
    return {
        id: col.nameCamel,
        displayAsText: col.name,
        initialWidth: (col.nameCamel === 'rowId') ? 80 : undefined,
        actions: {
            showMoveLeft: (update) ? columnAction('Move Left', 'sortLeft', () => {update(col.nameSlug, -1)}) : undefined,
            showMoveRight: (update) ? columnAction('Move Right', 'sortRight', () => {update(col.nameSlug, 1)}) : undefined
        }
    }
}

type Active = {
    row? :Nav
    cell? :Nav
}

const TableVersionRowTable = ({columns, rowNav, moveColumn} :Props) => {
    const location = useLocation()
    const navigate = useNavigate()

    const { rowCount, renderer, renderCell } = useTableLiveData(columns, rowNav)

    const onCellClick = async (rowIndex :number, columnId :string) => {
        toggleRowControl(renderer.rowIndexToRow(rowIndex), renderer.buildCellUrl(columnId, rowIndex))
    }

    const gridColumns :DataGridColumn[] = columns.map(c => {return columnMapper(c, onCellClick, moveColumn)})

    const [visibleColumns, setVisibleColumns] = useState<string[]>([])

    const [activeNavs, setActiveNavs] = useState<Active>({})

    useEffect(() => { setVisibleColumns(gridColumns.map(c => c.id)) }, [columns])

    const columnVisibility :DataGridColumnVisibility = {
        visibleColumns: visibleColumns,
        setVisibleColumns: (visibleColumns :string[]) => {setVisibleColumns([...visibleColumns])}
    }

    const schemaDetectors :DataGridSchemaDetector[] = []

    const sortField :string | null = SearchParamUtils.getSortField()
    const sortDirection :PageSortDirection | null = SearchParamUtils.getSortDirection()

    const toggleRowControl = async (object? :any, cellNavLink? :string) => {
        if (!object || activeNavs.row) {
            setActiveNavs({})
        } else {
            const linkToNav :string | undefined = object[linkTo]
            if (linkToNav) {
                const rowPromise :Promise<Nav> = NavClient.fetchNav(linkToNav)
                if (cellNavLink) {
                    const cellPromise :Promise<Nav> = NavClient.fetchNav(cellNavLink)
                    setActiveNavs({row: await rowPromise, cell: await cellPromise})
                } else {
                    setActiveNavs({row: await rowPromise, cell: undefined})
                }
            }
        }
    }

    const sorting :DataGridSorting | undefined = (sortField && sortDirection) ? {
        columns: [{ id: sortField, direction: sortDirection }],
        onSort: (columns: { id: string; direction: PageSortDirection; }[]) => {
            const index = columns.length - 1
            if (columns[index]) {
                const update :ParamUpdate[] = [{param: 'sortField', paramValue: columns[index].id}, {param: 'sortDirection', paramValue: columns[index].direction}]
                const updateUrl :string = SearchParamUtils.updateParamForLocation(location, update)
                navigate(updateUrl)
            }
        },
    } : undefined

    const leadingControlColumns :DataGridControlColumn[] = [
        {
            id: 'Viewport',
            width: 36,
            headerCellRender: () => null,
            rowCellRender: (object :any) => {
                const row: any | null = renderer.rowIndexToRow(object.rowIndex)
                const link :string | undefined = row && row[linkTo]
                return <div style={{marginRight: '6px', float: 'right'}}>
                    {link && <TableVersionRowTableRowView linkTo={link}/>}
                </div>
            },
        }
    ]

    const trailingControlColumns :DataGridControlColumn[] = [
        {
            id: 'actions',
            width: 36,
            headerCellRender: () => null,
            rowCellRender: (object :any) => {
                const row: any | null = renderer.rowIndexToRow(object.rowIndex)
                const link :string | undefined = row && row[linkTo]
                const rowId :number | undefined = renderer.rowIndexToRowId(object.rowIndex)
                return <div style={{marginRight: '6px', float: 'right'}}>
                    {link && rowId && <TableVersionRowTableRowDelete rowId={rowId} linkTo={link}/>}
                </div>
            }
        }
    ]

    const renderCellPopover = ({rowIndex, columnId} :DataGridCellPopoverElement ) :ReactNode => {
        const row :any | null = renderer.rowIndexToRow(rowIndex)
        const rowId :number | undefined = renderer.rowIndexToRowId(rowIndex)
        const column :TableVersionColumnEntity | undefined = renderer.nameCamelToColumnEntity(columnId)
        return (row && rowId && column) ? <div style={{margin: '-8px'}}><CellContextMenu rowLinkTo={row[linkTo]} column={column} rowId={rowId}/></div> : <div>{row[columnId]}</div>
    }

    return <>
        <DataGrid columns={gridColumns} columnVisibility={columnVisibility} rowCount={rowCount}
                  renderCellValue={renderCell} schemaDetectors={schemaDetectors} sorting={sorting}
                  trailingControlColumns={trailingControlColumns} leadingControlColumns={leadingControlColumns}
                  renderCellPopover={renderCellPopover}
        />
        <NavTablePagination totalElements={rowNav.data.page?.totalElements ?? 0}/>
    </>
}

export default TableVersionRowTable

