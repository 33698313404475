import { EuiBeacon } from '@elastic/eui'

type Props = {
    size? :string | number
    color? :'accent' | 'primary' | 'success' | 'warning' | 'danger' | 'subdued'
}

const ComponentEuiBeacon = ({size, color} :Props) => {
    return <EuiBeacon size={size} color={color}/>
}

export default ComponentEuiBeacon