import { PageTitle } from 'components'
import {ApiEntity, UserEntity } from 'entity'
import {Button, FlexGroup, FlexItem, PageSection, Spacer } from 'eui'
import {ApiModalDisplayNew, EmailConfirmationCallout, GenericActionButtons, NavDataEntity, NavDetailsTab, NavResourceTabContent, NavResourceTabs } from 'modules'
import { useState } from 'react'
import {NavigateFunction, useNavigate } from 'react-router-dom'
import { NavStore, SessionStore } from 'store'
import {DataPayload, Nav, NavKey } from 'types'

type Props = {
    tabKey? :NavKey
}

const actionTitles = {delete: 'Close Account', patch: 'Update'}

const apiKey :NavKey = 'api'

const UserPageContent = ({tabKey} :Props) => {

    const navigate :NavigateFunction = useNavigate()

    const [newApi, setNewApi] = useState<ApiEntity | undefined>()

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const refreshPage :() => void = NavStore(store => store.refresh)
    const userNav :Nav | undefined = NavStore(store => store.contextNavs['user'])
    const clearToken :() => void = SessionStore(state => state.clearToken)
    const sessionEntity :UserEntity | ApiEntity | undefined = SessionStore(state => state.entity)
    if (!pageNav || !userNav) return null

    const user :UserEntity | undefined = pageNav.context['user'] as UserEntity

    if (!user) return null

    const onNewApiSuccess = (response: Nav, req? :DataPayload) => {
        setNewApi(response.data.entity)
    }

    const closeNewApi = () => {
        setNewApi(undefined)
        refreshPage()
    }

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <EmailConfirmationCallout/>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {`${user.firstName} ${user.lastName}`}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                            <GenericActionButtons nav={userNav} contextKey={'user'} onSuccess={() => {navigate('/')}} toggleIcon={'gear'} buttonSize={'s'} buttonTitle={actionTitles}/>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>
                    {sessionEntity && sessionEntity.linkTo === user.linkTo && <Button iconType={'exit'} onClick={clearToken} color={'accent'} size={'s'} fill={false}>Logout</Button>}
                </FlexItem>
            </FlexGroup>

            <Spacer size={'m'}/>
            <NavResourceTabs nav={userNav} tab={tabKey} ignore={['email-confirmation', 'password-reset']}>
                <NavDetailsTab linkTo={user.linkTo} isSelected={(!tabKey)} icon={'user'}/>
            </NavResourceTabs>
        </PageSection>
        <PageSection color={'plain'} alignment={'top'} paddingSize={'none'}>
        {tabKey ?
            <NavResourceTabContent nav={pageNav} navKey={tabKey} onSuccess={(tabKey === apiKey) ? onNewApiSuccess : undefined}/> :
            <NavDataEntity configKey={'user'}/>
        }
        </PageSection>
        { newApi && <ApiModalDisplayNew apiEntity={newApi} onClose={closeNewApi}/> }
    </>
}

export default UserPageContent