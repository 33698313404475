import React from 'react'
import { EuiHeader } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
    dark? :boolean
    top? :number
    boxShadow?: boolean
}

const Header = ({ dark, top, boxShadow = false, children }: Props) => {
    const style: any = {}
    if (!boxShadow) style.boxShadow = 'none'
    if (!dark) style.background = '#F7F8FC'
    return <EuiHeader theme={(dark) ? 'dark' : 'default'} style={style}>{children}</EuiHeader>
}

export default Header