import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiLink, ComponentEuiSideNav } from 'Component'
import { CSSProperties, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    title :string
    href :string
    name :string
    right? :ReactNode
    isActive? :boolean
}

const ComponentPageSidebarEntity = ({ title, href, name, right, isActive } :Props) => {

    const style :CSSProperties = (isActive) ? {
        color: '#006bb8',
        fontWeight: 700,
        textDecoration: 'underline'
    } : {}

    const navigate = useNavigate()

    const onClick = () => navigate(href)

    return <ComponentEuiSideNav items={[{
        id: 'title', name: title, items: [
            {
                id: title,
                name: <ComponentEuiLink color={'text'} onClick={onClick} fullWidth>
                    <ComponentEuiFlexGroup alignItems={'baseline'} justifyContent={'spaceBetween'} style={style}>
                        <ComponentEuiFlexItem>{name}</ComponentEuiFlexItem>
                        { right && <ComponentEuiFlexItem>
                            {right}
                        </ComponentEuiFlexItem> }
                    </ComponentEuiFlexGroup>
                </ComponentEuiLink>
            }]
    }]}/>
}

export default ComponentPageSidebarEntity