import { ChildEntity } from 'entity'
import { useNavigate } from 'react-router-dom'


const NavigateToChildRow = () :(child :ChildEntity) => void => {

    const navigate = useNavigate()

    return async (child :ChildEntity) => {
        console.log('navigating', child.linkTo)
        navigate(`${child.linkTo}/row`)
    }
}

export default NavigateToChildRow