import { FlexGroup, FlexItem } from 'eui'
import { ReactNode, useEffect, useState } from 'react'
import { CollapsedStore } from 'store'

import './CollapsibleSideBarItem.css'

type Props = {
    fixed? :ReactNode
    forceCollapsed? :boolean
    gutter? :number
    children? :ReactNode
}

const CollapsibleSideBarItem = ({fixed, forceCollapsed, gutter = 0, children} :Props) => {

    const isCollapsed :boolean = (forceCollapsed === undefined) ? CollapsedStore(store => store.isCollapsed) : forceCollapsed

    const [showChildren, setShowChildren] = useState<boolean>(!isCollapsed)

    useEffect(() => {
        if (isCollapsed && showChildren) {
            setShowChildren(false)
        }

        if (!isCollapsed) {
            setShowChildren(false)
            setTimeout(() => {
                setShowChildren(true)
            }, 300)
        }

    }, [isCollapsed])

    return <FlexGroup gutterSize={'none'} alignItems={'baseline'}>
        {(fixed) ? <FlexItem className={'fixed-side-bar-item'}>
            {fixed}
        </FlexItem> : null}
        <FlexItem className={'collapsible-side-bar-item'} grow>
            <div style={{paddingLeft: (isCollapsed) ? 0 : `${gutter}px`, width: (isCollapsed) ? 0 : 'auto', height: (isCollapsed) ? 0 : 'auto'}}>
                {(showChildren) ? children : null}
            </div>
        </FlexItem>
    </FlexGroup>
}

export default CollapsibleSideBarItem