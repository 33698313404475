import { EuiPage } from '@elastic/eui'
import { useIsLocationHome } from 'hooks'
import { Children } from 'props'
import {useLocation, useNavigate } from 'react-router-dom'
import { RouteUtils } from 'utils'

type Props = Children & {
    paddingSize? : 's' | 'none' | 'm' | 'xs' | 'l' | 'xl'
    grow? : boolean
    direction? : 'row' | 'column'
    restrictWidth? : string | number | boolean
}

const Page = ({paddingSize, grow, direction, restrictWidth, children} :Props) => {

    const location = useLocation()

    const breadcrumbs :boolean = !useIsLocationHome() && !RouteUtils.isStatic(location.pathname)

    const headerHeight :number = 48 * (breadcrumbs ? 2 : 1)

    return <EuiPage style={{minHeight: `calc(100% - ${headerHeight}px)`, width: '100%'}} paddingSize={paddingSize} grow={grow} direction={direction}
             restrictWidth={restrictWidth}>{children}</EuiPage>

}
export default Page