import { EuiToolTip } from '@elastic/eui'
import {ReactElement, ReactNode } from 'react'

type Props = {
    content? :ReactNode
    display? :'inlineBlock' | 'block'
    delay? :'regular' | 'long'
    title? :ReactNode
    position? :'left' | 'right' | 'top' | 'bottom'
    onMouseOut? :(event :any) => void
    children :ReactElement
}

const ToolTip = ({content, display, delay, title, position, onMouseOut, children} :Props) =>
    <EuiToolTip content={content} display={display} delay={delay} title={title} position={position} onMouseOut={onMouseOut}>{ children }</EuiToolTip>

export default ToolTip