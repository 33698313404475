import {TableRowDisplay} from 'modules'
import {Nav, NavKey, NavControlResource} from 'types'
import {NavStore} from 'store'
import {TableVersionColumnEntity} from 'entity'
import {useEffect, useState} from 'react'
import {NavClient} from 'utils'
import {PageSection} from 'eui';

const versionKey :NavKey = 'version'
const rowKey :NavKey = 'row'
const columnKey :NavKey = 'column'

const RowContentRowDisplay = () => {

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const columnResource :NavControlResource | undefined = NavStore(store => store.contextNavs[versionKey]?.control.resource[columnKey])
    const rowEntity :any | undefined = pageNav?.context[rowKey]

    const [parentColumns, setParentColumns] = useState<TableVersionColumnEntity[] | undefined>()

    const fetchParentColumns = async () => {
        if (columnResource) {
            const columnNav :Nav = await NavClient.fetchAll(columnResource.linkTo)
            setParentColumns(columnNav?.data.page?.content)
        }
    }

    useEffect(() => {
        fetchParentColumns()
    },[columnResource])

    if (!rowEntity || !parentColumns) return null

    return <PageSection paddingSize={'none'}>
        <div style={{margin: '-1px -1px'}}>
            <TableRowDisplay rowEntity={rowEntity} columns={parentColumns} />
        </div>
    </PageSection>
}

export default RowContentRowDisplay