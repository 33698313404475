import {HookNavActionAuto, HookNavContext, HookNavGet, HookNavPage, HookNavResource, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import {PageVersionBody, PageVersionSidebar } from 'Page'
import {useEffect, useState} from 'react'
import {TypeEntityConnection, TypeEntityInterfaceMap, TypeEntityRequester, TypeEntityTable, TypeEntityTableVersion,
    TypeEntityTableVersionColumn, TypeHttpLoadable, TypeHttpPayload, TypeNav, TypeNavControlResource, TypeNavHttpControl,
    TypeNavHttpControlActionAuto, TypeUrlParam } from 'Type'

const sortColumnsBy :(keyof TypeEntityTableVersionColumn)[] = ['orderIndex']

const isConnected :(keyof TypeEntityConnection) = 'isConnected'

const PageVersion = () => {

    //page
    const pageControl :TypeNavHttpControl<TypeEntityTableVersion> = HookNavPage<TypeEntityTableVersion>()
    const pageNav :TypeNav<TypeEntityTableVersion> | undefined = pageControl.res?.data

    //row
    const rowResource :TypeNavControlResource | undefined = HookNavResource('row', pageNav)

    //columns
    const columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn> = HookNavResourceNav({key: 'column', nav: pageNav, paramUpdates: [{type: 'sortField', values: sortColumnsBy}]})
    const refControl :TypeNavHttpControl<TypeEntityTableVersionColumn> = HookNavResourceNav({key: 'ref', nav: pageNav})

    //context
    const table :TypeEntityTable | undefined = HookNavContext('table', pageNav)

    //req
    const reqControl :TypeNavHttpControl<TypeEntityRequester> = HookNavResourceNav({key: 'req', nav: pageNav})
    const reqNav :TypeNav<TypeEntityRequester> | undefined = reqControl.res?.data
    const req :TypeEntityRequester | undefined = reqNav?.data.entity

    //conn
    const [isConnectedOnly, setIsConnectedOnly] = useState<boolean>(true)
    const connectionParams :TypeUrlParam[] = (isConnectedOnly === undefined) ? [] : (isConnectedOnly) ? [{type: 'filter-*', dynamic: [isConnected], values: ['true']}] : [{type: 'filter-*', dynamic: [isConnected], values: ['false']}]
    const connControl :TypeNavHttpControl<TypeEntityConnection> = HookNavGet<TypeEntityConnection>({linkTo: req, resource: 'conn', paramUpdates: connectionParams})

    //map
    const mapControl :TypeNavHttpControl<TypeEntityInterfaceMap> = HookNavGet<TypeEntityInterfaceMap>({linkTo: req, resource: 'map'})
    const mapNav :TypeNav<TypeEntityInterfaceMap> | undefined = mapControl.res?.data
    const addMapControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMap> = HookNavActionAuto('post', mapNav)

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Page', control: pageControl},
        {label: 'Columns', control: columnControl},
        {label: 'References', control: refControl},
        {label: 'Requester', control: reqControl},
    ]

    if (!req || req.linkTo) {
        toLoad.push({label: 'Map Maps', control: mapControl})
        toLoad.push({label: 'Connections', control: connControl})
    }

    const sidebar = rowResource && <PageVersionSidebar pageControl={pageControl} rowResource={rowResource}/>
    const body = table && pageNav && req && <PageVersionBody table={table} columnControl={columnControl} connectionControl={connControl} req={req} refControl={refControl}
      reqControl={reqControl} mapControl={mapControl} versionNav={pageNav} setIsConnectedOnly={setIsConnectedOnly} isConnectedOnly={isConnectedOnly} addMapControl={addMapControl}/>

    return <ModulePage control={pageControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={pageControl}>{body}</ModulePage>

}

export default PageVersion