import { Location } from 'react-router-dom'
import {TypeNav, TypeUrlParam, TypeUrlParamType } from 'Type'
import { UtilString } from 'Util'

const PARAM_KEY_DYNAMIC_DELIMITER :string = '-'
const PARAM_KEY_DYNAMIC_PLACEHOLDER :string = '*'

const updateSearchHistory = (urlSearchParams :URLSearchParams) :void => {
    window.history.replaceState(null, '', '?'+urlSearchParams.toString());
}

const findSearchParamAllBeginsWith = (searchParams :URLSearchParams, paramBeginsWith :string) :string[] => {
    const list :string[] = []
    for (const paramKey of searchParams.keys()) if (paramKey.startsWith(paramBeginsWith)) list.push(paramKey)
    return [... new Set(list)]
}

const paramBeginsWith = (paramType :TypeUrlParamType) :string => paramType.split(PARAM_KEY_DYNAMIC_DELIMITER)[0]

const extractDynamic = (param :string) :string[] => {
    return param.split(PARAM_KEY_DYNAMIC_DELIMITER).slice(1)
}

const getAllParams = (url :URL, paramType :TypeUrlParamType) :TypeUrlParam[] => {
    const buildParam = (param :string) :TypeUrlParam => ({
        type: paramType,
        dynamic :extractDynamic(param),
        values :url.searchParams.getAll(param)
    })
    return findSearchParamAllBeginsWith(url.searchParams, paramBeginsWith(paramType)).map(buildParam)
}

const checkIsValid = (param :TypeUrlParam) => { if (param.dynamic && UtilString.countCharsIn(param.type, PARAM_KEY_DYNAMIC_PLACEHOLDER) !== param.dynamic.length) throw Error('Dynamic param mismatch') }

type ParamData = {
    key :string,
    values :string[]
}

const buildKey = (param :TypeUrlParam) :string => {
    let key :string = param.type
    if (!param.dynamic) return key
    param.dynamic.forEach((dynamicField :string) => {
        key = key.replace(PARAM_KEY_DYNAMIC_PLACEHOLDER, dynamicField)
    })
    return key
}

const buildParam = (param :TypeUrlParam) :ParamData => {
    checkIsValid(param)
    return {
        key: buildKey(param),
        values: param.values
    }
}

const appendParamFn = (url :URL) => (paramData :ParamData) => paramData.values.forEach((paramValue :string) => url.searchParams.append(paramData.key, paramValue))

const setAllParams = (url :URL, params :TypeUrlParam[]) :void => {
    params.forEach((param) => removeParam(url, param))
    appendAllParams(url, params)
}

const appendAllParams = (url :URL, params :TypeUrlParam[]) :void => params.map(buildParam).forEach(appendParamFn(url))

const removeParam = (url :URL, param :TypeUrlParam) :void => {
    const paramData :ParamData = buildParam(param)
    url.searchParams.delete(paramData.key)
}

const removeParamValue = (url :URL, param :TypeUrlParam) :void => {
    const paramData :ParamData = buildParam(param)
    const valuesSet :Set<string> = new Set(url.searchParams.getAll(paramData.key))
    param.values.forEach((toDelete :string) => valuesSet.delete(toDelete))
    url.searchParams.delete(paramData.key)
    valuesSet.forEach((valueToSet :string) => url.searchParams.append(paramData.key, valueToSet))
}

const getPath = (url :URL) :string => url.href.replace(url.origin, '')

const buildUrlObject = (backendUrl :string, path :string) :URL =>
    new URL(`${UtilString.removeTrailingChars(backendUrl, '/')}${UtilString.addLeadingChars(path, '/')}`)

const getPageUrl :() => URL = () => new URL(window.location.href)

const UtilParam = {
    url: (backendUrl :string, path? :string) => {
        const pageUrl :URL = buildUrlObject(backendUrl, path ?? '/')
        return {
            getAllParams: (paramType: TypeUrlParamType) :TypeUrlParam[] => getAllParams(pageUrl, paramType),
            setAllParams: (params: TypeUrlParam[]) :void => setAllParams(pageUrl, params),
            removeParam: (param :TypeUrlParam) :void => removeParam(pageUrl, param),
            removeParamValue: (param :TypeUrlParam) :void => removeParamValue(pageUrl, param),
            getPath: () :string => getPath(pageUrl),
            toString: () :string => pageUrl.toString()
        }
    },
    page: (location :Location) => {
        const pageUrl :URL = getPageUrl()
        return {
            getAllParams: (paramType: TypeUrlParamType): TypeUrlParam[] => getAllParams(pageUrl, paramType),
            setAllParams: (params: TypeUrlParam[]): void => setAllParams(pageUrl, params),
            removeParam: (param :TypeUrlParam) :void => removeParam(pageUrl, param),
            removeParamValue: (param :TypeUrlParam) :void => removeParamValue(pageUrl, param),
            appendAllParams: (params: TypeUrlParam[]): void => appendAllParams(pageUrl, params),
            getPath: (): string => getPath(pageUrl),
            updateSearchHistory: () => updateSearchHistory(pageUrl.searchParams)
        }
    },
    nav: (nav? :TypeNav<any>) => {
        const pageUrl :URL = new URL(nav?.path ?? '/', window.location.origin)
        return {
            getAllParams: (paramType :TypeUrlParamType) :TypeUrlParam[] => getAllParams(pageUrl, paramType),
            setAllParams: (params :TypeUrlParam[]) :void => setAllParams(pageUrl, params),
            removeParam: (param :TypeUrlParam) :void => removeParam(pageUrl, param),
            removeParamValue: (param :TypeUrlParam) :void => removeParamValue(pageUrl, param),
            appendAllParams: (params: TypeUrlParam[]): void => appendAllParams(pageUrl, params),
            getPath: (): string => getPath(pageUrl)
        }
    },
    defaultPageParam: (param :TypeUrlParam) => {
        const pageUrl :URL = getPageUrl()
        if (getAllParams(getPageUrl(), param.type).length === 0) {
            setAllParams(pageUrl, [param])
            updateSearchHistory(pageUrl.searchParams)
        }
    }
}

export default UtilParam