import {NavKey} from 'types'
import {Nav} from 'types'
import {FlexGroup, FlexItem, LoadingSpinner, Spacer} from "../../eui";
import {GenericActionButtons} from "../index";
import {NavControlResource, NavHookControl} from "../../types";
import {useNav} from "../../hooks";
import {useEffect} from "react";
import {NavModalTable} from "components";

type Props = {
    navResource: NavControlResource
    contextKey: NavKey
}

const ModalResourceTablePage = ({navResource, contextKey} :Props) => {

    const navControl :NavHookControl = useNav()

    useEffect(() => {navControl.fetch(navResource.linkTo)}, [navResource.linkTo])

    if (!navControl.nav) return <LoadingSpinner/>

    const nav :Nav = navControl.nav

    return <>
        <FlexGroup justifyContent={'spaceBetween'}>
            <FlexItem>
                <p style={{color: '#69707D'}}>{nav.meta.description}</p>
                <Spacer/>
            </FlexItem>
            <FlexItem>
                <GenericActionButtons nav={nav} contextKey={contextKey} button={'empty'} buttonSize={'s'} onSuccess={navControl.refresh}/>
            </FlexItem>
        </FlexGroup>
        <Spacer/>
        <NavModalTable nav={nav} navKey={contextKey} navigate={'control'} onSuccess={navControl.refresh}/>
    </>
}

export default ModalResourceTablePage