import {ComponentEuiButtonEmpty, ComponentEuiListGroup, ComponentEuiListGroupItem, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { TypeControlSwitch } from 'Type'

type Props = {
    value :string
    onChange :(value :string) => void
    searchKeys :string[]
}

const ComponentNavFormFieldSearchSelect = ({ value, onChange, searchKeys } :Props) => {
    const control :TypeControlSwitch = HookControlSwitch()

    const onChangeFn = (newKey :string) => () => onChange(newKey)

    if (searchKeys.length < 2) return null

    return <ComponentEuiPopover
        className={'euiFormControlLayout__prepend'}
        button={<ComponentEuiButtonEmpty size={'s'} iconType={'arrowDown'} iconSide={'right'} onClick={control.turnOn}> {value}
        </ComponentEuiButtonEmpty>}
        closePopover={control.turnOff} isOpen={control.value} panelPaddingSize={'s'}>
        <div style={{maxHeight: 600, overflow: 'auto', width: 400}}>
            <ComponentEuiListGroup>
                {searchKeys.map((searchKey :string) => {
                    return <ComponentEuiListGroupItem key={searchKey} label={searchKey} size={'s'} onClick={onChangeFn(searchKey)}/>
                })}
            </ComponentEuiListGroup>
        </div>
    </ComponentEuiPopover>
}

export default ComponentNavFormFieldSearchSelect