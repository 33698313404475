import { AuditDisplay } from 'display';
import DescriptionDisplay from 'display/parts/DescriptionDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import { TableVersionEntity } from 'entity';
import { DisplayConfig } from 'types';

const config :DisplayConfig<TableVersionEntity>[] = [
    ...DescriptionDisplay,
    {field: 'versionNumber', name: 'Version Number'},
    {field: 'isRetired', name: 'Is Retired'},
    ...AuditDisplay]

export default config