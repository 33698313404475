import {Panel} from 'eui';
import {DisplayConfig, NavKey,} from 'types';
import {MappingUtils} from 'utils';

type Props = {
    panel? :boolean
    entity :any,
    displayKey :NavKey,
    ignore :string[]
}

const CellContextMenuDetailsContent = ({panel = true, entity, displayKey, ignore} :Props) => {

    const content = MappingUtils.contextDisplayConfig(displayKey)
        .filter((dc :DisplayConfig) => !ignore.includes(dc.field))
        .map((dc :DisplayConfig) => {
            const fieldValue :any = entity[dc.field]
            return <div key={dc.field} style={{minWidth: '100%', padding: '2px 2px 0px 2px'}}>
                <div style={{fontWeight: 700, fontSize: '12px', lineHeight: '16px', marginBottom: '4px'}}>{dc.name}</div>
                <div style={{minHeight: '40px', fontSize: '16px', lineHeight: '20px', backgroundColor: '#FAFBFD', borderRadius: '6px', padding: '10px', color: '#6a788f'}}>{(dc.render && dc.render(fieldValue)) ?? fieldValue}</div>
            </div>
        })

    return (panel) ? <Panel paddingSize={'s'}>
        {content}
    </Panel> : <>{content}</>
}

export default CellContextMenuDetailsContent