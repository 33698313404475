import {PropsWithChildren} from 'react'


type Props = {
    padding :number
}

const ComponentDivPadding = ({ padding, children } :PropsWithChildren<Props>) => {
    return <div style={{padding: `${padding}px`}}>{children}</div>
}

export default ComponentDivPadding