import { FlexGroup, FlexItem, Icon, LoadingSpinner, ToolTip } from 'eui'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavControlResource } from 'types'

type Props = {
    resource :NavControlResource
}

const wrap = (node :ReactNode) :ReactNode => {
    return <div style={{margin: '6px 8px 6px 8px', color: '#69707D'}}>{node}</div>
}

const wrapDisabled = (node :ReactNode, disabled :string | undefined) :ReactNode => {
    return <ToolTip content={disabled}><div style={{margin: '6px 8px 6px 8px', color: '#b7bbc2', cursor: 'not-allowed'}}>{node}</div></ToolTip>
}

const item = (resource :NavControlResource) :ReactNode => {
    return <Link to={resource.linkTo}>
        <FlexGroup justifyContent={'spaceBetween'} gutterSize={'m'}>
            <FlexItem>{wrap(resource.key)}</FlexItem>
            <FlexItem>{wrap(<Icon type={'arrowRight'}/>)}</FlexItem>
        </FlexGroup>
    </Link>
}

const disabledItem = (resource :NavControlResource, disabled :string | undefined) :ReactNode => {
    return <FlexGroup justifyContent={'spaceBetween'} gutterSize={'m'}>
        <FlexItem>{wrapDisabled(resource.key, disabled)}</FlexItem>
        <FlexItem>{wrapDisabled(<Icon type={'cross'}/>, disabled)}</FlexItem>
    </FlexGroup>
}

const NavResourceSideNavItem = ({resource} :Props) => {

    const disabled :string | undefined = (resource.auth.authorized) ? undefined : resource.auth.message

    return <div key={resource.key} className={'resource-list-item'}>
        {(disabled) ? disabledItem(resource, disabled) : item(resource)}
    </div>
}

export default NavResourceSideNavItem