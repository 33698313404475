import {DestinationEntity, TransformationEntity, TransformationExcludeEntity, TransformationHeaderEntity, TransformationHeaderErrorEntity } from 'entity';
import {DataPayload, Nav, NavControlResource, NavKey, NavMap, PromiseNavMap } from 'types';
import { NavClient } from 'utils';
import create from 'zustand';

type Store = {
    transformationNav? :Nav
    transformation? :TransformationEntity
    headerNav? :Nav
    headerMatchNavMap? :HeaderErrorNavMap
    outputNav? :Nav
    excludeNav? :Nav
    excludedRowIds :number[]
    loadNav? :Nav
    destinationNav? :Nav
    destinationRowCount? :number
    loading :boolean
    load :(transformation :TransformationEntity) => void
    refreshStageProgress: (transformation? :TransformationEntity, headerNav? :Nav, loadNav? :Nav) => void
    setRowHeader : (rowIndex :number | undefined) => void
    setRowExclude: (rowIndex :number | undefined) => void
    patchHeaderMatch :(destinationColumnNameSlug :string, field: 'column' | 'defaultValue' | 'toSave', value :string) => void
    fixError :(destinationColumnNameSlug :string, errorNav :Nav, fixValue :string) => void
    refresh: () => void
    clear: () => void
    stageProgress: Record<Stages, Progress>
}

const headerKey :NavKey = 'header'
const outputKey :NavKey = 'output'
const excludeKey :NavKey = 'exclude'
const errorKey :NavKey = 'error'
const loadKey :NavKey = 'load'
const destinationKey :NavKey = 'destination'

type Stages = '#header' | '#match' | '#exclude' | '#fix' | '#load'

type Progress = 'check' | 'asterisk' | 'empty'

type HeaderErrorNav = {
    header :Nav
    error? :Nav
}

type HeaderErrorNavMap = {[key :string] :HeaderErrorNav}



const fetchHeaderMatchNavs = async (headerNav :Nav) :Promise<HeaderErrorNavMap> => {
    const headerPromiseMap :PromiseNavMap = {}
    const headers : TransformationHeaderEntity[] = ((headerNav.data.page && headerNav.data.page.content) || [])
    headers.forEach((th :TransformationHeaderEntity) => {
        headerPromiseMap[th.destinationColumnNameSlug] = NavClient.fetchNav(th.linkTo)
    })

    const resp :HeaderErrorNavMap = {}
    const errorPromiseMap :PromiseNavMap = {}

    const keys :string[] = Object.keys(headerPromiseMap);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        const headerNav :Nav = await headerPromiseMap[key]
        const errorResource :NavControlResource | undefined = headerNav.control.resource[errorKey]
        if (errorResource && errorResource.auth.authorized) {
            errorPromiseMap[key] = NavClient.fetchAll(errorResource.linkTo)
        }
        resp[key] = {header: headerNav}
    }

    const errorKeys :string[] = Object.keys(errorPromiseMap)
    for (let i = 0; i < errorKeys.length; i++) {
        const key = errorKeys[i]
        resp[key].error = await errorPromiseMap[key]
    }

    return resp
}

const buildExcludedRows = (excludeNav :Nav) :TransformationExcludeEntity[] => {
    return (excludeNav && excludeNav.data.page && excludeNav.data.page.content) || []
}

const buildExcludedRowIds = (excludeNav :Nav) :number[] => {
    return buildExcludedRows(excludeNav).map((r :TransformationExcludeEntity) => r.sheetRowIndex)
}

const findByRowIndex = (excluded :TransformationExcludeEntity[], rowIndex :number) :TransformationExcludeEntity | undefined => {
    for (let i = 0; i < excluded.length; i++) {
        const e: TransformationExcludeEntity = excluded[i]
        if (e.sheetRowIndex === rowIndex) return e
    }
    return undefined
}

const headerMatchingNeeded = (headers :TransformationHeaderEntity[]) :boolean => {
    for (let i = 0; i < headers.length; i++) {
        const header :TransformationHeaderEntity = headers[i]
        if (header.destinationIsRequired && !header.extractColumn && !header.defaultValue) return true
    }
    return false
}

const initStageProgress :Record<Stages, Progress> = {
    '#header' : 'empty',
    '#match' : 'empty',
    '#exclude' : 'empty',
    '#fix' : 'empty',
    '#load' : 'empty',
}

const TransformationStore = create<Store>((set, get) => ({
    transformationNav: undefined,
    transformation: undefined,
    headerNav: undefined,
    headerMatchNavMap: undefined,
    outputNav: undefined,
    excludeNav: undefined,
    excludedRowIds: [],
    loadNav: undefined,
    destinationNav: undefined,
    destinationRowCount: undefined,
    loading: false,
    stageProgress : initStageProgress,
    refreshStageProgress: (transformation? :TransformationEntity, headerNav? :Nav, loadNav? :Nav) => {
        const stageProgress :Record<Stages, Progress> = initStageProgress
        if ((loadNav?.data.page?.totalElements ?? 0) > 0) {
            stageProgress["#header"] = (transformation && transformation.headerRowIndex !== undefined) ? 'check' : 'empty'
            const headers :TransformationHeaderEntity[] = (headerNav && headerNav.data.page && headerNav.data.page.content) || []
            stageProgress['#match'] = (headerMatchingNeeded(headers)) ? 'asterisk' : 'check'
            stageProgress['#fix'] = (loadNav && loadNav.control.action['post'] && loadNav.control.action['post'].auth.authorized) ? 'check' : 'asterisk'
        }
        set({stageProgress})
    },
    load :async (newTransformation :TransformationEntity) => {
        const currentTransformation :TransformationEntity | undefined = get().transformation
        const currentLoading :boolean = get().loading
        if (currentTransformation && currentTransformation.linkTo === newTransformation.linkTo) return
        set({transformation: newTransformation, loading: true})
        const transformationNav :Nav = await NavClient.fetchNav(newTransformation.linkTo)
        const resourceNavs :NavMap = await NavClient.fetchNavResourceNavs(transformationNav, {header : true, exclude : true})
        const headerNav :Nav | undefined = resourceNavs[headerKey]
        const headerMatchNavMap :HeaderErrorNavMap | undefined = headerNav && await fetchHeaderMatchNavs(headerNav)
        const excludeNav :Nav | undefined = resourceNavs[excludeKey]
        const excludedRowIds :number[] = buildExcludedRowIds(excludeNav)
        const outputNav :Nav | undefined = resourceNavs[outputKey]
        const loadNav :Nav | undefined = resourceNavs[loadKey]
        const destinationNav :Nav | undefined = resourceNavs[destinationKey]
        const destination :DestinationEntity | undefined = destinationNav?.data.entity
        const destinationRowCount :number | undefined = destination && await NavClient.fetchRowCount(destination.linkTo)
        set({transformationNav, headerNav, headerMatchNavMap, excludeNav, excludedRowIds, outputNav, loadNav, destinationNav, destinationRowCount, loading: false})
        get().refreshStageProgress(newTransformation, headerNav, loadNav)
    },
    setRowHeader: async (rowIndex :number | undefined) => {
        const currentTransformation :TransformationEntity | undefined = get().transformation
        const currentTransformationNav :Nav | undefined = get().transformationNav
        const currentOutputNav :Nav | undefined = get().outputNav
        const currentLoadNav :Nav | undefined = get().loadNav
        const currentHeaderNav :Nav | undefined = get().headerNav
        const isComplete :boolean = (currentLoadNav?.data.page?.totalElements ?? 0) > 0
        if (isComplete || !currentTransformation || !currentTransformationNav || !currentOutputNav || !currentLoadNav || !currentHeaderNav) return
        const data :DataPayload = {}
        data['headerRowIndex'] = (currentTransformation.headerRowIndex === rowIndex) ? 'null' : `${rowIndex}`
        const transformationNav :Nav = await NavClient.action(currentTransformationNav, 'patch', data)
        const transformation :TransformationEntity = transformationNav.data.entity
        const updated :NavMap = await NavClient.refresh({outputNav: currentOutputNav, loadNav: currentLoadNav})
        const outputNav = updated['outputNav']
        const loadNav = updated['loadNav']
        const headerMatchNavMap :HeaderErrorNavMap = await fetchHeaderMatchNavs(currentHeaderNav)
        set({outputNav, loadNav, transformationNav, transformation, headerMatchNavMap})
        get().refreshStageProgress(transformation, undefined, loadNav)
    },
    setRowExclude: async (rowIndex :number | undefined) => {
        const currentTransformation :TransformationEntity | undefined = get().transformation
        const currentExcludeNav :Nav | undefined = get().excludeNav
        const currentExcludedRowIds :number[] = get().excludedRowIds
        const currentExcludedRows :TransformationExcludeEntity[] = (currentExcludeNav) ? buildExcludedRows(currentExcludeNav) : []
        const currentOutputNav :Nav | undefined = get().outputNav
        const currentLoadNav :Nav | undefined = get().loadNav
        const isComplete :boolean = (currentLoadNav?.data.page?.totalElements ?? 0) > 0
        if (isComplete || !rowIndex || !currentTransformation || !currentExcludeNav || !currentOutputNav || !currentLoadNav) return
        if (currentTransformation.headerRowIndex && rowIndex <= currentTransformation.headerRowIndex) return

        const existingExcluded :TransformationExcludeEntity | undefined = findByRowIndex(currentExcludedRows, rowIndex)

        let respNav :Nav | undefined = undefined

        const refreshExcluded = async () => {
            if (respNav && !respNav.error) {
                const updated :NavMap = await NavClient.refresh({excludeNav: currentExcludeNav, outputNav: currentOutputNav, loadNav: currentLoadNav}, {excludeNav: true})
                const excludeNav = updated['excludeNav']
                const outputNav = updated['outputNav']
                const loadNav = updated['loadNav']

                set({excludeNav, outputNav, loadNav, excludedRowIds: buildExcludedRowIds(excludeNav)})
                get().refreshStageProgress(currentTransformation, undefined, loadNav)
            }
        }

        if (existingExcluded) {
            respNav = await NavClient.dangerAction(existingExcluded.linkTo, 'delete')
        } else {
            const data :DataPayload = {}
            data['sheetRowIndex'] = `${rowIndex}`
            respNav = await NavClient.action(currentExcludeNav, 'post', data)
        }
        refreshExcluded()
    },
    patchHeaderMatch: async (destinationColumnNameSlug :string, field: 'column' | 'defaultValue' | 'toSave', value :string) :Promise<Nav | undefined> => {
        const currentHeaderMatchNavMap :HeaderErrorNavMap | undefined = get().headerMatchNavMap
        const currentHeaderNav :Nav | undefined = get().headerNav
        const currentOutputNav :Nav | undefined = get().outputNav
        const currentLoadNav :Nav | undefined = get().loadNav
        const headerMatchNav :HeaderErrorNav | undefined = currentHeaderMatchNavMap && currentHeaderMatchNavMap[destinationColumnNameSlug]
        const headerErrorResource :NavControlResource | undefined = headerMatchNav && headerMatchNav.header.control.resource[errorKey]
        const isComplete :boolean = (currentLoadNav?.data.page?.totalElements ?? 0) > 0
        if (isComplete || !headerMatchNav || !currentHeaderNav || !headerErrorResource || !currentOutputNav || !currentLoadNav) return
        const data :DataPayload = {}
        data[field] = value
        const respNav :Nav = await NavClient.action(headerMatchNav.header, 'patch', data)
        if (!respNav.error) {
            const headerMatchNavMap :HeaderErrorNavMap = {...currentHeaderMatchNavMap}
            headerMatchNavMap[destinationColumnNameSlug].header = await NavClient.fetchNav(headerMatchNav.header.path)
            if (headerErrorResource.auth.authorized) headerMatchNavMap[destinationColumnNameSlug].error = await NavClient.fetchNav(headerErrorResource.linkTo)
            const headerNav :Nav = await NavClient.fetchAll(currentHeaderNav.path)
            const refreshed :NavMap = await NavClient.refresh({outputNav: currentOutputNav, loadNav: currentLoadNav})
            const outputNav :Nav = refreshed['outputNav']
            const loadNav :Nav = refreshed['loadNav']
            set({outputNav, loadNav, headerMatchNavMap, headerNav})
            get().refreshStageProgress(undefined, headerNav, loadNav)
        }
        return respNav
    },
    fixError: async (destinationColumnNameSlug :string, errorNav :Nav, fixValue :string) => {
        const updatedErrorNav :Nav = await NavClient.action(errorNav, 'patch', {fixValue})
        const currentHeaderMatchNavMap :HeaderErrorNavMap | undefined = get().headerMatchNavMap
        const currentLoadNav :Nav | undefined = get().loadNav
        const isComplete :boolean = (currentLoadNav?.data.page?.totalElements ?? 0) > 0
        if (isComplete) return
        const headerErrorNav :Nav | undefined = currentHeaderMatchNavMap && currentHeaderMatchNavMap[destinationColumnNameSlug] &&currentHeaderMatchNavMap[destinationColumnNameSlug].error
        const refreshedHeaderErrorNav :Nav | undefined = headerErrorNav && await NavClient.fetchAll(headerErrorNav.path)
        const headerMatchNavMap :HeaderErrorNavMap = {...currentHeaderMatchNavMap}
        if (headerMatchNavMap[destinationColumnNameSlug]) headerMatchNavMap[destinationColumnNameSlug].error = refreshedHeaderErrorNav
        const loadNav :Nav | undefined = (currentLoadNav) ? await NavClient.fetchNav(currentLoadNav.path) : undefined
        set({headerMatchNavMap, loadNav})
        get().refreshStageProgress(undefined, undefined, loadNav)
    },
    refresh :() => {
        const transformationEntity :TransformationEntity | undefined = get().transformation
        if (!transformationEntity) return
        get().clear()
        get().load(transformationEntity)
    },
    clear :() => {
        set({transformation: undefined, transformationNav: undefined, headerNav: undefined, headerMatchNavMap: undefined, excludeNav: undefined, outputNav: undefined, loadNav: undefined, loading: false})
        get().refreshStageProgress()
    }
}))

export default TransformationStore