import { DisplayConfig } from '../../types';

const config :DisplayConfig[] = [
    {
        field: 'name',
        name: 'Name',
        sortable: true
    },
]

export default config