import {BasicTable, ButtonEmpty, FlexGroup, FlexItem, Panel} from "../../eui";
import {CellEntity, ExtractEntity, TableVersionColumnEntity} from "../../entity";
import {CellValueRendererConfig} from "../../config";
import {DisplayConfig, Nav, NavControlResource, NavKey} from "../../types";
import {CellArchiveTable, CellContextMenuDetailsContent, GenericActionButtons} from "../index";
import {MappingUtils, NavClient} from "../../utils";
import {NavTableNavPagination} from "../../components";
import {ReactNode, useEffect, useState} from "react";
import {LoadingPage} from "../../pages";
import {useNavigate} from "react-router-dom";

type Props = {
    cellNav: Nav
    column: TableVersionColumnEntity
    cellEntity: CellEntity
    rowId :number
}

const archiveKey :NavKey = 'archive'
const linkTo :string = 'linkTo'

const CellContextMenuArchiveContent = ({cellNav, column, cellEntity, rowId} :Props) => {

    const navigate = useNavigate()

    const [archiveNav, setArchiveNav] = useState<Nav | undefined>()
    const [expandedArchiveNav, setExpandedArchiveNav] = useState<Nav | undefined>()
    const [expandedExtract, setExpandedExtract] = useState<ExtractEntity | undefined>()

    useEffect(() => {
        fetchArchiveNav()
    }, [cellNav])

    const fetchArchiveNav = async () => {
        const archiveResource :NavControlResource | undefined = cellNav.control.resource[archiveKey]
        if (archiveResource) setArchiveNav(await NavClient.fetchNav(archiveResource.linkTo))
    }

    const getAndSetExtract = async (nav :Nav) => {
        const archive :CellEntity = nav.data.entity
        const extractResource :NavControlResource | undefined = cellNav.control.resource['extract']
        if (!extractResource?.auth.authorized) return

        const extractNav :Nav = await NavClient.fetchNav(extractResource.linkTo)
        const extract :ExtractEntity | undefined = extractNav.data.page?.content.filter((e :ExtractEntity) => e.cellVersionNumber === archive.versionNumber)[0]
        setExpandedExtract(extract)
    }

    const toggleDetails = async (item: any) => {
        if (expandedArchiveNav?.path === item[linkTo]) {
            setExpandedArchiveNav(undefined)
        } else {
            const archiveNav :Nav = await NavClient.fetchNav(item[linkTo])
            getAndSetExtract(archiveNav)
            setExpandedArchiveNav(archiveNav)
        }
    };

    const navigateToExpandedExtract = () => {
        if (expandedExtract) navigate(expandedExtract.linkTo)
    }

    const rowProps = (item :any) => {
        if (!item[linkTo]) return {}
        return {
            onClick: () => toggleDetails(item)
        }
    }

    const itemIdToExpandedRowMap :{[id :string] :ReactNode} = {}
    if (expandedArchiveNav) itemIdToExpandedRowMap[expandedArchiveNav.path] =
            <div style={{width: '100%'}}>
                <CellContextMenuDetailsContent entity={expandedArchiveNav.data.entity} displayKey={'archive'} ignore={['value']} panel={false}/>
                <FlexGroup gutterSize={'s'} justifyContent={'spaceBetween'}>
                    <FlexItem>
                        {expandedExtract && <ButtonEmpty size={'s'} color={'primary'} fill={false} onClick={navigateToExpandedExtract} iconType={'tableDensityNormal'}>Extract</ButtonEmpty>}
                    </FlexItem>
                    <FlexItem>
                        <GenericActionButtons buttonSize={'s'} nav={expandedArchiveNav} contextKey={'archive'} ignore={['patch']}/>
                    </FlexItem>
                </FlexGroup>
                <FlexGroup gutterSize={'s'} justifyContent={'spaceBetween'}>
                </FlexGroup>

            </div>

    const archive :any[] | undefined = archiveNav?.data.page?.content

    if (!archiveNav || !archive) return <LoadingPage/>

    return <Panel paddingSize={'none'}>
        <BasicTable columns={MappingUtils.contextDisplayConfig('archive').filter((dc :DisplayConfig) => (dc.field === 'value'))}
                    items={archive}
                    onChange={(context :any) => {console.log(context)}}
                    itemId={linkTo}
                    rowProps={rowProps}
                    itemIdToExpandedRowMap={itemIdToExpandedRowMap}

        />
        <NavTableNavPagination nav={archiveNav} setNewNav={setArchiveNav}/>
    </Panel>
}

export default CellContextMenuArchiveContent