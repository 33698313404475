import {Spacer, PageSection} from 'eui'
import {useLocation} from 'react-router-dom'
import {DocumentationContentItem, DocumentationHash} from 'types'

import './DocumentationSectionVideo.css'
import {DocumentationContentConfig} from 'config'

type Props = {
    documentationHash :DocumentationHash
    isSub? :boolean
}

const DocumentationSectionVideo = ({documentationHash, isSub} :Props) => {

    const content :DocumentationContentItem = DocumentationContentConfig[documentationHash]

    const { hash } = useLocation();

    const applyClass = (itemHash :string, isSub? :boolean) :string => {
        let className = (isSub) ? 'doc-page-title-sub' : 'doc-page-title'
        if (hash === itemHash) className += ' doc-page-title-selected'
        return className
    }

    return <PageSection key={documentationHash} paddingSize={'none'}>
        <PageSection bottomBorder={(isSub) ? undefined : 'extended'} restrictWidth>
            <h1 className={applyClass(documentationHash as string, isSub)}>{content.title}</h1>
            <Spacer/>
            {content.introParagraphs.map((para :string) => <p>{para}</p>)}
            <Spacer/>
            {content.storyLaneVideoId && <>
              <script src={'https://js.storylane.io/js/v1/storylane.js'}/>
                <div className={'sl-embed'} style={{position: 'relative', paddingBottom: 'calc(68.36% + 27px)', width: '100%', height:0, transform: 'scale(1)'}}>
                    <iframe className={'sl-demo'} src={`https://app.storylane.io/demo/${content.storyLaneVideoId}`} name={'sl-embed'} allow={'fullscreen; camera; microphone'}
                            style={{position: 'absolute', top:0, left:0, width:'100%', height:'100%', borderRadius: '10px;box-sizing:border-box'}}></iframe>
                </div>
            </>}
        </PageSection>
        {content && content.items && content.items.filter((h :DocumentationHash) => (h === hash)).map((h :DocumentationHash) => <DocumentationSectionVideo documentationHash={h} isSub={true}/>)}
    </PageSection>
}

export default DocumentationSectionVideo
