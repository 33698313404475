import {useState} from 'react'
import { TypeControlSwitch } from 'Type'

const HookControlSwitch = (init? :boolean) :TypeControlSwitch => {
    const [value, set] = useState<boolean>(Boolean(init))
    const toggle = () => set((open :boolean) => !open)
    const turnOn = () => set(true)
    const turnOff = () => set(false)
    return {value, set, turnOn, turnOff, toggle}
}

export default HookControlSwitch