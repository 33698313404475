import { HookNavGet } from 'Hook'
import {TypeEntityCell, TypeEntityRow, TypeEntityTableVersionColumn, TypeNavHttpControl } from 'Type'
import { UtilRoute } from 'Util'

type Props = {
    row :TypeEntityRow
    column :TypeEntityTableVersionColumn
}

const HookModuleTableDataCellNav = ({row, column} :Props) :TypeNavHttpControl<TypeEntityCell> => {
    return HookNavGet({linkTo: UtilRoute.buildCellLinkTo(row, column)})
}

export default HookModuleTableDataCellNav