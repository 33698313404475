import {useEffect, useState} from 'react'
import {TypeEuiPropsDataGridColumn, TypeEuiPropsDataGridColumnVisibility } from 'Type'

const toId = (col :TypeEuiPropsDataGridColumn) => col.id

const HookEuiDataGridColumnVisibility = (columns :TypeEuiPropsDataGridColumn[]) :TypeEuiPropsDataGridColumnVisibility => {

    const [visibleColumns, setVisibleColumns] = useState<string[]>(columns.map(toId))

    useEffect(() => {setVisibleColumns(columns.map(toId))}, [columns.length])

    return  {
        visibleColumns,
        setVisibleColumns
    }
}

export default HookEuiDataGridColumnVisibility