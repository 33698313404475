import {EuiFormRow} from '@elastic/eui'
import {ReactElement, ReactNode} from 'react'

type Props = {
    labelType? :'label' | 'legend'
    display? :'center' | 'row' | 'rowCompressed' | 'columnCompressed' | 'centerCompressed' | 'columnCompressedSwitch'
    hasEmptyLabelSpace? :boolean
    fullWidth? :boolean
    hasChildLabel? :boolean
    label :ReactNode
    labelAppend? :any
    id? :string
    isInvalid? :boolean
    error? :ReactNode | ReactNode[]
    helpText? :ReactNode | ReactNode[]
    isDisabled? :boolean
    children :ReactElement
}

const ComponentEuiFormRow = ({labelType, display, hasEmptyLabelSpace, fullWidth, hasChildLabel, label, labelAppend, id, isInvalid, error, helpText, isDisabled, children} :Props) => {
    return <EuiFormRow labelType={labelType} display={display} hasEmptyLabelSpace={hasEmptyLabelSpace} fullWidth={fullWidth} hasChildLabel={hasChildLabel}
    label={label} labelAppend={labelAppend} id={id} isInvalid={isInvalid} error={error} helpText={helpText} isDisabled={isDisabled}>
        { children }
    </EuiFormRow>
}

export default ComponentEuiFormRow