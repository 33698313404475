import { BasicTable, ButtonIcon, FieldSelect, FlexGroup, FlexItem } from 'eui'
import {useEffect} from 'react'
import { Nav, NavControlResource, BasicTableColumn, NavDataPage, NavHookControl, FieldSelectOption, DataPayload, NavControlAction, TableColumnHookControl } from 'types'
import {InterfaceMapFieldEntity, TableVersionColumnEntity} from 'entity'
import {useNav} from 'hooks'
import { NavClient } from 'utils'
import { NavStore } from 'store'

type Props = {
    fieldResource :NavControlResource
    columnControls :TableColumnHookControl
}

type SelectProps = {
    value: any
    record: InterfaceMapFieldEntity
    tableColumns :TableVersionColumnEntity[]
    usedNameCamels :string[]
    refresh: () => void
}

type ColumnProps = {
    columnsNav :Nav
    record: InterfaceMapFieldEntity
    refresh: () => void
}

const createMap = async (name :string, nameCamel :string, record: InterfaceMapFieldEntity) => {
    const data :any = {}
    data['name'] = name
    data['nameCamel'] = nameCamel
    await NavClient.dangerAction(record.linkTo, 'patch', data)
}

const TableColumnSelect = ({value, record, tableColumns, usedNameCamels, refresh} :SelectProps) => {

    const onChange = async (name: string, value: string | File | null) => {
        await createMap(name, value as string, record)
        refresh()
    }

    const options :FieldSelectOption[] = tableColumns
    .filter((e :TableVersionColumnEntity) => {
        if (e.nameCamel === value) return true
        if (e.isSystem) return false
        if (usedNameCamels.includes(e.nameCamel)) return false
        return true
    }).map((e :TableVersionColumnEntity) => {
        return {text: e.name, value: e.nameCamel}
    })

    return <FieldSelect value={value}
                 options={options}
                 placeholder={''}
                 name={record.name}
                 isInvalid={false}
                 onChange={onChange}/>
}

const CreateColumn = ({columnsNav, record, refresh} :ColumnProps) => {

    const slickRefresh :() => void = NavStore(store => store.slickRefresh)

    const action :NavControlAction | undefined = columnsNav.control.action['post']

    const authorized :boolean = action?.auth.authorized ?? false

    const onClick = async () => {
        const data :DataPayload = {
            name: record.name,
            type: record.type,
            isRequired: 'false',
            isUnique: 'false',
        }
        const nav :Nav = await NavClient.action(columnsNav, action.action, data)
        const newColumn :TableVersionColumnEntity | undefined = nav.data.entity
        if (newColumn) await createMap(newColumn.name, newColumn.nameCamel, record)
        slickRefresh()
        refresh()
    }

    return <FlexGroup justifyContent={'flexEnd'}>
        <FlexItem><ButtonIcon isDisabled={!!(!authorized || (record.mapNameCamel && record.mapNameCamel.length > 0))} ariaLabel={'add-column'} onClick={onClick} iconType={'plus'}/></FlexItem></FlexGroup>
}


const TableModalConnectInterfaceEntityFieldMap = ({fieldResource, columnControls} :Props) => {

    const navControl :NavHookControl = useNav()
    const nav = navControl.nav
    useEffect(() => {navControl.fetch(fieldResource.linkTo)}, [fieldResource.linkTo])

    const columnsNav :Nav | undefined = columnControls.columnsNav

    const page :NavDataPage<InterfaceMapFieldEntity> | undefined = nav?.data.page

    const usedNameCamels :string[] = page?.content.map((e :InterfaceMapFieldEntity) => e.mapNameCamel) ?? []

    const refreshMap = async () => {
        await navControl.refresh()
    }

    const refreshColumn = async () => {
        await columnControls.refreshColumns()
        await navControl.refresh()
    }

    const columns :BasicTableColumn<any>[] = [
        {
            field: 'name',
            name: 'Interface Field'
        },
        {
            field: 'mapNameCamel',
            name: 'Map To Table Column',
            render :(value: any, record: InterfaceMapFieldEntity) => {
                return columnsNav && <TableColumnSelect value={value} record={record} usedNameCamels={usedNameCamels} tableColumns={columnsNav.data.page?.content ?? []} refresh={refreshMap}/>
            }
        },
        {
            field: 'newColumn',
            name: 'Create New Column',
            render :(value: any, record: InterfaceMapFieldEntity) => {
                return columnsNav && <CreateColumn record={record} columnsNav={columnsNav} refresh={refreshColumn}/>
            }
        }
    ]

    const data :any[] = page?.content ?? []

    return <BasicTable items={data} columns={columns} clickable={false}/>
}

export default TableModalConnectInterfaceEntityFieldMap