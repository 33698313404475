import {ComponentEuiForm, ComponentNavFormCalloutUnauthorized, ComponentNavFormCalloutUnavailable, ComponentNavFormField, ComponentNavFormRow } from 'Component'
import {TypeHttpPayload,
    TypeHttpPayloadValue, TypeNav, TypeNavControlAction, TypeNavControlActionFormField, TypeNavControlActionType, TypeNavError } from 'Type'

type Props = {
    nav :TypeNav<any>
    actionType :TypeNavControlActionType
    payload :TypeHttpPayload
    error? :TypeNavError
    onChange :(payload :TypeHttpPayload) => void
    disableAll? :boolean
    fullWidth? :boolean
}

const ComponentNavForm = ({nav, actionType, payload, error, onChange, disableAll, fullWidth} :Props) => {

    const action :TypeNavControlAction | undefined = nav.control.action[actionType]

    if (!action) return <ComponentNavFormCalloutUnavailable/>
    if (!action.auth.authorized) return <ComponentNavFormCalloutUnauthorized/>

    const fieldErrors :{[key :string] :string} = error?.fieldErrors ?? {}
    const fieldErrorArr :string[] = Object.values(fieldErrors)

    const onChangeInner = (field :string, value: TypeHttpPayloadValue) => {
        const payload :TypeHttpPayload = {}
        payload[field] = value
        onChange(payload)
    }

    const toFormRow = (field :TypeNavControlActionFormField) => <ComponentNavFormRow key={field.name} field={field} error={fieldErrors[field.name]} disabled={disableAll} fullWidth={fullWidth}>
        <ComponentNavFormField field={field} nav={nav} value={payload[field.name]} onChange={onChangeInner} error={fieldErrors[field.name]} disabled={disableAll}/>
    </ComponentNavFormRow>

    return <ComponentEuiForm component={'form'} error={fieldErrorArr} isInvalid={fieldErrorArr.length > 0} invalidCallout={'above'} fullWidth={fullWidth}>
        { action.form?.fields.map(toFormRow) ?? [] }
    </ComponentEuiForm>
}

export default ComponentNavForm