import { ResponderPage } from 'pages'
import { FinderNode } from 'types'

const ResponderProfile :FinderNode = {
    render: () => <ResponderPage/>,
    next: {
        'url' : {
            render: () => <ResponderPage tab={'url'}/>,
            next: {}
        },
        'test' : {
            render: () => <ResponderPage tab={'test'}/>,
            next: {}
        },
        'map' : {
            render: () => <ResponderPage tab={'map'}/>,
            next: {}
        }
    }
}

const Responder :FinderNode = {
    next: {'*' : ResponderProfile}
}

export default Responder;