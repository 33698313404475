import {HookNavGet, HookNavPage, HookNavResource, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import {PageUserBody, PageUserSidebar } from 'Page'
import { TypeNavHttpControl, TypeEntityWorkspace, TypeHttpLoadable, TypeEntityOrganisation, TypeNav, TypeEntityUser, TypeNavKey, TypeEntityApi, TypeUrlParam, TypeNavControlResource } from 'Type'

const userKey :TypeNavKey = 'user'

const pageSizeParam :TypeUrlParam = {type: 'size', values: ['10']}

const PageUser = () => {

    //Workspace
    const wsControl :TypeNavHttpControl<TypeEntityWorkspace> = HookNavPage<TypeEntityWorkspace>()
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    //user
    const user :TypeEntityUser | undefined = wsNav?.context[userKey] as TypeEntityUser
    const userControl :TypeNavHttpControl<TypeEntityUser> = HookNavGet<TypeEntityUser>({linkTo: user?.linkTo})
    const userNav :TypeNav<TypeEntityUser> | undefined = userControl.res?.data
    const userEntity :TypeEntityUser | undefined = userNav?.data.entity

    //Org
    const orgResource :TypeNavControlResource | undefined = HookNavResource('org', userNav)
    const orgControl :TypeNavHttpControl<TypeEntityOrganisation> = HookNavGet({linkTo: orgResource?.linkTo, paramUpdates: [pageSizeParam]})

    //Api
    const apiControl :TypeNavHttpControl<TypeEntityApi> = HookNavResourceNav({key: 'api', nav: userNav, paramUpdates: [pageSizeParam]})

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Workspaces', control: wsControl},
    ]

    if (orgResource) toLoad.push({label: 'Organisations', control: orgControl})

    const sidebar = userEntity && <PageUserSidebar userEntity={userEntity} orgControl={orgControl} apiControl={apiControl}/>
    const body = userNav && <PageUserBody userNav={userNav} wsControl={wsControl}/>

    return <ModulePage control={wsControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={wsControl}>{body}</ModulePage>
}

export default PageUser