import { EuiComboBox } from "@elastic/eui"
import { ReactElement } from "react"

type option = {
    label :string
    key? :string
    disabled? :boolean
}

type Props = {
    prepend? :string | ReactElement
    onSearchChange :(searchValue: string, hasMatchingOptions?: boolean) => void
    options :option[]
    isLoading? :boolean
    onChange :(option :any) => void
    selectedOptions : option[]

}

const ComboBox = ({ isLoading, prepend, onSearchChange, options, onChange, selectedOptions } :Props) => {
    return <EuiComboBox isLoading={isLoading} prepend={prepend} singleSelection={{ asPlainText: true }} onSearchChange={onSearchChange} options={options} onChange={onChange} selectedOptions={selectedOptions} fullWidth>
        {(list :any, search :any) => (
            <>
                {search}
                {list}
            </>
        )}
    </EuiComboBox>
}

export default ComboBox