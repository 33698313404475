import {ComponentDivMax, ComponentDivPadding, ComponentEuiLoadingSpinner, ComponentEuiModal } from 'Component'
import { TypeNavControlDownloadBlob } from 'Type'

type Props = {
    blobControl :TypeNavControlDownloadBlob
    onClose :() => void
}

const ModuleTableCellImagePreviewModal = ({ blobControl, onClose } :Props) => {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl :string | undefined = blobControl.blob && urlCreator.createObjectURL(blobControl.blob);
    return <ComponentEuiModal onClose={onClose}>
        <ComponentDivPadding padding={24}>
            <ComponentDivMax width={600}>
                { (imageUrl) ? <img width={552} src={imageUrl}></img> : <ComponentEuiLoadingSpinner/> }
            </ComponentDivMax>
        </ComponentDivPadding>
    </ComponentEuiModal>
}

export default ModuleTableCellImagePreviewModal