import {FlexGroup, FlexItem, Title, Spacer, Text, PageSection, ButtonEmpty } from 'eui'
import {Nav, NavControlAction, NavControlActionType, NavMeta} from 'types'
import {NavDataPageTable, NavDataEntity, NavFileDownloadButton, } from 'modules'
import { NavStore } from 'store'
import {BackButton, GreyHeader, NavPageForm, NavResourceSideNavItem, NoDataPlaceholder } from 'components'
import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

const renderMeta = (meta? :NavMeta) => {
    if (!meta) return null

    return <FlexGroup justifyContent={'spaceBetween'}>
        <FlexItem>
            <Title><h3 style={{fontWeight: 500, fontSize: 22}}>{meta.title}</h3></Title>
            {
                (!meta.description || meta.description === '') ? <></> : <>
                    <Spacer/>
                    <Text><p>{meta.description}</p></Text>
                </>
            }
        </FlexItem>
        <FlexItem>
        </FlexItem>
    </FlexGroup>
}

type ActionConfig = {
    icon :'plusInCircle' | 'trash' | 'documentEdit'
    color :'primary' | 'danger'
}

const actionConfigMap :{[key :string] :ActionConfig} = {
    'post' : {icon: 'plusInCircle', color: 'primary'},
    'delete' : {icon: 'trash', color: 'danger'},
    'patch' : {icon: 'documentEdit', color: 'primary'},
    'put' : {icon: 'documentEdit', color: 'primary'},
}

const actionNavItem = (action :NavControlAction) :ReactNode => {
    const actionConfig :ActionConfig = actionConfigMap[action.action]
    return <div key={action.action} style={{float: 'left', padding: '2px 6px'}}>
        {
            (!action.auth.authorized) ?
                <ButtonEmpty onClick={() => {}} isDisabled={!action.auth.authorized} iconType={actionConfig.icon} iconSide={'left'} color={actionConfig.color}>{action.action}</ButtonEmpty> :
                <Link to={{hash: action.action}}>
                    <ButtonEmpty onClick={() => {}} iconType={actionConfig.icon} iconSide={'left'} color={actionConfig.color}>{action.action}</ButtonEmpty>
                </Link>
        }

    </div>
}

const actionNav = (nav :Nav) => {
    return <div style={{display: 'inline-block', width: '100%', borderBottom: '1px solid #D3DAE6'}}>
        <div style={{float: 'left', padding: '2px 6px'}}>
            <Link to={{hash: ''}}>
                <ButtonEmpty key={'data'} color={'text'} onClick={() => {}} iconType={'tableDensityNormal'} iconSide={'left'}>{'Data'}</ButtonEmpty>
            </Link>
        </div>
        {Object.values(nav.control.action).map(a => actionNavItem(a))}
        <div style={{float: 'right', padding: '2px 6px'}}>
            <NavFileDownloadButton nav={nav}/>
        </div>
    </div>
}

const hashToAction = (hash :string) :NavControlActionType => hash.replace('#', '') as NavControlActionType

const NavigatorPageUserInterface = () => {

    const nav = NavStore(state => state.nav)
    const { hash } = useLocation();

    if (!nav) return null
    
    return <>
        <PageSection paddingSize={'l'}>
            <BackButton/>
            <Spacer size={'m'}/>
            { renderMeta(nav.meta) }
        </PageSection>
        <FlexGroup gutterSize={'none'}>
            <FlexItem>
                <GreyHeader title={'Resources'}/>
                <div style={{padding: '8px 16px 8px 16px', backgroundColor: '#F7F8FC', height: '100%'}}>
                    {Object.values(nav.control.resource).map(r => <NavResourceSideNavItem key={r.key} resource={r}/>)}
                    {(Object.values(nav.control.resource).length === 0) ? <NoDataPlaceholder text={'This URL has no resources'}/> : ''}
                </div>
                <div style={{width: 244}}>
                </div>
            </FlexItem>
            <FlexItem grow>
                <div style={{width: '100%', height: '100%', borderLeft: '1px solid #D3DAE6'}}>
                    <GreyHeader title={'Data & Actions'}/>
                    {actionNav(nav)}
                    {(hash === '') ?
                        <><NavDataPageTable/><NavDataEntity/></> :
                        <NavPageForm nav={nav} actionType={hashToAction(hash)}/>
                    }
                </div>
            </FlexItem>
        </FlexGroup>
    </>
}

export default NavigatorPageUserInterface