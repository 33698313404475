import { HookHttpDownload } from 'Hook'
import { TypeNav, TypeNavControlDownload } from 'Type'

const HookNavDownload = (nav? :TypeNav<any>) :TypeNavControlDownload => {

    const isDownloadable :boolean = nav?.control.downloadable ?? false

    return { isDownloadable, ...HookHttpDownload(nav?.path) }
}

export default HookNavDownload