import { NavActionModal } from 'components'
import { useState, useEffect } from 'react'
import {DataPayload, Nav } from 'types'
import { NavClient } from 'utils'

type Type = 'user' | 'api' | 'signup'

type Props = {
    type: Type
    onClose: () => void
    onSuccess: (response: Nav, req? :DataPayload) => void
}

type Config = {
    url :string,
    title :string
}

const config :Record<Type, Config> = {
    user: {url: '/session/user', title: 'User Link'},
    api: {url: '/session/api', title: 'API Link'},
    signup: {url: '/user', title: 'Sign Up'}
}

const Session = ({type, onClose, onSuccess} :Props) => {

    let [nav, setNav] = useState<Nav | undefined>(undefined)

    const getNav = async () => {
        setNav(await NavClient.fetchNav(config[type].url))
    }

    useEffect(() => {getNav()},[])

    return (nav) ? <NavActionModal title={config[type].title} nav={nav} actionType={'post'} onClose={onClose} onSuccess={onSuccess}/> : null
}

export default Session