import {ComponentEuiButtonEmpty, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { ModuleTransformOutputTablePopoverRowContent } from 'Module'
import { TypeControlSwitch, TypeEntityTransform, TypeModuleControlRefresh, TypeNav, TypeNavControlResource } from 'Type'

type Props = {
    rowIndex :string
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformOutputTablePopoverRow = ({rowIndex, transformNav, excludeResource, refreshControl} :Props) => {

    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'s'} anchorPosition={'rightUp'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} color={'text'} iconType={'boxesVertical'} iconSide={'left'}>{ rowIndex }</ComponentEuiButtonEmpty>}>
        <ModuleTransformOutputTablePopoverRowContent rowIndex={rowIndex} transformNav={transformNav} excludeResource={excludeResource} refreshControl={refreshControl}/>
    </ComponentEuiPopover>
}

export default ModuleTransformOutputTablePopoverRow