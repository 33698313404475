import { useEffect, useState } from 'react'
import { TypeWebSocketControl, TypeWebSocketPayloadAcc } from 'Type'
import {HookBrowserTabActive, HookWebSocketUrl} from 'Hook'
import { ClassClientSocket } from 'Class'

type Props = {
    path? :string
}

const HookWebSocket = <M extends TypeWebSocketPayloadAcc>({ path } :Props) :TypeWebSocketControl<M> => {

    const url :string | undefined = HookWebSocketUrl(path).url

    const [client, setClient] = useState<ClassClientSocket<M> | undefined>()
    const [data, setData] = useState<TypeWebSocketControl<M>>({ messages: [], latestMessage :undefined, isConnected :false})

    const tabReactivated :boolean = HookBrowserTabActive()

    const connect = () => {
        if (url) {
            if (client) client.permanentlyClose()
            setClient(new ClassClientSocket(url, setData))
        }
    }

    const disconnect = () => {
        if (client) client.permanentlyClose()
    }

    const reconnect = () => {
        if (client) {
            setClient(client.cloneAndClose())
        } else {
            connect()
        }
    }

    useEffect(() => {
        if (url) connect()
        return () => { disconnect() }
    }, [url])

    useEffect(() => {
        if (tabReactivated) reconnect()
    }, [tabReactivated])

    return data
}

export default HookWebSocket