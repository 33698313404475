import { ExtractPage } from 'pages';
import { FinderNode } from 'types';
import Transformation from '../Transformation/Transformation';

const Extract :FinderNode = {
    next: {
        '*' : {
            render: () => <ExtractPage/>,
            next: {
                'sheet' : {
                    next: {
                        '*': {
                            render: () => <ExtractPage/>,
                            next: {
                                'transform' : Transformation
                            }
                        }
                    }
                }
            }
        }
    }
}

export default Extract