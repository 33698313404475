import react, { useState } from 'react'
import { Breadcrumbs as EuiBreadcrumbs, Icon, LoadingSpinner } from 'eui'
import { NavStore } from 'store'
import { Breadcrumb } from 'types';
import { useNavigate } from 'react-router-dom';

const Breadcrumbs = () => {

    const navigate = useNavigate()
    const [nav, loading] = NavStore(store => [store.nav, store.loading])
    if (!nav) return null
    const pathParts = (nav && nav.path || '').split('?')[0].split('/').filter((p) => p !== '')
    const breadcrumbs :Breadcrumb[] = [];

    breadcrumbs.push({
        text: <span style={{padding: '0px 4px'}}>{(loading) ? <LoadingSpinner/> : <Icon type='home'/>}</span>,
        truncate: false,
        onClick: async () => {
            navigate('/')
        }
    })
    for (let i = 0; i < pathParts.length; i++) {
        const part = pathParts[i];
        const path = `/${pathParts.filter((p, index) => (index <= i)).join('/')}`
        const b :Breadcrumb = {
            text: <span style={{padding: '0px 4px'}}>{part.split('?')[0].toLocaleLowerCase()}</span>,
            truncate: false,
            onClick: async () => {
                navigate(path)
            }
        }
        if (i === pathParts.length - 1) {
            b.color = 'primary'
            b.onClick = undefined
        }
        breadcrumbs.push(b)
    }

    return <div style={{paddingLeft: 14, width: '100%'}}><EuiBreadcrumbs breadcrumbs={breadcrumbs} type={'page'} max={0}/></div>
}

export default Breadcrumbs