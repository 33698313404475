import axios from 'axios'
import * as FileSaver from 'file-saver';
import { SessionStore } from 'store'
import {DataPayload, Nav, NavControlActionType, NavMap, PromiseNavMap } from 'types'
import {URLSearchParams} from "url";
import { Env, SearchParamUtils, WebSocketClient } from 'utils'
import {ParamUpdate} from "../../types";

const constructHeaders = () => {
    const headers :any = {}
    const token = SessionStore.getState().token
    if (token) headers['Authorization'] = `Bearer ${token}`
    return headers
}

const errorToNav = (e :any) => {
    if (e.code === 'ERR_NETWORK') return {error: {message: 'Server seems to be down'}} as Nav
    return ((e.response && e.response.data) ? e.response.data : {}) as Nav
}

const isFilePresent = (data? :DataPayload) :boolean => {
    let isFile = false
    if (!data) return isFile
    Object.values(data).forEach((v, k) => {if (v instanceof File) isFile = true})
    return isFile
}

const toFormData = (data :DataPayload) :FormData => {
    const formData :FormData = new FormData()
    Object.keys(data).forEach(k => {
        formData.set(k, data[k])
    })
    return formData
}

const updateQueryParam = (url :string, param? :string, paramValue? :string) :string => {
    if (param && paramValue !== undefined) {
        const urlObj = new URL(url)
        urlObj.searchParams.set(param, paramValue)
        return urlObj.toString()
    }
    return url
}

const fetch = async (path :string, param? :string, paramValue? :string) :Promise<Nav> => {
    try {
        let url :string = Env.httpUrl + path
        const resp = await axios.request({method: 'get', url: updateQueryParam(url, param, paramValue), headers: constructHeaders()})
        return resp.data as Nav
    } catch (e) {
        return errorToNav(e)
    }
}

const fetchRowCountNav = async (path :string) :Promise<Nav> => {
    return fetch(path, 'size', `${0}`)
}

const getTotalElementCount = (nav :Nav) :number => {
    return nav.data.page?.totalElements ?? 0
}

const fetchRowCount = async (path :string) :Promise<number> => {
    const nav :Nav = await fetch(path, 'size', `${0}`)
    return new Promise(resolve => {
        resolve(getTotalElementCount(nav))
    })
}

const fetchAll = async (path :string) :Promise<Nav> => {
    const countNav :Nav = await fetchRowCountNav(path);
    const totalElements :number = getTotalElementCount(countNav)
    return (totalElements > 0) ? fetch(path, 'size', `${totalElements}`) : new Promise(resolve => {resolve(countNav)})
}

const dangerAction = async (path :string, action :NavControlActionType, data? :DataPayload) :Promise<Nav> => {
    const headers :any = constructHeaders()
    const hasFile :boolean = isFilePresent(data)
    headers['Content-Type'] = (hasFile) ? 'multipart/form-data' : 'application/json'
    const dataToSend :any | undefined = (!data) ? null : (hasFile) ? toFormData(data) : data
    try {
        const resp = await axios.request({method: action, url: Env.httpUrl + path, data: dataToSend, headers: headers})
        return resp.data as Nav
    } catch (e) {return errorToNav(e)}
}

const NavClient = {
    fetchAll: fetchAll,
    fetchRowCount: fetchRowCount,
    fetchNav: async (path :string, param? :string, paramValue? :string) :Promise<Nav> => {return fetch(path, param, paramValue)},
    fetchDownloadBlob: async (path :string) :Promise<Blob> => {
        const downloadPath = new URL(Env.httpUrl + path)
        downloadPath.searchParams.append('download', 'true')
        const resp = await axios.request({method: 'get', url: downloadPath.toString(), headers: constructHeaders(), responseType: 'arraybuffer'})
        return new Blob([resp.data])
    },
    download: async (path :string) => {
        try {
            const downloadPath = new URL(Env.httpUrl + path)
            downloadPath.searchParams.append('download', 'true')
            const resp = await axios.request({method: 'get', url: downloadPath.toString(), headers: constructHeaders(), responseType: 'arraybuffer'})
            const fileName = (resp.headers['content-disposition'] || 'unknown').replace('attachment;filename=', '')
            FileSaver.saveAs(new Blob([resp.data]), fileName)

        } catch (e) {
            return errorToNav(e)}
    },
    action: async (nav :Nav, action :NavControlActionType, data :DataPayload) :Promise<Nav> => {
        return dangerAction(nav.path, action, data)
    },
    dangerAction: dangerAction,
    fetchNavResourceNavs: async (nav :Nav, fetchAllMap? :{[key :string] :boolean}) :Promise<NavMap> => {
        const promiseMap :{[key :string] :Promise<Nav>} = {}
        Object.keys(nav.control.resource)
            .filter((resourceKey :string) :boolean => {
                return nav.control.resource[resourceKey].auth.authorized
            }).forEach((key :string) => {
                promiseMap[key] = ((fetchAllMap && fetchAllMap[key]) ? fetchAll : fetch)(nav.control.resource[key].linkTo)
            })

        const respMap :{[key :string] :Nav} = {}

        const keys :string[] = Object.keys(promiseMap);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            respMap[key] = await promiseMap[key]
        }
        return respMap
    },
    updateNavContextNavs: async (nav :Nav, currentNavs :NavMap) :Promise<NavMap> => {

        const promiseMap :{[key :string] :Promise<Nav>} = {}
        const respMap :{[key :string] :Nav} = {}

        Object.keys(nav.context).forEach((key :string) => {
            const existingNav :Nav | undefined = currentNavs[key]
            const contextPath :string = nav.context[key].linkTo
            if (existingNav && existingNav.path === contextPath) {
                respMap[key] = existingNav
            } else if (nav.path === contextPath) {
                respMap[key] = nav
            } else {
                promiseMap[key] = fetch(nav.context[key].linkTo)
            }
        })

        const keys :string[] = Object.keys(promiseMap);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            respMap[key] = await promiseMap[key]
        }
        return respMap
    },
    searchFormField: async (nav :Nav, field :string, value :string) :Promise<Nav> => {
        const path :string = SearchParamUtils.clearParamForPath(nav.path)
        return fetch(path, `formSearch-${field}`, value)
    },
    searchTableField: async (nav :Nav, field :string, value :string) :Promise<Nav> => {
        return fetch(nav.path, `field-${field}`, value)
    },
    refresh: async (navs :NavMap, all :{[key :string] :boolean} = {}) :Promise<NavMap> => {
        const promiseMap :PromiseNavMap = {}
        const resp :NavMap = {}
        const keys :string[] = Object.keys(navs)

        keys.forEach(key => {
            const path :string = navs[key].path
            promiseMap[key] = (all[key]) ? fetchAll(path) : fetch(path)
        })

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            resp[key] = await promiseMap[key]
        }

        return resp
    },
    buildWebsocketClient: (path :string, onOpen :() => void, onClose :() => void) :WebSocketClient => {
        return new WebSocketClient(updateQueryParam(Env.wsUrl + path, 'token', SessionStore.getState().token), onOpen, onClose)
    }
}

export default NavClient