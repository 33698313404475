import {Button, Card, FlexGroup, FlexItem, PageSection, Spacer } from 'eui'

import './HomePageHeroSection.css'
import MorphHeroImage from './MorphHero.svg'
import GridImage from './Grid.svg'
import { useState } from 'react'
import { SignUpModal } from 'modules'

const HomePageHeroSection = () => {

    const [signUpModal, setSignUpModal] = useState<boolean>(false)

    return <PageSection paddingSize={'xl'} style={{backgroundColor: '#17191D', paddingTop: '220px', paddingBottom: '180px'}}>
            <img src={GridImage} alt={'landing table image'} style={{position: 'absolute', zIndex: 1, top: -150, left: -50, width: 700, height: 420}}/>
            <img src={GridImage} alt={'landing table image'} style={{position: 'absolute', zIndex: 1, top: -50, left: 1200, width: 500, height: 200}}/>
            <img src={GridImage} alt={'landing table image'} style={{position: 'absolute', zIndex: 1, top: 160, left: 800, width: 1800, height: 800}}/>
            <FlexGroup gutterSize={'xl'}>
                <FlexItem grow>
                    <div style={{width: '80%', margin: '72px auto', zIndex: 2}}>
                        <div className={'hero-title'}>Digitization</div>
                        <div className={'hero-title'}>made</div>
                        <div className={'hero-title'}>easy</div>
                        <Spacer/>
                        <div>
                            <p style={{fontSize: '24px', fontWeight: 400, color: '#fff'}}>No-code, relational data tables, in minutes.</p>
                        </div>
                        <Spacer size={'xxl'}/>
                        <div>
                            <Button onClick={() => setSignUpModal(true)} color={'primary'} fill>Create a free account today</Button>
                        </div>
                    </div>
                </FlexItem>
                <FlexItem grow>
                    <img src={MorphHeroImage} alt={'landing table image'} style={{paddingRight: '0px 60px', width: '100%', zIndex: 2}}/>
                </FlexItem>
            </FlexGroup>
        {(signUpModal) ? <SignUpModal onClose={() => setSignUpModal(false)}/> : null}
    </PageSection>
}

export default HomePageHeroSection