import { ComponentEuiTableBasic, ComponentNavDataPagePagination } from 'Component'
import { HookNavDataPageAction, HookNavDisplayColumns, HookNavPaginate } from 'Hook'
import { ReactNode } from 'react'
import {TypeEntityLinkTo, TypeEuiPropsBasicTableColumn,
    TypeEuiPropsBasicTableColumnActions, TypeFieldDisplay, TypeNav,
    TypeNavControlDataAction, TypeNavControlPaginate, TypeNavHttpControl } from 'Type'

type Props<T extends object & TypeEntityLinkTo> = {
    initialNav: TypeNav<T>
    showColumns: (keyof T)[]
    entityName: string
    action?: 'navigate' | 'menu' | 'custom'
    onActionSuccess?: () => void
    customMenuComponent? :(navControl :TypeNavHttpControl<T>) => ReactNode
    customButtons? :(nav :TypeNav<T>) => ReactNode[]
    customRenders? : { [field: string]: TypeFieldDisplay }
    paginate? :boolean
}

type Column<T> = TypeEuiPropsBasicTableColumn<T> | TypeEuiPropsBasicTableColumnActions<T>

const itemId :(keyof TypeEntityLinkTo) = 'linkTo'

const ComponentNavDataPageTable = <T extends object & TypeEntityLinkTo>({initialNav, showColumns, entityName, action, onActionSuccess, customButtons, customMenuComponent, customRenders, paginate = true} :Props<T>) => {
    const control :TypeNavControlPaginate<T> = HookNavPaginate<T>({ initialNav })

    const columns :Column<T>[] = HookNavDisplayColumns({showColumns, customRenders})
    const actionControl :TypeNavControlDataAction<T> = HookNavDataPageAction({itemId, entityName, action, onActionSuccess, customMenuComponent, customButtons})

    return <>
        <ComponentEuiTableBasic items={control.data} columns={columns} itemId={itemId} itemIdToExpandedRowMap={actionControl.itemIdToExpandedRowMap} rowProps={actionControl.rowProps}/>
        {paginate && <ComponentNavDataPagePagination control={control}/> }
    </>
}

export default ComponentNavDataPageTable
