import { HookNavParamSimple } from 'Hook'

import { TypeControlParamSimple, TypeNav, TypeUrlParamType } from 'Type'

type Props = {
    type :TypeUrlParamType
    onChange :(path :string) => void
    nav? :TypeNav<any>
    defaultPageValue? :boolean
}

const HookNavParamSimpleInteger = ({ type, nav, onChange, defaultPageValue } :Props) :TypeControlParamSimple<number> => {

    const [ valueStr, setStr ] :TypeControlParamSimple = HookNavParamSimple({ type, nav, onChange, defaultPageValue })

    const value :number | undefined = (valueStr !== undefined) ? parseInt(valueStr) : undefined

    const setValue = (newValue :number) => setStr(`${newValue}`)

    return [value, setValue]
}

export default HookNavParamSimpleInteger