import { ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiSpacer, ComponentNavSidebarButtonAction,
    ComponentNavSidebarButtonNavigate, ComponentNavSidebarContextEntity, ComponentNavSidebarTitle } from 'Component'
import {TypeEntityRow, TypeEntityTable, TypeEntityTableVersion, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    pageControl :TypeNavHttpControl<TypeEntityRow>
    table :TypeEntityTable
    version :TypeEntityTableVersion
}

const PageTableSidebar = ({ pageControl, table, version } :Props) => {
    const nav :TypeNav<TypeEntityRow> | undefined = pageControl.res?.data
    if (!nav) return null
    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={table.name} iconType={'tableDensityNormal'} entityName={'Row'}/>
            <ComponentEuiSpacer/>
            <ComponentNavSidebarContextEntity title={'Owner'} navKey={'user'} nameFields={['firstName', 'lastName']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Organisation'} navKey={'org'} nameFields={['name']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Workspace'} navKey={'ws'} nameFields={['name']} nav={nav}/>
            <ComponentEuiSpacer size={'s'}/>
            <ComponentNavSidebarButtonAction actionType={'post'} iconType={'plusInCircle'} nav={nav} title={'Row'} color={'primary'} fill/>
            <ComponentEuiSpacer/>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ComponentNavSidebarButtonNavigate entity={version} buttonType={'empty'} iconType={'gear'}>{ 'Setup' }</ComponentNavSidebarButtonNavigate>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageTableSidebar