import { useNavigate } from 'react-router-dom'
import {ReactNode, useEffect, useState} from 'react'
import { NavTableNavPagination } from 'components'
import { BasicTable, FlexGroup, FlexItem } from 'eui'
import { Nav, NavKey, NavHookControl, DisplayConfig } from 'types'
import { MappingUtils } from 'utils'
import {LinkToEntity} from 'entity'
import {useNav} from 'hooks'

import {GenericActionButtons, NavTableFilter, NavTableFilterBadges} from 'modules'

type FilterOpt = {
    value :string
    text :ReactNode
}

type Props<T> = {
    nav: Nav
    navKey: NavKey
    rowProps?: any
    navigate?: 'navigate' | 'control' | 'callback'
    onSuccess?: () => void
    ignoreAudit? :boolean
    ignoreFields? :string[]
    onClick? :(t :T) => void
}

const NavModalTable = <T extends LinkToEntity>({nav, navKey, rowProps, navigate = 'navigate', onSuccess, ignoreAudit, ignoreFields, onClick} :Props<T>) => {

    const navigateTo = useNavigate()

    const [innerNav, setInnerNav] = useState<Nav>(nav)
    const entityNavControl :NavHookControl = useNav()

    useEffect(() => {
        setInnerNav(nav)
    }, [nav])

    const innerOnClick = (t :T) => {
        if (navigate === 'control') entityNavControl.fetch(t.linkTo)
        if (navigate === 'callback' && onClick) onClick(t)
        if (navigate === 'navigate') navigateTo(t.linkTo)
    }

    const data :any[] = (innerNav.data.page && innerNav.data.page.content) || []

    const entityNav :Nav | undefined = entityNavControl.nav

    const itemIdToExpandedRowMap :any = {}

    if (entityNav) itemIdToExpandedRowMap[entityNav.path] = <FlexGroup>
        <FlexItem grow></FlexItem>
        <FlexItem>
            <GenericActionButtons nav={entityNav} contextKey={navKey} startOpen={true} buttonSize={'s'} onSuccess={onSuccess}/>
        </FlexItem>
    </FlexGroup>

    const displayConfig :DisplayConfig[] = MappingUtils.contextDisplayConfig(navKey, ignoreAudit)

    const filteredConfig = (ignoreFields) ? displayConfig.filter((c :DisplayConfig) => !ignoreFields.includes(c.field)) : displayConfig

    const toFilterOpt = (nameCamel :string) :FilterOpt => {
        const column :DisplayConfig | undefined = filteredConfig.find((c :DisplayConfig) => c.field === nameCamel)
        return {value: nameCamel, text: column?.name ?? nameCamel}
    }

    const fieldFilterOptions :FilterOpt[] = nav?.data.page?.searchableFields.map(toFilterOpt) ?? []

    return <>
        <FlexGroup gutterSize={'m'} >
            <FlexItem></FlexItem>
            { fieldFilterOptions.length > 0 && <>
              <FlexItem><NavTableFilter fieldOptions={fieldFilterOptions} nav={innerNav} setNav={setInnerNav}/></FlexItem>
              <FlexItem><NavTableFilterBadges nav={innerNav} setNav={setInnerNav}/></FlexItem>
            </> }
            <FlexItem>

            </FlexItem>
        </FlexGroup>
        <BasicTable columns={filteredConfig} items={data} onChange={(context :any) => {console.log(context)}}
                    rowProps={rowProps} itemId={'linkTo'} itemIdToExpandedRowMap={itemIdToExpandedRowMap} onClick={innerOnClick}/>
        <NavTableNavPagination nav={innerNav} setNewNav={setInnerNav}/>
    </>
}

export default NavModalTable