import { FlexPageContent } from 'components'
import {FlexGroup, Page, PageBody } from 'eui'
import {RowPageContent, TablePageLeftNavBar, UserPageLeftNavBar } from 'modules'
import { useState } from 'react'
import {LoadingPage} from 'pages';
import {NavStore} from 'store';
import {NavKey} from 'types';
import {LinkToEntity} from 'entity';

const rowKey :NavKey = 'row'

const RowPage = () => {
    const [loadedSideBar, setLoadedSideBar] = useState<boolean>()

    const rowEntity :LinkToEntity | undefined = NavStore(store => store.nav?.context[rowKey])

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar forceCollapsed={true} wsSelected/>
                <TablePageLeftNavBar callBack={() => setLoadedSideBar(true)}/>
                <FlexPageContent leftBorder={true} minWidth={0}>
                    {(loadedSideBar && rowEntity) ? <RowPageContent key={rowEntity.linkTo}/> : <LoadingPage/>}
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default RowPage