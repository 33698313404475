import React from 'react'
import {EuiAvatar} from '@elastic/eui'

type Props = {
    name :string,
    size? : 's' | 'm' | 'l' | 'xl' | undefined,
    onClick? :() => void
    style? :any
}

const Avatar = ({ name, size = 'm', onClick, style }: Props) =>
    <EuiAvatar name={name} size={size} type={'space'} onClick={onClick} style={style}/>

export default Avatar