import create from 'zustand'
import {NavClient, WebSocketClient} from 'utils'
import {Nav, WebSocketListener} from 'types'

type Store = {
    isConnected :boolean
    client :WebSocketClient | undefined
    connect :(nav :Nav) => void
    heartbeat :() => void
    listen :(listener :WebSocketListener) => void
    stopListening :(id :string) => void
    close :() => void
}


const TableSocketStore = create<Store>((set, get) => ({
    isConnected: false,
    client: undefined,
    connect: (nav :Nav) => {
        if (!nav.control.connectable) throw new Error('Not connectable')
        let client :WebSocketClient | undefined = get().client
        if (client) client.close()
        let newClient :WebSocketClient = NavClient.buildWebsocketClient(nav.path, () => {set({isConnected: true})}, () => set({isConnected: false}))
        set({client : newClient, isConnected: true})
        get().heartbeat()
    },
    heartbeat: () => {
        const client :WebSocketClient | undefined = get().client
        const heartbeat :() => void = get().heartbeat
        if (client) client.message('beep')
        setTimeout(heartbeat, 55000)
    },
    listen(listener :WebSocketListener) {
        const client :WebSocketClient | undefined = get().client
        if (client) client.listen(listener)
    },
    stopListening(id :string) {
        const client :WebSocketClient | undefined = get().client
        if (client) client.stopListening(id)
    },
    close() {
        const client :WebSocketClient | undefined = get().client
        if (client) client.close()
    }
}))

export default TableSocketStore