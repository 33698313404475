import { ComponentEuiDataGrid } from 'Component'
import { ContextTableData } from 'Context'
import { HookModuleTableColumn, HookModuleTableDataCellRenderer, HookPageLocation } from 'Hook'
import { ModuleTableColumnFilterModal, ModuleTableDataContext } from 'Module'
import {useContext, useEffect, useRef} from 'react'
import { TypeContextTableData, TypeEntityRow, TypeEuiPropsDataGridRef, TypeModuleTableColumnControl,
    TypeModuleTableDataCellRenderer, TypeModuleTableDataProps, TypeNav } from 'Type'


const ModuleTableDataTableInner = ({ dataControl, columnControl, fieldNameMap } :TypeModuleTableDataProps) => {

    const dataGridRef = useRef<TypeEuiPropsDataGridRef | null>(null);

    const { location } = HookPageLocation()

    const closeCellPopover :() => void = () => {
        if (dataGridRef.current) {
            dataGridRef.current.closeCellPopover()
        }
    }

    const dataNav :TypeNav<TypeEntityRow> | undefined = dataControl.res?.data
    const dataContext :TypeContextTableData = useContext(ContextTableData)

    const { gridColumns, columnVisibility, leadingControlColumns, trailingControlColumns, toFilterColumn, clearToFilterColumn, sorting } :TypeModuleTableColumnControl = HookModuleTableColumn(dataNav!, columnControl, dataContext.data)
    const { renderCellValue, renderCellPopover } :TypeModuleTableDataCellRenderer = HookModuleTableDataCellRenderer()

    useEffect(() => {
        closeCellPopover()
    }, [location])

    const rowCount :number = dataContext.data.length
    return <>
        <ComponentEuiDataGrid columns={gridColumns} rowCount={rowCount} columnVisibility={columnVisibility}
                          toolbarVisibility={true} renderCellValue={renderCellValue}
                          leadingControlColumns={leadingControlColumns} trailingControlColumns={trailingControlColumns}
                          renderCellPopover={renderCellPopover} sorting={sorting} forwardRef={dataGridRef}/>
        { dataNav && toFilterColumn && <ModuleTableColumnFilterModal nav={dataNav} column={toFilterColumn} fieldNameMap={fieldNameMap} onClose={clearToFilterColumn}/> }
    </>

}

const ModuleTableDataTable = ({ dataControl, columnControl, fieldNameMap, updatesControl, toLoad } :TypeModuleTableDataProps) => {
    return <div>
        <ModuleTableDataContext dataControl={dataControl} columnControl={columnControl} updatesControl={updatesControl}>
            <ModuleTableDataTableInner dataControl={dataControl} columnControl={columnControl} fieldNameMap={fieldNameMap} updatesControl={updatesControl} toLoad={toLoad}/>
        </ModuleTableDataContext>
    </div>
}

export default ModuleTableDataTable