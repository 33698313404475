import { ComponentEuiButton, ComponentEuiButtonEmpty, ComponentEuiButtonIcon,
    ComponentEuiFlexItem, ComponentNavActionModal } from 'Component'
import { HookControlSwitch } from 'Hook'
import { TypeControlSwitch, TypeEuiPropsIconType, TypeNav, TypeNavControlAction, TypeNavControlActionType } from 'Type'

type Props<T> = {
    nav :TypeNav<T>
    actionType :TypeNavControlActionType
    iconType :TypeEuiPropsIconType
    color :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    buttonTitle :string
    entityName :string
    onSuccess? :(entity? :any) => void
    buttonType? :'normal' | 'empty' | 'icon'
    size? :'s' | 'm'
}

const ComponentNavActionModalButton = <T,>({ nav, actionType, color, iconType, buttonTitle, entityName, onSuccess, buttonType, size } :Props<T>) => {

    const switchControl :TypeControlSwitch = HookControlSwitch()

    const action :TypeNavControlAction | undefined = nav.control.action[actionType]

    if (!action) return null

    const onSuccessInner = (entity? :any) => {
        switchControl.turnOff()
        if (onSuccess) onSuccess(entity)
    }

    return <ComponentEuiFlexItem>
        {(buttonType === 'empty') ? <ComponentEuiButtonEmpty size={size} color={color} iconType={iconType} onClick={switchControl.turnOn} isDisabled={!action.auth.authorized}>{ buttonTitle }</ComponentEuiButtonEmpty> :
            (buttonType === 'icon') ? <ComponentEuiButtonIcon size={size} color={color} iconType={iconType} onClick={switchControl.turnOn} isDisabled={!action.auth.authorized}/> :
            <ComponentEuiButton size={size} color={color} iconType={iconType} onClick={switchControl.turnOn} isDisabled={!action?.auth.authorized} fullWidth>{ buttonTitle }</ComponentEuiButton>
        }
        {(switchControl.value) ? <ComponentNavActionModal nav={nav} actionType={actionType} onClose={switchControl.turnOff} entityName={entityName} onSuccess={onSuccessInner}/> : null}
    </ComponentEuiFlexItem>
}

export default ComponentNavActionModalButton