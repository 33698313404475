import {useState} from 'react'

import { ComponentEuiFieldComboBox, ComponentNavFormFieldSearchSelect } from 'Component'
import { HookNavFormFieldSearch } from 'Hook'
import {TypeEntitySearchResult, TypeEuiFormFieldProps, TypeEuiPropsFieldComboBox, TypeNav } from 'Type'

type Props = TypeEuiFormFieldProps<string | null> & {nav :TypeNav<any>, searchKeys :string[]}

const ComponentNavFormFieldSearch = ({value, placeholder, isInvalid, fullWidth, isLoading, prepend, append, onChange, nav, searchKeys } :Props) => {

    const [searchStr, setSearchStr] = useState<string>('')
    const [searchKey, setSearchKey] = useState<string>(searchKeys[0])

    const [options, loading] :[TypeEntitySearchResult[], boolean] = HookNavFormFieldSearch(nav, searchKey ?? '', searchStr)

    const onComboBoxChange = (options :TypeEuiPropsFieldComboBox[]) => {
        if (options.length > 0 && options[0].key) {
            onChange(options[0].key);
        } else {
            onChange(null)
        }
    }

    const innerIsLoading :boolean = isLoading || loading

    const selectedOptions :TypeEntitySearchResult[] = options.filter((o :TypeEntitySearchResult) => o.key === (value || ''))

    if (searchKeys.length === 0) return null

    

    return <ComponentEuiFieldComboBox isLoading={innerIsLoading} onSearchChange={setSearchStr} onChange={onComboBoxChange}
                                      options={options} selectedOptions={selectedOptions} fullWidth={fullWidth}
                                      placeholder={placeholder} isInvalid={isInvalid}
                                      prepend={<ComponentNavFormFieldSearchSelect searchKeys={searchKeys} value={searchKey} onChange={setSearchKey}/>}
                                      append={append}/>
}

export default ComponentNavFormFieldSearch