import { OrganisationPage } from 'pages';
import { FinderNode } from 'types';
import Workspace from '../Workspace/Workspace';

const OrganisationProfile :FinderNode = {
    render: () => <OrganisationPage/>,
    next: {
        'ws': {
            render: () => <OrganisationPage tab={'ws'}/>,
            next: {'*': Workspace}
        },
        'api': {
            render: () => <OrganisationPage tab={'api'}/>,
            next: {}
        },
        'member': {
            render: () => <OrganisationPage tab={'member'}/>,
            next: {}
        }
    }
}

const Organisation :FinderNode = {
    next: {'*' : OrganisationProfile}
}

export default Organisation;