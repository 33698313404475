
import { ComponentEuiButtonIcon } from 'Component'
import { HookNavAction, HookNavResourceNav } from 'Hook'
import { useNavigate } from 'react-router-dom'
import { TypeEntityCell, TypeEntityExtract, TypeHttpResponse, TypeNav, TypeNavDataPage, TypeNavHttpControl, TypeNavHttpControlAction } from 'Type'


type Props = {
    cellNav :TypeNav<TypeEntityCell>
    entity :TypeEntityCell
}

const cellVersionNumber :(keyof TypeEntityExtract) = 'cellVersionNumber'

const ModuleTableCellExtractButton = ({ cellNav, entity } :Props) => {

    const navigate = useNavigate()

    const extractControl :TypeNavHttpControl<TypeEntityExtract> | undefined = HookNavResourceNav({key: 'extract', nav: cellNav, paramUpdates: [{type: 'filter-*', dynamic: [cellVersionNumber], values: [`${entity.versionNumber}`]}]})
    const extractNav :TypeNav<TypeEntityExtract> | undefined = extractControl.res?.data
    const extractPage :TypeNavDataPage<TypeEntityExtract> | undefined = extractNav?.data.page
    const extractEntity :TypeEntityExtract | undefined = (extractPage && extractPage.content.length && extractPage.content[0]) || undefined
    const actionControl :TypeNavHttpControlAction<TypeEntityExtract> | undefined = HookNavAction<TypeEntityExtract>('post', extractNav)
    const isLoading :boolean = actionControl.isLoading

    const isExtractExists :boolean = Boolean(extractEntity)
    const isEntityCurrentCell :boolean = entity.versionNumber === cellNav.data.entity?.versionNumber
    const isActionable :boolean = actionControl.isActionable

    const canCreate :boolean = isEntityCurrentCell && isActionable
    const canShowButton : boolean = canCreate || isExtractExists

    const onClick = async () => {
        if (canCreate) {
            const resp :TypeHttpResponse<TypeNav<TypeEntityExtract>> = await actionControl.submit()
            if (resp.data?.data.entity) navigate(resp.data.data.entity.linkTo)
        } else {
            if (extractEntity) navigate(extractEntity.linkTo)
        }
    }

    return (canShowButton) ? <ComponentEuiButtonIcon color={'text'} iconType={'inspect'} isLoading={isLoading} onClick={onClick}/> : null
}

export default ModuleTableCellExtractButton