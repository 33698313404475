import { FlexPageContent } from 'components'
import {FlexGroup, Page, PageBody } from 'eui'
import { UserPageContent, UserPageLeftNavBar } from 'modules'
import {NavKey } from 'types'

type Props = {
    tab? :NavKey
}

const UserPage = ({tab} :Props) => {
    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar/>
                <FlexPageContent>
                    <UserPageContent tabKey={tab}/>
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default UserPage