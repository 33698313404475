import { RecordNavFieldInput } from 'Record'
import { TypeEuiFormFieldProps, TypeNav, TypeNavControlActionFormField, TypeHttpPayloadValue } from 'Type'

type Props = {
    field :TypeNavControlActionFormField
    nav :TypeNav<any>
    value :TypeHttpPayloadValue
    onChange :(field :string, value: TypeHttpPayloadValue) => void
    error? :string
    fullWidth? :boolean
    compressed? :boolean
    disabled? :boolean
}

const ComponentNavFormField = ({field, nav, value, onChange, error, fullWidth, compressed, disabled} :Props) => {

    const props :TypeEuiFormFieldProps<TypeHttpPayloadValue> = {
        value: value ?? '',
        onChange: (newValue :TypeHttpPayloadValue) :void => onChange(field.name, newValue),
        placeholder: field.placeholder,
        isInvalid: Boolean(error),
        fullWidth: fullWidth,
        compressed: compressed,
        isLoading: false,
        disabled: disabled,
        readOnly: false,
        options: field.options
    }

    return RecordNavFieldInput[field.type].render(props, nav, field.search);
}

export default ComponentNavFormField