import {ComponentEuiButtonEmpty, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { ModuleTransformTitlePopoverHeaderContent } from 'Module'
import { TypeControlSwitch, TypeEntityTransform, TypeModuleControlRefresh, TypeNav } from 'Type'

type Props = {
    transformNav :TypeNav<TypeEntityTransform>
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformTitlePopoverHeader = ({transformNav, refreshControl} :Props) => {

    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'none'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} color={'primary'} iconType={'arrowDown'} iconSide={'right'} size={'s'}>{'Header'}</ComponentEuiButtonEmpty>}>
        <ModuleTransformTitlePopoverHeaderContent transformNav={transformNav} refreshControl={refreshControl} onClose={menuSwitch.turnOff}/>
    </ComponentEuiPopover>
}

export default ModuleTransformTitlePopoverHeader