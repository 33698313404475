import React, {ChangeEvent, ReactElement, useState} from 'react'
import moment from 'moment-timezone'
import {EuiDatePicker} from '@elastic/eui'
import { Moment } from 'moment'

import './FieldYearmonth.css'
import { Field } from 'props'
import {ButtonEmpty, ListGroup, Popover, Spacer } from 'eui'

const STRING_FORMAT = 'YYYY-MM';

const FieldYearmonth = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true}: Field) => {

    const valueStr : string = (typeof value === 'string') ? value : ''

    const datetime = (valueStr !== '') ? moment(valueStr, STRING_FORMAT) : undefined

    const fn = (date: moment.Moment | null, event?: React.SyntheticEvent<any>) => {
        onChange(name, (date) ? date.format(STRING_FORMAT) : '')
    }

    return <div className={'custom-datepicker'}>
        <EuiDatePicker
            dayClassName={() => {return 'custom-yearmonth-display-none'}}
            calendarClassName={'custom-yearmonth'}
            dateFormat={STRING_FORMAT}
            selected={datetime}
            onChange={fn}
    /></div>
}

export default FieldYearmonth