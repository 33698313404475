import {EuiForm} from '@elastic/eui'
import {PropsWithChildren, ReactNode} from 'react'

type Props = {
    component? :'div' | 'form'
    isInvalid? :boolean
    error? :ReactNode | ReactNode[]
    invalidCallout? :'none' | 'above'
    fullWidth? :boolean
}

const ComponentEuiForm = ({ component, isInvalid, error, invalidCallout, fullWidth, children } :PropsWithChildren<Props>)  => {
    return <EuiForm component={component} isInvalid={isInvalid} error={error} invalidCallout={invalidCallout} fullWidth={fullWidth}>
        { children }
    </EuiForm>
}

export default ComponentEuiForm