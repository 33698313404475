import {AboutPage, DocumentationPage, HomePage, PrivacyPage, UserPage } from 'pages';
import { FinderNode } from 'types';
import { RouteUtils } from 'utils';
import Api from './Api/Api';
import Organisation from './Organisation/Organisation';
import User from './User/User';
import Responder from './Responder/Responder';

const Home :FinderNode = {
    render: () => <HomePage/>,
    next: {
        user: User,
        org: Organisation,
        api: Api,
        web: Responder,
        about: {
            render: () => <AboutPage/>,
            next: {}
        },
        privacy: {
            render: () => <PrivacyPage/>,
            next: {}
        },
        documentation: {
            render: () => <DocumentationPage/>,
            next: {}
        }
    }
}

type Props = {
    path :string
}

type FinderResponse = {
    render :() => JSX.Element
    path :string,
}

const error = () :JSX.Element => {
    return <div>Error</div>
}

const PageFinder = ({ path } :Props) :FinderResponse => {
    const parts :string[] = path.split('/').filter(p => p !== "")
    let node :FinderNode = Home;
    let response :FinderResponse = {render: () => <HomePage/>, path: '/'}
    for (let i = 0; i < parts.length; i++) {
        let part = parts[i]
        let n :FinderNode | undefined = (node.next['*']) ? node.next['*'] : node.next[part]
        if (!n) break;
        node = n
        if (n.render) response = {render: n.render, path: RouteUtils.buildRoute(parts, i)}

    }
    return response
}

export default PageFinder;