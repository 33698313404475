import AuditDisplay from 'display/Audit/AuditDisplay';
import DescriptionDisplay from 'display/parts/DescriptionDisplay';
import IsPublicDisplay from 'display/parts/IsPublicDisplay';
import NameDisplay from 'display/parts/NameDisplay';
import OwnerDisplay from 'display/parts/OwnerDisplay';
import { WorkspaceEntity } from 'entity';
import { DisplayConfig } from 'types';

const config :DisplayConfig<WorkspaceEntity>[] = [...NameDisplay, ...DescriptionDisplay, ...IsPublicDisplay, ...OwnerDisplay, ...AuditDisplay]

export default config