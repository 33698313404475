import { NavActionModal } from 'components'
import {Button, ButtonIcon, FlexGroup, FlexItem } from 'eui'
import { NavFileDownloadButton } from 'modules'
import { useState } from 'react'
import {DataPayload, Nav, NavControlAction, NavControlActionType, NavKey } from 'types'
import { MappingUtils, StringUtils } from 'utils'

type Props = {
    contextKey :NavKey
    nav :Nav
    button? : 'empty' | 'normal'
    onSuccess? :(response: Nav, req? :DataPayload) => void
    toggleIcon? :string
    startOpen? :boolean
    buttonSize? : 's' | 'm'
    showDownload? :boolean
    buttonTitle? :{[key :string] :string}
    side? :'left' | 'right',
    ignore? :NavControlActionType[]
}

type TypeButtonProps = {
    color :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    iconType? :string
    isDisabled? :boolean
    onClick: () => void
}

const propsByType = (action: NavControlAction, setSelectedActionType: (type :NavControlActionType) => void, buttonSize? :'s' | 'm') :TypeButtonProps => {

    const props :any = {
        isDisabled: !action.auth.authorized,
        onClick: () => setSelectedActionType(action.action),
        size: buttonSize
    }

    switch (action.action) {
        case 'put' : return {...props, color: 'primary', iconType: 'documentEdit'}
        case 'post' : return {...props, color: 'primary', iconType: 'plusInCircle'}
        case 'delete' :return {...props, color: 'danger', iconType: 'alert'}
    }
    return {...props, color: 'text'}
}

const textByType = (type :NavControlActionType, contextKey :NavKey) :string => {
    switch (type) {
        case 'post' : return `New ${MappingUtils.contextTitle(contextKey)}`
        case 'put' : return `Update ${MappingUtils.contextTitle(contextKey)}`
    }
    return StringUtils.capitalizeFirst(type)
}

const GenericActionButtons = ({contextKey, nav, button = 'normal', onSuccess, toggleIcon, startOpen, buttonSize, showDownload = true, buttonTitle, ignore = []} :Props) => {

    const [selectedActionType, setSelectedActionType] = useState<NavControlActionType | undefined>(undefined)

    const [toggle, setToggle] = useState<boolean>(!toggleIcon)

    const doToggle = () => setToggle(!toggle)

    const closeModal = () => setSelectedActionType(undefined)

    const onSuccessInner = (response: Nav, req? :DataPayload) => {
        closeModal()
        if (onSuccess) onSuccess(response, req)
    }

    const actions :NavControlAction[] = Object.values(nav.control.action).filter((action :NavControlAction) => !ignore.includes(action.action))

    return <>
        <FlexGroup gutterSize={'m'}>
            {toggleIcon && actions.length > 0 && <FlexItem><ButtonIcon ariaLabel={'page-actions'} onClick={doToggle} iconType={toggleIcon} size={buttonSize}/></FlexItem>}
            {toggle && actions.map((action: NavControlAction) => {
                const buttonProps :TypeButtonProps = propsByType(action, setSelectedActionType, buttonSize)
                const title :string = buttonTitle && buttonTitle[action.action] || textByType(action.action, contextKey)
                return <FlexItem key={action.action}>
                    {(button === 'normal') ?
                        <Button {...buttonProps} fill={true}>{title}</Button> :
                        <Button {...buttonProps} fill={false}>{title}</Button>
                    }
                </FlexItem>
            })}
            {showDownload && nav.control.downloadable && <FlexItem><NavFileDownloadButton nav={nav} size={buttonSize}/></FlexItem>}
        </FlexGroup>
        {selectedActionType && <NavActionModal nav={nav} actionType={selectedActionType} onClose={closeModal} onSuccess={(onSuccess) ? onSuccessInner : undefined} title={textByType(selectedActionType, contextKey)}/>}
    </>
}

export default GenericActionButtons