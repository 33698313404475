import {ComponentDivWidth, ComponentNavActionPopoverPatch } from 'Component'
import { HookNavGet } from 'Hook'
import { TypeEntityTransformHeader, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    header :TypeEntityTransformHeader
    onSuccess :() => Promise<void>
    onClose :() => void
}

const ModuleTransformOutputTablePopoverHeaderContent = ({ header, onSuccess, onClose } :Props) => {

    const navControl :TypeNavHttpControl<TypeEntityTransformHeader> | undefined = HookNavGet<TypeEntityTransformHeader>(header)
    const nav :TypeNav<TypeEntityTransformHeader> | undefined = navControl.res?.data

    const innerOnSuccess = async () => {
        const promise :Promise<any> = navControl.submit()
        await onSuccess()
        await promise
    }

    return <ComponentDivWidth width={400}>
        { nav && <ComponentNavActionPopoverPatch entityName={header.destinationColumnName} nav={nav} onSuccess={innerOnSuccess} onClose={onClose}/>}
    </ComponentDivWidth>
}

export default ModuleTransformOutputTablePopoverHeaderContent