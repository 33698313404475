import { FlexPageContent } from 'components'
import {FlexGroup, Page, PageBody } from 'eui'
import {ApiPageContent, ApiPageLeftNavBar } from 'modules'
import { NavKey } from 'types'


type Props = {
    tab? :NavKey
}

const ApiPage = ({tab} :Props) => {
    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <ApiPageLeftNavBar/>
                <FlexPageContent>
                    <ApiPageContent tabKey={tab}/>
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default ApiPage