import {LinkToEntity} from "../../entity";
import {Nav, NavControlResource, NavKey} from "../../types";
import {
    ButtonEmpty,
    FlexGroup,
    FlexItem, LoadingSpinner,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalHeaderTitle,
    Spacer
} from "../../eui";
import {GenericActionButtons} from "../index";
import {EntityDisplay} from "../../components";
import {MappingUtils, NavClient} from "../../utils";
import {useEffect, useState} from "react";
import {NavStore} from "../../store";
import {ModalResourceTablePage} from 'modules'

type Props = {
    entity :LinkToEntity
    entityKey :NavKey
    resourceKey :NavKey
    onClose :() => void
}


const GenericEntityResourceModal = ({entity, entityKey, resourceKey, onClose} :Props) => {


    const refresh :() => void = NavStore(store => store.refresh)

    const [entityNav, setEntityNav] = useState<Nav | undefined>(undefined)

    const resource :NavControlResource | undefined = entityNav && entityNav.control.resource[resourceKey]

    const fetchEntityNav = async () => {
        setEntityNav(undefined)
        setEntityNav(await NavClient.fetchNav(entity.linkTo))
    }

    useEffect(() => {
        fetchEntityNav()
    }, [entity.linkTo])

    return <Modal onClose={onClose}>
        <ModalHeader>
            {entityNav && <ModalHeaderTitle><h1>{entityNav.meta.title}</h1></ModalHeaderTitle>}
        </ModalHeader>
        <ModalBody>
            {(entityNav) ? <>
                <FlexGroup justifyContent={'spaceBetween'}>
                    <FlexItem></FlexItem>
                    <FlexItem><GenericActionButtons nav={entityNav} contextKey={entityKey} onSuccess={refresh} toggleIcon={'gear'} buttonSize={'s'}/></FlexItem>
                </FlexGroup>
                <EntityDisplay object={entityNav.data.entity} config={(entityKey) ? MappingUtils.contextDisplayConfig(entityKey) : undefined}/>
            </> : <LoadingSpinner/>}
            <Spacer/>
            {resource ? <ModalResourceTablePage navResource={resource} contextKey={resourceKey}/> : undefined}
        </ModalBody>
        <ModalFooter>
            <ButtonEmpty color={'danger'} onClick={onClose}>Close</ButtonEmpty>
        </ModalFooter>
    </Modal>
}

export default GenericEntityResourceModal