import { FlexPageContent } from 'components'
import {FlexGroup, Page, PageBody } from 'eui'
import {NavKey } from 'types'
import {ResponderPageLeftNavBar} from 'modules'
import {ResponderPageContent} from "../../modules";

type Props = {
    tab? :NavKey
}

const ResponderPage = ({tab} :Props) => {

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <ResponderPageLeftNavBar tab={tab}/>
                <FlexPageContent>
                    <ResponderPageContent tab={tab}/>
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default ResponderPage