
import {useEffect, useState} from "react";
import {NavPageForm} from "../../components";
import {InterfaceMapEntity, ResponderEntity} from "../../entity";
import {FlexGroup, FlexItem, PageSection, Spacer} from "../../eui";
import {useNav} from "../../hooks";
import {NavStore} from "../../store";
import {DataPayload, Nav, NavControlResource, NavHookControl, NavKey} from "../../types";
import {InterfaceMappingSection, InterfaceMappingTestResponse} from "../index";

const responderKey :NavKey = 'responder'
const mapKey :NavKey = 'map'
const testKey :NavKey = 'test'

const dir :string = 'res'

const ResponderPageContentTest = () => {

    const mapNavControl :NavHookControl = useNav()
    const nav :Nav | undefined = NavStore(store => store.nav)
    const responderNav :Nav | undefined = NavStore(store => store.contextNavs[responderKey])
    const mapResource :NavControlResource | undefined = responderNav?.control.resource[mapKey]
    useEffect(() => {if (mapResource) mapNavControl.fetch(mapResource.linkTo)}, [mapResource && mapResource.linkTo])
    const mapNav :Nav | undefined = mapNavControl.nav
    const [testResponseData, setTestResponseData] = useState<any | undefined>()

    const onSuccess = (response: Nav, req? :DataPayload) => setTestResponseData(response.data.entity)

    const mapEntity :InterfaceMapEntity | undefined = mapNav?.data.page?.content?.find((m :InterfaceMapEntity) => (m.direction === dir))

    if (!nav) return null

    return <PageSection>
        <FlexGroup>
            <FlexItem grow>
                <NavPageForm nav={nav} title={'Test Service'} actionType={'post'} fullWidth hasBorder onSuccess={onSuccess}/>
            </FlexItem>
            <FlexItem grow>
                <Spacer/>
                {mapEntity && <InterfaceMappingTestResponse responseData={testResponseData} mapEntity={mapEntity}/>}
            </FlexItem>
        </FlexGroup>
    </PageSection>
}

export default ResponderPageContentTest