import { ContextNavMeta } from 'Context'
import {useContext, useEffect} from 'react'
import {TypeContextNavMeta, TypeNavMeta } from 'Type'

const HookNavGlobalIdMeta = (globalId :string) :TypeNavMeta | undefined => {
    const { map, fetchMeta } :TypeContextNavMeta = useContext(ContextNavMeta)

    const meta :TypeNavMeta | undefined = map[globalId]

    useEffect(() => {
        if (!meta) fetchMeta([globalId])
    }, [globalId])

    return map[globalId]
}

export default HookNavGlobalIdMeta