import React from 'react'
import {EuiText} from '@elastic/eui'
import { Children } from 'props';

type Props = Children & {
    textAlign?: 'left' | 'right' | 'center'
    size?: 'xs' | 's' | 'm'
    color?: 'default' | 'secondary' | 'accent' | 'warning' | 'danger' | 'subdued' | 'ghost'
    grow?: boolean
};

const Text = ({textAlign, size, color, grow,children}: Props ) =>
    <EuiText textAlign={textAlign} size={size} color={color} grow={grow}>{children}</EuiText>

export default Text