import {ComponentEuiButtonIcon, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiIcon } from 'Component'
import {HookNavGet } from 'Hook'
import { useEffect } from 'react'
import { TypeEntityInterfaceMap, TypeEntityRequester, TypeNavHttpControl, TypeNavHttpControlActionAuto, TypeUrlParam } from 'Type'

type Props = {
    req :TypeEntityRequester
    responderMap :TypeEntityInterfaceMap
    addMapControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMap>
    onSuccess :() => void
}

const interfaceUuid :(keyof TypeEntityInterfaceMap) = 'interfaceUuid'

const ModuleVersionConnectionMenuDirection = ({req, responderMap, addMapControl, onSuccess} :Props) => {

    const paramUpdates :TypeUrlParam[] = [{type: 'filter-*', dynamic: [interfaceUuid], values: [responderMap.interfaceUuid]}]
    const tableInterfaceMapsControl :TypeNavHttpControl<TypeEntityInterfaceMap> = HookNavGet({linkTo: req.linkTo, resource: 'map', paramUpdates})

    const isTableMapped :boolean = tableInterfaceMapsControl.res?.data?.data.page?.totalElements === 1

    const onClick = () => {
        addMapControl.addToPayload({ interfaceUuid: responderMap.interfaceUuid })
    }

    useEffect(() => {
        onSuccess()
        tableInterfaceMapsControl.submit()
    }, [addMapControl.submittedSuccess])

    if (!tableInterfaceMapsControl.res?.data) return null

    return <ComponentEuiFlexGroup justifyContent={'spaceBetween'}>
        <ComponentEuiFlexItem>{ responderMap.name }</ComponentEuiFlexItem>
        <ComponentEuiFlexItem>{ (isTableMapped) ? <ComponentEuiIcon color={'success'} type={'check'} /> : <ComponentEuiButtonIcon color={'primary'} iconType={'plusInCircle'} onClick={onClick}/>}</ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ModuleVersionConnectionMenuDirection