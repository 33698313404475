import { ComponentEuiModal,
    ComponentEuiModalFooter, ComponentEuiModalHeader, ComponentEuiModalHeaderTitle, ComponentNavDataPageTable, ComponentNavFormButtonCancel } from 'Component'
import {ReactNode} from 'react'
import {TypeEntityLinkTo, TypeFieldDisplay, TypeNav } from 'Type'
import { UtilString } from 'Util'


type Props<T extends object & TypeEntityLinkTo> = {
    initialNav :TypeNav<T>
    showColumns :(keyof T)[]
    entityName :string
    onClose :() => void
    action?: 'navigate' | 'menu' | 'custom'
    customButtons? :(nav :TypeNav<T>) => ReactNode[]
    customRenders? : { [field: string]: TypeFieldDisplay }
}

const ComponentNavDataPageTableModal = <T extends object & TypeEntityLinkTo>({initialNav, showColumns, entityName, action, customButtons, customRenders, onClose} :Props<T>) => {
    return <ComponentEuiModal onClose={onClose}>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(entityName)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentNavDataPageTable initialNav={initialNav} showColumns={showColumns} entityName={entityName} action={action} customButtons={customButtons} customRenders={customRenders}/>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonCancel onClick={onClose}/>
        </ComponentEuiModalFooter>
    </ComponentEuiModal>
}

export default ComponentNavDataPageTableModal