import { ComponentEuiButtonIcon } from 'Component'
import { HookControlSwitch, HookNavDownloadBlob } from 'Hook'
import { ModuleTableCellImagePreviewModal } from 'Module'
import { TypeControlSwitch, TypeEntityCell, TypeNav, TypeNavControlDownloadBlob } from 'Type'

type Props = {
    nav :TypeNav<TypeEntityCell>
}

const ModuleTableCellImagePreview = ({ nav } :Props) => {

    const modalSwitch :TypeControlSwitch = HookControlSwitch()

    const blobControl :TypeNavControlDownloadBlob = HookNavDownloadBlob((modalSwitch.value) ? nav : undefined)

    return <>
        <ComponentEuiButtonIcon color={'text'} iconType={'image'} isLoading={blobControl.isLoading} onClick={modalSwitch.turnOn}/>
        { modalSwitch.value && blobControl.blob && <ModuleTableCellImagePreviewModal blobControl={blobControl} onClose={modalSwitch.turnOff}/> }
    </>
}

export default ModuleTableCellImagePreview