import { ComponentEuiFlexGroup, ComponentEuiIcon } from 'Component'
import { FlexItem } from 'eui'
import { TypeEuiPropsIconType } from 'Type'


type Props = {
    title :string
    iconType :TypeEuiPropsIconType
    entityName :string
}

const ComponentNavSidebarTitle = ({ title, iconType, entityName } :Props) =>
    <>
        <div style={{backgroundColor: '#25282f', borderRadius: '6px', padding: '12px', color: 'white'}}>
            <ComponentEuiFlexGroup alignItems={'baseline'} gutterSize={'s'}>
                <FlexItem grow><span style={{color: '#FFF', fontWeight: 500}}>{title}</span></FlexItem>
                <FlexItem><span style={{}}><ComponentEuiIcon type={iconType}/></span></FlexItem>
            </ComponentEuiFlexGroup>
        </div>
    </>

export default ComponentNavSidebarTitle