import { ComponentEuiTablePagination } from 'Component'
import { TypeNavControlPaginate } from 'Type'

type Props<T> = {
    control :TypeNavControlPaginate<T>
    itemsPerPageOptions? :number[]
    action? :'navigate' | 'menu'
    compressed? :boolean
    showPerPageOptions? :boolean
}

const ComponentNavDataPagePagination = <T,>({control, itemsPerPageOptions = [5, 10, 25, 50, 100], compressed, showPerPageOptions = true} :Props<T>) => {

    const { size, setSize, page, setPage, pageCount } = control

    return <ComponentEuiTablePagination showPerPageOptions={showPerPageOptions} itemsPerPage={size} activePage={page} pageCount={pageCount}
                                        itemsPerPageOptions={itemsPerPageOptions} onChangeItemsPerPage={setSize} onChangePage={setPage} compressed={compressed}/>
}

export default ComponentNavDataPagePagination