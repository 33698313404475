
const StringUtils = {
    capitalizeFirst: (string :string) :string => {
        return (string.length === 0) ? string : string.charAt(0).toUpperCase() + string.slice(1)
    },
    deCamel: (string :string) :string => {
        return  string.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(str){ return str.toUpperCase(); })
    }
}

export default StringUtils