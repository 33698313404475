import {
    ComponentEuiBadge, ComponentEuiFlexGroup, ComponentEuiFlexItem,
    ComponentEuiSpacer,
    ComponentNavDataPageTableSidebar, ComponentNavSidebarButtonAction, ComponentNavSidebarContextEntity, ComponentNavSidebarTitle, ComponentPageSidebarEntity } from "Component"
import { HookNavContext, HookNavContextOptional, HookNavGet, HookNavGetCount, HookNavResourceNav } from "Hook"
import { ModuleTransformLoad } from "Module"
import {ReactNode} from "react";
import {TypeEntityChild, TypeEntityExtractSheet, TypeEntityRow, TypeEntityTable,
    TypeEntityTransform, TypeEntityTransformDestination, TypeEntityTransformLoad, TypeNav, TypeNavHttpControl } from "Type"

type Props = {
    pageControl :TypeNavHttpControl<TypeEntityRow>
    loadNav? :TypeNav<TypeEntityTransformLoad>
    destination? :TypeEntityTransformDestination
}

const PageRowSidebar = ({ pageControl, loadNav, destination } :Props) => {
    const nav :TypeNav<TypeEntityRow> | undefined = pageControl.res?.data
    if (!nav) return null

    const tableContext :TypeEntityTable | undefined = HookNavContext('table', nav)
    const rowContext :TypeEntityRow | undefined = HookNavContext('row', nav)
    const childContext :TypeEntityChild | undefined = HookNavContextOptional('child', nav)
    const sheetContext :TypeEntityExtractSheet | undefined = HookNavContextOptional('sheet', nav)
    const transformContext :TypeEntityTransform | undefined = HookNavContextOptional('transform', nav)

    const onSheetPage :boolean = Boolean(sheetContext && !transformContext)

    const sheetControl :TypeNavHttpControl<TypeEntityExtractSheet> = HookNavGet<TypeEntityExtractSheet>({linkTo: (onSheetPage) ? sheetContext?.linkTo : undefined})
    const sheetNav :TypeNav<TypeEntityExtractSheet> | undefined = sheetControl.res?.data
    const transformResourceControl :TypeNavHttpControl<TypeEntityTransform> = HookNavResourceNav<TypeEntityTransform>({key: 'transform', nav: sheetNav})

    if (!tableContext || !rowContext) return null

    const destinationTableRowCount :number | undefined = HookNavGetCount(destination)
    const destinationTableRowCountBadge :ReactNode = destinationTableRowCount && <ComponentEuiBadge color={'primary'}>{destinationTableRowCount}</ComponentEuiBadge>

    return <ComponentEuiFlexGroup gutterSize={'none'} direction={'column'} style={{height: '100%'}}>
        <ComponentEuiFlexItem grow>
            <ComponentNavSidebarTitle title={`${tableContext.name}, Row ${rowContext.rowId}`} iconType={'tableDensityNormal'} entityName={'Row'}/>
            <ComponentEuiSpacer/>
            <ComponentNavSidebarContextEntity title={'Owner'} navKey={'user'} nameFields={['firstName', 'lastName']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Organisation'} navKey={'org'} nameFields={['name']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Workspace'} navKey={'ws'} nameFields={['name']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Row Table'} navKey={'table'} nameFields={['name']} nav={nav}/>
            <ComponentNavSidebarContextEntity title={'Child Table'} navKey={'child'} nameFields={['childTableName']} nav={nav} hrefColumn={'childTableLinkTo'}/>
            <ComponentNavSidebarContextEntity title={'Sheet'} navKey={'sheet'} nameFields={['name']} nav={nav}/>
            <ComponentEuiSpacer size={'s'}/>
            { childContext && <ComponentNavSidebarButtonAction actionType={'post'} iconType={'plusInCircle'} nav={nav} title={childContext.childTableName} color={'primary'} fill/> }
            { (sheetContext && !transformContext) ? <ComponentNavDataPageTableSidebar navControl={transformResourceControl}
                  showColumn={'name'} entityName={'Transformation'} title={'Transformations'} emptyMessage={'You have not begun any transformations.'} navigateOnCreate/> : undefined}
            { (transformContext && loadNav && destination) ? <>
              <ComponentPageSidebarEntity title={'Destination'} name={destination.tableName} href={destination.linkTo} right={destinationTableRowCountBadge}/>
              <ComponentEuiSpacer size={'m'}/>
              <ModuleTransformLoad loadNav={loadNav} destination={destination}/>
            </> : undefined }

        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default PageRowSidebar