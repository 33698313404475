import {TypeEntityTransformHeader, TypeEntityTransformHeaderError } from 'Type'

import './ModuleTransformOutputTableCell.css'

type Props = {
    value :string
    header :TypeEntityTransformHeader
    error? :TypeEntityTransformHeaderError
}

const ModuleTransformOutputTableCell = ({value, header, error} :Props) => {
    const usingDefault :boolean = header.defaultValue !== undefined && Boolean(error && !error.fixValue) && header.defaultValue === value
    const usingErrorFix :boolean = Boolean(error && error.fixValue !== null)
    const spaceValue :string = (value === '') ? '-' : value
    return <div className={(usingDefault || usingErrorFix) ? 'transformation-error-cell-inner fixed' : (error) ? 'transformation-error-cell-inner error' : 'transformation-error-cell-inner'}>
        <div>{(error && error.fixValue) || (error && error.cellValue) || spaceValue}</div>
    </div>
}

export default ModuleTransformOutputTableCell