import React from 'react'
import {EuiModal} from '@elastic/eui'

type Props = {
    onClose: () => void
    children?: React.ReactNode
    style? :any
}

const Modal = ({children, style, onClose}: Props) =>
    <EuiModal style={style} onClose={onClose}>{children}</EuiModal>


export default Modal