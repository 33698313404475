
export const FORMAT_DATE = 'YYYY-MM-DD'
export const FORMAT_TIME = 'HH:mm:ss'
export const FORMAT_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss'
export const FORMAT_DATE_TIME_ZONE = 'YYYY-MM-DDTHH:mm:ss Z'
export const FORMAT_YEAR_MONTH = 'YYYY-MM'
export const FORMAT_TZ_UTC = 'UTC'

export const FORMAT_TELEPHONE_CHARS = '0123456789+()-. '

export const FORMAT_FILE_ACCEPT_EXCEL = '.xlsx'
export const FORMAT_FILE_ACCEPT_WORD = '.docx'
export const FORMAT_FILE_ACCEPT_PDF = '.pdf'
export const FORMAT_FILE_ACCEPT_IMAGE = '.jpeg, .png, .gif, .tiff, .psd, .pdf, .eps, .ai, .indd, .raw'

export const FORMAT_PAGE_STATIC = ['/', '/about', '/documentation', '/privacy', '/terms']
export const FORMAT_PAGE_HEADER_SINGLE_HEIGHT = 48

export const FORMAT_PAGE_URLS = {
  home: '/',
  userLogin: '/session/user',
  userPasswordReset: '/session/user/reset',
  userSignup: '/user'
}

export const FORMAT_LOGIN_FIELDS = {
  userId: 'userId',
  password: 'password'
}

export const FORMAT_TABLE_DATA_SYSTEM_COLUMNS = {
  rowId: 'rowId',
  createdAt: 'createdAt',
  createdBy: 'createdBy',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy'
}

export const FORMAT_TABLE_COLOR_FLASH_MS = 400