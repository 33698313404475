import { useLocation, useNavigate } from 'react-router-dom'
import { TypeControlPageLocation } from 'Type'

const HookPageLocation = () :TypeControlPageLocation => {
    const { pathname, search } = useLocation()
    const location = `${ pathname }${ search }`
    const navigate = useNavigate()
    const setLocation = (location :string) => navigate(location)
    return { location, setLocation }
}

export default HookPageLocation