import { ComponentDivMarginHorizontal, ComponentDivMarginVertical, ComponentEuiSpacer } from 'Component'
import { ModuleTableDataRow  } from 'Module'
import { PropsWithChildren } from 'react'
import { TypeModuleTableDataProps } from 'Type'

type Props = {
    rowTableProps :TypeModuleTableDataProps
}

const PageRowBody = ({ rowTableProps, children } :PropsWithChildren<Props>) => {
    return <>
        <ComponentDivMarginHorizontal margin={-1}>
            <ComponentDivMarginVertical margin={-1}>
                <ModuleTableDataRow {...rowTableProps}/>
            </ComponentDivMarginVertical>
        </ComponentDivMarginHorizontal>
        <ComponentEuiSpacer/>
        { children }
    </>
}

export default PageRowBody