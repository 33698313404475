import { EuiComboBox } from '@elastic/eui'
import { ReactElement } from 'react'
import { TypeEuiPropsFieldComboBox } from 'Type'

type Props = {
    prepend? :string | ReactElement
    append? :string | ReactElement
    onSearchChange :(searchValue: string, hasMatchingOptions?: boolean) => void
    options :TypeEuiPropsFieldComboBox[]
    isLoading? :boolean
    onChange :(option :TypeEuiPropsFieldComboBox[]) => void
    selectedOptions :TypeEuiPropsFieldComboBox[]
    fullWidth? :boolean
    placeholder? :string
    isInvalid? :boolean
}

const ComboBox = ({ isLoading, prepend, append, onSearchChange, options, onChange, selectedOptions, fullWidth, placeholder, isInvalid } :Props) => {
    return <EuiComboBox isLoading={isLoading} prepend={prepend} singleSelection={{ asPlainText: true }}
                        onSearchChange={onSearchChange} options={options} onChange={onChange}
                        selectedOptions={selectedOptions} fullWidth={fullWidth} placeholder={placeholder}
                        isInvalid={isInvalid} append={append}>
        {(list :any, search :any) => <>
            {search}
            {list}
        </>}
    </EuiComboBox>
}

export default ComboBox