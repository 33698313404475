import { AuditDisplay } from 'display';
import NameDisplay from 'display/parts/NameDisplay';
import { DisplayConfig } from 'types';

let config :DisplayConfig[] = [
    ...NameDisplay,
    {field: 'nameCamel', name: 'Name Camel'},
    {field: 'type', name: 'Type'},
    {field: 'isRequired', name: 'Is Required', render: (item :any) => (item) ? 'Yes' : 'No'},
    ...AuditDisplay
]

export default config