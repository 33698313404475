import React from 'react'
import { EuiHeaderSection } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
    grow?: boolean
}

const HeaderSection = ({ grow, children }: Props) => {
    return <EuiHeaderSection grow={grow}>{children}</EuiHeaderSection>
}

export default HeaderSection