import { PropsWithChildren, ReactElement, ReactNode} from 'react'
import { ComponentDivCenter, ComponentEuiEmptyPrompt, ComponentEuiFlexGrid, ComponentEuiFlexItem,
    ComponentEuiLoadingSpinner, ComponentEuiPageBody, ComponentPageTitle } from 'Component'
import { TypeHttpLoadable } from 'Type'
import { ModulePageLoadingItem } from 'Module'

type Props = {
    toLoad :TypeHttpLoadable[]
    onMisaligned? :boolean
}

const ModulePageLoading = ({toLoad, children, onMisaligned} :PropsWithChildren<Props>) => {

    const loading = (l :TypeHttpLoadable) => (onMisaligned) ? (l.control.isMisaligned && l.control.isLoading) : !l.control.res
    const errored = (l :TypeHttpLoadable) => l.control.res?.error

    const stillLoading :boolean = Boolean(toLoad.find(loading))
    const hasErrored :boolean = Boolean(toLoad.find(errored))

    if (!stillLoading) return <>{children}</>

    const title :ReactElement = <ComponentPageTitle>{(hasErrored) ? 'Error' : 'Loading'}</ComponentPageTitle>
    const body :ReactNode = <ComponentEuiFlexGrid columns={1} gutterSize={'l'}>
        { toLoad.map((load :TypeHttpLoadable) => <ComponentEuiFlexItem key={load.label}><ModulePageLoadingItem load={load} onMisaligned={onMisaligned}/></ComponentEuiFlexItem>) }
    </ComponentEuiFlexGrid>

    return <ComponentEuiPageBody panelled panelProps={{hasShadow: false}}>
        <ComponentDivCenter>
            <ComponentEuiEmptyPrompt
                title={title}
                body={body}
                icon={<ComponentEuiLoadingSpinner size={'xxl'}/>}
            />
        </ComponentDivCenter>
    </ComponentEuiPageBody>
}

export default ModulePageLoading