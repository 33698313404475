import {ComponentEuiDescriptionList, ComponentMoment, ComponentNavGlobalIdLink } from 'Component'
import {TypeEntityAudit, TypeEuiPropsDescriptionListItem } from 'Type'

type Props = {
    entity? :any
}

const getAuditValue = (key :(keyof TypeEntityAudit), entity? :any) :any |undefined => entity[key]

const ComponentEntityAuditDisplay = ({ entity } :Props) => {
    const listItems :TypeEuiPropsDescriptionListItem[] = [
        {
            title: 'Created By',
            description: <ComponentNavGlobalIdLink globalId={getAuditValue('createdBy', entity)} />
        },
        {
            title: 'At',
            description: <ComponentMoment value={getAuditValue('createdAt', entity)} display={'date-time-z'}/>
        },

    ]

    return <ComponentEuiDescriptionList listItems={listItems} compressed type={'inline'}/>
}

export default ComponentEntityAuditDisplay