import { useNavigate } from 'react-router-dom'
import { TypeEntityLinkTo, TypeNavKey } from 'Type'

type Props = {
    entity? :TypeEntityLinkTo,
    resource? :TypeNavKey
}

const HookNavNavigateEntity = ({entity, resource} :Props) :() => void => {
    const navigate = useNavigate()
    if (!entity) return () => {}
    const linkTo :string = (resource) ? `${entity.linkTo}/${resource}` : entity.linkTo
    return () => navigate(linkTo)
}

export default HookNavNavigateEntity