import React from 'react'
import { EuiHeaderSectionItem } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
    border?: 'left' | 'right' | 'none'
}

const HeaderSectionItem = ({ border, children }: Props) =>
    <EuiHeaderSectionItem border={border}>{children}</EuiHeaderSectionItem>

export default HeaderSectionItem