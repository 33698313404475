import {ComponentEuiFlexGrid, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiSpacer, ComponentEuiText,
    ComponentNavActionModalButton, ComponentNavDataPagePagination, ComponentNavDataPageTableSidebarItem, ComponentPageTitle } from 'Component'
import { HookNavPaginate } from 'Hook'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { TypeEntityLinkTo, TypeNav, TypeNavControlPaginate, TypeNavHttpControl } from 'Type'


type Props<T extends object & TypeEntityLinkTo> = {
    navControl :TypeNavHttpControl<T>
    showColumn :(keyof T)
    title :string
    entityName :string
    emptyMessage :string
    customRender? :(entity :T) => ReactNode
    navigateOnCreate? :boolean
}

const linkTo :(keyof TypeEntityLinkTo) = 'linkTo'

const ComponentNavDataPageTableSidebar = <T extends object & TypeEntityLinkTo>({ navControl, showColumn, entityName, title, emptyMessage, customRender, navigateOnCreate } :Props<T>) => {
    const nav :TypeNav<T> | undefined = navControl.res?.data
    const paginationControl :TypeNavControlPaginate<T> = HookNavPaginate<T>({ initialNav: nav })
    const totalElements :number = nav?.data.page?.totalElements ?? 0
    const totalPages :number = nav?.data.page?.totalPages ?? 0

    const navigate = useNavigate()

    if (!nav) return null

    const onSuccess = (entity? :any) => {
        if (navigateOnCreate && entity && entity[linkTo]) {
            navigate(entity[linkTo])
        } else {
            navControl.submit()
        }
    }

    return <div>
        <ComponentEuiFlexGroup>
            <ComponentEuiFlexItem grow>
                <ComponentPageTitle secondary={true}>{ title }</ComponentPageTitle>
            </ComponentEuiFlexItem>
            <ComponentEuiFlexItem>
                <ComponentNavActionModalButton color={'primary'} nav={nav} buttonType={'icon'} entityName={entityName} buttonTitle={''} actionType={'post'} iconType={'plusInCircle'} onSuccess={onSuccess}/>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup>
        <ComponentEuiSpacer size={'m'}/>
        <ComponentEuiFlexGrid columns={1} gutterSize={'m'}>
            { (nav?.data.page?.content ?? []).map((entity :T) => {
                return <ComponentEuiFlexItem key={entity.linkTo} grow>
                    { (customRender) ? customRender(entity) : <ComponentNavDataPageTableSidebarItem entity={entity} showColumn={showColumn}/> }
                </ComponentEuiFlexItem>
            }) }
        </ComponentEuiFlexGrid>
        { (totalPages > 1) ? <ComponentEuiFlexGroup justifyContent={'flexEnd'}>
            <ComponentEuiFlexItem>
                <ComponentEuiSpacer size={'m'}/>
                <ComponentNavDataPagePagination control={paginationControl} compressed showPerPageOptions={false}/>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup> : null}
        { (totalElements === 0) ? <ComponentEuiFlexGroup justifyContent={'spaceAround'}>
            <ComponentEuiFlexItem>
                <ComponentEuiText textAlign={'center'} color={'subdued'}><small>{emptyMessage}</small></ComponentEuiText>
            </ComponentEuiFlexItem>
        </ComponentEuiFlexGroup> : null }
    </div>
}

export default ComponentNavDataPageTableSidebar