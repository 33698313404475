import {EuiSideNav} from '@elastic/eui'
import {MouseEventHandler, ReactElement, ReactNode, ReactPortal } from 'react'
import { SideNavItem } from 'types'

type HeadingProps = {
    element? :'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span'
}

type MobileBreakPointProps = 'xs' | 's' | 'm' | 'l' | 'xl'

type Props = {
    heading? :string | number | boolean | {} | ReactElement | Iterable<ReactNode> | ReactPortal
    headingProps? :HeadingProps
    toggleOpenOnMobile? :MouseEventHandler<HTMLButtonElement>
    isOpenOnMobile? :boolean
    mobileTitle? :string | number | boolean | {} | ReactElement | Iterable<ReactNode> | ReactPortal
    mobileBreakpoints? :MobileBreakPointProps[]
    items? :SideNavItem[]
    truncate? :boolean
}

const SideNav = ({heading, headingProps, toggleOpenOnMobile, isOpenOnMobile, mobileTitle, mobileBreakpoints, items, truncate} : Props) => {
    return <EuiSideNav heading={heading} headingProps={headingProps} toggleOpenOnMobile={toggleOpenOnMobile}
                       isOpenOnMobile={isOpenOnMobile} mobileTitle={mobileTitle} mobileBreakpoints={mobileBreakpoints}
                       items={items} truncate={truncate}/>
}

export default SideNav