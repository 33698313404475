import { EuiTablePagination } from "@elastic/eui"

type ResponsiveOpt = 'xs' | 's' | 'm' | 'l' | 'xl'

type Props = {
    showPerPageOptions? :boolean
    itemsPerPage? :number
    itemsPerPageOptions? :number[]
    onChangeItemsPerPage? :(pageSize :number) => void
    onChangePage? :(pageIndex :number) => void
    responsive? :false | ResponsiveOpt[]
    compressed? :boolean
    pageCount? :number
    activePage? :number
}

const TablePagination = ({showPerPageOptions, itemsPerPage, itemsPerPageOptions, onChangeItemsPerPage, onChangePage, responsive, compressed, pageCount, activePage} :Props) => {
    return <EuiTablePagination showPerPageOptions={showPerPageOptions} itemsPerPage={itemsPerPage}
                               itemsPerPageOptions={itemsPerPageOptions} onChangeItemsPerPage={onChangeItemsPerPage}
                               onChangePage={onChangePage} responsive={responsive} compressed={compressed}
                               pageCount={pageCount} activePage={activePage}/>
}
export default TablePagination