import { UserEntity } from 'entity'
import { GenericPageForm } from 'modules'
import { useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { Nav } from 'types'

const PasswordResetPage = () => {

    const navigate = useNavigate()

    const nav :Nav | undefined = NavStore(state => state.nav)

    const onSuccess = (responseNav :Nav) => {
        const user :UserEntity | undefined = nav && nav.context['user']
        navigate((user && user.linkTo) || '/')
    }

    return <GenericPageForm actionType={'patch'} onSuccess={onSuccess} title={'Reset Password'} />
}

export default PasswordResetPage