
import moment, { Moment } from 'moment-timezone'
import { TypeControlSwitch } from 'Type'
import { ComponentEuiButtonEmpty, ComponentEuiFieldComboBox, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { FORMAT_DATE_TIME_ZONE, FORMAT_TZ_UTC } from 'Format'

const OPTIONS = moment.tz.names().map(k => {return {label: k}})

type Props = {
    value? :string
    onChange :(value :Moment) => void
}

const ComponentEuiFieldPickerDateTimeZoneSelect = ({value, onChange} :Props) => {
    const control :TypeControlSwitch = HookControlSwitch()

    if (!value || value === '') return null

    const dateTimeZone :Moment = moment(value, FORMAT_DATE_TIME_ZONE )
    const tz :string = dateTimeZone.tz() || FORMAT_TZ_UTC

    const onSearchChange = () => {

    }

    const innerOnChange = (options :any) => {
        const newTz :string | undefined = options.length > 0 && options[0].label
        if (!newTz) return
        if (newTz) onChange(dateTimeZone.tz(newTz, true))
    }

    const selectedOptions = (tz) ? [{label: tz}] : []

    return <ComponentEuiPopover
        className={'euiFormControlLayout__prepend'}
        button={<ComponentEuiButtonEmpty size='s' iconType='arrowDown' iconSide='right' onClick={control.turnOn}>{tz}</ComponentEuiButtonEmpty>}
        closePopover={control.turnOff} isOpen={control.value} panelPaddingSize={'s'}>
        <div style={{width : '320px'}}>
            <ComponentEuiFieldComboBox
                options={OPTIONS}
                onSearchChange={onSearchChange}
                onChange={innerOnChange}
                selectedOptions={selectedOptions}
            />
        </div>
    </ComponentEuiPopover>
}

export default ComponentEuiFieldPickerDateTimeZoneSelect