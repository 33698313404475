import {TypeEntityRow, TypeEntityTable, TypeEntityTableVersionColumn, TypeNavKey } from 'Type'

const cellKey :TypeNavKey = 'cell'
const versionKey :TypeNavKey = 'version'
const rowKey :TypeNavKey = 'row'

const buildCellLinkTo = (row :TypeEntityRow, column :TypeEntityTableVersionColumn, resourceKey? :TypeNavKey) :string => {

    const cellLinkTo :string = `${row.linkTo}/${cellKey}/${column.nameSlug}`
    return (resourceKey) ? `${cellLinkTo}/${resourceKey}` : cellLinkTo
}

const tableToRowLinkTo = (table :TypeEntityTable) :string => {
    return `${table.linkTo}/${versionKey}/${table.latestVersionNumber}/${rowKey}`
}

const pathOnly = (path :string) :string => {
    if (path.includes("?")) return path.split("?")[0]
    return path
}

const pathMatch = (first :string, second :string) :boolean => (pathOnly(first) === pathOnly(second))

const pathContains = (path :string, contains :string) :boolean => (pathOnly(path).includes(pathOnly(contains)))

const UtilRoute = {
    buildCellLinkTo,
    tableToRowLinkTo,
    pathMatch,
    pathContains
}

export default UtilRoute