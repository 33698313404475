import {ApiEntity, UserEntity } from "entity"
import { ModalSession } from "modules"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { SessionStore } from "store"
import { DataPayload, Nav } from "types"
import { NavClient } from "utils"

type Props = {
    onClose: () => void
}

const extractFields = (fields :string[], from :DataPayload) :DataPayload => {
    const to :DataPayload = {}
    fields.forEach((k :string) => {
        if (from[k]) to[k] = from[k]
    })
    return to
}

const SignUpModal = ({onClose} :Props) => {

    const navigate = useNavigate()

    const setToken = SessionStore(state => state.setToken)
    const token = SessionStore(state => state.token)
    const entity :UserEntity | ApiEntity | undefined = SessionStore(state => state.entity)

    const login = async (req :DataPayload) => {
        const nav :Nav = await NavClient.dangerAction('/session/user', 'post', extractFields(['userId', 'password'], req))
        if (nav.data.entity) {
            const newEntity :UserEntity | ApiEntity | undefined = await setToken(nav.data.entity)
            if (newEntity) navigate(newEntity.linkTo)
        }
    }

    const onSuccessIntercept = async (nav :Nav, req? :DataPayload) => {
        if (nav.error) {
            navigate(nav.error.lastSuccessfulRoute)
        } else {
            if (req) await login(req)
            navigate(nav.path)
        }
        onClose()
    }

    if (token && entity) return null

    return <ModalSession type={'signup'} onSuccess={onSuccessIntercept} onClose={onClose}/>
}

export default SignUpModal