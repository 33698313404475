import { ComponentNavActionModalButton, ComponentNavDataPageTable, ComponentPagePanel } from 'Component'
import {HookNavResourceNav } from 'Hook'
import { ModuleVersionRequesterMapFieldTable } from 'Module'

import {TypeEntityInterfaceMap, TypeEntityTableVersion, TypeEntityTableVersionColumn, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    versionNav :TypeNav<TypeEntityTableVersion>
    columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
    mapControl :TypeNavHttpControl<TypeEntityInterfaceMap>
}

const ModuleVersionRequesterMap = ({ mapControl, columnControl, versionNav } :Props) => {
    const columnCount :number = columnControl.res?.data?.data.page?.totalElements ?? 0
    const mapNav :TypeNav<TypeEntityInterfaceMap> | undefined = mapControl.res?.data
    const allColumnControl :TypeNavHttpControl<TypeEntityTableVersionColumn> = HookNavResourceNav({key: 'column', nav: versionNav, paramUpdates: [{type: 'size', values: [`${columnCount}`]}] })
    const allColumnsNav :TypeNav<TypeEntityTableVersionColumn> | undefined = allColumnControl.res?.data

    const columnRefreshInner = () => {
        allColumnControl.submit()
        columnControl.submit()
    }

    if (!mapNav || !allColumnsNav) return null

    const rightActions = <ComponentNavActionModalButton nav={mapNav} buttonType={'icon'} actionType={'post'} iconType={'plusInCircle'} color={'primary'} entityName={'Interface Mapping'} onSuccess={mapControl.submit} buttonTitle={'Add Mapping'}/>
    const customMenuComponent = (navControl :TypeNavHttpControl<TypeEntityInterfaceMap>) => <ModuleVersionRequesterMapFieldTable navControl={navControl} versionNav={versionNav} columnRefresh={columnRefreshInner} allColumnsNav={allColumnsNav}/>

    return <ComponentPagePanel title={'Interface Mapping'} rightActions={rightActions}>
        <ComponentNavDataPageTable initialNav={mapNav} showColumns={['name']} entityName={'Columns'} action={'menu'} onActionSuccess={mapControl.submit} customMenuComponent={customMenuComponent}/>
    </ComponentPagePanel>
}

export default ModuleVersionRequesterMap