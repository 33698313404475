import {PageWorkspaceGeneric} from 'Page'
import {TypeEntityInterface, TypeEntityResponder, TypeEntityTable, TypeNavKey } from 'Type'

type Props = {
    activeResourceKey :TypeNavKey
}

const showTableColumns :(keyof TypeEntityTable)[] = ['name', 'isPublic']

const showIfaceColumns :(keyof TypeEntityInterface)[] = ['name', 'isPublic']

const showResponderColumns :(keyof TypeEntityResponder)[] = ['name', 'isPublic', 'type']

const PageWorkspace = ({ activeResourceKey } :Props) => {

    return (activeResourceKey === 'table') ? <PageWorkspaceGeneric activeResourceKey={activeResourceKey} showColumns={showTableColumns}/> :
        (activeResourceKey === 'iface') ? <PageWorkspaceGeneric activeResourceKey={activeResourceKey} showColumns={showIfaceColumns}/> :
            (activeResourceKey === 'responder') ? <PageWorkspaceGeneric activeResourceKey={activeResourceKey} showColumns={showResponderColumns}/> : null
}

export default PageWorkspace