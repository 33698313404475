import {EuiModalBody} from '@elastic/eui'
import {CSSProperties, ReactNode} from 'react'

import './ComponentEuiModalBody.css'

type Props = {
    children :ReactNode
    zeroPadding? :boolean
}

const ComponentEuiModalBody = ({ children, zeroPadding } :Props) => {

    return <EuiModalBody className={(zeroPadding) ? 'ComponentEuiModalBodyZeroPadding' : undefined}>{ children }</EuiModalBody>
}

export default ComponentEuiModalBody