import { useLocation, useNavigate, Location } from 'react-router-dom'
import { TypeControlParam, TypeUrlParamType } from 'Type'
import { UtilParam } from 'Util'

const HookPageParam = (type :TypeUrlParamType) :TypeControlParam => {
    const location :Location = useLocation()
    const navigate = useNavigate()
    const page = UtilParam.page(location)
    const params = page.getAllParams(type)
    const path = page.getPath()

    const set = (values :string[], dynamic? :string[]) => {
        const newPage = UtilParam.page(location)
        newPage.setAllParams([{type, values, dynamic: dynamic || []}])
        newPage.updateSearchHistory()
        navigate(newPage.getPath())
    }

    const append = (values :string[], dynamic? :string[]) => {
        const newPage = UtilParam.page(location)
        newPage.appendAllParams([{type, values, dynamic: dynamic || []}])
        newPage.updateSearchHistory()
        navigate(newPage.getPath())
    }

    const remove = (dynamic? :string[]) => {
        const newPage = UtilParam.page(location)
        newPage.removeParam({type, values: [], dynamic: dynamic || []})
        newPage.updateSearchHistory()
        navigate(newPage.getPath())
    }

    const removeValue = (values :string[], dynamic? :string[]) => {
        const newPage = UtilParam.page(location)
        newPage.removeParamValue({type, values, dynamic: dynamic || []})
        newPage.updateSearchHistory()
        navigate(newPage.getPath())
    }

    return { type, params, set, append, remove, removeValue, path }
}

export default HookPageParam