import { ComponentEuiTabs } from 'Component'
import { ModuleRowChildTab } from 'Module'
import { TypeEntityChild } from 'Type'

type Props = {
    tables :TypeEntityChild[]
    active :TypeEntityChild
}

const ModuleRowChildTabs = ({ tables, active } :Props) => {
    return <ComponentEuiTabs>
        {tables.map((childTable :TypeEntityChild) => {
            return <ModuleRowChildTab key={childTable.linkTo} active={active} child={childTable}/>
        })}
    </ComponentEuiTabs>
}

export default ModuleRowChildTabs