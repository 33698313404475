import React from 'react'
import {EuiFormRow} from '@elastic/eui'
import { Children } from 'props'

import './FormRow.css'

type Props = Children & {
    label: string
    helpText?: string
    error?: string[]
    fullWidth?: boolean
    children: React.ReactElement
}

const FormRow = ({label = '', helpText = '', children, error = [], fullWidth}: Props) =>

    <EuiFormRow
                className={(fullWidth) ? 'form-row-full-width' : ''}
                label={label}
                helpText={helpText}
                error={error}
                isInvalid={error.length > 0}
                fullWidth={fullWidth}>
        {children}
    </EuiFormRow>

export default FormRow
