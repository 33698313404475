import React from 'react'
import {EuiPopover} from '@elastic/eui'
import {PopoverProps} from 'props';


const Popover = ({className, id, ownFocus, repositionOnScroll, button, isOpen, anchorPosition, closePopover, panelPaddingSize, children}: PopoverProps) =>
    <EuiPopover
        className={className}
        id={id}
        ownFocus={ownFocus}
        repositionOnScroll={repositionOnScroll}
        button={button}
        isOpen={isOpen}
        anchorPosition={anchorPosition}
        closePopover={closePopover}
        panelPaddingSize={panelPaddingSize}>
            {children}
    </EuiPopover>

export default Popover