import React from 'react';
import {EuiFlexItem} from '@elastic/eui';
import { Children } from 'props';

type Props = Children & {
    grow?: 1 | 2 | 3 | boolean
    className? :string
    style? :any
};

const FlexItem = ({grow = false, className, style, children}: Props) =>
    <EuiFlexItem style={style} className={className} grow={grow}>{children}</EuiFlexItem>

export default FlexItem