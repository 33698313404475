import { PageTitle } from 'components'
import {TableEntity, WorkspaceEntity, MembershipEntity } from 'entity'
import {FlexGroup, FlexItem, PageSection, Spacer } from 'eui'
import { useNavigateToTable } from 'hooks'
import { GenericActionButtons, NavDataEntity, NavResourceTabContent, WorkspaceMembershipButton, GenericProfileModal } from 'modules'
import { NavStore } from 'store'
import { Nav, NavKey } from 'types'
import {useState} from 'react';
import {InterfaceEntity} from "../../entity";
import {GenericEntityResourceModal} from "../index";

type Props = {
    tab? :NavKey
}

const actionTitles = {delete: 'Delete'}

const ifaceKey :NavKey = 'iface'
const fieldKey :NavKey = 'field'


const WorkspacePageContent = ({tab}:Props) => {

    const refresh :() => void = NavStore(store => store.refresh)

    const navigateToTable = useNavigateToTable()
    const tableNavigateOverride = (tableEntity :TableEntity) => navigateToTable(tableEntity)
    const [activeMember, setActiveMember] = useState<MembershipEntity | undefined>()
    const [activeInterface, setActiveInterface] = useState<InterfaceEntity | undefined>()
    const clearActiveMember = () => setActiveMember(undefined)
    const clearActiveInterface = () => setActiveInterface(undefined)

    const onMemberClick = (member :any) => {
        setActiveMember(member)
    }

    const onInterfaceClick = (iface :any) => {
        setActiveInterface(iface)
    }

    const pageNav :Nav | undefined = NavStore(store => store.nav)

    const workspaceNav :Nav | undefined = NavStore(state => state.contextNavs['ws'] )

    if (!pageNav || !workspaceNav) return null

    const workspace :WorkspaceEntity = workspaceNav.data.entity as WorkspaceEntity

    const navigateOverride = (tab === 'table') ? tableNavigateOverride : (tab === 'member') ? onMemberClick : (tab === 'iface') ? onInterfaceClick : undefined

    return <>
        <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
            <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}>
                <FlexItem>
                    <FlexGroup alignItems={'baseline'}>
                        <FlexItem>
                            <PageTitle>
                                {workspace.name}
                            </PageTitle>
                        </FlexItem>
                        <FlexItem>
                            <GenericActionButtons nav={workspaceNav} contextKey={'ws'} toggleIcon={'gear'} buttonSize={'s'} buttonTitle={actionTitles}/>
                        </FlexItem>
                    </FlexGroup>
                    <FlexGroup>
                        <FlexItem>
                            <Spacer size={'s'}/>
                            <p style={{color: '#69707D'}}>{workspace.description}</p>
                            <Spacer/>
                        </FlexItem>
                    </FlexGroup>
                </FlexItem>
                <FlexItem>
                    <WorkspaceMembershipButton workspaceNav={workspaceNav}/>
                </FlexItem>
            </FlexGroup>
        </PageSection>
        <PageSection color={'plain'} alignment={'top'} paddingSize={'none'}>
            {tab ?
                <NavResourceTabContent nav={pageNav} navKey={tab} tableOnly={(tab === 'table')} navigateOverride={navigateOverride}/> :
                <NavDataEntity configKey={'ws'} ignoreFields={['name', 'description']}/>
            }
            {activeMember && <GenericProfileModal onClose={clearActiveMember} onSuccess={clearActiveMember} navLinkTo={activeMember.linkTo} contextKey={'member'}/>}
            {activeInterface && <GenericEntityResourceModal entity={activeInterface} entityKey={ifaceKey} resourceKey={fieldKey} onClose={clearActiveInterface}/>}
        </PageSection>
    </>
}

export default WorkspacePageContent