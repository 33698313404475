import { EuiListGroupItem } from '@elastic/eui'
import { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { TypeEuiPropsGroupItemExtraAction, TypeEuiPropsIcon, TypeEuiPropsIconType } from 'Type'

type Props = {
    size? :'xs' | 's' | 'm' | 'l'
    color? :'text' | 'primary' | 'subdued'
    label :ReactNode
    isActive? :boolean
    isDisabled? :boolean
    iconType? :TypeEuiPropsIconType
    iconProps? :Omit<TypeEuiPropsIcon, 'type'>
    icon? :ReactElement
    showTooltip? :boolean
    extraAction? :TypeEuiPropsGroupItemExtraAction
    onClick? :MouseEventHandler<HTMLButtonElement>
    wrapText? :boolean
}

const ComponentEuiListGroupItem = ({size, color, label, isActive, isDisabled, iconType, iconProps, icon, showTooltip, extraAction, onClick, wrapText} :Props) =>
    <EuiListGroupItem size={size} color={color} label={label} isActive={isActive} isDisabled={isDisabled}
                      iconType={iconType} iconProps={iconProps} icon={icon} showToolTip={showTooltip}
                      extraAction={extraAction} onClick={onClick} wrapText={wrapText}/>

export default ComponentEuiListGroupItem