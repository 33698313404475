import { CollapsibleLeftNavBar, CollapsibleSideBarItem, SideBarListItem } from 'components'
import {ApiEntity, UserEntity } from 'entity'
import {FlexGroup, FlexItem, Icon, Spacer } from 'eui'
import { Link } from 'react-router-dom'
import { NavStore } from 'store'
import {Nav, NavControlResource, NavKey } from 'types'
import { MappingUtils } from 'utils'

type Props = {
    wsSelected? :boolean
    forceCollapsed? :boolean
}


const apiKey :NavKey = 'api'
const wsKey :NavKey = 'ws'

const ApiPageLeftNavBar = ({wsSelected, forceCollapsed} :Props) => {

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const apiNav :Nav | undefined = NavStore(store => store.contextNavs[apiKey])
    if (!pageNav || !apiNav) return null

    const api :ApiEntity | undefined = pageNav.context[apiKey] as ApiEntity
    const workspaceResource :NavControlResource | undefined = apiNav.control.resource[wsKey]

    if (!api || !workspaceResource) return null

    return <CollapsibleLeftNavBar expandedWidth={320} collapsedWidth={0} forceCollapsed={forceCollapsed}>
        <Link to={api.linkTo}>
            <SideBarListItem isTitle={!wsSelected}>
                <CollapsibleSideBarItem fixed={<div style={{padding: '0px 0px 3px 0px', lineHeight: '20px', fontSize: '14px', color: (wsSelected) ? '#A2ABBA' : 'black'}}><Icon type={MappingUtils.contextIcon(apiKey)}/></div>} forceCollapsed={forceCollapsed} gutter={10}>
                    <span style={{lineHeight: '20px', fontSize: '14px', fontWeight: 700, color: 'black'}}>{api.name}</span>
                </CollapsibleSideBarItem>
            </SideBarListItem>
        </Link>
        <Spacer size={'s'}/>
        <Link to={workspaceResource.linkTo}>
            <SideBarListItem isTitle={wsSelected}>
                <CollapsibleSideBarItem fixed={<div style={{padding: '0px 0px 3px 0px', color: '#69707D'}}><Icon type={MappingUtils.contextIcon(wsKey)}/></div>} forceCollapsed={forceCollapsed} gutter={10}>
                    <FlexGroup justifyContent={'spaceBetween'}>
                        <FlexItem grow>
                            <span style={{lineHeight: '20px', fontSize: '14px', fontWeight: 700}}>Workspaces</span>
                        </FlexItem>
                        <FlexItem>
                            <Icon type={'arrowRight'}/>
                        </FlexItem>
                    </FlexGroup>
                </CollapsibleSideBarItem>
            </SideBarListItem>
        </Link>

    </CollapsibleLeftNavBar>
}

export default ApiPageLeftNavBar