import {useContext} from 'react'
import { ContextTableData } from 'Context'
import { TypeContextTableData, TypeEntityTableVersionColumn } from 'Type'

const HookModuleTableDataContextColumn = (nameCamelOrSlug :string) :TypeEntityTableVersionColumn | undefined => {
    const context :TypeContextTableData = useContext(ContextTableData)
    const columns :TypeEntityTableVersionColumn[] = Object.values(context.columns)
    const isSlug :boolean = nameCamelOrSlug.includes('-')

    const findByNameSlug = (c :TypeEntityTableVersionColumn) :boolean => c.nameSlug === nameCamelOrSlug
    const findByNameCamel = (c :TypeEntityTableVersionColumn) :boolean => c.nameCamel === nameCamelOrSlug

    return columns.find((isSlug) ? findByNameSlug : findByNameCamel)
}

export default HookModuleTableDataContextColumn