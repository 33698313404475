import {useEffect, useState} from 'react'

const visibilitychange :string = 'visibilitychange'
const visible :string = 'visible'

const HookBrowserTabActive = () :boolean => {
    const [active, setActive] = useState<boolean>(false)

    const handleVisibilityChange = () => setActive(document.visibilityState === visible)

    useEffect(() => {
        document.addEventListener(visibilitychange, handleVisibilityChange)
        return () => document.removeEventListener(visibilitychange, handleVisibilityChange)
    }, [])

    useEffect(() => {
        if (active) setActive(false)
    }, [active])

    return active;
}

export default HookBrowserTabActive