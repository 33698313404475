import {EuiFieldText} from '@elastic/eui'
import React, {ChangeEvent} from 'react'
import { TypeEuiFormFieldProps } from 'Type'
import { ComponentEuiFieldTelephoneCode } from 'Component'
import { FORMAT_TELEPHONE_CHARS } from 'Format'


type Props = TypeEuiFormFieldProps<string>

const stripInvalid = (value :string) :string => [...value].filter((char :string) => FORMAT_TELEPHONE_CHARS.includes(char)).join()

const ComponentEuiFieldTelephone = ({value, placeholder, isInvalid, fullWidth, isLoading, append, onChange} :Props) => {

    const setValue = (newValue :string) => onChange(newValue)

    const evtFn = (e: ChangeEvent<HTMLInputElement>) => onChange(stripInvalid(e.target.value))

    return <EuiFieldText
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        prepend={<ComponentEuiFieldTelephoneCode value={value} onChange={setValue}/>}
        append={append}
        isLoading={isLoading}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldTelephone