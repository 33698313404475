import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { ComponentEuiLink } from 'Component'
import { HookNavGet } from 'Hook'
import {TypeEntityLinkTo, TypeModuleTableDataCellProps, TypeNavHttpControl } from 'Type'
import { UtilRoute } from 'Util'


const ModuleTableDataTableCellDisplayReference :FC<TypeModuleTableDataCellProps> = ({ column, row, value } :TypeModuleTableDataCellProps) => {
    const navigate = useNavigate()
    const [linkTo, setLinkTo] = useState<string | undefined>()

    const control :TypeNavHttpControl<TypeEntityLinkTo> = HookNavGet<TypeEntityLinkTo>({linkTo})
    const parentTable :TypeEntityLinkTo | undefined = control.res?.data?.data.entity

    const onClick = async () => setLinkTo(UtilRoute.buildCellLinkTo(row, column, 'parent'))

    useEffect(() => {
        if (parentTable?.linkTo) navigate(parentTable?.linkTo)
    }, [parentTable?.linkTo])

    if (!value) return null

    return <ComponentEuiLink onClick={onClick}>{value}</ComponentEuiLink>
}

export default ModuleTableDataTableCellDisplayReference