import { EuiFieldNumber } from '@elastic/eui'
import { ChangeEvent } from 'react'
import { TypeEuiFormFieldProps } from 'Type'

type Props = TypeEuiFormFieldProps<string>

const isZero = (value :string) :boolean => (Number(value) === 0)

const toPercentage = (value :string) :string => (isZero(value)) ? value : `${Number(value + 'e2')}`

const fromPercentage = (value :string) :string => (isZero(value)) ? value : `${Number(value + 'e-2')}`

const ComponentEuiFieldPercentage = ({value, placeholder, isInvalid, fullWidth, isLoading, prepend, onChange} :Props) => {

    const evtFn = (e: ChangeEvent<HTMLInputElement>) => onChange(fromPercentage(e.target.value))

    return <EuiFieldNumber
        placeholder={placeholder}
        value={toPercentage(value)}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        prepend={prepend}
        append={'%'}
        isLoading={isLoading}
        onChange={evtFn}
    />
}

export default ComponentEuiFieldPercentage