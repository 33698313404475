import { ComponentEuiTab } from 'Component'
import { HookNavNavigateEntity } from 'Hook'
import { TypeEntityChild } from 'Type'

type Props = {
    child: TypeEntityChild
    active :TypeEntityChild
}

const ModuleRowChildTab = ({ child, active } :Props) => {

    const onClick :() => void = HookNavNavigateEntity({entity: child, resource: 'row'})

    return <ComponentEuiTab onClick={onClick} isSelected={child.linkTo === active?.linkTo}>{ child.childTableName }</ComponentEuiTab>
}

export default ModuleRowChildTab