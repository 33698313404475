import React, {ChangeEvent} from 'react'
import {EuiSelect} from '@elastic/eui'
import {FieldSelectOption} from 'types'
import { Field } from 'props'

type Props = Field & {
    options?: FieldSelectOption[]
}

const FieldSelect = ({placeholder, name, value = '', isInvalid = false, onChange, fullWidth = true, options }: Props) => {

    const valueStr : string = (typeof value === 'string') ? value : '';

    const fn = (e: ChangeEvent<HTMLSelectElement>) => {
        onChange(name, e.target.value)
    }

    const opts = [...options || []]
    opts.unshift({text: '', value: '' })

    return <EuiSelect placeholder={placeholder}
                           name={name}
                           value={valueStr}
                           isInvalid={isInvalid}
                           fullWidth={fullWidth}
                           onChange={fn}
                            options={opts}/>
}

export default FieldSelect