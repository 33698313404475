import { TypeNav, TypeUrlParamType, TypeControlParam, TypeUrlParam } from 'Type'
import { UtilParam } from 'Util'

type Props = {
    type :TypeUrlParamType
    onChange? :(path :string) => void
    nav? :TypeNav<any>
}

const HookNavParam = ({type, nav, onChange} :Props) :TypeControlParam => {

    const navUrl = UtilParam.nav(nav)

    const path :string = navUrl.getPath()

    const params :TypeUrlParam[] = navUrl.getAllParams(type)

    const set = (values :string[], dynamic? :string[]) => {
        if (!onChange) return
        const param :TypeUrlParam = {type, dynamic, values}
        navUrl.setAllParams([param])
        onChange(navUrl.getPath())
    }

    const append = (values :string[], dynamic? :string[]) => {
        if (!onChange) return
        const params :TypeUrlParam[] = [{type, dynamic, values}]
        navUrl.appendAllParams(params)
        onChange(navUrl.getPath())
    }

    const remove = (dynamic? :string[]) => {
        if (!onChange) return
        navUrl.removeParam({type, values: [], dynamic: dynamic || []})
        onChange(navUrl.getPath())
    }

    const removeValue = (values :string[], dynamic? :string[]) => {
        if (!onChange) return
        navUrl.removeParamValue({type, values, dynamic: dynamic || []})
        onChange(navUrl.getPath())
    }

    return { type, params, path, set, append, remove, removeValue }
}

export default HookNavParam