import { ComponentEuiCallout, ComponentEuiText } from 'Component'
import {HookModuleTableDataContextColumn, HookModuleTableDataContextRow } from 'Hook'
import { ModuleTableCellPopover } from 'Module'
import { TypeEntityRow, TypeEntityTableVersionColumn } from 'Type'

type Props = {
    rowIndex :number
    columnNameSlug :string
}

const ModuleTableDataTableCellPopover = ({ rowIndex, columnNameSlug} :Props) => {
    const row :TypeEntityRow | undefined = HookModuleTableDataContextRow(rowIndex)
    const column :TypeEntityTableVersionColumn | undefined = HookModuleTableDataContextColumn(columnNameSlug)
    if (!row || !column) return <ComponentEuiCallout title={'Error'} iconType={'alert'} color={'danger'}><ComponentEuiText><p>Missing data</p></ComponentEuiText></ComponentEuiCallout>
    return <ModuleTableCellPopover row={row} column={column}/>
}

export default ModuleTableDataTableCellPopover