import { HookHttpAction } from 'Hook'
import { TypeHttpPayload, TypeNav,
    TypeNavControlAction, TypeNavControlActionFormField, TypeNavControlActionType, TypeNavHttpControlAction } from 'Type'

const HookNavAction = <T>(actionType :TypeNavControlActionType, nav? :TypeNav<any>) :TypeNavHttpControlAction<T> => {

    const initialPayload :any = {}

    const entity :any = {...nav?.data.entity}

    const action :TypeNavControlAction | undefined = nav?.control.action[actionType]

    const fields :TypeNavControlActionFormField[] = action?.form?.fields ?? [];

    const authorized :boolean = action?.auth.authorized ?? false

    const addDefault = (field :TypeNavControlActionFormField) => {
        if (field.type === 'bool') initialPayload[field.name] = entity[field.name] ?? 'false'
    }

    fields.forEach(addDefault)

    const path :string | undefined = nav?.path
    const method :TypeNavControlActionType = actionType

    const isRequiredFieldEmpty = (currentPayload :TypeHttpPayload) => (field :TypeNavControlActionFormField) :boolean =>
        (field.required && !currentPayload[field.name])

    const allRequiredFieldsSatisfied = (currentPayload :TypeHttpPayload) :boolean =>
        (fields.filter(isRequiredFieldEmpty(currentPayload)).length === 0)

    const isFieldUserAddedDataPresent = (currentPayload :TypeHttpPayload) => (field :TypeNavControlActionFormField) :boolean =>
        !!(currentPayload[field.name] && currentPayload[field.name] !== initialPayload[field.name])

    const hasUserInput = (currentPayload :TypeHttpPayload) :boolean =>
        (fields.filter(isFieldUserAddedDataPresent(currentPayload)).length > 0)

    const calcIsActionable :(currentPayload :TypeHttpPayload) => boolean = (actionType === 'patch') ? hasUserInput : allRequiredFieldsSatisfied

    return HookHttpAction({path, method, initialPayload, calcIsActionable, authorized});
}

export default HookNavAction