import {Nav} from 'types';
import {CellEntity, TableVersionColumnEntity} from "../../entity";
import {NavControlAction, NavControlActionType} from "../../types";
import {Button, Callout, FlexGroup, FlexItem, Panel, Spacer} from "../../eui";
import {MappingUtils, NavClient} from "../../utils";
import {useState} from "react";

type Props = {
    cellNav: Nav
    onDelete: () => void
}

const actionType :NavControlActionType = 'delete'

const CellContextMenuDeleteContent = ({cellNav, onDelete} :Props) => {

    const action :NavControlAction | undefined = cellNav.control.action[actionType]
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const onSubmit = async () => {
        const responseNav :Nav = await NavClient.action(cellNav, actionType, {})
        const error = responseNav.error
        if (error) {
            setErrorMessage(error.message)
        } else {
            onDelete()
        }
    }
    return <Panel>
        { errorMessage && <><Spacer size={'m'}/><Callout title={errorMessage} color={'danger'} size={'s'} iconType={'faceSad'}/><Spacer size={'m'}/></> }
        <FlexGroup justifyContent={'spaceBetween'}>
            <FlexItem></FlexItem>
            <FlexItem><Button color={'danger'} size={'s'} isDisabled={!action.auth.authorized} fill onClick={onSubmit}>{MappingUtils.buttonTitle(actionType)}</Button></FlexItem>
        </FlexGroup>
    </Panel>
}

export default CellContextMenuDeleteContent