import React, {ChangeEvent} from 'react'
import {EuiFilePicker} from '@elastic/eui'
import { Field } from 'props'

type Props = Field & {
    accept? :string
}

const FieldFile = ({placeholder, name, value, isInvalid = false, onChange, fullWidth = true, accept}: Props) => {

    const fnOld = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(name, e.target.value)
    }

    const fn = (files :FileList | null) => {
        if (files && files[0]) onChange(name, files[0])
    };

    return <EuiFilePicker
        id={name}
        display={'default'}
        initialPromptText="Select file"
        onChange={fn}
        accept={accept}
    />
}

export default FieldFile