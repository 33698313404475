import { ComponentNavMetaPageSectionHeader, ComponentDivMarginHorizontal } from 'Component'
import { ModuleTransformOutputTable, ModuleTransformTitlePopover } from 'Module'
import {TypeEntityTransform, TypeEntityTransformHeader, TypeNav, TypeModuleTransformHeaderErrorMap, TypeModuleControlRefresh, TypeNavControlResource } from 'Type'

type Props = {
    headerNav :TypeNav<TypeEntityTransformHeader>
    headerErrors :TypeModuleTransformHeaderErrorMap
    transformNav :TypeNav<TypeEntityTransform>
    excludeResource :TypeNavControlResource
    outputNav :TypeNav<any>
    refreshControl :TypeModuleControlRefresh
}

const ModuleTransformBody = ({ headerNav, headerErrors, outputNav, transformNav, excludeResource, refreshControl } :Props) => {
    return <>
        <ComponentNavMetaPageSectionHeader title={`Transform ${transformNav.meta.title}`} compact rightActions={<ModuleTransformTitlePopover transformNav={transformNav} excludeResource={excludeResource} refreshControl={refreshControl}/>}/>
        <ComponentDivMarginHorizontal margin={-1}>
            <ModuleTransformOutputTable headerNav={headerNav} headerErrors={headerErrors} outputNav={outputNav} refreshControl={refreshControl}
                                        transformNav={transformNav} excludeResource={excludeResource}/>
        </ComponentDivMarginHorizontal>
    </>
}

export default ModuleTransformBody