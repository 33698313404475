import {useEffect} from 'react'
import { HookHttpClient } from 'Hook'
import { TypeHttpControl, TypeHttpControlGet, TypeUrlParam } from 'Type'

type Props<Res> = {
    path? :string
    paramUpdates? :TypeUrlParam[]
}

const HookHttpGet = <Res>({ path, paramUpdates } :Props<Res>) :TypeHttpControlGet<Res> => {
    const control :TypeHttpControl<Res> = HookHttpClient({method: 'get', path, paramUpdates});
    const url :string | undefined = control.url
    useEffect(() => {
        if (url) control.submit()
    }, [url])
    return control
}

export default HookHttpGet