import { HookNavGet, HookNavResource } from 'Hook'
import { TypeNav, TypeNavControlResource, TypeNavHttpControl, TypeNavKey, TypeUrlParam } from 'Type'

type Props = {
    key :TypeNavKey
    nav? :TypeNav<any>
    paramUpdates? :TypeUrlParam[]
}

const HookNavResourceNav = <T>({key, nav, paramUpdates} :Props) :TypeNavHttpControl<T> => {
    const resource :TypeNavControlResource | undefined = HookNavResource(key, nav)
    return HookNavGet<T>({linkTo: (resource && resource.auth.authorized) ? resource : undefined, paramUpdates})
}

export default HookNavResourceNav