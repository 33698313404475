import { AuditDisplay } from 'display';
import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'cellVersionNumber',
        name: 'Cell Version Number'
    },
    ...AuditDisplay
]

export default config