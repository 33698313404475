import { ComponentEuiButtonEmpty, ComponentEuiPopover } from 'Component'
import { HookControlSwitch } from 'Hook'
import { ModuleTransformOutputTablePopoverHeaderContent } from 'Module'
import {TypeControlSwitch, TypeEntityTransformHeader, TypeModuleControlRefresh } from 'Type'

type Props = {
    header :TypeEntityTransformHeader
    refreshControl :TypeModuleControlRefresh
}

const hasMatch = (header :TypeEntityTransformHeader) :boolean => header.defaultValue || header.extractColumn

const isRequired = (header :TypeEntityTransformHeader) :boolean => header.destinationIsRequired

const buttonColor = (header :TypeEntityTransformHeader) :'text' | 'warning' | 'danger' =>
    (hasMatch(header)) ? 'text' : (isRequired(header)) ? 'danger' : 'warning'

const buttonIcon = (header :TypeEntityTransformHeader) :'check' | 'alert' =>
    (hasMatch(header)) ? 'check' : 'alert'

const ModuleTransformOutputTablePopoverHeader = ({ header, refreshControl } :Props) => {

    const menuSwitch :TypeControlSwitch = HookControlSwitch();

    const innerHeaderRefresh = async () => {
        await refreshControl.header(header.destinationColumnNameCamel)
        menuSwitch.turnOff()
    }

    return <ComponentEuiPopover closePopover={menuSwitch.turnOff} isOpen={menuSwitch.value} panelPaddingSize={'none'}
                                button={<ComponentEuiButtonEmpty onClick={menuSwitch.toggle} color={buttonColor(header)} iconType={buttonIcon(header)}>{ header.destinationColumnName }</ComponentEuiButtonEmpty>}>
        <ModuleTransformOutputTablePopoverHeaderContent header={header} onSuccess={innerHeaderRefresh} onClose={menuSwitch.turnOff}/>
    </ComponentEuiPopover>
}

export default ModuleTransformOutputTablePopoverHeader