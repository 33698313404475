import { useEffect } from 'react'
import { ComponentEuiModal, ComponentEuiModalBody,
    ComponentEuiModalFooter, ComponentEuiModalHeader, ComponentEuiModalHeaderTitle, ComponentNavForm, ComponentNavFormButtonCancel, ComponentNavFormButtonSubmit } from 'Component'
import { HookNavAction } from 'Hook'
import { RecordNavAction } from 'Record'
import { TypeNav, TypeNavControlActionType, TypeNavHttpControlAction } from 'Type'
import { UtilString } from 'Util'

type Props<T> = {
    entityName :string
    nav :TypeNav<any>
    actionType :TypeNavControlActionType
    onClose :() => void
    onSuccess :(entity? :T) => void
}

const ComponentNavActionModal = <T,>({entityName, nav, actionType, onClose, onSuccess} :Props<T>) => {

    const control :TypeNavHttpControlAction<T> = HookNavAction(actionType, nav);

    const respNav :TypeNav<T> | undefined = control.res?.data

    const isRequestSuccess :boolean = Boolean(control.res && !control.res.error)

    const isActionSuccess :boolean = Boolean(respNav && !respNav.error)

    useEffect(() => {
        if (isRequestSuccess && isActionSuccess) onSuccess(respNav?.data.entity)
    }, [isRequestSuccess, isActionSuccess])

    return <ComponentEuiModal onClose={onClose}>
        <ComponentEuiModalHeader>
            <ComponentEuiModalHeaderTitle>{UtilString.capitalize(entityName)}</ComponentEuiModalHeaderTitle>
        </ComponentEuiModalHeader>
        <ComponentEuiModalBody>
            <ComponentNavForm nav={nav} actionType={actionType} onChange={control.addToPayload} payload={control.payload} error={respNav?.error}/>
        </ComponentEuiModalBody>
        <ComponentEuiModalFooter>
            <ComponentNavFormButtonCancel onClick={onClose}/>
            <ComponentNavFormButtonSubmit nav={nav} actionType={actionType} entityName={RecordNavAction[actionType].buttonText(entityName)} onClick={control.submit} isLoading={control.isLoading}/>
        </ComponentEuiModalFooter>
    </ComponentEuiModal>
}

export default ComponentNavActionModal