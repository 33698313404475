import create from 'zustand'
import { Nav } from 'types'

type Store = {
    isCollapsed :boolean
    toggleCollapsed :() => void
}

const CollapsedStore = create<Store>((set, get) => ({
    isCollapsed: false,
    toggleCollapsed: () => {
        set({isCollapsed: !get().isCollapsed})
    }
}))

export default CollapsedStore