import {DataPayload, Nav, NavControlResource, NavKey} from 'types';
import {TableVersionColumnEntity} from 'entity';
import {useState} from 'react';
import {NavClient, SystemColumnsUtils} from 'utils';
import {TableColumnHookControl} from "../../types";

const columnKey :NavKey = 'column'

const TableColumns = () :TableColumnHookControl => {

    const [versionNav, setVersionNav] = useState<Nav | undefined>(undefined)
    const [columnsNav, setColumnsNav] = useState<Nav | undefined>(undefined)
    const [columns, setColumns] = useState<TableVersionColumnEntity[] | undefined>(undefined)

    const clear = () => {
        setVersionNav(undefined)
        setColumnsNav(undefined)
        setColumns(undefined)
    }

    const doFetchColumns = async (versionNav :Nav) => {
        const columnResource :NavControlResource | undefined = versionNav.control.resource[columnKey]
        if (columnResource) {
            const nav :Nav = await NavClient.fetchAll(columnResource.linkTo)
            setVersionNav(versionNav)
            setColumnsNav(nav)
            setColumns(SystemColumnsUtils.getVisibleColumns(nav, SystemColumnsUtils.getShowSystemColumns()))
        }
    }

    const fetchColumns = async (versionNav :Nav) => {
        clear()
        doFetchColumns(versionNav)
    }

    const toggleShowSystemColumns = () => {
        if (columnsNav) setColumns(SystemColumnsUtils.getVisibleColumns(columnsNav, SystemColumnsUtils.toggleShowSystemColumns()))
    }

    const moveColumn = async (nameSlug :string, move :number) => {
        if (!columns) return

        const matches :TableVersionColumnEntity[] = columns.filter((column :TableVersionColumnEntity) => column.nameSlug === nameSlug)
        if (matches.length === 0) return

        const columnNav :Nav = await NavClient.fetchNav(matches[0].linkTo)
        const dataPayload :DataPayload = {}
        dataPayload['reorder'] = move.toString()
        await NavClient.action(columnNav, 'patch', dataPayload)
        if (versionNav) fetchColumns(versionNav)
    }

    const refreshColumns = () => {
        if (versionNav) doFetchColumns(versionNav)
    }

    const showSystemColumns :boolean = SystemColumnsUtils.getShowSystemColumns()

    return {
        fetchColumns,
        refreshColumns,
        toggleShowSystemColumns,
        showSystemColumns,
        moveColumn,
        columns,
        columnsNav
    }

}

export default TableColumns