import {PropsWithChildren} from 'react'
import { EuiPageBody } from '@elastic/eui'
import { TypeEuiPropsPanel } from 'Type'

type Props = {
    restrictWidth? :string | number |boolean
    panelled? :boolean
    paddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    panelProps? :TypeEuiPropsPanel
}

const ComponentEuiPageBody = ({restrictWidth, panelled, paddingSize, children, panelProps} :PropsWithChildren<Props>) =>
    <EuiPageBody restrictWidth={restrictWidth} panelled={panelled} panelProps={panelProps} paddingSize={paddingSize} >{ children }</EuiPageBody>

export default ComponentEuiPageBody