import React from 'react'
import { EuiProvider } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
}

const Provider = ({ children }: Props) =>
    <EuiProvider colorMode="light">{children}</EuiProvider>

export default Provider