

const isString = (o :any | undefined) :boolean => o && typeof o === 'string'

const capitalize = (str :string) :string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const titleFromCamel = (camel :string) :string => camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').map(capitalize).join(' ')

const removeTrailingChars = (str :string, chars :string) :string => {
    const len : number = chars.length
    if (len < 1) return str
    return (str.endsWith(chars)) ? str.substring(0, len - 1) : str
}

const addLeadingChars = (str :string, chars :string) :string => {
    return (str.startsWith(chars)) ? str : `${chars}${str}`
}

const countCharsIn = (str :string, char :string) :number => str.split(char).length - 1;

const UtilString = {
    isString,
    capitalize,
    titleFromCamel,
    removeTrailingChars,
    addLeadingChars,
    countCharsIn
}

export default UtilString