import moment from 'moment';
import { Link } from 'react-router-dom';
import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'createdAt',
        name: 'Created At',
        sortable: true,
        render: (value :any, record :any) => {
            return moment(value).format('LLL')
        }
    }
]

export default config