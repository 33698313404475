import React, {PropsWithChildren} from 'react'
import {EuiFlexItem} from '@elastic/eui'

type Props = {
    grow?: 1 | 2 | 3 | boolean
    className? :string
    style? :any
};

const ComponentEuiFlexItem = ({grow = false, className, style, children}: PropsWithChildren<Props>) =>
    <EuiFlexItem style={style} className={className} grow={grow}>{children}</EuiFlexItem>

export default ComponentEuiFlexItem