import { ComponentEuiDescriptionList, ComponentEuiLink, ComponentMoment, ComponentNavGlobalIdLink } from 'Component'
import { FC } from 'react'
import { RecordTableDataCellDisplay } from 'Record'
import { TypeEntityCell, TypeEntityRow,
    TypeEntityTableVersionColumn, TypeEuiPropsDescriptionListItem,
    TypeModuleTableDataCellProps } from 'Type'

type Props = {
    row :TypeEntityRow
    column :TypeEntityTableVersionColumn
    cell :TypeEntityCell
}

const ModuleTableCellDetails = ({ row, column, cell } :Props) => {

    const DisplayCellValue :FC<TypeModuleTableDataCellProps> = RecordTableDataCellDisplay[column.type]

    const truncateValue : boolean = !`${cell.value}`.includes(' ')

    const listItems :TypeEuiPropsDescriptionListItem[] = [
        {
            title: 'Row',
            description: <ComponentEuiLink href={row.linkTo}>{row.rowId}</ComponentEuiLink>
        },
        {
            title: 'Column',
            description: column.name
        },
        {
            title: 'Cell Version',
            description: cell.versionNumber
        },
        {
            title: 'Value',
            description: <DisplayCellValue value={cell.value} column={column} row={row}/>
        }
    ]

    return <ComponentEuiDescriptionList listItems={listItems} compressed type={'column'} descriptionClassName={(truncateValue) ? 'eui-textTruncate' : undefined}/>
}

export default ModuleTableCellDetails