import {LoadingSpinner, FlexGroup, FlexItem} from 'eui';


const LoadingPage = () => {
    return <div style={{margin: '52px'}}>
        <FlexGroup justifyContent={'center'} >
            <FlexItem>
                <LoadingSpinner size={'xxl'}/>
            </FlexItem>
        </FlexGroup>
    </div>
}

export default LoadingPage