import { HookNavDisplayColumn } from 'Hook'
import { TypeEuiPropsBasicTableColumn, TypeEuiPropsBasicTableColumnActions, TypeFieldDisplay } from 'Type'

type Column<T> = TypeEuiPropsBasicTableColumn<T> | TypeEuiPropsBasicTableColumnActions<T>

type Props<T extends object> = {
    showColumns :(keyof T)[]
    actionColumn? :TypeEuiPropsBasicTableColumnActions<T>
    customRenders? :{[field :string] :TypeFieldDisplay}
}

const HookNavDisplayColumns = <T extends object>({showColumns, actionColumn, customRenders} :Props<T>) :Column<T>[] => {
    const columns :TypeEuiPropsBasicTableColumn<T>[] = showColumns.map((field :(keyof T)) => HookNavDisplayColumn(field, customRenders))
    return (actionColumn && actionColumn.actions.length > 0) ? [...columns, actionColumn] : columns
}

export default HookNavDisplayColumns