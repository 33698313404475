import { ComponentEuiButtonIcon, ComponentEuiFieldSelect, ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiFormRow } from 'Component'
import { HookNavActionAuto } from 'Hook'
import { useEffect, useState} from 'react'
import { TypeEntityInterfaceMapField, TypeEntityTableVersionColumn, TypeEuiFormFieldSelectOption, TypeHttpPayload,
    TypeModuleVersionColumnMappingControl, TypeNav, TypeNavHttpControl, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    navControl :TypeNavHttpControl<TypeEntityInterfaceMapField>
    columnMappingControl :TypeModuleVersionColumnMappingControl
}

const columnToOption = (col :TypeEntityTableVersionColumn) :TypeEuiFormFieldSelectOption => ({value: col.nameCamel, text: col.name})

const findByNameCameFn = (findByNameCamel? :string) => (col :TypeEntityTableVersionColumn) :boolean => col.nameCamel === findByNameCamel

const ModuleVersionRequesterMapFieldTableColumnMapper = ({ navControl, columnMappingControl } :Props) => {

    const [columnToMapTo, setColumnToMapTo] = useState<TypeEntityTableVersionColumn | undefined>()

    const nav :TypeNav<TypeEntityInterfaceMapField> | undefined = navControl.res?.data
    const mapField :TypeEntityInterfaceMapField | undefined = nav?.data.entity
    const patchControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMapField> = HookNavActionAuto('patch', nav)

    const mapColumnOptions :TypeEuiFormFieldSelectOption[] = columnMappingControl.unMappedColumns.map(columnToOption)

    const onSelectChange = (columnNameCamel :string) => {
        const col :TypeEntityTableVersionColumn | undefined = columnMappingControl.unMappedColumns.find(findByNameCameFn(columnNameCamel))
        if (col) setColumnToMapTo(col)
    }

    const patchMapping = async (name :string, nameCamel :string) => {
        const payload :TypeHttpPayload = {name, nameCamel}
        await patchControl.addToPayload(payload)
        setColumnToMapTo(undefined)
    }

    useEffect(() => {
        if (columnToMapTo) patchMapping(columnToMapTo.name, columnToMapTo.nameCamel)
    }, [columnToMapTo?.linkTo])

    useEffect(() => {
        if (patchControl.submittedSuccess) {
            columnMappingControl.refreshFields()
            navControl.submit()
        }
    }, [patchControl.submittedSuccess])

    const createColumnAndMap = () => {
        if (mapField) {
            columnMappingControl.createColumn(mapField)
            patchMapping(mapField.name, mapField.nameCamel)
        }
    }

    const matchingNameSlug :boolean = columnMappingControl.mappedColumns.find(findByNameCameFn(mapField?.nameCamel)) !== undefined

    const mappedColumn :TypeEntityTableVersionColumn | undefined = columnMappingControl.mappedColumns.find(findByNameCameFn(mapField?.mapNameCamel))
    const mappedValue :string = mappedColumn?.nameCamel ?? ''
    if (mappedColumn) mapColumnOptions.unshift(columnToOption(mappedColumn))

    return <ComponentEuiFlexGroup>
        <ComponentEuiFlexItem grow>
            <ComponentEuiFormRow label={'Map To Column'}>
                <ComponentEuiFieldSelect options={mapColumnOptions} value={mappedValue} onChange={onSelectChange}/>
            </ComponentEuiFormRow>
        </ComponentEuiFlexItem>
        <ComponentEuiFlexItem>
            <ComponentEuiFormRow label={'Create New Column'}>
                <ComponentEuiFlexGroup justifyContent={'flexEnd'}>
                    <ComponentEuiButtonIcon iconType={'plusInCircle'} color={'primary'} isDisabled={matchingNameSlug} size={'m'} onClick={createColumnAndMap}/>
                </ComponentEuiFlexGroup>
            </ComponentEuiFormRow>
        </ComponentEuiFlexItem>
    </ComponentEuiFlexGroup>
}

export default ModuleVersionRequesterMapFieldTableColumnMapper