import { EuiFieldNumber } from '@elastic/eui'
import { ChangeEvent } from 'react'
import { TypeEuiFormFieldProps } from 'Type'


type Props = TypeEuiFormFieldProps<string> & {
    min? :number
    max? :number
    step? :number
}

const ComponentEuiFieldNumber = ({value, placeholder, isInvalid, fullWidth, isLoading, prepend, append, onChange, min, max, step} :Props) => {

    const evtFn = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)

    return <EuiFieldNumber
        placeholder={placeholder}
        value={value}
        isInvalid={isInvalid}
        fullWidth={fullWidth}
        prepend={prepend}
        append={append}
        isLoading={isLoading}
        onChange={evtFn}
        min={min}
        max={max}
        step={step}
    />
}

export default ComponentEuiFieldNumber