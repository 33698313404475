import { HookNavActionAuto, HookNavResourceNavAll } from 'Hook'
import { useEffect } from 'react'
import { TypeEntityInterfaceMap, TypeEntityInterfaceMapField, TypeEntityTableVersion, TypeEntityTableVersionColumn,
    TypeNav, TypeNavHttpControl, TypeModuleVersionColumnMappingControl, TypeNavHttpControlActionAuto, TypeHttpPayload } from 'Type'

const HookModuleVersionColumnMapping = (versionNav :TypeNav<TypeEntityTableVersion>, allColumnsNav :TypeNav<TypeEntityTableVersionColumn>, columnRefresh: () => void, mapNav? :TypeNav<TypeEntityInterfaceMap>) :TypeModuleVersionColumnMappingControl => {
    const mapFieldControl :TypeNavHttpControl<TypeEntityInterfaceMapField> = HookNavResourceNavAll({key: 'field', nav: mapNav})
    const newColumnControl :TypeNavHttpControlActionAuto<TypeEntityTableVersionColumn> = HookNavActionAuto<TypeEntityTableVersionColumn>('post', allColumnsNav)

    const columns :TypeEntityTableVersionColumn[] = (allColumnsNav.data.page?.content ?? [])
    const mapFields :TypeEntityInterfaceMapField[] = mapFieldControl.res?.data?.data.page?.content ?? []

    const mapNameCamels :Set<string> = new Set<string>(mapFields.map((map :TypeEntityInterfaceMapField) => map.mapNameCamel))

    const mappedColumns :TypeEntityTableVersionColumn[] = []
    const unMappedColumns :TypeEntityTableVersionColumn[] = []
    columns.forEach((col :TypeEntityTableVersionColumn) => {
        if (mapNameCamels.has(col.nameCamel)) {
            mappedColumns.push(col)
        } else if (!col.isSystem) {
            unMappedColumns.push(col)
        }
    })

    const refreshFields = mapFieldControl.submit

    const createColumn = async (field :TypeEntityInterfaceMapField) => {
        const payload :TypeHttpPayload = {type: field.type, name: field.name}
        newColumnControl.addToPayload(payload)
    }

    const createdColumn :TypeEntityTableVersionColumn | undefined = newColumnControl.res?.data?.data.entity

    useEffect(() => {
        if (createdColumn) { columnRefresh() }
    }, [createdColumn?.linkTo])

    return { unMappedColumns, mappedColumns, refreshFields, createColumn, mapNameCamels }
}

export default HookModuleVersionColumnMapping