import {Nav} from 'types';
import {CellEntity, TableVersionColumnEntity} from 'entity';
import {Button, Callout, FlexGroup, FlexItem, Panel, Spacer} from "../../eui";
import {
    CellType,
    DataPayload,
    FieldOnChange,
    NavControlAction,
    NavControlActionForm,
    NavControlActionType
} from "../../types";
import {NavField} from "../../components";
import {useState} from "react";
import {FormUtils, MappingUtils, NavClient} from "../../utils";

type Props = {
    cellNav: Nav
    column: TableVersionColumnEntity
    cellEntity: CellEntity
    rowId :number
}

const actionType :NavControlActionType = 'put'
const fileTypes :CellType[] = ['file', 'excel', 'image', 'word', 'pdf', ]

const CellContextMenuDataContent = ({cellNav, column, cellEntity, rowId} :Props) => {

    const action :NavControlAction | undefined = cellNav.control.action[actionType]
    const form :NavControlActionForm | undefined = action && action.form

    const defaultValue :string | undefined = (fileTypes.includes(column.type)) ? undefined : cellEntity.value

    const defaultData :DataPayload = {}
    if (defaultValue) defaultData[column.nameCamel] = defaultValue

    const [data, setData] = useState<DataPayload>(defaultData)
    const [formErrors, setFormErrors] = useState<{[key :string] :string }>({})
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const onChange: FieldOnChange = (name: string, value: string | File | null) => {
        const updated :DataPayload = {...data}
        if (value !== null) {
            updated[name] = value
        } else {
            delete updated[name]
        }
        setData(updated)
    }

    const onSubmit = async () => {
        const responseNav :Nav = await NavClient.action(cellNav, actionType, data)
        const error = responseNav.error
        if (error) {
            setFormErrors({...error.fieldErrors})
            setErrorMessage(error.message)
        }
    }

    return <Panel>
        { (form && form.fields.map(field => {
            const { name } = field
            return <NavField key={name} nav={cellNav} value={data[name]} error={formErrors[name]} field={field} onChange={onChange}/>
        })) || []}
        { errorMessage && <><Spacer size={'m'}/><Callout title={errorMessage} color={'danger'} size={'s'} iconType={'faceSad'}/></> }
        <Spacer size={'m'}/>
        <FlexGroup justifyContent={'spaceBetween'}>
            <FlexItem></FlexItem>
            <FlexItem><Button size={'s'} fill onClick={onSubmit}>{MappingUtils.buttonTitle(actionType)}</Button></FlexItem>
        </FlexGroup>
    </Panel>
}

export default CellContextMenuDataContent

