import { ComponentEuiButtonEmpty } from 'Component'
import { FORMAT_PAGE_URLS } from 'Format'
import { useNavigate } from 'react-router-dom'

const ModuleUserPasswordResetRequest = () => {

    const navigate = useNavigate()

    const onClick = () => navigate(FORMAT_PAGE_URLS.userPasswordReset)

    return <ComponentEuiButtonEmpty onClick={onClick} color={'danger'} >{ 'Request password reset' }</ComponentEuiButtonEmpty>
}

export default ModuleUserPasswordResetRequest