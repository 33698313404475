import { TypeHttpPayload } from 'Type'

type Props = {
    entity? :any
    formPayload :TypeHttpPayload
}

const HookHttpActionPayloadBuild = ({entity, formPayload} :Props) :TypeHttpPayload => {
    const payload :TypeHttpPayload = {}
    Object.keys(entity ?? {}).forEach((k :string) => {
        if (k in entity && entity[k] !== null) payload[k] = `${entity[k]}`
    })
    Object.keys(formPayload).forEach((k :string) => {
        if (k in formPayload && formPayload[k] !== null) payload[k] = `${formPayload[k]}`
        if (formPayload[k] === null) payload[k] = null
    })
    return payload
}

export default HookHttpActionPayloadBuild