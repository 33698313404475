import { EuiCallOut } from '@elastic/eui'
import { ReactNode } from 'react'
import { TypeEuiPropsIconType } from 'Type'

type Props = {
    title? :ReactNode
    iconType? :TypeEuiPropsIconType
    color? :'primary' | 'success' | 'warning' | 'danger'
    size? :'s' | 'm'
    heading? :'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
    children? :ReactNode
}

const ComponentEuiCallout = ({title, iconType, color, size, heading, children} :Props) =>
    <EuiCallOut title={title} iconType={iconType} color={color} size={size} heading={heading}>{children}</EuiCallOut>

export default ComponentEuiCallout