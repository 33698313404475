import {DocumentationContentItem, DocumentationHash} from 'types';

const DocumentationContentConfig :Record<DocumentationHash, DocumentationContentItem> = {
    '#identity' : {
        title: 'Identity',
        introParagraphs: [],
        items: ['#identity-api']
    },
    '#identity-api' : {
        title: 'API',
        introParagraphs: []
    },
    '#collaboration' : {
        title: 'Collaboration',
        introParagraphs: [],
        items: ['#collaboration-org', '#collaboration-ws']
    },
    '#collaboration-org' : {
        title: 'Organisation',
        introParagraphs: []
    },
    '#collaboration-ws' : {
        title: 'Workspace',
        introParagraphs: ['Workspaces allow you to organise and collaborate on your data and assets.'],
        storyLaneVideoId: '8vz77hhbatfv'

    },
    '#table' : {
        title: 'Table',
        introParagraphs: [],
        items: ['#table-version', '#table-column', '#table-row', '#table-cell']
    },
    '#table-version' : {
        title: 'Version',
        introParagraphs: []
    },
    '#table-column' : {
        title: 'Column',
        introParagraphs: []
    },
    '#table-row' : {
        title: 'Row',
        introParagraphs: []
    },
    '#table-cell' : {
        title: 'Cell',
        introParagraphs: []
    },
    '#extract' : {
        title: 'Excel Extract',
        introParagraphs: []
    },
    '#transform' : {
        title: 'Transform',
        introParagraphs: []
    },
}


export default DocumentationContentConfig
