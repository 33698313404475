import {EmailConfirmationPage, PasswordResetPage, UserPage } from 'pages';
import { FinderNode } from 'types';
import Workspace from '../Workspace/Workspace';

const UserProfile :FinderNode = {
    render: () => <UserPage/>,
    next: {
        'ws' : {
            render: () => <UserPage tab={'ws'}/>,
            next: {'*' : Workspace}
        },
        'org' : {
            render: () => <UserPage tab={'org'}/>,
            next: {}
        },
        'api' : {
            render: () => <UserPage tab={'api'}/>,
            next: {}
        },
        'password-reset' : {
            next: {
                '*': {
                    render: () => <PasswordResetPage/>,
                    next: {}
                }
            }
        },
        'email-confirmation' : {
            next: {
                '*': {
                    render: () => <EmailConfirmationPage/>,
                    next: {}
                }
            }
        }
    }
}

const User :FinderNode = {
    next: {'*' : UserProfile}
}

export default User;