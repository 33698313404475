import { ComponentEuiTab } from 'Component'
import { HookNavNavigateEntity } from 'Hook'
import { TypeEntityExtractSheet } from 'Type'

type Props = {
    sheet: TypeEntityExtractSheet
    active :TypeEntityExtractSheet
}

const ModuleRowSheetTab = ({ sheet, active } :Props) => {

    const onClick :() => void = HookNavNavigateEntity({entity: sheet, resource: 'data'})

    return <ComponentEuiTab onClick={onClick} isSelected={sheet.linkTo === active?.linkTo}>{ sheet.name }</ComponentEuiTab>
}

export default ModuleRowSheetTab