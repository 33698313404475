import { HookNavParam } from 'Hook'
import { useEffect } from 'react'
import { TypeControlParam, TypeControlParamSimple, TypeNav, TypeUrlParam, TypeUrlParamType } from 'Type'
import { UtilParam } from 'Util'

type Props = {
    type :TypeUrlParamType
    onChange? :(path :string) => void
    nav? :TypeNav<any>
    defaultPageValue? :boolean
}

const HookNavParamSimple = ({ type, nav, onChange, defaultPageValue } :Props) :TypeControlParamSimple => {

    const { params, set } :TypeControlParam = HookNavParam({ type, nav, onChange })

    const param :TypeUrlParam | undefined = params[0]

    const value :string | undefined = param?.values[0]

    useEffect(() => {
        if (defaultPageValue && value) UtilParam.defaultPageParam({type, values: [value]})
    }, [value, type, defaultPageValue])

    const setValue = (newValue :string) => set([newValue])

    return [value, setValue]
}

export default HookNavParamSimple