import {FlexGroup, FlexItem, Link, PageSection, Spacer } from 'eui'
import { Logo } from 'modules'
import { useNavigate } from 'react-router-dom'

const HomePageFooterSection = () => {

    const navigate = useNavigate()

    return <PageSection paddingSize={'xl'} style={{zIndex: 2, backgroundColor: '#17191D', color: '#fff'}}>
        <FlexGroup justifyContent={'spaceBetween'} gutterSize={'xl'}>
            <FlexItem grow>
                <Logo/>
            </FlexItem>
            <FlexItem grow>
                <FlexGroup gutterSize={'xl'} justifyContent={'spaceBetween'}>
                    <FlexItem>
                        <div style={{color: '#666', fontWeight: 500}}>Product</div>
                        <Spacer/>
                        <Link color={'ghost'} href={'/documentation'}>Documentation</Link>
                    </FlexItem>
                    <FlexItem>
                        <div style={{color: '#666', fontWeight: 500}}>General</div>
                        <Spacer/>
                        <Link color={'ghost'} href={'/about'}>About</Link>
                    </FlexItem>
                    <FlexItem>
                        <div style={{color: '#666', fontWeight: 500}}>Legal</div>
                        <Spacer/>
                        <Link color={'ghost'} href={'/privacy'}>Privacy</Link>
                    </FlexItem>
                </FlexGroup>
            </FlexItem>
        </FlexGroup>
        <Spacer size={'xxl'}/>
        <Spacer size={'xxl'}/>
        <div style={{color: '#666', fontSize: '12px'}}>
            <FlexGroup justifyContent={'spaceBetween'}>
                <FlexItem>
                    {`© ${new Date().getFullYear()} Morph Cloud.`}
                </FlexItem>
                <FlexItem>
                    {'All rights reserved'}
                </FlexItem>
            </FlexGroup>
        </div>
    </PageSection>
}


export default HomePageFooterSection