import {ComponentEuiHeaderLink, ComponentEuiHeaderSectionItem } from 'Component'
import { GlobalViewNav } from 'Global'
import {string} from "prop-types";
import {useNavigate} from "react-router-dom";

type Props = {
    link :string
    title :string
}

const ModulePageHeaderItemLink = ({ link, title } :Props) => {
    const navigate = useNavigate()
    const onClick = () => navigate(link)

    return <ComponentEuiHeaderSectionItem>
        <ComponentEuiHeaderLink onClick={onClick}>{title}</ComponentEuiHeaderLink>
    </ComponentEuiHeaderSectionItem>
}

export default ModulePageHeaderItemLink