import {Header, HeaderLogo, HeaderSection, HeaderSectionItem} from 'eui'
import React from 'react'

import Breadcrumbs from './Breadcrumbs/Breadcrumbs'
import Toggle from './Navigator/Toggle/Toggle'
import Login from './Session/Login/Login'
import SignUp from './Session/SignUp/SignUp'
import User from './Session/User/User'

import { useLocation } from 'react-router-dom'
import { useIsLocationHome } from 'hooks'
import { Logo } from 'modules'
import { RouteUtils } from 'utils'

const HeaderBar = () => {

    const location = useLocation()

    const breadcrumbs :boolean = !useIsLocationHome() && !RouteUtils.isStatic(location.pathname)

    return <>
        <Header dark>
            <HeaderSection grow={false}>
                <HeaderSectionItem>
                    <Logo/>
                </HeaderSectionItem>
            </HeaderSection>
            <HeaderSection grow={false}>
                <Toggle/>
                <Login/>
                <SignUp/>
                <User/>
            </HeaderSection>
        </Header>
        { breadcrumbs && <Header top={48}>
            <HeaderSection grow={true}>
                <HeaderSectionItem>
                    <Breadcrumbs/>
                </HeaderSectionItem>
            </HeaderSection>
        </Header> }
    </>
}


export default HeaderBar