import { TablePage, RowPage } from 'pages';
import { FinderNode } from 'types';
import Extract from '../Extract/Extract';
import {ColumnPage} from "../../index";

const Table :FinderNode = {
    render: () => <TablePage/>,
    next: {
        'version': {
            next: {'*' : {
                    next: {'row' : {
                            render: () => <TablePage/>,
                            next: {
                                '*' : {
                                    render: () => <RowPage/>,
                                    next: {
                                        'cell' : {
                                            next : {
                                                '*': {
                                                    next : {
                                                        'extract': Extract
                                                    }
                                                }
                                            }
                                        },
                                        'child' : {
                                            next : {
                                                '*' : {
                                                    next: {
                                                        'row' : {
                                                            render: () => <RowPage/>,
                                                            next : {

                                                            }
                                                        },
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        'column' : {
                            render: () => <ColumnPage/>,
                            next : {

                            }
                        },
                    },
                }
            }
        }
    }
}

export default Table;