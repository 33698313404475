import {ComponentDivMarginHorizontal, ComponentDivMarginVertical, ComponentNavMetaPageSectionHeader } from 'Component'
import { HookModuleRowChild, HookNavContextOptional } from 'Hook'
import { ModulePageLoading, ModuleRowChildTabs, ModuleTableDataTable } from 'Module'
import { TypeEntityChild, TypeEntityRow, TypeHttpLoadable, TypeModuleTableDataProps, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    pageControl :TypeNavHttpControl<TypeEntityRow>
    childTableProps :TypeModuleTableDataProps
}

const PageRowBodyChild = ({ pageControl, childTableProps } :Props) => {

    const pageNav :TypeNav<TypeEntityRow> | undefined = pageControl.res?.data
    const childContext :TypeEntityChild | undefined = HookNavContextOptional('child', pageNav)

    const [childResourceControl, toLoadChild] :[TypeNavHttpControl<TypeEntityChild>, TypeHttpLoadable[]] = HookModuleRowChild(pageNav)
    const childTables :TypeEntityChild[] = childResourceControl.res?.data?.data.page?.content ?? []

    const isChildLoaded :boolean = Boolean(Object.keys(childTableProps.fieldNameMap).length > 0 && childTables.length)

    if (!childContext || !isChildLoaded) return null

    const toLoad :TypeHttpLoadable[] = [...childTableProps.toLoad, ...toLoadChild]

    return <ModulePageLoading toLoad={toLoad} onMisaligned>
      <ComponentNavMetaPageSectionHeader title={'Related Data'} compact/>
      <ModuleRowChildTabs active={childContext} tables={childTables}/>
        <ComponentDivMarginHorizontal margin={-1}>
          <ComponentDivMarginVertical margin={-1}>
              {<ModuleTableDataTable {...childTableProps}/> }
          </ComponentDivMarginVertical>
        </ComponentDivMarginHorizontal>
    </ModulePageLoading>
}

export default PageRowBodyChild