import { ComponentPageSidebarEntity } from 'Component'
import { ReactNode } from 'react'
import {TypeEntityLinkTo, TypeNav, TypeNavKey } from 'Type'
import { UtilObject } from 'Util'

type Props = {
    title :string
    navKey :TypeNavKey
    nameFields :string[]
    nav? :TypeNav<any>
    right? :ReactNode
    hrefColumn? :string
}

const linkTo :(keyof TypeEntityLinkTo) = 'linkTo'

const ComponentNavSidebarContextEntity = ({ title, navKey, nameFields, nav, right, hrefColumn } :Props) => {
    const entity :any = nav && nav.context[navKey] as any
    if (!entity) return null
    const name :string = UtilObject.getFields(entity, nameFields).join(' ')
    const href :string = (hrefColumn && entity[hrefColumn]) ? entity[hrefColumn] : entity[linkTo]
    return <ComponentPageSidebarEntity title={title} name={name} href={href} right={right}/>
}

export default ComponentNavSidebarContextEntity