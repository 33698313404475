import {EuiHeaderSectionItem} from '@elastic/eui'
import React, {PropsWithChildren} from 'react'

type Props = {
    border?: 'left' | 'right' | 'none'
}

const ComponentEuiHeaderSectionItem = ({ border, children }: PropsWithChildren<Props>) =>
    <EuiHeaderSectionItem border={border}>{children}</EuiHeaderSectionItem>

export default ComponentEuiHeaderSectionItem