import { Icon, LoadingSpinner } from 'eui'
import { useIsLocationHome } from 'hooks'
import { useState } from 'react'
import {Link, useNavigate } from 'react-router-dom'
import { NavStore } from 'store'
import { Nav } from 'types'
import { RouteUtils } from 'utils'

type Props = {
    linkTo? :string
    onClick? :() => void
}

const BackButton = ({linkTo, onClick} :Props) => {

    const nav :Nav | undefined = NavStore(store => store.nav)
    if (useIsLocationHome() || !nav) return null
    if (onClick) return <span onClick={onClick} style={{color: '#98A2B3', cursor: 'pointer'}}><Icon type={'arrowLeft'} size={'s'}/>   Back  </span>
    return <Link to={linkTo || RouteUtils.back(nav && nav.path || '')}><span style={{color: '#98A2B3', cursor: 'pointer'}}><Icon type={'arrowLeft'} size={'s'}/>   Back  </span></Link>
}

export default BackButton