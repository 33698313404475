import { DisplayConfig } from 'types';

const config :DisplayConfig[] = [
    {
        field: 'isPublic',
        name: 'Public',
        sortable: true,
        render: (value :any) => {
            return (value && `${value}` === 'true') ? 'Yes' : 'No'
        }
    },
]

export default config