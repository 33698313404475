import React, {MouseEventHandler} from 'react';
import {EuiButtonEmpty} from '@elastic/eui';

type Props = {
    style? :any
    type?: string
    fill?: boolean
    size?: 'xs' | 's' | 'm'
    isDisabled? :boolean
    color?: 'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    iconSide?: 'left' | 'right'
    iconType?: string
    onClick: MouseEventHandler
    children?: React.ReactNode
};

export default ({style, fill = false, onClick, size, color, iconSide, iconType, isDisabled, children}: Props) => {
    return <EuiButtonEmpty style={style} onClick={onClick} size={size} color={color} iconSide={iconSide}
                    iconType={iconType} isDisabled={isDisabled}>{children}</EuiButtonEmpty>
}