import {useNavigate} from "react-router-dom";
import {CollapsibleLeftNavBar, CollapsibleSideBarItem} from "../../components";
import {ResponderEntity} from "../../entity";
import {FlexGroup, FlexItem, Icon, SideNav, Spacer} from "../../eui";
import {NavStore} from "../../store";
import {Nav, NavControlResource, NavKey, SideNavItem} from "../../types";

const responderKey :NavKey = 'responder'
const testKey :NavKey = 'test'
const urlKey :NavKey = 'url'
const mapKey :NavKey = 'map'

const buildSideNavItem = (resource :NavControlResource, navigate :(linkTo :string) => void, tab? :NavKey, rightArrow? :boolean) :SideNavItem => {
    return {
        id: resource.key,
        name: <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}><FlexItem grow>{resource.meta.title}</FlexItem><FlexItem>{(rightArrow) ? <Icon size={'s'} type={'arrowRight'}/> : null}</FlexItem></FlexGroup>,
        onClick: () => navigate(resource.linkTo),
        isSelected: (resource.key === tab)
    }
}

type Props = {
    tab? :NavKey
}

const ResponderPageLeftNavBar = ({tab} :Props) => {

    const navigate = useNavigate()

    const resourceNav :Nav | undefined = NavStore(store => store.contextNavs[responderKey])
    const resourceEntity :ResponderEntity | undefined = NavStore(store => store.nav?.context[responderKey])

    const testResource :NavControlResource | undefined = resourceNav?.control.resource[testKey]
    const urlResource :NavControlResource | undefined = resourceNav?.control.resource[urlKey]
    const mapResource :NavControlResource | undefined = resourceNav?.control.resource[mapKey]

    if (!resourceEntity) return null

    const responderItems :SideNavItem[] = [
        {
            id: 'details',
            name: 'Details',
            onClick: () => navigate(resourceEntity.linkTo),
            isSelected: !tab
        }
    ]

    if (urlResource) responderItems.push(buildSideNavItem(urlResource, navigate, tab))
    if (mapResource) responderItems.push(buildSideNavItem(mapResource, navigate, tab))
    if (testResource) responderItems.push(buildSideNavItem(testResource, navigate, tab))

    const responderItem :SideNavItem = {
        id: 'responder',
        name: resourceEntity.name,
        items: responderItems,
        forceOpen: true
    }

    const workspaceItem :SideNavItem = {
        id: 'workspace',
        name: 'Workspace',
        items: [{
            id: resourceEntity.linkTo,
            name: <FlexGroup justifyContent={'spaceBetween'} alignItems={'baseline'}><FlexItem grow>{resourceEntity.wsName}</FlexItem></FlexGroup>,
            onClick: () => navigate(resourceEntity.ownerLinkTo),
            isSelected: false
        }],
        forceOpen: true
    }

    const rootItem :SideNavItem = {
        id: 'title',
        name: 'Responder',
        items: [responderItem]
    }

    return <CollapsibleLeftNavBar expandedWidth={320} collapsedWidth={0} darker>
        <Spacer size={'s'}/>
        <CollapsibleSideBarItem gutter={0}>
            <SideNav
                items={[workspaceItem, rootItem]}
            />
        </CollapsibleSideBarItem>
    </CollapsibleLeftNavBar>
}

export default ResponderPageLeftNavBar