import { EuiPageBody } from '@elastic/eui'
import { Children } from 'props'

type Props = Children & {
    restrictWidth? :string | number | boolean
    panelled? :boolean
    paddingSize? :'s' | 'none' | 'm' | 'xs' | 'l' | 'xl'
}

const PageBody = ({restrictWidth, panelled, paddingSize, children} :Props) =>
    <EuiPageBody restrictWidth={restrictWidth} panelled={panelled} paddingSize={paddingSize}>{children}</EuiPageBody>

export default PageBody