import {useEffect, useState} from 'react'
import { AxiosRequestHeaders } from 'axios'

import {TypeControlSwitch, TypeHttpControl, TypeHttpMethod, TypeHttpPayload,
    TypeHttpRequestControl, TypeHttpResponse, TypeUrlParam } from 'Type'
import {HookControlSwitch, HookHttpUrl, HookNavHttpHeaders } from 'Hook'
import { UtilHttpClient, UtilRoute } from 'Util'

type Props<Res> = {
    path? :string,
    method :TypeHttpMethod
    payload? :TypeHttpPayload
    paramUpdates? :TypeUrlParam[]
}

const HookHttpClient = <Res>({path, method, payload, paramUpdates} :Props<Res>) :TypeHttpControl<Res> => {

    const [resControl, setRequestControl] = useState<TypeHttpRequestControl<Res> | undefined>();
    const loadingControl :TypeControlSwitch = HookControlSwitch()
    const [res, setRes] = useState<TypeHttpResponse<Res> | undefined>()

    const { url } = HookHttpUrl(path, paramUpdates)

    const headers :AxiosRequestHeaders = HookNavHttpHeaders()

    const submit = async () => {
        if (!url) throw new Error('Url not present')
        if (resControl) resControl.abort.abort()
        loadingControl.turnOn()
        const newResControl :TypeHttpRequestControl<Res> = UtilHttpClient.request(url, method, headers, payload)
        setRequestControl(newResControl)
        const newRes :TypeHttpResponse<Res> = await newResControl.promise
        if (!newResControl.abort.signal.aborted) setRes(newRes)
        loadingControl.turnOff()
        return newRes
    }

    useEffect(() => {return () => resControl && resControl.abort.abort()}, [resControl])

    const isLoading :boolean = loadingControl.value
    const isMisaligned :boolean = Boolean(url && res?.url && !UtilRoute.pathMatch(url, res.url))
    return { res, isLoading, isMisaligned, submit, url }
}

export default HookHttpClient