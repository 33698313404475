import { EuiPage } from '@elastic/eui'
import {CSSProperties, PropsWithChildren} from 'react'

type Props = {
    height? :number
    paddingSize? :'none' | 'xs' | 's' | 'm' | 'l' | 'xl'
    grow? :boolean
    direction? :'row' | 'column'
    restrictWidth? :string | number | boolean
}

const ComponentEuiPage = ({height, paddingSize, grow, direction, restrictWidth, children} :PropsWithChildren<Props>) => {
    const style :CSSProperties = {}
    if (height) style.height = `${height}px`
    return <EuiPage style={style} paddingSize={paddingSize} grow={grow} direction={direction}
             restrictWidth={restrictWidth}>{children}</EuiPage>
}

export default ComponentEuiPage