import { NavActionModal } from 'components'
import { Button } from 'eui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {DataPayload, Nav, NavControlAction, NavControlActionType, NavKey } from 'types'
import {MappingUtils, StringUtils } from 'utils'

type TypeButtonProps = {
    color :'primary' | 'success' | 'warning' | 'danger' | 'ghost' | 'text'
    iconType? :string
    isDisabled? :boolean
    onClick: () => void
}

const propsByType = (action: NavControlAction, buttonSize? :'s' | 'm') :TypeButtonProps => {

    const props :any = {
        isDisabled: !action.auth.authorized,
        size: buttonSize
    }

    switch (action.action) {
        case 'put' : return {...props, color: 'primary', iconType: 'documentEdit'}
        case 'post' : return {...props, color: 'primary', iconType: 'plusInCircle'}
        case 'delete' :return {...props, color: 'danger', iconType: 'alert'}
    }
    return {...props, color: 'text'}
}

const textByType = (type :NavControlActionType, contextKey :NavKey) :string => {
    switch (type) {
        case 'post' : return `New ${MappingUtils.contextTitle(contextKey)}`
        case 'put' : return `Update ${MappingUtils.contextTitle(contextKey)}`
    }
    return StringUtils.capitalizeFirst(type)
}

type Props = {
    nav :Nav
    action :NavControlAction
    tabKey :NavKey
    onSuccess? :() => void
}

const SideBarActionModal = ({nav, action, tabKey, onSuccess} :Props) => {

    const navigate = useNavigate()

    const [open, setOpen] = useState<boolean>()

    const title = textByType(action.action, tabKey)

    const buttonProps :TypeButtonProps = propsByType(action, 's')

    const innerOnSuccess = (response: Nav, req? :DataPayload) => {
        setOpen(false)
        if (onSuccess) onSuccess()
        if (response.data.entity) navigate(response.data.entity['linkTo'] || response.path)
    }

    return <>
        <Button {...buttonProps} fullWidth onClick={() => setOpen(true)}>{title}</Button>
        {open && <NavActionModal nav={nav} actionType={action.action} onClose={() => setOpen(false)} title={title} onSuccess={innerOnSuccess}/>}
    </>

}

export default SideBarActionModal