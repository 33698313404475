import { ComponentDivCenter, ComponentEuiEmptyPrompt } from 'Component'

type Props = {
    title? :string
}

const ComponentPageErrorPrompt = ({title} :Props) => {
    return <ComponentDivCenter>
        <ComponentEuiEmptyPrompt color={'danger'} iconType={'cloudDrizzle'} title={<h2>{title || 'Unknown Error'}</h2>} body={<p>Something has gone wrong.</p>}/>
    </ComponentDivCenter>
}

export default ComponentPageErrorPrompt