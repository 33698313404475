import { ComponentDivCenter, ComponentNavActionPanel } from 'Component'
import { HookNavPage } from 'Hook'
import { ModulePage } from 'Module'
import { useNavigate } from 'react-router-dom'
import { TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'
import {FORMAT_PAGE_URLS} from 'Format'

const PagePasswordNew = () => {

    const navigate = useNavigate()

    const pageControl :TypeNavHttpControl<any> = HookNavPage<any>()
    const pageNav :TypeNav<any> | undefined = pageControl.res?.data

    const toLoad :TypeHttpLoadable[] = [
        {label: 'New Password', control: pageControl}
    ]

    const onSuccess = async () => navigate(FORMAT_PAGE_URLS.userLogin)

    return <ModulePage toLoad={toLoad} control={pageControl}>
        <ComponentDivCenter>
            { pageNav && <ComponentNavActionPanel title={'Update Password'} isTitleVerb nav={pageNav} actionType={'patch'} onSuccess={onSuccess}/> }
        </ComponentDivCenter>
    </ModulePage>
}

export default PagePasswordNew