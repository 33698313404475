import { HookNavContextNav, HookNavContextOptional, HookNavNavigateEntity, HookNavResourceNavAll } from 'Hook'
import { useEffect } from 'react'
import { TypeEntityChild, TypeEntityRow, TypeHttpLoadable, TypeNav, TypeNavHttpControl } from 'Type'
import { UtilRoute } from 'Util'

const HookModuleRowChild = (pageNav? :TypeNav<TypeEntityRow>) :[TypeNavHttpControl<TypeEntityChild>, TypeHttpLoadable[]] => {

    const childContext :TypeEntityChild | undefined = HookNavContextOptional('child', pageNav)

    const childNotPresent :boolean = Boolean(pageNav && !childContext)

    const rowControl :TypeNavHttpControl<TypeEntityRow> = HookNavContextNav('row', pageNav)
    const rowEntityNav :TypeNav<TypeEntityRow> | undefined = rowControl.res?.data
    const childResourceControl :TypeNavHttpControl<TypeEntityChild> = HookNavResourceNavAll({ key: 'child', nav: rowEntityNav, sortDirection: 'asc'})
    const childTables :TypeEntityChild[] = childResourceControl.res?.data?.data.page?.content ?? []
    const firstChild :TypeEntityChild | undefined = (childTables.length) ? childTables[0] : undefined
    const navigateToFirstChild = HookNavNavigateEntity({entity: firstChild, resource: 'row'})
    const isStateAligned :boolean = Boolean(firstChild && pageNav && UtilRoute.pathContains(firstChild.linkTo, pageNav?.path))

    useEffect(() => {
        if (firstChild && childNotPresent && isStateAligned) {
            navigateToFirstChild()
        }
    }, [firstChild, childNotPresent])

    const toLoad :TypeHttpLoadable[] = [
        { label: 'Row', control: rowControl },
        { label: 'Related Tables', control: childResourceControl },
    ]

    return [childResourceControl, toLoad]
}

export default HookModuleRowChild