import {FlexGroup, FlexItem, Link, PageSection, Spacer} from 'eui';
import {PageTitle} from 'components';
import {useNavigate} from 'react-router-dom';
import {Nav, NavKey} from 'types';
import {NavStore} from 'store';
import {TableEntity, TableVersionEntity} from 'entity';
import {GenericActionButtons} from 'modules';

const tableKey :NavKey = 'table'
const versionKey :NavKey = 'version'
const rowKey :NavKey = 'row'

const RowContentHeader = () => {

    const navigate = useNavigate()

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const tableEntity :TableEntity | undefined = pageNav?.context[tableKey]
    const versionEntity :TableVersionEntity | undefined = pageNav?.context[versionKey]
    const versionLinkTo :string | undefined = versionEntity?.linkTo
    const rowEntity :any | undefined = pageNav?.context[rowKey]
    const rowNav :Nav | undefined = NavStore(store => store.contextNavs[rowKey])

    const onSuccessDelete = () => {
        if (tableEntity) navigate(tableEntity.linkTo)
    }

    if (!tableEntity || !rowNav || !rowEntity) return null

    return <PageSection paddingSize={'none'} xPadding={24} bottomBorder={'extended'}>
        <FlexGroup alignItems={'baseline'}>
            <FlexItem>
                <PageTitle>
                    <Link href={versionLinkTo} color={'text'} >{tableEntity.name}</Link>
                    <span style={{fontWeight: 500}}>{`, row ${rowEntity?.rowId}`}</span>
                </PageTitle>
            </FlexItem>
            <FlexItem>
                {rowNav && <GenericActionButtons nav={rowNav} contextKey={'user'} onSuccess={onSuccessDelete} toggleIcon={'gear'} buttonSize={'s'}/>}
            </FlexItem>
        </FlexGroup>
        <FlexGroup>
            <FlexItem>
                <p style={{color: '#69707D'}}>{}</p>
                <Spacer/>
            </FlexItem>
        </FlexGroup>
    </PageSection>
}

export default RowContentHeader