import { ComponentNavDataPageTableModal } from 'Component'
import { HookModuleTableDataCellNav, HookNavResource } from 'Hook'
import {ModuleTableCellExtractButton, ModuleTableCellImagePreview } from 'Module'
import {FC, ReactNode} from 'react'
import { RecordTableDataCellDisplay } from 'Record'
import {
    TypeEntityCell, TypeEntityCellArchive,
    TypeEntityRow, TypeEntityTableVersionColumn, TypeFieldDisplay, TypeModuleTableDataCellProps, TypeNav,
    TypeNavControlResource, TypeNavHttpControl } from 'Type'

type Props = {
    initialNav :TypeNav<TypeEntityCellArchive>
    onClose :() => void
    column :TypeEntityTableVersionColumn
    row :TypeEntityRow
}

const value :(keyof TypeEntityCellArchive) = 'value'

const ModuleTableCellArchiveModal = ({ initialNav, column, row, onClose } :Props) => {

    const cellControl :TypeNavHttpControl<TypeEntityCell> = HookModuleTableDataCellNav({row, column})
    const cellNav :TypeNav<TypeEntityCell> | undefined = cellControl.res?.data
    const extractResource :TypeNavControlResource | undefined = HookNavResource('extract', cellNav)
    const isExtractable :boolean = extractResource?.auth.authorized ?? false

    const isPreviewable :boolean = column.type === 'image'

    const customButtons :(entityNav :TypeNav<TypeEntityCellArchive>) => ReactNode[] = (entityNav :TypeNav<TypeEntityCellArchive>) => {
        const arr :ReactNode[] = []
        const archiveCellEntity :TypeEntityCellArchive | undefined = entityNav.data.entity
        if (isExtractable && cellNav  && archiveCellEntity) arr.push(<ModuleTableCellExtractButton entity={archiveCellEntity} cellNav={cellNav}/>)
        if (isPreviewable) arr.push(<ModuleTableCellImagePreview nav={entityNav}/>)
        return arr
    }

    const DisplayCellValue :FC<TypeModuleTableDataCellProps> = RecordTableDataCellDisplay[column.type]

    const customRenders : { [field: string]: TypeFieldDisplay } = {}
    customRenders[value] = {
        column: () => ({
            field: value as string,
            name: 'Value',
            render :(value: any) => <DisplayCellValue value={value} column={column} row={row}/>
        })
    }

    return initialNav && <ComponentNavDataPageTableModal initialNav={initialNav} entityName={'Archive'} onClose={onClose} showColumns={['versionNumber', 'value']} action={'menu'} customButtons={customButtons} customRenders={customRenders}/>
}

export default ModuleTableCellArchiveModal