import { EuiText } from '@elastic/eui'
import { ReactNode } from 'react'


type Props = {
    textAlign? :'left' | 'right' | 'center'
    size? :'xs' | 's' | 'm' | 'relative'
    color? :string | 'default' | 'accent' | 'success' | 'warning' | 'danger' | 'ghost' | 'subdued'
    grow? :boolean
    children? :ReactNode
}

const ComponentEuiText = ({textAlign, size, color, grow, children} :Props) =>
    <EuiText textAlign={textAlign} size={size} color={color} grow={grow}>{ children }</EuiText>

export default ComponentEuiText