import {HookNavGet, HookNavPage, HookNavResourceNav } from 'Hook'
import { ModulePage } from 'Module'
import {PageApiBody, PageApiSidebar } from 'Page'
import {TypeEntityApi, TypeEntityWorkspace, TypeHttpLoadable, TypeNav, TypeNavHttpControl, TypeNavKey } from 'Type'

const apiKey :TypeNavKey = 'api'

const PageApi = () => {

    //Workspace
    const wsControl :TypeNavHttpControl<TypeEntityWorkspace> = HookNavPage<TypeEntityWorkspace>()
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    //Api
    const apiEntity :TypeEntityApi | undefined = wsNav?.context[apiKey] as TypeEntityApi
    const apiControl :TypeNavHttpControl<TypeEntityApi> = HookNavGet<TypeEntityApi>({linkTo: apiEntity?.linkTo})
    const apiNav :TypeNav<TypeEntityApi> | undefined = apiControl.res?.data

    //Secret
    const secretControl :TypeNavHttpControl<TypeEntityApi> = HookNavResourceNav<TypeEntityApi>({key: 'secret', nav: apiNav})
    const secretNav :TypeNav<TypeEntityApi> | undefined = secretControl.res?.data

    const toLoad :TypeHttpLoadable[] = [
        {label: 'Organisation', control: wsControl},
        {label: 'Workspaces', control: wsControl},
    ]

    const sidebar = <PageApiSidebar apiEntity={apiEntity} secretNav={secretNav}/>
    const body = <PageApiBody wsControl={wsControl}/>

    return <ModulePage control={wsControl} sidebarContent={sidebar} sidebarPaddingSize={'m'} toLoad={toLoad} paginationControl={wsControl}>{body}</ModulePage>
}

export default PageApi