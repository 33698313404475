import React from 'react'
import {EuiModalFooter} from '@elastic/eui'

type Props = {
    children?: React.ReactNode
}

const ModalFooter = ({children}: Props) =>
    <EuiModalFooter>{children}</EuiModalFooter>

export default ModalFooter