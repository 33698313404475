import {
    ComponentEuiLoadingSpinner, ComponentNavActionModalButton, ComponentNavDataPageTable, ComponentNavMetaPageSectionHeader } from 'Component'
import {TypeEntityWorkspace, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    wsControl :TypeNavHttpControl<TypeEntityWorkspace>
}

const PageOrganisationBody = ({ wsControl } :Props) => {
    const wsNav :TypeNav<TypeEntityWorkspace> | undefined = wsControl.res?.data

    const newWsButton = wsNav && <ComponentNavActionModalButton nav={wsNav} entityName={'Workspace'} actionType={'post'} buttonType={'icon'} onSuccess={wsControl.submit} iconType={'plusInCircle'} color={'primary'} size={'m'} buttonTitle={'New Workspace'}/>

    return (wsNav) ? <>
        <ComponentNavMetaPageSectionHeader title={'Workspaces'} bottomBorder={'extended'} rightActions={newWsButton} compact/>
        <ComponentNavDataPageTable action={'navigate'} entityName={'Workspace'} paginate={false} initialNav={wsNav} showColumns={['name', 'isPublic', 'ownerGlobalId']}/>
    </> : <ComponentEuiLoadingSpinner/>
}

export default PageOrganisationBody