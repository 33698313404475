import {DataPayload, NavControlAction, NavControlActionFormField } from 'types'

const defaultValues = (action? :NavControlAction, initData? :DataPayload) :DataPayload => {
    const defaults :DataPayload = {}
    if (!action) return defaults
    if (action.form) {
        action.form.fields.reduce((defaults :DataPayload, field :NavControlActionFormField) :DataPayload => {
            if (initData && initData[field.name]) {
                defaults[field.name] = initData[field.name]
            } if (field.type === 'bool') {
                defaults[field.name] = 'false'
            }
            return defaults
        }, defaults)
    }
    return defaults
}

export default { defaultValues }