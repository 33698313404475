import create from 'zustand'

type Store = {
    isNav :boolean
    toggleNav :() => void
}

const IS_NAV_KEY :string = 'is-nav'

const getIsNav = () :boolean => {
    return (localStorage.getItem(IS_NAV_KEY) === 'true')
}

const toggleIsNav = () :boolean => {
    localStorage.setItem(IS_NAV_KEY, `${!getIsNav()}`)
    return getIsNav()
}

const GlobalViewNav = create<Store>((set, get) => ({
    isNav: getIsNav(),
    toggleNav: () => {
        set({isNav: toggleIsNav()})
    }
}))

export default GlobalViewNav