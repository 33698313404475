import { FlexItem, Icon } from 'eui'
import { ReactNode } from 'react'
import { CollapsedStore } from 'store'

import './FlexPageContent.css'

type Props = {
    leftBorder?: boolean
    children: ReactNode
    minWidth? :number
}


const FlexPageContent = ({children, leftBorder = true, minWidth} :Props) => {

    const isCollapsed :boolean = CollapsedStore(store => store.isCollapsed)
    const toggleCollapsed :() => void = CollapsedStore(store => store.toggleCollapsed)
    const style = (minWidth !== undefined) ? {minWidth: `${minWidth}px`} : {}

    return <FlexItem grow style={style}>
        <div style={{width: '100%', height: '100%', borderLeft: (leftBorder) ? '1px solid #D3DAE6' : ''}}>
            <div className={'flex-page-content-toggle'} onClick={toggleCollapsed}><Icon size={'s'} type={(isCollapsed) ? 'doubleArrowRight' : 'doubleArrowLeft'}/></div>
        {children}
        </div>
    </FlexItem>
}

export default FlexPageContent