import { ComponentDivWidth, ComponentNavActionPopoverPatch } from 'Component'
import { HookNavGet } from 'Hook'
import { TypeEntityTransformHeaderError, TypeNav, TypeNavHttpControl } from 'Type'

type Props = {
    value :string
    error? :TypeEntityTransformHeaderError
    onSuccess :() => Promise<void>
}

const ModuleTransformOutputTablePopoverCell = ({value, error, onSuccess} :Props) => {

    const navControl :TypeNavHttpControl<TypeEntityTransformHeaderError> = HookNavGet({linkTo: error})

    const nav :TypeNav<TypeEntityTransformHeaderError> | undefined = navControl.res?.data

    const innerOnSuccess = async () => {
        const promise :Promise<any> = navControl.submit()
        await onSuccess()
        await promise
    }

    if (!nav) return null

    return <ComponentDivWidth width={380}>
        <ComponentNavActionPopoverPatch entityName={'Fix Cell'} nav={nav} onSuccess={innerOnSuccess}/>
    </ComponentDivWidth>

}

export default ModuleTransformOutputTablePopoverCell