import {ComponentEuiFlexGroup, ComponentEuiFlexItem, ComponentEuiPageSection,
    ComponentEuiSpacer, ComponentNavMetaPageSectionHeader } from 'Component'
import { ModuleVersionColumn, ModuleVersionConnection, ModuleVersionRequesterButton, ModuleVersionRequesterMap } from 'Module'
import {
    TypeEntityConnection, TypeEntityInterfaceMap, TypeEntityRequester, TypeEntityTable,
    TypeEntityTableVersion, TypeEntityTableVersionColumn, TypeNav, TypeNavHttpControl, TypeNavHttpControlActionAuto } from 'Type'

type Props = {
    table :TypeEntityTable
    versionNav :TypeNav<TypeEntityTableVersion>
    columnControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
    refControl :TypeNavHttpControl<TypeEntityTableVersionColumn>
    connectionControl :TypeNavHttpControl<TypeEntityConnection>
    reqControl :TypeNavHttpControl<TypeEntityRequester>
    mapControl :TypeNavHttpControl<TypeEntityInterfaceMap>
    setIsConnectedOnly: (isConnectedOnly :boolean) => void
    isConnectedOnly :boolean
    req :TypeEntityRequester
    addMapControl :TypeNavHttpControlActionAuto<TypeEntityInterfaceMap>
}

const PageVersionBody = ({ table, columnControl, refControl, connectionControl, reqControl, mapControl, versionNav, setIsConnectedOnly, isConnectedOnly, req, addMapControl } :Props) => {

    return <>
        <ComponentNavMetaPageSectionHeader title={table.name} bottomBorder={'extended'} rightActions={<ModuleVersionRequesterButton reqControl={reqControl}/>}/>
        <ComponentEuiPageSection paddingSize={'s'}>
            <ComponentEuiFlexGroup gutterSize={'s'}>
                <ComponentEuiFlexItem grow={3}>
                    <ModuleVersionColumn columnControl={columnControl} refControl={refControl}/>
                </ComponentEuiFlexItem>
                {mapControl.res && <ComponentEuiFlexItem grow={2}>
                    <ModuleVersionConnection connectionControl={connectionControl} setIsConnectedOnly={setIsConnectedOnly} isConnectedOnly={isConnectedOnly} req={req} mapControl={mapControl} addMapControl={addMapControl}/>
                    <ComponentEuiSpacer size={'s'}/>
                    <ModuleVersionRequesterMap mapControl={mapControl} versionNav={versionNav} columnControl={columnControl}/>
                </ComponentEuiFlexItem>}
            </ComponentEuiFlexGroup>
        </ComponentEuiPageSection>
    </>
}

export default PageVersionBody