import { ComponentDivWidth, ComponentNavActionPopoverPatch } from 'Component'
import { TypeEntityTransform, TypeModuleControlRefresh, TypeNav } from 'Type'

type Props = {
    transformNav :TypeNav<TypeEntityTransform>
    refreshControl :TypeModuleControlRefresh
    onClose :() => void
}

const ModuleTransformTitlePopoverHeaderContent = ({transformNav, refreshControl, onClose} :Props) => {

    const innerOnSuccess = async () => {
        await refreshControl.headerRow()
        onClose()
    }

    return <ComponentDivWidth width={400}>
        <ComponentNavActionPopoverPatch entityName={'Set Header'} nav={transformNav} onSuccess={innerOnSuccess} onClose={onClose}/>
    </ComponentDivWidth>
}

export default ModuleTransformTitlePopoverHeaderContent