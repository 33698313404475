import { FlexPageContent } from 'components';
import { ExtractEntity, SheetEntity } from 'entity';
import {FlexGroup, Page, PageBody } from 'eui'
import {ExtractPageContent, ExtractPageLeftNavBar, UserPageLeftNavBar } from 'modules'
import {useEffect, useState } from 'react'
import {ExtractStore, NavStore } from 'store';
import { Nav, NavKey } from 'types';

const extractKey :NavKey = 'extract'
const sheetKey :NavKey = 'sheet'

const ExtractPage = () => {

    const pageNav :Nav | undefined = NavStore(store => store.nav)
    const load :(extract :ExtractEntity, newSheet? :SheetEntity) => void = ExtractStore(store => store.load)
    const clear :() => void = ExtractStore(store => store.clear)

    useEffect(() => {
        if (pageNav && pageNav.context[extractKey]) load(pageNav.context[extractKey], pageNav.context[sheetKey])
        return clear
    }, [pageNav])

    const [loadedSideBar, setLoadedSideBar] = useState<boolean>()

    return <Page>
        <PageBody paddingSize={'none'} panelled={true}>
            <FlexGroup gutterSize={'none'}>
                <UserPageLeftNavBar forceCollapsed={true} wsSelected/>
                <ExtractPageLeftNavBar callBack={() => setLoadedSideBar(true)}/>
                <FlexPageContent leftBorder={true} minWidth={0}>
                    {(loadedSideBar) ? <ExtractPageContent/> : null}
                </FlexPageContent>
            </FlexGroup>
        </PageBody>
    </Page>
}

export default ExtractPage