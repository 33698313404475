import { PageTitle, StaticPageParagraph } from 'components'
import { Page, PageBody, PageSection, Title, Text, Spacer } from 'eui'
import { StaticPage } from 'modules'
import {ReactNode} from 'react';

const AboutPage = () => {
    return <StaticPage>
        <PageSection restrictWidth bottomBorder={'extended'}>
            <Title><h1>About</h1></Title>
        </PageSection>
        <PageSection restrictWidth>
            <PageTitle>
                Alex Dabell
            </PageTitle>
            <Spacer/>
            <StaticPageParagraph>
                Alex is a full stack developer and the founder of Morph Cloud.
                Recently, Alex spent 5 years bulding software for the London specialty insurance market.
                Prior to that, he worked in the energy markets as a trader and quant and in the gambling industry as an entrepeneur and quant developer.
            </StaticPageParagraph>
        </PageSection>
        <PageSection restrictWidth>
            <PageTitle>
                Morph Cloud
            </PageTitle>
            <Spacer/>
            <StaticPageParagraph>
                A general purpose and flexible data management and integration platform with a mission to solve London specialty insurance's biggest challenge - efficiently handling and analyzing diverse exposure data.
            </StaticPageParagraph>
        </PageSection>
    </StaticPage>
}

export default AboutPage